import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';

import * as auth from '../redux/AuthRedux';
import { login } from '../services/AuthCRUD';

const initialValues = {
  email: '',
  password: '',
};

export function Login() {
  const intl = useIntl();
  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: 'login.invalidEmail' }))
      .required(intl.formatMessage({ id: 'login.emailRequired' })),
    password: Yup.string().required(
      intl.formatMessage({ id: 'login.passwordRequired' })
    ),
  });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        login(values.email, values.password)
          .then((user) => {
            setLoading(false);
            dispatch(auth.actions.login(user.data));
          })
          .catch(() => {
            setLoading(false);
            setSubmitting(false);
            setStatus(intl.formatMessage({ id: 'login.invalidCredentials' }));
          });
      }, 1000);
    },
  });

  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      <div className="text-center mb-10">
        <h1 className="text-dark mb-3">
          {intl.formatMessage({ id: 'login.title' })}
        </h1>
      </div>
      {formik.status ? (
        <div className="mb-lg-5 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      ) : (
        ''
      )}
      <div className="fv-row mb-10">
        <label className="form-label fs-6 fw-bolder text-dark">
          {intl.formatMessage({ id: 'login.emailLabel' })}
        </label>
        <input
          placeholder={intl.formatMessage({ id: 'login.emailPlaceholder' })}
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.email && formik.errors.email,
            },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type="email"
          name="email"
          autoComplete="off"
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <span role="alert">{formik.errors.email}</span>
          </div>
        )}
      </div>
      <div className="fv-row mb-10">
        <div className="d-flex justify-content-between mt-n5">
          <div className="d-flex flex-stack mb-2">
            <label className="form-label fw-bolder text-dark fs-6 mb-0">
              {intl.formatMessage({ id: 'login.passwordLabel' })}
            </label>
          </div>
        </div>
        <input
          type="password"
          placeholder={intl.formatMessage({ id: 'login.passwordPlaceholder' })}
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      <div className="text-center">
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-lg btn-primary w-100 mb-5"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && (
            <span className="indicator-label">
              {intl.formatMessage({ id: 'login.cta' })}
            </span>
          )}
          {loading && (
            <span className="indicator-progress" style={{ display: 'block' }}>
              {intl.formatMessage({ id: 'login.pleaseWait' })}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
    </form>
  );
}

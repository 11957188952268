import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../../setup';

import { PlanModel } from '../models/PlanModel';
import { planStatusSelector } from '../redux/PlanRedux';

export default function MealPlanList() {
  const intl = useIntl();
  const selectedPlan: PlanModel | undefined = useSelector(
    (state: RootState) => state.plan.selectedPlan,
    shallowEqual
  );
  const status: string = useSelector(planStatusSelector, shallowEqual);
  const isLoading = [
    'loading_selected_plan',
    'loading_recalculate_plan',
    'loading_plan_orders',
  ].includes(status);

  function getMeal(mealPlan) {
    return selectedPlan?.eligible_meals.find(
      (eligibleMeal) => eligibleMeal.id === mealPlan.meal_id
    );
  }

  return (
    <section className="card p-10">
      <h1 className="mb-8">
        {intl.formatMessage({ id: 'planDetail.mealPlans' })}
      </h1>
      {isLoading && (
        <div>
          <div className="spinner-border text-primary" role="status" />
        </div>
      )}
      {!isLoading && !selectedPlan?.meal_plans.length && (
        <p>{intl.formatMessage({ id: 'planDetail.noMealPlans' })}</p>
      )}
      {!isLoading && selectedPlan?.meal_plans.length > 0 && (
        <div className="overflow-scroll">
          <table className="table table-hover table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
            <thead className="table-header">
              <tr className="border-0 fw-bold">
                <th className="">
                  {intl.formatMessage({ id: 'planDetail.meal' })}
                </th>
                <th className="text-end">
                  {intl.formatMessage({ id: 'planDetail.days' })}
                </th>
                <th className="text-end w-80px pe-4">
                  {intl.formatMessage({ id: 'planDetail.trialDays' })}
                </th>
                <th className="text-end">
                  {intl.formatMessage({ id: 'planDetail.weight' })}
                </th>
                <th className="text-end">
                  {intl.formatMessage({ id: 'planDetail.soldWeight' })}
                </th>
                <th className="text-end">
                  {intl.formatMessage({ id: 'planDetail.trialWeight' })}
                </th>
                <th className="text-end">
                  {intl.formatMessage({ id: 'planDetail.soldTrialWeight' })}
                </th>
              </tr>
            </thead>
            <tbody>
              {selectedPlan?.meal_plans.map((mealPlan) => (
                <tr
                  key={mealPlan.id}
                  className={clsx(!getMeal(mealPlan) && 'text-danger')}
                >
                  <td>
                    {getMeal(mealPlan)?.name || (
                      <span className="fw-bold">
                        {intl.formatMessage({
                          id: 'planDetail.nonEligibleMeal',
                        })}
                      </span>
                    )}
                  </td>
                  <td className="text-end">{mealPlan.days}</td>
                  <td className="text-end pe-4">{mealPlan.trial_days}</td>
                  <td className="text-end">{mealPlan.weight_in_gr}</td>
                  <td className="text-end">{mealPlan.sold_weight_in_gr}</td>
                  <td className="text-end">{mealPlan.trial_weight_in_gr}</td>
                  <td className="text-end">
                    {mealPlan.sold_trial_weight_in_gr}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </section>
  );
}

import axios from 'axios';
import { LeadOrderModel } from '../models/LeadOrderModel';

const ORDERS_PER_PAGE = 20;
const API_URL = process.env.REACT_APP_API_URL;
const ORDERS_URL = `${API_URL}/api/b2b/lead-orders/`;

interface ListLeadOrdersResponse {
  results: Array<LeadOrderModel>;
  count: number;
}
interface ListLeadOrdersProps {
  distributorId?: number;
  selectedState?: string;
  page: number;
  country?: string;
  since?: string;
  until?: string;
  code?: string;
  shipping_service?: string;
}

export function listLeadOrders({
  distributorId,
  selectedState,
  page = 1,
  country,
  since,
  until,
  code,
  shipping_service,
}: ListLeadOrdersProps): Promise<{ data: ListLeadOrdersResponse }> {
  const params: any = {
    distributor: distributorId,
    state: selectedState,
    offset: (page - 1) * ORDERS_PER_PAGE,
    limit: ORDERS_PER_PAGE,
    country,
    promise_date__gte: since,
    promise_date__lte: until,
    code,
    shipping_service,
  };

  return axios.get(ORDERS_URL, { params });
}

export function createLeadOrder(
  order: LeadOrderModel
): Promise<{ data: LeadOrderModel }> {
  return axios.post(ORDERS_URL, order);
}

export enum BulkActionsEnum {
  PREPARE_LEAD_ORDERS = 'prepare_lead_orders',
  GENERATE_PICKING_SHEET = 'generate_lead_warehouse_operations_sheet',
}

interface BulkActionProps {
  orders: Array<number>;
  action: BulkActionsEnum;
  distributor: number;
  state: string;
  country?: string;
  since?: string;
  until?: string;
  code?: string;
  shipping_service?: string;
}

export function performBulkAction({
  orders,
  action,
  distributor,
  state,
  country,
  since,
  until,
  code,
  shipping_service,
}: BulkActionProps) {
  const endpoint = `${API_URL}/api/b2b/lead-orders/massive-actions/`;

  return axios.post(endpoint, {
    action,
    orders,
    distributor,
    state,
    country,
    promise_date__gte: since,
    promise_date__lte: until,
    code,
    shipping_service,
  });
}

export function updateLeadOrder(
  order: LeadOrderModel
): Promise<{ data: LeadOrderModel }> {
  const { distributor, promise_date, shipping_address, state, comments, kind } =
    order;

  return axios.patch(`${ORDERS_URL}${order.id}/`, {
    distributor,
    promise_date,
    shipping_address,
    state,
    comments,
    kind,
  });
}

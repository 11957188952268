import { useEffect, useState } from 'react';
import { LoyaltyLevel } from '../models/LoyaltyLevel';
import {
  listRewardsPerLevel,
  updateReward,
  createReward,
  deleteRewards,
} from '../services/LoyaltyCRUD';
import { LoyaltyReward } from '../models/LoyaltyReward';
import { RewardModal } from './RewardModal';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { RewardRow } from './RewardRow';

const USE_MOCKS = false;
const MOCK_REWARDS_PER_LEVEL: Array<LoyaltyLevel> = [
  {
    id: 1,
    name: 'puppy',
    max_stamps: 8,
    min_stamps: 0,
    rewards: [
      {
        name_ca: 'a',
        name_es: 'Reward 1',
        name_fr: 'c',
        name_en: 'd',
        name_de: 'e',
        name_it: 'f',
        name_pt: 'g',
        description_ca: 'h',
        description_es: 'Reward 1 description',
        description_fr: 'j',
        description_en: 'k',
        description_de: 'l',
        description_it: 'm',
        description_pt: 'n',
        order: 0,
        id: 1,
      },
      {
        name_ca: 'a',
        name_es: 'Reward 2',
        name_fr: 'c',
        name_en: 'd',
        name_de: 'e',
        name_it: 'f',
        name_pt: 'g',
        description_ca: 'h',
        description_es: 'Reward 2 description',
        description_fr: 'j',
        description_en: 'k',
        description_de: 'l',
        description_it: 'm',
        description_pt: 'n',
        order: 1,
        id: 2,
      },
      {
        name_ca: 'a',
        name_es: 'Reward 3',
        name_fr: 'c',
        name_en: 'd',
        name_de: 'e',
        name_it: 'f',
        name_pt: 'g',
        description_ca: 'h',
        description_es: 'Reward 3 description',
        description_fr: 'j',
        description_en: 'k',
        description_de: 'l',
        description_it: 'm',
        description_pt: 'n',
        order: 2,
        id: 3,
      },
    ],
  },
  {
    id: 2,
    name: 'peludo',
    max_stamps: 9,
    min_stamps: 16,
    rewards: [],
  },
  {
    id: 3,
    name: 'senior',
    max_stamps: 24,
    min_stamps: 17,
    rewards: [],
  },
];

export function LoyaltyRewards() {
  const intl = useIntl();
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const [rewardsPerLevel, setRewardsPerLevel] = useState<Array<LoyaltyLevel>>(
    []
  );
  const [editingReward, setEditingReward] = useState<LoyaltyReward | null>(
    null
  );

  async function fetchRewards() {
    if (USE_MOCKS) {
      setRewardsPerLevel(
        MOCK_REWARDS_PER_LEVEL.map((level) => {
          return {
            ...level,
            rewards: level.rewards.sort((a, b) => a.order - b.order),
          };
        })
      );
      return;
    } else {
      listRewardsPerLevel()
        .then(({ data }) => {
          console.log(data);
          setRewardsPerLevel(
            data.map((level) => {
              return {
                ...level,
                rewards: level.rewards.sort((a, b) => a.order - b.order),
              };
            })
          );
        })
        .catch(() => {
          toast.error(
            intl.formatMessage({ id: 'loyaltyRewards.errorLoadingRewards' })
          );
        });
    }
  }

  useEffect(() => {
    fetchRewards();
  }, []);

  function onAddReward(level: LoyaltyLevel) {
    setEditingReward({
      name_ca: '',
      name_es: '',
      name_fr: '',
      name_en: '',
      name_de: '',
      name_it: '',
      name_pt: '',
      description_ca: '',
      description_es: '',
      description_fr: '',
      description_en: '',
      description_de: '',
      description_it: '',
      description_pt: '',
      level: level.id,
      order: level.rewards.length,
      id: -1,
    });
  }

  function onEditReward(reward: LoyaltyReward) {
    setEditingReward(reward);
  }

  async function onDeleteReward(reward: LoyaltyReward) {
    try {
      const hasConfirmed = window.confirm(
        intl.formatMessage({ id: 'loyaltyRewards.confirmDeleteReward' })
      );
      if (!hasConfirmed) {
        return;
      }
      setRewardsPerLevel((rewardsPerLevel) => {
        return rewardsPerLevel.map((level) => {
          if (level.rewards.find((r) => r.id === reward.id)) {
            return {
              ...level,
              rewards: level.rewards.filter((r) => r.id !== reward.id),
            };
          }
          return level;
        });
      });
      await deleteRewards([reward.id]);
      toast.success(intl.formatMessage({ id: 'loyaltyRewards.rewardDeleted' }));
    } catch (error) {
      console.warn(error);
      toast.error(
        intl.formatMessage({ id: 'loyaltyRewards.errorDeletingReward' })
      );
      fetchRewards();
    }
  }

  async function onSubmitReward(reward: LoyaltyReward) {
    try {
      if (reward?.id > -1) {
        const { data: updatedReward } = await updateReward(reward);
        setRewardsPerLevel((rewardsPerLevel) => {
          return rewardsPerLevel.map((level) => {
            if (level.id === reward.level) {
              return {
                ...level,
                rewards: level.rewards.map((existingReward) => {
                  if (existingReward.id === reward.id) {
                    return updatedReward;
                  }
                  return existingReward;
                }),
              };
            }
            return level;
          });
        });
      } else {
        const { data: createdReward } = await createReward(reward);
        setRewardsPerLevel((rewardsPerLevel) => {
          return rewardsPerLevel.map((level) => {
            if (level.id === reward.level) {
              return {
                ...level,
                rewards: [...level.rewards, createdReward],
              };
            }
            return level;
          });
        });
      }

      toast.success(intl.formatMessage({ id: 'loyaltyRewards.rewardSaved' }));
      setEditingReward(null);
    } catch (error) {
      console.warn(error);
      toast.error(
        intl.formatMessage({ id: 'loyaltyRewards.errorSavingReward' })
      );
    }
  }

  async function handleDragEnd(event, level: LoyaltyLevel) {
    const { active, over } = event;

    if (active.id !== over.id) {
      try {
        const updatedLevel = { ...level };
        const activeIndex = updatedLevel.rewards.findIndex(
          (reward) => reward.id === active.id
        );
        const overIndex = updatedLevel.rewards.findIndex(
          (reward) => reward.id === over.id
        );
        const updatedRewards = arrayMove(
          updatedLevel.rewards,
          activeIndex,
          overIndex
        );

        updatedLevel.rewards = updatedRewards.map((reward, index) => {
          return {
            ...reward,
            order: index,
          };
        });

        setRewardsPerLevel((rewardsPerLevel) => {
          return [
            ...rewardsPerLevel.slice(0, rewardsPerLevel.indexOf(level)),
            updatedLevel,
            ...rewardsPerLevel.slice(rewardsPerLevel.indexOf(level) + 1),
          ];
        });

        await updateReward(updatedLevel.rewards[activeIndex]);
        await updateReward(updatedLevel.rewards[overIndex]);

        toast.success(
          intl.formatMessage({ id: 'loyaltyRewards.orderChanged' })
        );
      } catch (error) {
        console.warn(error);
        toast.error(
          intl.formatMessage({ id: 'loyaltyRewards.errorSavingReward' })
        );
      }
    }
  }

  return (
    <main>
      <h2 className="mb-8">
        {intl.formatMessage({ id: 'loyaltyRewards.title' })}
      </h2>
      <div className="d-flex flex-column gap-4">
        {rewardsPerLevel.map((level, index) => {
          return (
            <div key={index} className="">
              <h3 className="d-flex justify-content-between align-items-center fw-normal text-muted border-bottom pb-4 border-light">
                <div>
                  <span className="fw-bolder text-dark">
                    {intl.formatMessage({ id: `loyaltyLevels.level` })}{' '}
                    {level.name}{' '}
                  </span>
                  ({level.min_stamps} - {level.max_stamps}{' '}
                  {intl.formatMessage({ id: 'loyaltyRewards.stamps' })})
                </div>
                <button
                  onClick={() => onAddReward(level)}
                  className="btn btn-primary"
                >
                  <span className="fa-icon fas fa-plus me-3"></span>
                  {intl.formatMessage({ id: 'loyaltyRewards.addReward' })}
                </button>
              </h3>
              <div className="mt-6 mb-4 d-flex gap-4 flex-column">
                {level?.rewards?.length > 0 && (
                  <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={(event) => handleDragEnd(event, level)}
                  >
                    <SortableContext
                      items={level?.rewards}
                      strategy={verticalListSortingStrategy}
                    >
                      {level?.rewards?.map((reward) => (
                        <RewardRow
                          id={reward.id}
                          key={reward.id}
                          reward={reward}
                          onEdit={() => onEditReward(reward)}
                          onDelete={() => onDeleteReward(reward)}
                        />
                      ))}
                    </SortableContext>
                  </DndContext>
                )}
                {!level?.rewards?.length && (
                  <p className="text-muted">
                    {intl.formatMessage({ id: 'loyaltyRewards.noRewards' })}
                  </p>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <RewardModal
        visible={!!editingReward}
        reward={editingReward}
        onClose={() => setEditingReward(null)}
        onSubmit={onSubmitReward}
      />
    </main>
  );
}

import { ORDERS_PER_PAGE } from '../redux/OrderRedux';
import axios from 'axios';

import { OrderModel, OrderStateEnum } from '../models/OrderModel';
import { OrderListingModel } from '../models/OrderListingModel';
import { CategoryModel } from '../models/CategoryModel';
import { ProductModel } from '../models/ProductModel';
import { ShippingService } from '../models/ShippingService';
import { OrderTracking } from '../models/OrderTracking';
import { Incident } from '../models/TrackingHistory';

const API_URL = process.env.REACT_APP_API_URL;

export enum PetKindEnum {
  DOG = 1,
  CAT = 2,
}

export const ORDER_TRACKINGS_PER_PAGE = 20;

type ListOrderProps = {
  page: number;
  state?: string;
  promise_date__gte?: string;
  promise_date__lte?: string;
  pet__pet_kind?: PetKindEnum;
  is_trial?: boolean;
  store?: number;
  sortByWeight?: boolean;
  code?: string;
  shippingService?: number;
};

export function listOrders({
  page = 1,
  state = undefined,
  promise_date__gte = undefined,
  promise_date__lte = undefined,
  pet__pet_kind = undefined,
  is_trial = undefined,
  store = undefined,
  sortByWeight = false,
  code = undefined,
  shippingService = undefined,
}: ListOrderProps): Promise<{ data: Array<OrderListingModel> }> {
  const endpoint = `${API_URL}/api/orders/`;
  const limit = ORDERS_PER_PAGE;
  const offset = (page - 1) * limit;
  const params = {
    limit,
    offset,
    state,
    promise_date__gte,
    promise_date__lte,
    pet__pet_kind,
    is_trial,
    store,
    code,
    shipping_service: shippingService,
  };

  if (sortByWeight) {
    params['order'] = 'weight';
  }

  return axios.get(endpoint, { params });
}

export function listUserOrders(
  userId: number,
  page: number = 1
): Promise<{ data: Array<OrderListingModel> }> {
  const limit = ORDERS_PER_PAGE;
  const offset = (page - 1) * limit;
  const endpoint = `${API_URL}/api/orders/?u=${userId}&limit=${limit}&offset=${offset}`;

  return axios.get(endpoint);
}

export function listPlanOrders(
  planId: number,
  page: number = 1
): Promise<{ data: Array<OrderListingModel> }> {
  const limit = ORDERS_PER_PAGE;
  const offset = (page - 1) * limit;
  const endpoint = `${API_URL}/api/orders/?plan=${planId}&limit=${limit}&offset=${offset}`;

  return axios.get(endpoint);
}

export function listPetOrders(
  petId: number,
  page: number = 1
): Promise<{ data: Array<OrderListingModel> }> {
  const limit = ORDERS_PER_PAGE;
  const offset = (page - 1) * limit;
  const endpoint = `${API_URL}/api/orders/?pet=${petId}&limit=${limit}&offset=${offset}`;

  return axios.get(endpoint);
}

export function getOrderById(id: number): Promise<{ data: OrderModel }> {
  const endpoint = `${API_URL}/api/orders/${id}/`;

  return axios.get(endpoint);
}

export function updateOrder(
  orderId: number,
  props: any
): Promise<{ data: OrderModel }> {
  const endpoint = `${API_URL}/api/orders/${orderId}/`;

  return axios.patch(endpoint, props);
}

export function changeShippingAddress(
  orderId: number,
  shippingAddressId: number
): Promise<{ data: OrderModel }> {
  const endpoint = `${API_URL}/api/orders/${orderId}/change-shipping-address/`;
  const props = {
    shipping_address_id: shippingAddressId,
  };

  return axios.post(endpoint, props);
}

export function getAvailableDates(
  init_date: string,
  end_date: string
): Promise<{ data: Array<string> }> {
  const params = {
    init_date,
    end_date,
  };
  const endpoint = `${API_URL}/api/orders/available-delivery-dates/`;

  return axios.get(endpoint, { params });
}

export function cancelOrder(orderId: number): Promise<{ data: OrderModel }> {
  const endpoint = `${API_URL}/api/orders/${orderId}/cancel/`;

  return axios.patch(endpoint);
}

export function completeOrder(orderId: number): Promise<{ data: OrderModel }> {
  const endpoint = `${API_URL}/api/orders/${orderId}/complete/`;

  return axios.patch(endpoint);
}

export function processOrder(orderId: number): Promise<{ data: OrderModel }> {
  const endpoint = `${API_URL}/api/orders/${orderId}/process/`;

  return axios.patch(endpoint);
}

export function lockOrder(orderId: number): Promise<{ data: OrderModel }> {
  const endpoint = `${API_URL}/api/orders/${orderId}/lock/`;

  return axios.patch(endpoint);
}

export function lockOrderPendingPayment(
  orderId: number
): Promise<{ data: OrderModel }> {
  const endpoint = `${API_URL}/api/orders/${orderId}/lock-order-wo-payment/`;

  return axios.post(endpoint);
}

export function refreshPrices(orderId: number): Promise<{ data: OrderModel }> {
  const endpoint = `${API_URL}/api/orders/${orderId}/refresh-prices/`;

  return axios.patch(endpoint);
}

export function listCategories(): Promise<{ data: Array<CategoryModel> }> {
  const endpoint = `${API_URL}/api/categories/`;

  return axios.get(endpoint);
}

export function listProducts(
  categoryId: number
): Promise<{ data: Array<ProductModel> }> {
  const endpoint = `${API_URL}/api/additional-products/?category=${categoryId}`;

  return axios.get(endpoint);
}

export function setProduct(
  orderId: number,
  productId: number,
  units: number,
  isFree: boolean = false
): Promise<{ data: OrderModel }> {
  const endpoint = `${API_URL}/api/orders/${orderId}/set-product/`;

  return axios.post(endpoint, { product: productId, units, is_free: isFree });
}

export enum BulkActionsEnum {
  GENERATE_PICKING_SHEET = 'generate_picking_sheet',
  GENERATE_WAREHOUSE_OPERATIONS_SHEET = 'generate_warehouse_operations_sheet',
  PRINT_TAGS = 'print_tags',
  PRINT_INSTRUCTIONS_PDF = 'print_instructions_pdf',
  SET_READY_TO_SHIP = 'set_ready_to_ship',
  SET_PROCESSING = 'set_processing',
  GENERATE_PRODUCTS_NECESSITY_SHEET = 'generate_products_necessity_sheet',
}

type BulkActionProps = {
  action: BulkActionsEnum;
  promise_date__gte: string;
  promise_date__lte: string;
  pet__pet_kind: PetKindEnum;
  is_trial: boolean;
  store: number;
  state: string;
  code: string;
  shipping_service: number;
  orders: Array<number>;
};

export function performBulkAction({
  action,
  promise_date__gte,
  promise_date__lte,
  pet__pet_kind,
  is_trial,
  store,
  state,
  code,
  orders,
  shipping_service,
}: BulkActionProps) {
  const endpoint = `${API_URL}/api/orders/massive-actions/`;

  return axios.post(endpoint, {
    action,
    promise_date__gte,
    promise_date__lte,
    pet__pet_kind,
    is_trial,
    store,
    state,
    code,
    orders,
    shipping_service,
  });
}

export function undeliverOrder(orderId: number) {
  const endpoint = `${API_URL}/api/orders/${orderId}/undeliver/`;

  return axios.patch(endpoint);
}

export function reshipOrder({ orderId, reason, observations = '' }) {
  const endpoint = `${API_URL}/api/orders/${orderId}/reship/`;

  return axios.post(endpoint, {
    reason,
    observations,
  });
}

type CreateDeliveryChangeProps = {
  orderId: number;
  reason: string;
  observations?: string;
  isRecurrent?: boolean;
};
export function createDeliveryChange({
  orderId,
  reason,
  observations = '',
  isRecurrent = false,
}: CreateDeliveryChangeProps) {
  const endpoint = `${API_URL}/api/orders/${orderId}/delivery-changes/`;

  return axios.post(endpoint, {
    reason,
    observations,
    is_recurrent: isRecurrent,
  });
}

export function mergeOrders(orderIds: Array<number>) {
  if (orderIds?.length < 2) {
    throw new Error('You need to provide at least 2 orders to merge');
  }
  const endpoint = `${API_URL}/api/orders/${orderIds[0]}/merge/`;

  return axios.post(endpoint, {
    orders: orderIds.slice(1),
  });
}

export function markAsReadyToShipOrder(orderId: number) {
  const endpoint = `${API_URL}/api/orders/${orderId}/ready-to-ship/`;

  return axios.patch(endpoint);
}

type SaleableProductModel = {
  id: number;
  name?: string;
  price?: number;
  sku?: string;
  traits?: string;
};
export function listSaleableProducts(
  storeId: number
): Promise<{ data: Array<SaleableProductModel> }> {
  const endpoint = `${API_URL}/api/saleable-products/`;

  return axios.get(endpoint, { params: { store: storeId } });
}

export function markOrderAsWithIncidence(orderId: number) {
  const endpoint = `${API_URL}/api/orders/${orderId}/with-incidence/`;

  return axios.patch(endpoint);
}

export function listShippingServices(): Promise<{
  data: Array<ShippingService>;
}> {
  const endpoint = `${API_URL}/api/shipping-service/`;

  return axios.get(endpoint);
}

interface GenerateInvoiceResponse {
  pdf_file_url: string;
}
export function generateInvoice(
  orderId: number
): Promise<{ data: GenerateInvoiceResponse }> {
  const endpoint = `${API_URL}/api/invoice/generate-pdf/`;

  return axios.post(endpoint, { order_id: orderId });
}

interface DownloadInvoicesProps {
  orderIds: Array<number>;
  userId: number;
}
export function downloadInvoices({ orderIds, userId }: DownloadInvoicesProps) {
  const endpoint = `${API_URL}/api/invoice/download-selected-invoices/`;

  return axios.post(endpoint, {
    orders: orderIds,
    user_id: userId,
  });
}

export function forceLockOrder(orderId) {
  const endpoint = `${API_URL}/api/orders/${orderId}/force-locked/`;

  return axios.patch(endpoint);
}

export interface ListOrderTrackingsResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: Array<OrderTracking>;
}
interface ListOrderTrackingsProps {
  page: number;
  until?: Date;
  since?: Date;
  code?: string;
  state?: OrderStateEnum;
  agencyState?: string;
  incident?: Incident;
  displayIncidentsOnly?: boolean;
}
export function listOrderTrackings({
  page,
  until,
  since,
  code,
  state,
  agencyState,
  incident,
  displayIncidentsOnly,
}: ListOrderTrackingsProps): Promise<{ data: ListOrderTrackingsResponse }> {
  const lte = until
    ? until.toLocaleDateString('en-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
    : '';
  const gte = since
    ? since.toLocaleDateString('en-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
    : '';

  const endpoint = `${API_URL}/api/order-tracking/?limit=${
    ORDER_TRACKINGS_PER_PAGE
  }&offset=${(page - 1) * ORDER_TRACKINGS_PER_PAGE}&promise_date__lte=${
    lte
  }&promise_date__gte=${gte}&code=${code || ''}&state=${
    state || ''
  }&agency_state=${agencyState || ''}&delivery_state=${
    incident || ''
  }&has_delivery_problem=${displayIncidentsOnly ? 'true' : ''}`;

  return axios.get(endpoint);
}

export function downloadOrderTrackingExcel({
  until,
  since,
  code,
  state,
  agencyState,
  incident,
  displayIncidentsOnly,
}: Partial<ListOrderTrackingsProps>) {
  const lte = until
    ? until.toLocaleDateString('en-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
    : '';
  const gte = since
    ? since.toLocaleDateString('en-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
    : '';
  const endpoint = `${API_URL}/api/order-tracking/download-excel/?promise_date__lte=${
    lte
  }&promise_date__gte=${gte}&code=${code || ''}&state=${
    state || ''
  }&agency_state=${agencyState || ''}&delivery_state=${
    incident || ''
  }&has_delivery_problem=${displayIncidentsOnly ? 'true' : ''}`;

  return axios.get(endpoint, { responseType: 'blob' });
}

interface UpdateIncidentResponse {
  incident: string;
}
export function updateIncident({
  trackingHistoryId,
  incident,
}: {
  trackingHistoryId: number;
  incident: Incident;
}): Promise<{ data: UpdateIncidentResponse }> {
  const endpoint = `${API_URL}/api/tracking-history/${trackingHistoryId}/update-incident/`;

  return axios.patch(endpoint, { incident });
}

interface AddCommentToTrackingHistoryResponse {
  tracking_history_id: number;
  comment: string;
}

export function addCommentToTrackingHistory({
  trackingHistoryId,
  comment,
}: {
  trackingHistoryId: number;
  comment: string;
}): Promise<{ data: AddCommentToTrackingHistoryResponse }> {
  const endpoint = `${API_URL}/api/tracking-history-comment/`;

  return axios.post(endpoint, {
    tracking_history_id: trackingHistoryId,
    comment,
  });
}

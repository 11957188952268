import React, { Suspense, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { I18nProvider } from '../_metronic/i18n/i18nProvider';
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core';
import AuthInit from './modules/auth/redux/AuthInit';
import { Routes } from './routing/Routes';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

type Props = {
  basename: string;
};

const App: React.FC<Props> = ({ basename }) => {
  useEffect(() => {
    if (
      window.location.href.includes('-staging') ||
      window.location.href.includes('-local')
    ) {
      console.info(
        'Development environment detected. Skipping Sentry initialization.'
      );
    } else {
      Sentry.init({
        dsn: 'https://62f5afdcebe04290b5b337fc9fbea132@o992333.ingest.sentry.io/6588014',
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
      });
    }
  }, []);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <I18nProvider>
          <LayoutProvider>
            <AuthInit>
              <Routes />
            </AuthInit>
          </LayoutProvider>
        </I18nProvider>
      </BrowserRouter>
    </Suspense>
  );
};

export { App };

import { FC } from 'react';
import { useIntl } from 'react-intl';

import { PageTitle } from '../../../_metronic/layout/core';
import SegmentedReport from '../../modules/dashboard/components/SegmentedReport';

const SegmentedReportPage: FC = () => {
  const intl = useIntl();

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'businessDashboard.segmentedReport' })}
      </PageTitle>
      <div className="row gy-4 mw-800px">
        <div className="card p-10">
          <SegmentedReport />
        </div>
      </div>
    </>
  );
};

export { SegmentedReportPage };

import clsx from 'clsx';
import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { TaskModel } from '../../tasks';

type Props = {
  task: TaskModel;
  focused: boolean;
};

const TaskCard: FC<Props> = (props: Props) => {
  const intl = useIntl();
  const { task, focused } = props;
  const [hovered, setHovered] = useState(false);
  const cardClasses = clsx(
    'card px-5 py-4 overflow-hidden',
    !(focused || hovered) && 'border border-3 border-light bg-light',
    (focused || hovered) && 'border border-3 border-primary bg-white'
  );

  return (
    <>
      <div
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        className={cardClasses}
      >
        <div className="fw-bold fs-4 d-flex">
          <div
            className={clsx(
              'fs-3 fa text-muted me-1 mt-1',
              task?.email ? 'fa-envelope' : 'fa-phone-alt'
            )}
          />
          <span className="mx-1">{task?.email || task?.phone}</span>
          <span className="badge p-2 bg-danger mx-2 text-white">
            {intl.formatMessage({ id: 'tasks.unregistered' })}
          </span>
        </div>
        <div className="mt-2">
          {task?.email && task?.phone && (
            <span className="mx-8">
              <span>
                <span className="fas fa-phone-alt text-gray-600 me-2" />
                {task?.phone}
              </span>
            </span>
          )}
          {task?.email && !task?.phone && (
            <span className="mx-8 text-muted">
              {intl.formatMessage({ id: 'tasks.noPhone' })}
            </span>
          )}
          {!task?.email && task?.phone && (
            <span className="mx-8 text-muted">
              {intl.formatMessage({ id: 'tasks.noEmail' })}
            </span>
          )}
        </div>
        <div className="mt-2">
          <span className="mx-8 text-muted">
            {intl.formatMessage(
              { id: 'tasks.createdAtTime' },
              {
                day: new Date(task?.created_at).toLocaleDateString('es-ES'),
                time: new Date(task?.created_at).toLocaleTimeString('es-ES'),
              }
            )}
          </span>
        </div>
      </div>
    </>
  );
};

export { TaskCard };

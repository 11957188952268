import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import {
  DistributorModel,
  PaymentMethodEnum,
} from '../models/DistributorModel';
import { Modal } from 'react-bootstrap';
import { TaxModel } from '../models/TaxModel';

type Props = {
  distributor?: DistributorModel;
  taxes: TaxModel[];
  visible: boolean;
  onSaveDistributor: (distributor: DistributorModel) => void;
  onCancel: () => void;
};

const EMPTY_DISTRIBUTOR: DistributorModel = {
  id: 0,
  name: '',
  vat_number: '',
  email: '',
  phone: '',
  state: 'active',
  taxes: [1, 7],
  payment_method: PaymentMethodEnum.WIRE_TRANSFER,
  max_payment_days: 30,
  total_discounts: 0,
  discount_on: 'order',
  discount_mode: 'absolute',
};

export default function ModalDistributorForm({
  distributor,
  taxes,
  visible,
  onSaveDistributor,
  onCancel,
}: Props) {
  const intl = useIntl();
  const [distributorState, setDistributorState] = useState(distributor);

  useEffect(() => {
    if (distributor) {
      setDistributorState(distributor);
    } else {
      setDistributorState(EMPTY_DISTRIBUTOR);
    }
  }, [distributor, visible]);

  return (
    <Modal show={visible} onClose={onCancel}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">
            {distributorState?.id
              ? intl.formatMessage({ id: 'distributors.editDistributor' })
              : intl.formatMessage({ id: 'distributors.addDistributor' })}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={onCancel}
          ></button>
        </div>
        <div className="modal-body">
          <form>
            <div className="row mb-3">
              <div className="col-6">
                <label htmlFor="name" className="form-label">
                  {intl.formatMessage({ id: 'distributors.name' })} *
                </label>
                <input
                  type="text"
                  value={distributorState?.name}
                  onChange={(e) =>
                    setDistributorState({
                      ...distributorState,
                      name: e.target.value,
                    })
                  }
                  className="form-control"
                  id="name"
                  placeholder={intl.formatMessage({
                    id: 'distributors.namePlaceholder',
                  })}
                />
              </div>
              <div className="col-6">
                <label htmlFor="vat_number" className="form-label">
                  {intl.formatMessage({ id: 'distributors.vatNumber' })} *
                </label>
                <input
                  type="text"
                  value={distributorState?.vat_number}
                  onChange={(e) =>
                    setDistributorState({
                      ...distributorState,
                      vat_number: e.target.value,
                    })
                  }
                  className="form-control"
                  id="vat_number"
                  placeholder={intl.formatMessage({
                    id: 'distributors.vatNumberPlaceholder',
                  })}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-6">
                <label htmlFor="email" className="form-label">
                  {intl.formatMessage({ id: 'distributors.email' })}
                </label>
                <input
                  type="email"
                  required
                  value={distributorState?.email}
                  onChange={(e) =>
                    setDistributorState({
                      ...distributorState,
                      email: e.target.value,
                    })
                  }
                  className="form-control"
                  id="email"
                  placeholder={intl.formatMessage({
                    id: 'distributors.emailPlaceholder',
                  })}
                />
              </div>
              <div className="col-6">
                <label htmlFor="phone" className="form-label">
                  {intl.formatMessage({ id: 'distributors.phone' })}
                </label>
                <input
                  type="text"
                  value={distributorState?.phone}
                  onChange={(e) =>
                    setDistributorState({
                      ...distributorState,
                      phone: e.target.value,
                    })
                  }
                  className="form-control"
                  id="phone"
                  maxLength={14}
                  placeholder={intl.formatMessage({
                    id: 'distributors.phonePlaceholder',
                  })}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-6">
                <label htmlFor="payment_method" className="form-label">
                  {intl.formatMessage({ id: 'distributors.paymentMethod' })}
                </label>
                <select
                  className="form-select"
                  value={distributorState?.payment_method}
                  onChange={(e) =>
                    setDistributorState({
                      ...distributorState,
                      payment_method: e.target.value as PaymentMethodEnum,
                    })
                  }
                  id="payment_method"
                >
                  <option value={PaymentMethodEnum.BANK_DRAFT}>
                    {intl.formatMessage({ id: 'distributors.bankDraft' })}
                  </option>
                  <option value={PaymentMethodEnum.WIRE_TRANSFER}>
                    {intl.formatMessage({ id: 'distributors.wireTransfer' })}
                  </option>
                </select>
              </div>
              <div className="col-6">
                <label htmlFor="max_payment_days" className="form-label">
                  {intl.formatMessage({ id: 'distributors.maxPaymentDays' })}
                </label>
                <input
                  type="number"
                  value={distributorState?.max_payment_days}
                  onChange={(e) =>
                    setDistributorState({
                      ...distributorState,
                      max_payment_days: parseInt(e.target.value),
                    })
                  }
                  className="form-control"
                  id="max_payment_days"
                  placeholder={intl.formatMessage({
                    id: 'distributors.maxPaymentDaysPlaceholder',
                  })}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-6">
                <label htmlFor="discount_mode" className="form-label">
                  {intl.formatMessage({ id: 'distributors.discountMode' })}
                </label>
                <select
                  className="form-select"
                  value={distributorState?.discount_mode}
                  onChange={(e) =>
                    setDistributorState({
                      ...distributorState,
                      discount_mode: e.target.value as 'absolute' | 'relative',
                    })
                  }
                  id="discount_mode"
                >
                  <option value="absolute">
                    {intl.formatMessage({ id: 'distributors.absolute' })}
                  </option>
                  <option value="relative">
                    {intl.formatMessage({ id: 'distributors.relative' })}
                  </option>
                </select>
              </div>
              <div className="col-6">
                <label htmlFor="total_discounts" className="form-label">
                  {intl.formatMessage({ id: 'distributors.totalDiscounts' })}
                </label>
                <input
                  type="number"
                  value={distributorState?.total_discounts}
                  onChange={(e) =>
                    setDistributorState({
                      ...distributorState,
                      total_discounts: parseInt(e.target.value),
                    })
                  }
                  className="form-control"
                  id="total_discounts"
                  placeholder={intl.formatMessage({
                    id: 'distributors.totalDiscountsPlaceholder',
                  })}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-6">
                <label htmlFor="discount_on" className="form-label">
                  {intl.formatMessage({ id: 'distributors.discountOn' })}
                </label>
                <select
                  className="form-select"
                  value={distributorState?.discount_on}
                  onChange={(e) =>
                    setDistributorState({
                      ...distributorState,
                      discount_on: e.target.value as 'order' | 'order_line',
                    })
                  }
                  id="discount_on"
                >
                  <option value="order">
                    {intl.formatMessage({ id: 'distributors.order' })}
                  </option>
                  <option value="order_line">
                    {intl.formatMessage({ id: 'distributors.orderLine' })}
                  </option>
                </select>
              </div>
            </div>
            <div className="mt-8 mb-0 d-block">
              <details className="">
                <summary className="form-label">
                  &nbsp; {intl.formatMessage({ id: 'distributors.taxes' })} (
                  {distributorState?.taxes?.length})
                </summary>
                {taxes.map((tax) => {
                  return (
                    <div className="form-check" key={tax.id}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={tax.id}
                        id={`tax-${tax.id}`}
                        checked={
                          distributorState?.taxes?.find(
                            (aTax) => aTax === tax.id
                          )
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            setDistributorState({
                              ...distributorState,
                              taxes: [...distributorState.taxes, tax.id],
                            });
                          } else {
                            setDistributorState({
                              ...distributorState,
                              taxes: distributorState.taxes.filter(
                                (aTax) => aTax !== tax.id
                              ),
                            });
                          }
                        }}
                      />
                      <label
                        className="form-check-label mb-2"
                        htmlFor={`tax-${tax.id}`}
                      >
                        {tax.name} {tax.tax_class.name} {tax.country}{' '}
                        {tax.vat > 0 && `(${Math.trunc(tax.vat * 100)}%)`}
                      </label>
                    </div>
                  );
                })}
              </details>
            </div>
          </form>

          <div className="d-flex gap-4 pt-8 justify-content-end">
            <button
              type="button"
              className="btn btn-tertiary"
              data-bs-dismiss="modal"
              onClick={onCancel}
            >
              {intl.formatMessage({ id: 'common.close' })}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => onSaveDistributor(distributorState)}
              disabled={
                !distributorState?.name || !distributorState?.vat_number
              }
            >
              <span className="fa fa-save me-3"></span>
              {intl.formatMessage({ id: 'common.save' })}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import moment from 'moment';
import countryNames from '../../../constants/countryNames';

import { getAtcDashboard } from '../services/DashboardCRUD';
import DateRangeSelector from '../../../components/DateRangeSelector';
import LineChart from './LineChart';
import { useSelector } from 'react-redux';
import { authUserSelector } from '../../auth';
import { OrdersChart } from './OrdersChart';

export default function AtcDashboard() {
  const intl = useIntl();
  const initialSince = moment().subtract(31, 'days').toDate();
  const initialUntil = moment().subtract(1, 'days').toDate();
  const authUser = useSelector(authUserSelector);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedStore, setSelectedStores] = useState('ES');

  function getChartKind() {
    if (
      authUser?.cs_groups?.find((aGroup) => aGroup.name === 'ATC - Acquisition')
    ) {
      return 'trials';
    }
    if (
      authUser?.cs_groups?.find(
        (aGroup) => aGroup.name === 'ATC - Subscription'
      )
    ) {
      return 'rescate';
    }
    return false;
  }

  function onAcceptDateRange(since, until) {
    fetchData(since, until);
  }

  function getRelevantData(data) {
    const dataFromSelectedStore = data?.filter(
      (item) => item.store_country === selectedStore
    );
    const aggregatedData = [];

    dataFromSelectedStore?.forEach((item) => {
      const existingItem = aggregatedData.find(
        (aggregatedItem) =>
          aggregatedItem.name === item.name && aggregatedItem.date === item.date
      );

      if (existingItem) {
        existingItem.value += item.value;
      } else {
        aggregatedData.push(item);
      }
    });

    return aggregatedData;
  }

  async function fetchData(since, until) {
    if (!getChartKind()) {
      return;
    }
    let stringSince = `${since?.getFullYear()}-${(since?.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${since?.getDate().toString().padStart(2, '0')}`;
    let stringUntil = `${until?.getFullYear()}-${(until?.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${until?.getDate().toString().padStart(2, '0')}`;

    setLoading(true);
    try {
      const { data } = await getAtcDashboard({
        since: stringSince,
        until: stringUntil,
        agent: authUser?.id,
        kind: getChartKind() as any,
      });

      setData(data[getChartKind() as any]);
    } catch (error) {
      console.warn(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData(initialSince, initialUntil);
  }, []);

  function getStores() {
    return Object.keys(countryNames).map((country) => ({
      country,
      name: countryNames[country],
    }));
  }

  return (
    <div className="card pt-0">
      {getChartKind() ? (
        <>
          <div className="mb-10 d-flex align-items-start gap-8">
            <DateRangeSelector
              initialSince={initialSince}
              initialUntil={initialUntil}
              onSelected={onAcceptDateRange}
              disableFutureDates={true}
            />
          </div>
          <ul className="nav nav-tabs mb-10">
            {getStores().map((store) => (
              <li className="nav-item" key={store.country}>
                <a
                  className={`nav-link ${selectedStore === store.country ? 'active' : ''}`}
                  aria-current="page"
                  href="#"
                  onClick={() => setSelectedStores(store.country)}
                >
                  {store.name}
                </a>
              </li>
            ))}
          </ul>
          <LineChart
            title={intl.formatMessage({ id: `atcDashboard.${getChartKind()}` })}
            data={getRelevantData(data)}
            loading={loading}
            serieField="name"
          />
        </>
      ) : (
        <OrdersChart />
      )}
    </div>
  );
}

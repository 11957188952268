import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { UserModel } from '../models/UserModel';
import { currentUserSelector, userStatusSelector } from '../redux/UserRedux';

export default function AccountLink() {
  const intl = useIntl();
  const currentUser: UserModel | undefined = useSelector(
    currentUserSelector,
    shallowEqual
  );
  const status: string = useSelector(userStatusSelector, shallowEqual);
  const accountUrl =
    currentUser?.account_url || `https://${currentUser?.store?.name}`;

  async function copyToClipboard(text) {
    try {
      if (navigator?.clipboard) {
        navigator.clipboard.writeText(text);
      } else {
        var textField = document.createElement('textarea');
        textField.textContent = text;
        document.body.appendChild(textField);
        textField.select();
        document?.execCommand('copy');
        textField.remove();
      }
      toast.success(
        intl.formatMessage({ id: 'userProfile.accountLinkCopiedToClipboard' })
      );
    } catch (error) {
      console.warn({ error });
      toast.error(
        intl.formatMessage({
          id: 'userProfile.accountLinkCopiedToClipboardError',
        })
      );
    }
  }

  return (
    <div className="card p-10">
      <h1 className="mb-8">
        {intl.formatMessage({ id: 'userProfile.accountLink' })}
      </h1>
      {status === 'loading_request_user' ? (
        <div className="spinner-border text-primary" role="status" />
      ) : (
        <>
          <div className="row mb-4">
            <div className="col-8">
              <input
                className="form-control"
                type="text"
                readOnly
                value={accountUrl}
              />
            </div>
            <div className="col-4">
              <button
                onClick={() => copyToClipboard(accountUrl)}
                className="btn btn-secondary m-0 w-100"
              >
                {intl.formatMessage({ id: 'userProfile.copy' })}
              </button>
            </div>
          </div>
          <a
            href={accountUrl}
            target="_blank"
            className="btn btn-primary w-100"
            rel="noreferrer"
          >
            <span className="fas fa-external-link-alt me-4"></span>
            {intl.formatMessage({ id: 'userProfile.goToAccount' })}
          </a>
        </>
      )}
    </div>
  );
}

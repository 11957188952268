import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import * as user from '../redux/UserRedux';
import { UserModel } from '../models/UserModel';
import { BankcardModel } from '../models/BankcardModel';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

export default function Bankcards() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const currentUser: UserModel | undefined = useSelector(
    user.currentUserSelector,
    shallowEqual
  );
  const status = useSelector(user.userStatusSelector, shallowEqual);
  const bankcards: Array<BankcardModel> = currentUser?.bankcards || [];
  const [removingBankcard, setRemovingBankcard] = useState<
    BankcardModel | undefined
  >(undefined);
  const [makingDefaultBankcard, setMakingDefaultBankcard] = useState<
    BankcardModel | undefined
  >(undefined);

  function onRemove(bankcard: BankcardModel) {
    const confirms = window.confirm(
      intl.formatMessage({ id: 'userProfile.removeBankcardConfirmation' })
    );
    if (confirms) {
      setRemovingBankcard(bankcard);
      dispatch(user.actions.removeBankcard(bankcard));
    }
  }

  function onMakeDefault(bankcard: BankcardModel) {
    const confirms = window.confirm(
      intl.formatMessage({ id: 'userProfile.makeDefaultBankcardConfirmation' })
    );
    if (confirms) {
      setMakingDefaultBankcard(bankcard);
      dispatch(user.actions.makeDefaultBankcard(bankcard));
    }
  }

  useEffect(() => {
    switch (status) {
      case 'success_remove_bankcard': {
        setRemovingBankcard(undefined);
        toast.success(
          intl.formatMessage({ id: 'userProfile.removeBankcardSuccessful' })
        );
        break;
      }
      case 'error_remove_bankcard': {
        setRemovingBankcard(undefined);
        toast.error(
          intl.formatMessage({ id: 'userProfile.removeBankcardError' })
        );
        break;
      }
      case 'success_make_default_bankcard': {
        setMakingDefaultBankcard(undefined);
        toast.success(
          intl.formatMessage({
            id: 'userProfile.makeDefaultBankcardSuccessful',
          })
        );
        break;
      }
      case 'error_make_default_bankcard': {
        setMakingDefaultBankcard(undefined);
        toast.error(
          intl.formatMessage({ id: 'userProfile.makeDefaultBankcardError' })
        );
        break;
      }
    }
  }, [status]);

  return (
    <div className="card p-10">
      <form className="">
        <div className="d-flex flex-row align-items-center justify-content-between">
          <h1 className="d-inline-block mb-10">
            {intl.formatMessage({ id: 'userProfile.bankcards' })}
          </h1>
        </div>
        {status === 'loading_request_user' ? (
          <div className="spinner-border text-primary" role="status" />
        ) : (
          <>
            {bankcards?.length > 0 ? (
              <section>
                {bankcards.map((bankcard: BankcardModel, i) => (
                  <article
                    key={bankcard.id}
                    className={`mb-5 ${
                      i < bankcards.length - 1
                        ? 'border-bottom-1 pb-4'
                        : 'border-bottom-0'
                    } border-secondary border-bottom-dashed table-row-gray-200`}
                  >
                    <div className="d-flex justify-content-between align-items-center fw-bold fs-4 mb-4">
                      <div>
                        {bankcard?.card_type?.toUpperCase()}{' '}
                        {intl.formatMessage({ id: 'userProfile.endedOn' })}{' '}
                        {bankcard?.last_4_digits}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label className="form-label">
                          {intl.formatMessage({
                            id: 'userProfile.cardNameLabel',
                          })}
                        </label>
                        <p>{bankcard?.name}</p>
                      </div>
                      <div className="col">
                        <label className="form-label d-block mb-1">
                          {intl.formatMessage({
                            id: 'userProfile.cardStateLabel',
                          })}
                        </label>
                        <span
                          className={clsx(
                            'badge mb-0',
                            bankcard?.state === 'valid'
                              ? 'badge-success'
                              : 'badge-warning text-dark'
                          )}
                        >
                          {bankcard?.state}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label className="form-label">
                          {intl.formatMessage({
                            id: 'userProfile.createdAtLabel',
                          })}
                        </label>
                        <p>{intl.formatDate(bankcard?.created_at)}</p>
                      </div>
                      <div className="col">
                        <label className="form-label">
                          {intl.formatMessage({
                            id: 'userProfile.expiresAtLabel',
                          })}
                        </label>
                        <p>{intl.formatDate(bankcard?.expiry_date)}</p>
                      </div>
                    </div>
                    {!!bankcard?.invalid_exception && (
                      <div className="row">
                        <div className="col">
                          <label className="form-label">
                            {intl.formatMessage({
                              id: 'userProfile.invalidExceptionsLabel',
                            })}
                          </label>
                          <p>{bankcard?.invalid_exception}</p>
                        </div>
                        <div className="col">
                          <label className="form-label">
                            {intl.formatMessage({
                              id: 'userProfile.invalidReasonLabel',
                            })}
                          </label>
                          <p>{bankcard?.invalid_reason}</p>
                        </div>
                      </div>
                    )}
                    <div className="d-flex justify-content-s">
                      <button
                        onClick={() => onRemove(bankcard)}
                        type="button"
                        disabled={!!removingBankcard}
                        className="btn btn-sm btn-tertiary text-muted px-0 me-8 align-right"
                        data-kt-users-modal-action="submit"
                        data-kt-indicator={
                          removingBankcard?.id === bankcard.id ? 'on' : 'off'
                        }
                      >
                        <span className="indicator-label">
                          <span className="fa fa-trash-alt me-2" />
                          {intl.formatMessage({
                            id: 'userProfile.removeBankcard',
                          })}
                        </span>
                        <span className="indicator-progress">
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      </button>
                      {bankcard?.is_default ? (
                        <span className="text-success d-flex align-items-center fw-bold">
                          <span className="icon-lg la la-star me-2 fs-3" />
                          {intl.formatMessage({
                            id: 'userProfile.defaultBankcard',
                          })}
                        </span>
                      ) : (
                        <button
                          disabled={!!makingDefaultBankcard}
                          onClick={() => onMakeDefault(bankcard)}
                          type="button"
                          className="btn btn-sm btn-tertiary text-muted px-0 align-right"
                          data-kt-indicator={
                            makingDefaultBankcard?.id === bankcard.id
                              ? 'on'
                              : 'off'
                          }
                          data-kt-users-modal-action="submit"
                        >
                          <span className="indicator-label">
                            <span className="la la-star-o me-2 fs-4" />
                            {intl.formatMessage({
                              id: 'userProfile.makeDefaultBankcard',
                            })}
                          </span>
                          <span className="indicator-progress">
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        </button>
                      )}
                    </div>
                  </article>
                ))}
              </section>
            ) : (
              <>
                <p>{intl.formatMessage({ id: 'userProfile.noBankcards' })}</p>
              </>
            )}
          </>
        )}
      </form>
    </div>
  );
}

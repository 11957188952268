import { useIntl } from 'react-intl';
import { AsideMenuItem } from './AsideMenuItem';
import { useSelector } from 'react-redux';
import { currentUserSelector } from '../../../../app/modules/user-profile';
import { authUserSelector } from '../../../../app/modules/auth';

export function AsideMenuAtc() {
  const intl = useIntl();
  const currentUser = useSelector(currentUserSelector);
  const authUser = useSelector(authUserSelector);

  return (
    <>
      {currentUser && currentUser.id && (
        <>
          <AsideMenuItem
            to={`/user/${currentUser.id}/profile`}
            title={intl.formatMessage({ id: 'menu.userProfile' })}
            fontIcon="fa-user"
          />
          <AsideMenuItem
            to={`/user/${currentUser.id}/comments`}
            title={intl.formatMessage({ id: 'menu.comments' })}
            fontIcon="fa-comment"
          />
          <AsideMenuItem
            to={`/user/${currentUser.id}/wallet`}
            title={intl.formatMessage({ id: 'menu.wallet' })}
            fontIcon="fa-wallet"
          />
          <AsideMenuItem
            to={`/user/${currentUser.id}/pets`}
            title={intl.formatMessage({ id: 'menu.pets' })}
            fontIcon="fa-paw"
          />
          <AsideMenuItem
            to={`/user/${currentUser.id}/plans`}
            title={intl.formatMessage({ id: 'menu.plans' })}
            fontIcon="
        fas fa-calendar-alt"
          />
          <AsideMenuItem
            to={`/user/${currentUser.id}/orders`}
            title={intl.formatMessage({ id: 'menu.orders' })}
            fontIcon="fa-box"
          />
          <AsideMenuItem
            to={`/user/${currentUser.id}/invoices`}
            title={intl.formatMessage({ id: 'menu.invoices' })}
            fontIcon="fa-file-invoice-dollar"
          />
          <AsideMenuItem
            to={`/user/${currentUser.id}/tasks`}
            title={intl.formatMessage({ id: 'menu.tasks' })}
            fontIcon="fa-list-ol"
          />
          <AsideMenuItem
            to={`/user/${currentUser.id}/logs`}
            title={intl.formatMessage({ id: 'menu.logs' })}
            fontIcon="fa-history"
          />
          <AsideMenuItem
            to={`/user/${currentUser.id}/whatsapp-logs`}
            title={intl.formatMessage({ id: 'menu.whatsappLogs' })}
            fontIcon="fa-mobile-alt"
          />
          <AsideMenuItem
            to={`/user/${currentUser.id}/call-logs`}
            title={intl.formatMessage({ id: 'menu.callLogs' })}
            fontIcon="fa-phone-alt"
          />
          <div className="menu-item">
            <div className="menu-content">
              <div className="separator mx-1 my-4"></div>
            </div>
          </div>
        </>
      )}
      {authUser &&
        authUser.groups.find((aGroup) => aGroup.name.includes('ATC')) && (
          <>
            <h5 className="menu-title text-light pb-4 mb-0 mt-2 ms-6">
              {intl.formatMessage({ id: 'menu.atc' })}
            </h5>
            <AsideMenuItem
              to={`/delivery-changes`}
              title={intl.formatMessage({ id: 'menu.deliveryChanges' })}
              fontIcon="fa-edit"
            />
            <AsideMenuItem
              to={`/plan-calculator`}
              title={intl.formatMessage({ id: 'menu.planCalculator' })}
              fontIcon="fa-calculator"
            />
            <AsideMenuItem
              to={`/order-tracking-history`}
              title={intl.formatMessage({ id: 'menu.orderTrackingHistory' })}
              fontIcon="fa-route"
            />
            <AsideMenuItem
              to={`/whatsapp-templates`}
              title={intl.formatMessage({ id: 'menu.whatsappTemplates' })}
              fontIcon="fa-paper-plane"
            />
            <div className="menu-item">
              <div className="menu-content">
                <div className="separator mx-1 my-4"></div>
              </div>
            </div>
          </>
        )}
    </>
  );
}

import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { usePathProps } from '../../modules/user-profile/hooks/usePathProps';
import { PageTitle } from '../../../_metronic/layout/core';
import {
  OrderDetail,
  OrderEditableDetail,
  OrderModel,
  OrderActions,
  OrderPrices,
  OrderLines,
  SingleOrderTracking,
} from '../../modules/orders';
import * as order from '../../modules/orders/redux/OrderRedux';
import DeliveryChangeList from '../../modules/delivery-changes/components/DeliveryChangeList';
import isEditable from '../../modules/orders/services/isEditable';
import { authUserSelector } from '../../modules/auth';

const OrderDetailComponent: FC = () => {
  return (
    <>
      <div className="row position relative">
        <div className="col-md-8">
          <div className="mb-4">
            <OrderEditableDetail />
          </div>
          <div className="mb-4">
            <OrderDetail />
          </div>
          <div className="mb-4">
            <OrderLines
              editEnabled={isEditable({
                order: useSelector(order.selectedOrderSelector),
                authUser: useSelector(authUserSelector),
              })}
            />
          </div>
          <div className="mb-4">
            <OrderPrices />
          </div>
          <div className="mb-4">
            <SingleOrderTracking />
          </div>
          <div className="mb-4">
            <DeliveryChangeList onlySelectedOrder />
          </div>
        </div>
        <div className="col-md-4">
          <div className="">
            <div className="border-error w-100">
              <div className="position-fixed">
                <OrderActions />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const OrderDetailPage: FC = () => {
  const intl = useIntl();
  usePathProps();
  const selectedOrder: OrderModel | undefined = useSelector(
    order.selectedOrderSelector
  );

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage(
          { id: 'orderDetail.title' },
          { code: selectedOrder?.code }
        )}
      </PageTitle>
      <OrderDetailComponent />
    </>
  );
};

export { OrderDetailPage };

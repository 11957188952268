import { useIntl } from 'react-intl';
import { AsideMenuItem } from './AsideMenuItem';
import { useSelector } from 'react-redux';
import { authUserSelector } from '../../../../app/modules/auth';

export function AsideMenuB2B() {
  const intl = useIntl();
  const authUser = useSelector(authUserSelector);
  const isB2BUser = !!authUser?.groups?.find((aGroup) => aGroup.name === 'B2B');

  return isB2BUser ? (
    <>
      <h5 className="menu-title text-light pb-4 mb-0 mt-2 ms-6">
        {intl.formatMessage({ id: 'menu.b2b' })}
      </h5>
      <AsideMenuItem
        to={`/search-shops`}
        title={intl.formatMessage({ id: 'menu.searchShops' })}
        fontIcon="fa-search"
      />
      <AsideMenuItem
        to={`/lead-distributors`}
        title={intl.formatMessage({ id: 'menu.leadDistributors' })}
        fontIcon="fa-handshake"
      />
      <AsideMenuItem
        to={`/b2b-lead-orders`}
        title={intl.formatMessage({ id: 'menu.b2bLeadOrders' })}
        fontIcon="fa-gift"
      />
      <AsideMenuItem
        to={`/shops`}
        title={intl.formatMessage({ id: 'menu.shops' })}
        fontIcon="fa-store"
      />
      <AsideMenuItem
        to={`/distributors`}
        title={intl.formatMessage({ id: 'menu.distributors' })}
        fontIcon="fa-truck"
      />
      <AsideMenuItem
        to={`/b2b-orders`}
        title={intl.formatMessage({ id: 'menu.b2bOrders' })}
        fontIcon="fa-box"
      />
      <AsideMenuItem
        to={`/delivery-notes`}
        title={intl.formatMessage({ id: 'menu.deliveryNotes' })}
        fontIcon="fa-file-alt"
      />
      <AsideMenuItem
        to={`/invoices`}
        title={intl.formatMessage({ id: 'menu.invoices' })}
        fontIcon="fa-file-invoice-dollar"
      />
      <div className="menu-item">
        <div className="menu-content">
          <div className="separator mx-1 my-4"></div>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
}

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import * as userRedux from '../redux/UserRedux';
import * as petRedux from '../../pets/redux/PetRedux';
import * as planRedux from '../../plans/redux/PlanRedux';
import * as orderRedux from '../../orders/redux/OrderRedux';
import * as taskRedux from '../../tasks/redux/TaskRedux';

interface RouteParams {
  id?: string;
  petId?: string;
  planId?: string;
  orderId?: string;
  taskId?: string;
}

export function usePathProps() {
  const dispatch = useDispatch();
  const { id, petId, planId, orderId, taskId } = useParams<RouteParams>();
  const currentUser = useSelector(userRedux.currentUserSelector);
  const selectedPet = useSelector(petRedux.selectedPetSelector);
  const selectedPlan = useSelector(planRedux.selectedPlanSelector);
  const selectedOrder = useSelector(orderRedux.selectedOrderSelector);
  const selectedTask = useSelector(taskRedux.selectedTaskSelector);

  useEffect(() => {
    if (id?.length) {
      const numericId = parseInt(id, 10);
      if (numericId !== currentUser?.id) {
        dispatch(userRedux.actions.requestUser(numericId));
      }
    } else {
      dispatch(userRedux.actions.resetUser());
    }

    if (petId?.length) {
      const numericPetId = parseInt(petId, 10);
      if (numericPetId !== selectedPet?.id) {
        dispatch(petRedux.actions.requestPet(numericPetId));
      }
    }

    if (planId?.length) {
      const numericPlanId = parseInt(planId, 10);
      if (numericPlanId !== selectedPlan?.id) {
        dispatch(planRedux.actions.requestPlan(numericPlanId));
      }
    }

    if (orderId?.length) {
      const numericOrderId = parseInt(orderId, 10);
      if (numericOrderId !== selectedOrder?.id) {
        dispatch(orderRedux.actions.requestOrder(numericOrderId));
      }
    }

    if (taskId?.length) {
      const numericTaskId = parseInt(taskId, 10);
      if (numericTaskId !== selectedTask?.id) {
        dispatch(taskRedux.actions.requestTask(numericTaskId));
      }
    }
  }, [
    dispatch,
    id,
    currentUser?.id,
    petId,
    selectedPet?.id,
    planId,
    selectedPlan?.id,
    orderId,
    selectedOrder?.id,
    taskId,
    selectedTask?.id,
  ]);
}

export enum InvoiceStateEnum {
  PENDING_PAYMENT = 'pending-payment',
  PAID = 'paid',
  CANCELLED = 'cancelled',
  PARTIAL = 'partial',
}

export interface InvoiceModel {
  id: number;
  code: string;
  number: string;
  date: string;
  state: InvoiceStateEnum;
  edi_file_url?: string;
  pdf_file_url?: string;
  order: number;
  is_proforma: boolean;
  is_credit: boolean;
  is_preview: boolean;
}

import { useState, useMemo } from 'react';
import debounce from 'lodash.debounce';
import { ClientModel } from '../models/ClientModel';
import { searchClients } from '../services/SearchClients';
import { TaskModel } from '../../tasks';

const DEBOUNCE_MILLISECONDS = 800;

export function useSearchBarState() {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [clients, setClients] = useState<Array<ClientModel>>([]);
  const [tasks, setTasks] = useState<Array<TaskModel>>([]);
  const [loading, setLoading] = useState(false);

  async function fetchClients(event: any) {
    setLoading(true);
    try {
      const query = event?.target?.value?.trim();

      if (query) {
        const results = await searchClients(query);
        setClients(results.data?.users);
        setTasks(results.data?.orphan_tasks);
      } else {
        setClients([]);
        setTasks([]);
      }
    } catch (error) {
      console.warn(error);
      setClients([]);
    } finally {
      setLoading(false);
    }
  }

  const onChange = useMemo(
    () => debounce((event) => fetchClients(event), DEBOUNCE_MILLISECONDS),
    []
  );

  return {
    dropdownVisible,
    setDropdownVisible,
    clients,
    setClients,
    tasks,
    setTasks,
    loading,
    setLoading,
    onChange,
  };
}

import { FC } from 'react';
import { useIntl } from 'react-intl';

import { PageTitle } from '../../../_metronic/layout/core';
import { AtcDashboard } from '../../modules/dashboard';
import LeadStats from '../../modules/dashboard/components/LeadStats';

const AtcDashboardPage: FC = () => {
  const intl = useIntl();

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'atcDashboard.title' })}
      </PageTitle>
      <div className="row gy-5 g-xl-8">
        <div className="card p-10">
          <LeadStats />
        </div>
        <div className="card p-10">
          <AtcDashboard />
        </div>
      </div>
    </>
  );
};

export { AtcDashboardPage };

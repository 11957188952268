import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { PageTitle } from '../../../_metronic/layout/core';
import DistributorDetail from '../../modules/distributors/components/DistributorDetail';
import DistributorAddresses from '../../modules/distributors/components/DistributorAddresses';
import DistributorFiles from '../../modules/distributors/components/DistributorFiles';

type RouteParams = {
  distributorId: string;
};

const DistributorDetailPage: FC = () => {
  const intl = useIntl();
  const { distributorId } = useParams<RouteParams>();

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'distributorDetail.title' })}
      </PageTitle>
      <div className="row">
        <div className="col-6 d-flex flex-column gap-8">
          <DistributorDetail id={distributorId} />
          <DistributorFiles id={distributorId} />
        </div>
        <div className="col-6 d-flex flex-column gap-8">
          <DistributorAddresses id={distributorId} />
        </div>
      </div>
    </>
  );
};

export { DistributorDetailPage };

import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { currentUserSelector } from '../../user-profile';
import clsx from 'clsx';

export default function PetComments({ loading = false, comments = [] }) {
  const intl = useIntl();
  const currentUser = useSelector(currentUserSelector);

  return (
    <>
      <h1 className="mb-4">
        {intl.formatMessage({ id: 'comments.petComments' })}
      </h1>
      {loading && <div className="spinner-border text-primary" role="status" />}
      {!loading && comments.length === 0 && (
        <p className="text-muted">
          {intl.formatMessage({ id: 'comments.noPetComments' })}
        </p>
      )}
      {!loading &&
        comments.length > 0 &&
        comments.map((comment) => {
          return (
            <div
              key={comment.id}
              className={clsx(
                'rounded border border-dashed p-4 mb-2',
                comment?.notes?.length > 0
                  ? 'border-primary bg-light-primary'
                  : 'border-muted'
              )}
            >
              <a
                href={`/user/${currentUser?.id}/pet/${comment.id}`}
                className={clsx(
                  comment?.notes?.length > 0 ? 'text-primary' : 'text-muted'
                )}
              >
                <strong>{comment.name}</strong>
              </a>
              {comment?.notes?.length > 0 ? (
                <p className="mb-0">{comment.notes}</p>
              ) : (
                <p className="text-muted mb-0">
                  {intl.formatMessage({ id: 'comments.noCommentsForThisPet' })}
                </p>
              )}
            </div>
          );
        })}
    </>
  );
}

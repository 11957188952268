import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export function RewardRow({ id, reward, onEdit, onDelete }) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div className="card shadow pe-4" ref={setNodeRef} style={style}>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex gap-0 align-items-center">
          <i
            className="bi bi-grip-vertical fs-1 d-block px-4 py-8"
            {...listeners}
            {...attributes}
          ></i>
          <div>
            <h4 className="mb-1">{reward.name_es}</h4>
            <div>{reward.description_es}</div>
          </div>
        </div>
        <div className="w-150px d-flex gap-0 justify-content-end">
          <button className="btn btn-textual" onClick={onEdit}>
            <span className="fa-icon fas fa-edit"></span>
          </button>
          <button className="btn btn-textual text-danger" onClick={onDelete}>
            <span className="fa-icon fas fa-trash"></span>
          </button>
        </div>
      </div>
    </div>
  );
}

import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../redux/OrderRedux';
import { listSaleableProducts } from '../services/OrderCRUD';
import { currentUserSelector } from '../../user-profile';

const FR_STORE_ID = 6;
const ES_STORE_ID = 1;

export default function AddProductModal({ visible, onClose }) {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [products, setProducts] = useState(null);
  const [units, setUnits] = useState(1);
  const [isFree, setIsFree] = useState(false);
  const intl = useIntl();
  const dispatch = useDispatch();
  const currentUser = useSelector(currentUserSelector);

  function getStoreId(user) {
    if (user?.store.country.code.toLowerCase() === 'fr') {
      return FR_STORE_ID;
    }
    return ES_STORE_ID;
  }

  async function fetchProducts() {
    try {
      const { data } = await listSaleableProducts(getStoreId(currentUser));

      setProducts(
        data
          .map((product) => ({
            value: product.id,
            label: `${product.name} - ${product?.price || '-'}€`,
            disabled: !product?.price,
          }))
          .sort((a, b) => a.label.localeCompare(b.label))
      );
    } catch (error) {
      console.warn(error);
    }
  }

  useEffect(() => {
    if (products?.length === 1) {
      setSelectedProduct(products[0]);
    }
  }, [products]);

  useEffect(() => {
    setSelectedProduct(null);
    fetchProducts();
  }, [currentUser?.store.country.code]);

  useEffect(() => {
    setSelectedProduct(null);
    setUnits(1);
    setIsFree(false);
  }, [visible]);

  function onConfirm() {
    dispatch(
      actions.setProductUnitsInSelectedOrder({
        productId: parseInt(selectedProduct.value, 10),
        units,
        isFree,
      })
    );
    onClose();
  }

  return (
    <Modal show={visible} onClose={onClose}>
      <Modal.Header>
        <h4>
          <FormattedMessage id="orderDetail.addProductToOrder" />
        </h4>
      </Modal.Header>
      <Modal.Body>
        {
          <div className="form-group mb-6">
            <label className="fw-bolder mb-2">
              <FormattedMessage id="orderDetail.selectProduct" />
            </label>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              options={products}
              value={selectedProduct}
              isOptionDisabled={(option) => option.disabled}
              onChange={setSelectedProduct}
              placeholder={intl.formatMessage({ id: 'orderDetail.select' })}
            />
          </div>
        }
        {selectedProduct && (
          <div className="d-flex justify-content-start align-items-start gap-5">
            <div className="form-group w-75">
              <label className="fw-bolder mb-2">
                <FormattedMessage id="orderDetail.units" />
              </label>
              <input
                min={1}
                max={1000}
                type="number"
                className="form-control"
                value={units}
                onChange={(e) => setUnits(parseInt(e.target.value, 10))}
              />
            </div>
            <div className="form-group w-25">
              <label className="d-block fw-bolder mb-2">
                <FormattedMessage id="orderDetail.isFree" />
              </label>
              <label className="form-check form-switch form-switch-md form-check-custom form-check-solid mt-4">
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={isFree}
                  onChange={(e) => setIsFree(e.target.checked)}
                />
              </label>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-between w-100">
          <Button variant="tertiary" onClick={onClose}>
            <FormattedMessage id="orderDetail.close" />
          </Button>
          <Button
            variant="primary"
            onClick={onConfirm}
            disabled={!(selectedProduct && units > 0)}
          >
            <FormattedMessage id="orderDetail.addProduct" />
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

import { Link } from 'react-router-dom';
import { CallLog } from '../models/CallLog';
import Waveform from './Waveform';
import { useIntl } from 'react-intl';

export function CallLogCard({ callLog }: { callLog: CallLog }) {
  const intl = useIntl();

  return (
    <div className="card px-8 py-5 w-800px shadow">
      <div className="d-flex justify-content-between align-items-center gap-4">
        <div className="w-300px d-flex flex-column gap-1 justify-content-center align-items-start">
          <span
            className="badge badge-primary mb-1"
            style={{ marginLeft: '-2px' }}
          >
            {callLog.direction === 'inbound' ? (
              <span className="fas fa-arrow-down me-2"></span>
            ) : (
              <span className="fas fa-arrow-up me-2"></span>
            )}
            {callLog.direction === 'inbound'
              ? intl.formatMessage({ id: 'callLogs.inbound' })
              : intl.formatMessage({ id: 'callLogs.outbound' })}
          </span>
          <span className="">
            {callLog.direction === 'inbound'
              ? intl.formatMessage({ id: 'callLogs.receivedBy' })
              : intl.formatMessage({ id: 'callLogs.madeBy' })}
            {
              <Link
                to={
                  callLog?.agent?.id
                    ? `/user/${callLog?.agent?.id}/profile`
                    : '#'
                }
              >
                {callLog.agent?.name || callLog.agent?.email || '-'}
              </Link>
            }
          </span>
          <span className="">{callLog.phone}</span>
          <span className="text-muted">
            {new Date(callLog.started_at).toLocaleString('es-ES', {
              day: 'numeric',
              month: 'short',
              year: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
            })}{' '}
            ·{' '}
            {new Date(callLog.duration * 1000)
              .toISOString()
              ?.substr(11, 8)
              ?.split(':')
              ?.slice(-2)
              ?.map((v, i) => (i === 0 ? `${v}m` : `${v}s`))
              ?.join(' ')
              ?.replace('00m', '')
              ?.replace('00s', '')}
          </span>
        </div>
        <div className="w-100">
          {callLog?.recording && <Waveform url={callLog.recording} />}
          {!callLog?.recording && (
            <span className="text-muted">
              {intl.formatMessage({ id: 'callLogs.noRecording' })}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

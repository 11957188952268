import { FC } from 'react';
import { useIntl } from 'react-intl';

import { PageTitle } from '../../../_metronic/layout/core';
import { LoyaltyRewards } from '../../modules/loyalty';

const LoyaltyRewardsPage: FC = () => {
  const intl = useIntl();

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'app.loyaltyRewards' })}
      </PageTitle>
      <div className="row gy-4 mw-700px">
        <div className="card p-10">
          <LoyaltyRewards />
        </div>
      </div>
    </>
  );
};

export { LoyaltyRewardsPage };

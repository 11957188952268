export enum Source {
  VET_EXT = 'vet_ext',
  RRSS = 'rrss',
  FRIEND = 'friend',
  TV = 'tv',
  DID_NOT_ANSWER = 'nc',
}

export interface TaskSetResolutionModel {
  task_resolution: number;
  dispatch_after?: string;
  task_resolution_target?: number;
  group?: number;
  owner?: number;
  additional_info?: string;
  source?: Source;
  is_from_competitor?: boolean;
  competitor?: string;
}

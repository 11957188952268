import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as userRedux from '../redux/UserRedux';
import * as taskRedux from '../../tasks/redux/TaskRedux';
import { UserModel } from '../models/UserModel';
import { useIntl } from 'react-intl';
import { FormField, GenericForm } from '../../../components/GenericForm';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  ACQUISITION_GROUP_ID,
  SUBSCRIPTION_GROUP_ID,
  PET_CARE_GROUP_ID,
} from '../../tasks/redux/TaskRedux';

export default function Agents() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const currentUser: UserModel | undefined = useSelector(
    userRedux.currentUserSelector,
    shallowEqual
  );
  const status: string = useSelector(
    userRedux.userStatusSelector,
    shallowEqual
  );
  const staffUsers = useSelector(taskRedux.staffUsersSelector, shallowEqual);
  const error: any | undefined = useSelector(
    userRedux.userErrorSelector,
    shallowEqual
  );
  const [editing, setEditing] = useState(false);
  const getStaffUsersByGroupId = (groupId) => {
    return staffUsers
      ?.filter((staffUser) =>
        staffUser.cs_groups.find((aGroup) => aGroup.id === groupId)
      )
      ?.map((staffUser) => ({ value: staffUser?.id, label: staffUser?.name }));
  };
  const acquisitionOptions = getStaffUsersByGroupId(ACQUISITION_GROUP_ID);
  const subscriptionOptions = getStaffUsersByGroupId(SUBSCRIPTION_GROUP_ID);
  const petCareOptions = getStaffUsersByGroupId(PET_CARE_GROUP_ID);
  const formFields: Array<FormField<UserModel>> = [
    {
      id: 'cs_acquisition_agent',
      label: intl.formatMessage({ id: 'userProfile.acquisitionAgent' }),
      inputType: 'select',
      options: acquisitionOptions,
    },
    {
      id: 'cs_subscription_agent',
      label: intl.formatMessage({ id: 'userProfile.subscriptionAgent' }),
      inputType: 'select',
      options: subscriptionOptions,
    },
    {
      id: 'cs_pet_care_agent',
      label: intl.formatMessage({ id: 'userProfile.petCareAgent' }),
      inputType: 'select',
      options: petCareOptions,
    },
  ];

  function onSubmit(updatedUserModel) {
    const { cs_acquisition_agent, cs_subscription_agent, cs_pet_care_agent } =
      updatedUserModel;

    dispatch(
      userRedux.actions.updateAgents({
        id: currentUser?.id,
        props: {
          cs_acquisition_agent,
          cs_subscription_agent,
          cs_pet_care_agent,
        },
      })
    );
  }

  useEffect(() => {
    switch (status) {
      case 'success_update_agents':
        setEditing(false);
        toast.success(intl.formatMessage({ id: 'userProfile.agentsUpdated' }));
        break;
      case 'error_update_agents':
        toast.error(
          intl.formatMessage({ id: 'userProfile.agentsUpdateError' })
        );
    }
  }, [status]);

  return (
    <div className="card p-10">
      {status === 'loading_request_user' ? (
        <div className="spinner-border text-primary" role="status" />
      ) : (
        <>
          {currentUser ? (
            <GenericForm
              columns={1}
              error={error}
              editing={editing}
              toggleEdit={() => setEditing(!editing)}
              title={intl.formatMessage({ id: 'userProfile.agentsTitle' })}
              initialState={currentUser}
              fields={formFields}
              ctaLabel={intl.formatMessage({ id: 'userProfile.saveChanges' })}
              onSubmit={onSubmit}
              submitting={status === 'loading_update_agents'}
              submittingLabel={intl.formatMessage({
                id: 'userProfile.pleaseWait',
              })}
            />
          ) : (
            <p>{intl.formatMessage({ id: 'userProfile.noUser' })}</p>
          )}
        </>
      )}
    </div>
  );
}

import { useEffect, useState } from 'react';
import DateRangeSelector from '../../../components/DateRangeSelector';
import { useIntl } from 'react-intl';
import moment from 'moment';
import {
  listEstimatedSalesByRecipe,
  SaleItem,
} from '../services/DashboardCRUD';
import { toast } from 'react-toastify';

export default function RecipeSalesReport() {
  const intl = useIntl();
  const initialSince = new Date(new Date().setDate(new Date().getDate() + 7));
  const initialUntil = new Date(new Date().setDate(new Date().getDate() + 14));
  const [since, setSince] = useState(initialSince);
  const [until, setUntil] = useState(initialUntil);
  const [generatingSalesReport, setGeneratingSalesReport] = useState(false);
  const [recipeSales, setRecipeSales] = useState<{
    [key: string]: number;
  } | null>(null);

  async function generateSalesReport() {
    try {
      setGeneratingSalesReport(true);
      const { data: sales } = await listEstimatedSalesByRecipe({
        since: moment(since).format('YYYY-MM-DD'),
        until: moment(until).format('YYYY-MM-DD'),
      });
      setRecipeSales(sales);
    } catch (error) {
      toast.error(
        intl.formatMessage({ id: 'businessDashboard.recipeSalesReportError' })
      );
      console.warn(error);
    } finally {
      setGeneratingSalesReport(false);
    }
  }

  useEffect(() => {
    if (since && until) {
      generateSalesReport();
    }
  }, [since, until]);

  return (
    <>
      <h1 className="title mb-8">
        {intl.formatMessage({
          id: 'businessDashboard.generateRecipeSalesReport',
        })}
      </h1>
      <div className="d-flex gap-4 align-items-end">
        <DateRangeSelector
          initialSince={initialSince}
          initialUntil={initialUntil}
          onSelected={(since, until) => {
            setSince(since);
            setUntil(until);
          }}
          disableFutureDates={false}
        />
        {generatingSalesReport && (
          <span className="spinner-border spinner-border-sm mb-5 text-primary ms-2" />
        )}
      </div>
      {!generatingSalesReport && (
        <section
          className="card shadow mt-8 p-10"
          style={{
            width: 'fit-content',
          }}
        >
          <h2 className="mb-8">
            {intl.formatMessage({ id: 'businessDashboard.recipeSales' })}
          </h2>
          {recipeSales && Object.keys(recipeSales).length > 0 ? (
            <table className="table table-striped table-hover table-bordered w-600px">
              <thead className="table-light fw-bold">
                <tr>
                  <th className="ps-4">
                    {intl.formatMessage({ id: 'businessDashboard.product' })}
                  </th>
                  <th className="pe-4">
                    {intl.formatMessage({ id: 'businessDashboard.units' })}
                  </th>
                </tr>
              </thead>
              <tbody className="table-body">
                {Object.keys(recipeSales).map((key, index) => (
                  <tr key={index} className="table-row">
                    <td className="ps-4">{key}</td>
                    <td className="pe-4">{recipeSales[key]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>{intl.formatMessage({ id: 'businessDashboard.noTopSales' })}</p>
          )}
        </section>
      )}
    </>
  );
}

import { FC } from 'react';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../_metronic/layout/core';
import { CallLogList } from '../../modules/call-logs';
import { usePathProps } from '../../modules/user-profile/hooks/usePathProps';

export const CallLogsPage: FC = () => {
  usePathProps();
  const intl = useIntl();

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'menu.callLogs' })}
      </PageTitle>
      <div className="row">
        <div className="col-12">
          <CallLogList />
        </div>
      </div>
    </>
  );
};

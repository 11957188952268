import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { PageTitle } from '../../../_metronic/layout/core';
import {
  ShopifyOrder,
  ShopifyOrderList,
  ShopifyOrderStateEnum,
  BulkActionsEnum,
  listShopifyOrders,
  performBulkAction,
} from '../../modules/shopify';
import DateRangeSelector from '../../components/DateRangeSelector';
import moment from 'moment';
import { listShippingServices } from '../../modules/plans';

const ANY_STATE = 'ANY_STATE';

const ShopifyOrdersPage: FC = () => {
  const intl = useIntl();
  const [orders, setOrders] = useState<Array<ShopifyOrder>>([]);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState<ShopifyOrderStateEnum | null>(
    ShopifyOrderStateEnum.LOCKED
  );
  const [since, setSince] = useState<string | null>(null);
  const [until, setUntil] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const initialSince = moment().add(1, 'days').toDate();
  const initialUntil = moment().add(7, 'days').toDate();
  const [shippingServices, setShippingServices] = useState([]);
  const [selectedShippingService, setSelectedShippingService] = useState(null);

  useEffect(() => {
    if (!shippingServices?.length) {
      fetchShippingServices();
    }
  }, []);

  async function fetchShippingServices() {
    try {
      const { data: shippingServices } = await listShippingServices();
      setShippingServices(shippingServices);
    } catch (error) {
      console.warn({ error });
    }
  }

  function onPageChange(page: number) {
    setPage(page);
  }

  async function fetchOrders(withLoader = true) {
    try {
      if (withLoader) {
        setLoading(true);
      }
      const { data } = await listShopifyOrders({
        state: state,
        since,
        until,
        page,
        shippingService: selectedShippingService,
      });
      setOrders(data.results);
      setCount(data.count);
    } catch (error) {
      console.warn(error);
    } finally {
      if (withLoader) {
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    if (since && until && page > -1) {
      fetchOrders();
    }
  }, [state, since, until, selectedShippingService, page]);

  useEffect(() => {
    setPage(1);
  }, [state, since, until, selectedShippingService]);

  function onStateChange(event: React.ChangeEvent<HTMLSelectElement>) {
    if (event.target.value === ANY_STATE) {
      setState(null);
    } else {
      setState(event.target.value as ShopifyOrderStateEnum);
    }
  }

  useEffect(() => {
    onAcceptDateRange(initialSince, initialUntil);
  }, []);

  async function onPerformBulkAction(
    action: BulkActionsEnum,
    orders: number[]
  ) {
    return performBulkAction({
      action,
      orders,
      promise_date__gte: since,
      promise_date__lte: until,
      shippingService: selectedShippingService,
      state,
    });
  }

  function onAcceptDateRange(since: Date, until: Date) {
    setSince(
      `${since.getFullYear()}-${(since.getMonth() + 1).toString().padStart(2, '0')}-${since.getDate().toString().padStart(2, '0')}`
    );
    setUntil(
      `${until.getFullYear()}-${(until.getMonth() + 1).toString().padStart(2, '0')}-${until.getDate().toString().padStart(2, '0')}`
    );
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'shopifyOrders.title' })}
      </PageTitle>
      <div className="row">
        <div className="col-12">
          <div className="card p-10">
            <ShopifyOrderList
              orders={orders}
              loading={loading}
              page={page}
              count={count}
              onPageChange={onPageChange}
              onRefresh={fetchOrders}
              onBulkPrintTags={(orders) =>
                onPerformBulkAction(BulkActionsEnum.PRINT_TAGS, orders)
              }
              onBulkGeneratePickingSheet={(orders) =>
                onPerformBulkAction(
                  BulkActionsEnum.GENERATE_PICKING_SHEET,
                  orders
                )
              }
              onBulkGenerateWarehouseOperationsSheet={(orders) =>
                onPerformBulkAction(
                  BulkActionsEnum.GENERATE_WAREHOUSE_OPERATIONS_SHEET,
                  orders
                )
              }
              onBulkSetProcessing={(orders) =>
                onPerformBulkAction(BulkActionsEnum.SET_PROCESSING, orders)
              }
              onBulkGenerateProductsNecessitySheet={(orders) =>
                onPerformBulkAction(
                  BulkActionsEnum.GENERATE_PRODUCTS_NECESSITY_SHEET,
                  orders
                )
              }
            >
              <div className="col-12">
                <div className="d-inline-block me-3">
                  <DateRangeSelector
                    initialSince={initialSince}
                    initialUntil={initialUntil}
                    onSelected={onAcceptDateRange}
                    disableFutureDates={false}
                  />
                </div>
                <div className="form-group w-180px d-inline-block me-3">
                  <label className="fw-bolder mb-1">
                    {intl.formatMessage({ id: 'shopifyOrders.filterByState' })}
                  </label>
                  <select
                    className="form-control form-select"
                    value={state}
                    onChange={onStateChange}
                  >
                    <option value={ANY_STATE}>
                      {intl.formatMessage({ id: 'shopifyOrders.anyState' })}
                    </option>
                    {Object.values(ShopifyOrderStateEnum).map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group w-180px d-inline-block">
                  <label className="fw-bolder mb-1">
                    {intl.formatMessage({
                      id: 'shopifyOrders.filterShippingService',
                    })}
                  </label>
                  <select
                    className="form-control form-select"
                    value={selectedShippingService}
                    onChange={(e) => {
                      if (e.target.value === ANY_STATE) {
                        setSelectedShippingService(null);
                      } else {
                        setSelectedShippingService(e.target.value);
                      }
                    }}
                  >
                    <option value={ANY_STATE}>
                      {intl.formatMessage({
                        id: 'shopifyOrders.anyShippingService',
                      })}
                    </option>
                    {shippingServices.map((service) => (
                      <option key={service.id} value={service.id}>
                        {service.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </ShopifyOrderList>
          </div>
        </div>
      </div>
    </>
  );
};

export { ShopifyOrdersPage };

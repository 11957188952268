import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import * as petRedux from '../redux/PetRedux';
import * as planRedux from '../../plans/redux/PlanRedux';
import { PetModel } from '../models/PetModel';
import { getPetAvatar } from '../services/getPetAvatar';
import { currentUserSelector } from '../../user-profile';
import { PlanListingModel } from '../../plans/models/PlanListingModel';
import clsx from 'clsx';
import { actions, petStatusSelector } from '../redux/PetRedux';
import { useEffect } from 'react';

export default function PetListComponent() {
  const intl = useIntl();
  const pets: PetModel[] = useSelector(petRedux.petsSelector, shallowEqual);
  const plans: PlanListingModel[] = useSelector(
    planRedux.plansSelector,
    shallowEqual
  );
  const history = useHistory();
  const currentUser = useSelector(currentUserSelector, shallowEqual);
  const status = useSelector(petStatusSelector, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.listPets(currentUser?.id));
  }, [dispatch, currentUser?.id]);

  function getPlanState(pet) {
    return plans?.find((plan) => plan.pet_id === pet.id)?.state || false;
  }

  function onPetClick(pet: PetModel) {
    history.push(`/user/${currentUser?.id}/pet/${pet?.id}/`);
  }

  return (
    <main className="card p-10">
      <h1 className="mb-8">{intl.formatMessage({ id: 'pets.userPets' })}</h1>
      {status === 'loading_list_pets' && (
        <div className="spinner spinner-border text-primary" />
      )}
      {status === 'idle' && pets?.length > 0 && (
        <>
          <div className="table-responsive">
            <table className="table table-hover table-row-dashed table-row-gray-200 align-start gs-0 gy-4">
              <thead className="table-header sticky-top bg-white">
                <tr className="text-end fw-bolder">
                  <th className="w-70px">
                    {intl.formatMessage({ id: 'pets.pet' })}
                  </th>
                  <th className=""></th>
                  <th className="text-center">
                    {intl.formatMessage({ id: 'pets.hasPriority' })}
                  </th>
                  <th className="text-center">
                    {intl.formatMessage({ id: 'pets.neutered' })}
                  </th>
                  <th className="">
                    {intl.formatMessage({ id: 'pets.gender' })}
                  </th>
                  <th className="">{intl.formatMessage({ id: 'pets.age' })}</th>
                  <th className="">
                    {intl.formatMessage({ id: 'pets.weight' })}
                  </th>
                  <th className="">
                    {intl.formatMessage({ id: 'pets.planState' })}
                  </th>
                </tr>
              </thead>
              <tbody>
                {pets?.map((pet: PetModel) => (
                  <tr
                    className="cursor-pointer"
                    key={pet.id}
                    onClick={() => onPetClick(pet)}
                  >
                    <td>
                      <div className="symbol symbol-50px me-2">
                        <span className="symbol-label overflow-visible">
                          <img
                            src={getPetAvatar(pet)}
                            className="h-50px w-50px rounded-2 align-self-center"
                            alt=""
                          />
                          {pet?.from_ftk && (
                            <img
                              src="/media/logos/frankie.jpg"
                              alt="Frankie the King"
                              style={{
                                transform: 'translate(50%, -50%)',
                              }}
                              className="h-20px w-20px rounded-2 position-absolute top-0 end-0"
                            />
                          )}
                        </span>
                      </div>
                    </td>
                    <td>
                      <span className="text-dark fw-bolder text-hover-primary mb-1 fs-6">
                        {pet?.name || intl.formatMessage({ id: 'pets.noName' })}
                      </span>
                      <span className="text-muted fw-bold d-block">
                        {pet?.breed?.name ||
                          intl.formatMessage({ id: 'pets.unknownBreed' })}
                      </span>
                    </td>
                    <td className="text-center text-muted fw-bold">
                      {pet?.is_priority_pet
                        ? intl.formatMessage({ id: 'pets.yes' })
                        : intl.formatMessage({ id: 'pets.no' })}
                    </td>
                    <td className="text-center text-muted fw-bold">
                      {pet?.is_neutered
                        ? intl.formatMessage({ id: 'pets.yes' })
                        : intl.formatMessage({ id: 'pets.no' })}
                    </td>
                    <td className="text-end text-muted fw-bold">
                      <p className="mb-0">
                        {pet?.gender === 'male' &&
                          intl.formatMessage({ id: 'pets.male' })}
                        {pet?.gender === 'female' &&
                          intl.formatMessage({ id: 'pets.female' })}
                        {!pet?.gender &&
                          intl.formatMessage({ id: 'pets.unknownGender' })}
                      </p>
                    </td>
                    <td className="text-end text-muted fw-bold">
                      <p className="mb-0">
                        {pet?.age_years > 0 &&
                          intl.formatMessage(
                            { id: 'pets.years' },
                            { years: pet?.age_years }
                          )}
                        &nbsp;
                        {pet?.age_months > 0 &&
                          intl.formatMessage(
                            { id: 'pets.months' },
                            { months: pet?.age_months }
                          )}
                        {!pet?.age_years &&
                          !pet?.age_months &&
                          intl.formatMessage({ id: 'pets.unknownAge' })}
                      </p>
                    </td>
                    <td className="text-end">
                      <span className="badge badge-light-primary">
                        {pet?.weight_in_kg > 0
                          ? pet?.weight_in_kg + ' kg'
                          : intl.formatMessage({ id: 'pets.noWeight' })}
                      </span>
                    </td>
                    <td className="text-end">
                      {getPlanState(pet) ? (
                        <span
                          className={clsx(
                            'badge',
                            getPlanState(pet) === 'active' && 'badge-success',
                            getPlanState(pet) === 'proposal' && 'badge-warning',
                            getPlanState(pet) !== 'active' &&
                              getPlanState(pet) !== 'proposal' &&
                              'badge-danger'
                          )}
                        >
                          {getPlanState(pet)}
                        </span>
                      ) : (
                        <span className="badge badge-secondary">
                          {intl.formatMessage({ id: 'pets.noPlan' })}
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
      {status === 'idle' && pets?.length === 0 && (
        <p>{intl.formatMessage({ id: 'pets.noPets' })}</p>
      )}
    </main>
  );
}

import axios from 'axios';
import { BillingAddressModel } from '../models/BillingAddressModel';

const API_URL = process.env.REACT_APP_API_URL;
const BILLING_ADDRESSES_URL = `${API_URL}/api/b2b/billing-addresses/`;

export function listDistributorBillingAddresses(
  distributoId: number
): Promise<{ data: Array<BillingAddressModel> }> {
  return axios.get(BILLING_ADDRESSES_URL, {
    params: { distributor: distributoId, state: 'active' },
  });
}

export function retrieveDistributorBillingAddress(
  id: number
): Promise<{ data: BillingAddressModel }> {
  return axios.get(`${BILLING_ADDRESSES_URL}${id}/`);
}

export function createDistributorBillingAddress(
  address: BillingAddressModel
): Promise<{ data: BillingAddressModel }> {
  return axios.post(BILLING_ADDRESSES_URL, address);
}

export function updateDistributorBillingAddress(
  address: BillingAddressModel
): Promise<{ data: BillingAddressModel }> {
  return axios.patch(`${BILLING_ADDRESSES_URL}${address.id}/`, address);
}

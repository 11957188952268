import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { PageTitle } from '../../../_metronic/layout/core';
import {
  UserComments,
  PetComments,
  TaskComments,
} from '../../modules/comments';
import { usePathProps } from '../../modules/user-profile/hooks/usePathProps';
import { useSelector } from 'react-redux';
import { currentUserSelector } from '../../modules/user-profile';
import listComments, {
  CommentsResponse,
} from '../../modules/comments/services/listComments';
import DeliveryChanges from '../../modules/comments/components/DeliveryChanges';

const CommentsPage: FC = () => {
  const intl = useIntl();
  usePathProps();
  const currentUser = useSelector(currentUserSelector);
  const [comments, setComments] = useState<CommentsResponse>({
    user_notes: '',
    pets_notes: [],
    tasks: [],
    delivery_changes: [],
  });
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState('user');

  useEffect(() => {
    const fetchComments = async (userId) => {
      try {
        setLoading(true);
        const { data } = await listComments(userId);
        setComments(data);
      } catch (error) {
        console.warn(error);
      } finally {
        setLoading(false);
      }
    };
    if (currentUser?.id) {
      fetchComments(currentUser?.id);
    }
  }, [currentUser?.id]);

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'comments.title' })}
      </PageTitle>
      <div className="row">
        <div className="col-12 mw-900px">
          <div className="">
            <Tabs
              id="comment-tabs"
              activeKey={tab}
              onSelect={(k) => setTab(k)}
              className=""
            >
              <Tab
                eventKey="user"
                title={intl.formatMessage(
                  { id: 'comments.userComments' },
                  {
                    user: currentUser?.name || currentUser?.email || '-',
                  }
                )}
              >
                <div className="bg-white p-10 border-start border-end border-bottom">
                  <UserComments
                    loading={loading}
                    comment={comments?.user_notes || ''}
                  />
                </div>
              </Tab>
              <Tab
                eventKey="pet"
                title={intl.formatMessage({ id: 'comments.petComments' })}
              >
                <div className="bg-white p-10 border-start border-end border-bottom">
                  <PetComments
                    loading={loading}
                    comments={comments?.pets_notes || []}
                  />
                </div>
              </Tab>
              <Tab
                eventKey="task"
                title={intl.formatMessage({ id: 'comments.taskComments' })}
              >
                <div className="bg-white p-10 border-start border-end border-bottom">
                  <TaskComments
                    loading={loading}
                    tasks={comments?.tasks || []}
                  />
                </div>
              </Tab>
              <Tab
                eventKey="delivery"
                title={intl.formatMessage({ id: 'comments.deliveryChanges' })}
              >
                <div className="bg-white p-10 border-start border-end border-bottom">
                  <DeliveryChanges
                    loading={loading}
                    deliveryChanges={comments?.delivery_changes || []}
                  />
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};

export { CommentsPage };

import axios from 'axios';
import { LoyaltyLevel } from '../models/LoyaltyLevel';
import { LoyaltyReward } from '../models/LoyaltyReward';

const API_URL = process.env.REACT_APP_API_URL;

export function listRewardsPerLevel(): Promise<{ data: Array<LoyaltyLevel> }> {
  const endpoint = `${API_URL}/api/loyalty/rewards/`;

  return axios.get(endpoint);
}

export function createReward(
  reward: LoyaltyReward
): Promise<{ data: LoyaltyReward }> {
  const endpoint = `${API_URL}/api/loyalty/rewards/`;

  return axios.post(endpoint, reward);
}

export function updateReward(
  reward: LoyaltyReward
): Promise<{ data: LoyaltyReward }> {
  const endpoint = `${API_URL}/api/loyalty/rewards/`;

  return axios.patch(endpoint, reward);
}

export function deleteRewards(
  rewardIds: Array<number>
): Promise<{ data: LoyaltyLevel }> {
  const endpoint = `${API_URL}/api/loyalty/rewards/`;

  return axios.delete(endpoint, { data: { reward_ids: rewardIds } });
}

interface GetGiftPrevisionParams {
  promiseDateFrom: string;
  promiseDateTo: string;
}

export interface GiftPrevision {
  dog: number;
  cat: number;
}

export function getGiftPrevision({
  promiseDateFrom,
  promiseDateTo,
}: GetGiftPrevisionParams): Promise<{ data: GiftPrevision }> {
  const endpoint = `${API_URL}/api/loyalty/gift-prevision/`;
  const params = {
    promise_date__gte: promiseDateFrom,
    promise_date__lte: promiseDateTo,
  };

  return axios.get(endpoint, { params });
}

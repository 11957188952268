import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const INFLUENCERS_URL = `${API_URL}/api/influencers/`;

export function generateReport(code: string) {
  const endpoint = `${INFLUENCERS_URL}stats/?code=${encodeURIComponent(code)}`;

  return axios.get(endpoint);
}

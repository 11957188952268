import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { PageTitle } from '../../../_metronic/layout/core';
import {
  DistributorList,
  ModalDistributorForm,
  DistributorModel,
  listDistributors,
  createDistributor,
  updateDistributor,
  listTaxes,
} from '../../modules/distributors';
import { toast } from 'react-toastify';

const STATES = ['active', 'inactive'];
enum SORT_OPTIONS {
  ID = 'id',
  NAME = 'name',
}

const DistributorsPage: FC = () => {
  const intl = useIntl();
  const [distributors, setDistributors] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalFormVisible, setModalFormVisible] = useState(false);
  const [editingDistributor, setEditingDistributor] = useState<
    DistributorModel | undefined
  >(undefined);
  const [stateFilter, setStateFilter] = useState(STATES[0]);
  const [sortBy, setSortBy] = useState(SORT_OPTIONS.ID);
  const [asc, setAsc] = useState(true);
  const sortLabels = {
    [SORT_OPTIONS.ID]: intl.formatMessage({ id: 'distributors.sortById' }),
    [SORT_OPTIONS.NAME]: intl.formatMessage({ id: 'distributors.sortByName' }),
  };

  const fetchDistributors = async (state) => {
    try {
      setLoading(true);
      const { data } = await listDistributors(state);
      setDistributors(data);
      const { data: taxesData } = await listTaxes();
      setTaxes(taxesData);
    } catch (error) {
      console.warn(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDistributors(stateFilter);
  }, [stateFilter]);

  function onAddDistributor() {
    setEditingDistributor(undefined);
    setModalFormVisible(true);
  }

  async function onSaveDistributor(distributor: DistributorModel) {
    setModalFormVisible(false);
    setLoading(true);
    try {
      if (distributor.id) {
        const response = await updateDistributor(distributor);
        setDistributors([
          ...distributors.filter((s) => s.id !== distributor.id),
          response.data,
        ]);
      } else {
        const response = await createDistributor(distributor);
        setDistributors([...distributors, response.data]);
      }
      fetchDistributors(stateFilter);
      toast.success(intl.formatMessage({ id: 'distributors.saved' }));
    } catch (error) {
      if (Object.entries(error?.response?.data)?.length > 0) {
        Object.entries(error?.response?.data).forEach(([key, value]) => {
          toast.error(`${key}: ${value}`);
        });
      } else {
        toast.error(intl.formatMessage({ id: 'distributors.errorSaving' }));
      }

      console.warn(error);
    } finally {
      setLoading(false);
    }
  }

  async function onRemoveDistributor(distributor: DistributorModel) {
    const hasConfirmed = window.confirm(
      intl.formatMessage({ id: 'distributors.confirmRemove' })
    );
    if (!hasConfirmed) {
      return;
    }

    setLoading(true);
    try {
      await updateDistributor({ ...distributor, state: 'inactive' });
      setDistributors(distributors.filter((s) => s.id !== distributor.id));
      fetchDistributors(stateFilter);
      toast.success(intl.formatMessage({ id: 'distributors.removed' }));
    } catch (error) {
      console.warn(error);
    } finally {
      setLoading(false);
    }
  }

  function getDistributors(sortBy) {
    return distributors.sort((a, b) => {
      if (sortBy === SORT_OPTIONS.NAME) {
        if (asc) {
          return a.name.localeCompare(b.name);
        }
        return b.name.localeCompare(a.name);
      }
      if (asc) {
        return a.id - b.id;
      }
      return b.id - a.id;
    });
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'menu.distributors' })}
      </PageTitle>
      <div className="row">
        <div className="col-12">
          <div className="card p-10">
            {sortBy && (
              <DistributorList
                distributors={getDistributors(sortBy)}
                loading={loading}
                onAddDistributor={onAddDistributor}
                onRemoveDistributor={onRemoveDistributor}
              >
                <div className="mw-200px">
                  <label className="fw-bolder mb-1" htmlFor="stateFilter">
                    {intl.formatMessage({ id: 'distributors.filterByState' })}
                  </label>
                  <select
                    id="stateFilter"
                    className="form-control form-select"
                    value={stateFilter}
                    onChange={(e) => setStateFilter(e.target.value)}
                  >
                    {STATES.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mw-200px d-inline-block">
                  <label className="fw-bolder mb-1" htmlFor="sortBy">
                    {intl.formatMessage({ id: 'distributors.sortBy' })}
                  </label>
                  <select
                    id="sortBy"
                    className="form-control form-select"
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value as SORT_OPTIONS)}
                  >
                    {Object.values(SORT_OPTIONS).map((option) => (
                      <option key={option} value={option}>
                        {sortLabels[option]}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mw-100px d-inline-flex align-items-end">
                  <button
                    className="btn btn-primary text-light"
                    onClick={() => setAsc(!asc)}
                  >
                    {sortBy === SORT_OPTIONS.ID &&
                      (asc ? (
                        <span className="fas fa-sort-amount-down" />
                      ) : (
                        <span className="fas fa-sort-amount-up" />
                      ))}
                    {sortBy === SORT_OPTIONS.NAME &&
                      (asc ? (
                        <span className="fas fa-sort-alpha-down" />
                      ) : (
                        <span className="fas fa-sort-alpha-up" />
                      ))}
                  </button>
                </div>
              </DistributorList>
            )}
            <ModalDistributorForm
              taxes={taxes}
              distributor={editingDistributor}
              visible={modalFormVisible}
              onCancel={() => setModalFormVisible(false)}
              onSaveDistributor={onSaveDistributor}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export { DistributorsPage };

import { useIntl } from 'react-intl';
import { AsideMenuItem } from './AsideMenuItem';
import { useSelector } from 'react-redux';
import { authUserSelector } from '../../../../app/modules/auth';

export function AsideMenuOperations() {
  const intl = useIntl();
  const authUser = useSelector(authUserSelector);
  const isOperations = !!authUser?.groups?.find(
    (aGroup) => aGroup.name === 'Operations'
  );

  return isOperations ? (
    <>
      <h5 className="menu-title text-light pb-4 mb-0 mt-2 ms-6">
        {intl.formatMessage({ id: 'menu.operations' })}
      </h5>
      <AsideMenuItem
        to={`/operation-orders`}
        title={intl.formatMessage({ id: 'menu.operationOrders' })}
        fontIcon="fa-boxes"
      />
      <AsideMenuItem
        to={`/b2b-orders`}
        title={intl.formatMessage({ id: 'menu.b2bOrders' })}
        fontIcon="fa-box"
      />
      <AsideMenuItem
        to={`/b2b-lead-orders`}
        title={intl.formatMessage({ id: 'menu.b2bLeadOrders' })}
        fontIcon="fa-gift"
      />
      <AsideMenuItem
        to={`/shopify-orders`}
        title={intl.formatMessage({ id: 'menu.shopifyOrders' })}
        fontIcon="fa-cash-register"
      />
      <AsideMenuItem
        to={`/delivery-notes`}
        title={intl.formatMessage({ id: 'menu.deliveryNotes' })}
        fontIcon="fa-file-alt"
      />
      <AsideMenuItem
        to={`/delivery-changes`}
        title={intl.formatMessage({ id: 'menu.deliveryChanges' })}
        fontIcon="fa-edit"
      />
      <div className="menu-item">
        <div className="menu-content">
          <div className="separator mx-1 my-4"></div>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
}

import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { Invoice } from '../models/Invoice';
import { listInvoices } from '../services/InvoiceCRUD';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { currentUserSelector } from '../../user-profile';
import DateRangeSelector from '../../../components/DateRangeSelector';
import moment from 'moment';
import { Link } from 'react-router-dom';

export default function InvoiceList() {
  const intl = useIntl();
  const [invoices, setInvoices] = useState<Array<Invoice>>([]);
  const [loading, setLoading] = useState(false);
  const now = new Date();
  const aYearAgo = new Date();
  aYearAgo.setFullYear(aYearAgo.getFullYear() - 1);
  const [dateRange, setDateRange] = useState<[Date, Date]>([aYearAgo, now]);
  const currentUser = useSelector(currentUserSelector);

  const fetchInvoices = async (withLoader = true) => {
    try {
      if (withLoader) {
        setLoading(true);
      }
      const userId = currentUser?.id;
      const startDate = dateRange[0]
        ? moment(dateRange[0]).format('YYYY-MM-DD')
        : null;
      const endDate = dateRange[1]
        ? moment(dateRange[1]).format('YYYY-MM-DD')
        : null;
      const { data } = await listInvoices({
        userId,
        startDate,
        endDate,
      });
      setInvoices(data);
    } catch (error) {
      toast.error(error.message);
    } finally {
      if (withLoader) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (currentUser?.id) {
      fetchInvoices();
    }
  }, [dateRange, currentUser?.id]);

  function getInvoices() {
    return invoices.sort((a, b) => b.number.localeCompare(a.number));
  }

  return (
    <>
      <div className="card">
        <div className="card-body">
          <h2 className="mb-8">
            {intl.formatMessage({ id: 'invoices.invoiceList' })}
          </h2>
          <div className="d-flex justify-content-between align-items-center mb-5">
            <DateRangeSelector
              initialSince={dateRange[0]}
              initialUntil={dateRange[1]}
              onSelected={(since, until) => {
                setDateRange([since, until]);
              }}
              label={intl.formatMessage({ id: 'invoices.filterByDateRange' })}
            />
          </div>
          {loading && <div className="spinner-border text-primary my-10" />}
          {!loading && invoices?.length === 0 && (
            <p>{intl.formatMessage({ id: 'invoices.noInvoices' })}</p>
          )}
          {!loading && invoices?.length > 0 && (
            <div
              className="table-responsive"
              style={{
                height: 'calc(100vh - 420px)',
              }}
            >
              <table className="table table-hover table-row-dashed table-row-gray-200 align-start gs-0 gy-4">
                <thead className="table-header sticky-top bg-white">
                  <tr className="text-left fw-bolder">
                    <th className="ps-4">
                      {intl.formatMessage({ id: 'invoices.invoice' })}
                    </th>
                    <th>{intl.formatMessage({ id: 'invoices.number' })}</th>
                    <th className="">
                      {intl.formatMessage({ id: 'invoices.order' })}
                    </th>
                    <th className="">
                      {intl.formatMessage({ id: 'invoices.date' })}
                    </th>
                    <th className="">
                      {intl.formatMessage({ id: 'invoices.state' })}
                    </th>
                    <th className=""></th>
                  </tr>
                </thead>
                <tbody>
                  {getInvoices()?.map((invoice) => (
                    <tr className="align-start" key={invoice.code}>
                      <td className="ps-4">{invoice.code}</td>
                      <td className="">{invoice.number}</td>
                      <td>
                        <Link
                          to={`/user/${currentUser?.id}/order/${invoice.order}`}
                        >
                          {invoice.order}
                        </Link>
                      </td>
                      <td className="">
                        {invoice.date
                          ? new Date(invoice.date).toLocaleDateString()
                          : '-'}
                      </td>
                      <td className="">
                        <span
                          className={clsx('badge', {
                            'badge-success': invoice.state === 'paid',
                            'badge-warning text-dark': invoice.state !== 'paid',
                          })}
                        >
                          {invoice.state}
                        </span>
                      </td>
                      <td className="">
                        <a
                          href={invoice.invoice_url}
                          target="_blank"
                          className="btn btn-sm btn-light-primary"
                          rel="noreferrer"
                        >
                          <span className="fas fa-download me-3"></span>
                          {intl.formatMessage({ id: 'invoices.pdf' })}
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

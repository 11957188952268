import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../redux/OrderRedux';
import { mergeOrders } from '../services/OrderCRUD';
import { toast } from 'react-toastify';
import { currentUserSelector } from '../../user-profile';
import { useHistory } from 'react-router-dom';

export default function MergeOrdersModal({ order, orders, visible, onClose }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [selectedOrders, setSelectedOrders] = useState([]);
  const currentUser = useSelector(currentUserSelector);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  function getAvailebleOrders() {
    return orders
      ?.filter((anOrder) => anOrder?.promise_date)
      ?.filter((anOrder) => anOrder?.state === 'locked')
      ?.filter((anOrder) => anOrder?.promise_date === order?.promise_date)
      ?.map((anOrder) => ({
        value: anOrder.id,
        label: (
          <>
            {anOrder.code}
            <strong className="text-muted ms-2">
              ({anOrder.promise_date})
            </strong>
            <span className="badge badge-dark ms-2">{anOrder.state}</span>
          </>
        ),
        disabled:
          anOrder?.state !== 'locked' ||
          anOrder?.promise_date !== order?.promise_date,
      }));
  }

  async function onConfirm() {
    setLoading(true);
    try {
      const { data: mergedOrder } = await mergeOrders([
        order.id,
        ...selectedOrders.map((anOrder) => parseInt(anOrder.value, 10)),
      ]);
      if (mergedOrder) {
        toast.success(intl.formatMessage({ id: 'orderDetail.mergeSuccess' }));
        onClose();
        dispatch(actions.listOrders(currentUser.id));
        history.push(`/user/${currentUser.id}/order/${mergedOrder.id}`);
      } else {
        toast.error(intl.formatMessage({ id: 'orderDetail.mergeError' }));
      }
    } catch (error) {
      console.error(error);
      toast.error(intl.formatMessage({ id: 'orderDetail.mergeError' }));
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (visible) {
      setSelectedOrders([]);
    }
  }, [visible]);

  useEffect(() => {
    if (visible && getAvailebleOrders().length === 1) {
      setSelectedOrders(getAvailebleOrders());
    }
  }, [visible]);

  function onOrderSelected(option) {
    if (selectedOrders.find((anOrder) => anOrder.value === option.value)) {
      setSelectedOrders(
        selectedOrders.filter((anOrder) => anOrder.value !== option.value)
      );
    } else {
      setSelectedOrders([...selectedOrders, option]);
    }
  }

  return (
    <Modal show={visible} onClose={onClose}>
      <Modal.Header>
        <h4>
          <FormattedMessage id="orderDetail.mergeOrders" />
        </h4>
      </Modal.Header>
      <Modal.Body>
        {getAvailebleOrders().length > 0 ? (
          <div className="form-group mb-6">
            <label className="fw-bolder mb-4">
              <FormattedMessage id="orderDetail.selectOrders" />
            </label>
            {getAvailebleOrders().map((option, index) => (
              <div
                key={index}
                className="form-check form-check-custom form-check-solid mb-4"
              >
                <input
                  id={`selectOrder${index}`}
                  className="form-check-input"
                  type="checkbox"
                  name="selectOrder"
                  value={option.value}
                  checked={selectedOrders.find(
                    (anOrder) => anOrder.value === option.value
                  )}
                  onChange={() => onOrderSelected(option)}
                />
                <label
                  className="form-check-label"
                  htmlFor={`selectOrder${index}`}
                >
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        ) : (
          <>
            <div className="alert alert-warning">
              {intl.formatMessage(
                { id: 'orderDetail.noOrdersToMerge' },
                {
                  state: <span className="badge badge-dark">locked</span>,
                  date: <strong>{order?.promise_date}</strong>,
                }
              )}
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-between w-100">
          <Button variant="tertiary" onClick={onClose}>
            <FormattedMessage id="orderDetail.close" />
          </Button>
          <Button
            variant="primary"
            onClick={onConfirm}
            disabled={!selectedOrders.length || loading}
          >
            {loading ? (
              <FormattedMessage id="orderDetail.merging" />
            ) : (
              <FormattedMessage id="orderDetail.mergeConfirm" />
            )}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

import { Invoice } from '../models/Invoice';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const INVOICES_URL = `${API_URL}/api/invoice/`;
export const INVOICES_PER_PAGE = 20;

export function getInvoiceById(id: number): Promise<{ data: Invoice }> {
  const endpoint = `${INVOICES_URL}${id}/`;
  return axios.get(endpoint);
}

export interface ListInvoicesProps {
  userId: number;
  startDate: string;
  endDate: string;
}
export function listInvoices({
  userId,
  startDate,
  endDate,
}: ListInvoicesProps): Promise<{ data: Invoice[] }> {
  const endpoint = `${INVOICES_URL}?user=${userId}&start_date=${startDate}&end_date=${endDate}`;
  return axios.get(endpoint);
}

import axios from 'axios';
import { OrderLineModel } from '../models/OrderLineModel';

const API_URL = process.env.REACT_APP_API_URL;
const ORDERS_LINES_URL = `${API_URL}/api/b2b/order-lines/`;

export function listOrderLines(
  orderId: number
): Promise<{ data: Array<OrderLineModel> }> {
  return axios.get(ORDERS_LINES_URL, { params: { order: orderId } });
}

export function createOrderLine(
  orderLine: OrderLineModel
): Promise<{ data: OrderLineModel }> {
  return axios.post(ORDERS_LINES_URL, orderLine);
}

export function removeOrderLine(orderLine: OrderLineModel) {
  return axios.delete(`${ORDERS_LINES_URL}${orderLine.id}/`);
}

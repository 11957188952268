interface Props {
  options: string[];
  selectedOptions: string[];
  onChange: (options: string[]) => void;
  label: string;
}

export default function MultipleOptionSelector({
  options = [],
  selectedOptions = [],
  onChange,
  label = '',
}: Props) {
  return (
    <div>
      <label className="d-block mb-4 fw-bolder" htmlFor="store">
        {label}
      </label>
      {options?.map((option, index) => {
        return (
          <div key={index} className="d-inline-block">
            <input
              type="checkbox"
              className="form-check-input me-2"
              id={`option-${index}`}
              name={`option-${index}`}
              value={option}
              checked={selectedOptions.includes(option)}
              onChange={(e) => {
                if (e.target.checked) {
                  onChange([...selectedOptions, e.target.value]);
                } else {
                  onChange(
                    selectedOptions.filter((item) => item !== e.target.value)
                  );
                }
              }}
            />
            <label className="me-4" htmlFor={`option-${index}`}>
              {option}
            </label>
          </div>
        );
      })}
    </div>
  );
}

import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { staffUsersSelector } from '../redux/TaskRedux';

export default function TaskHistory({ task }) {
  const intl = useIntl();
  const staffUsers = useSelector(staffUsersSelector);

  function getUserName(userId) {
    const staffUser = staffUsers?.find((aUser) => aUser.id === userId);
    return staffUser?.name || staffUser?.email || '-';
  }

  return (
    <>
      <div className="card w-100 p-5">
        <div className="row">
          <div className="card-body">
            {task.historic?.length > 0 ? (
              <div className="timeline-label">
                {task.historic.map((historic) => (
                  <div className="timeline-item mb-0" key={historic.created_at}>
                    <div className="timeline-label fw-bolder text-gray-800 fs-9 mt-1 mb-4">
                      {new Intl.DateTimeFormat('es-ES', {
                        dateStyle: 'short',
                      }).format(new Date(Date.parse(historic.created_at)))}
                    </div>
                    <div className="timeline-badge">
                      <i className="fa fa-genderless text-success fs-1"></i>
                    </div>
                    <div className="timeline-content d-flex">
                      <div>
                        {historic?.performed_by && (
                          <div className="text-gray-700 fw-bolder ps-3">
                            <span className="fa fa-user me-2" />
                            {getUserName(historic?.performed_by)}
                          </div>
                        )}
                        <div className="text-gray-600 ps-3">
                          {historic.summary}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              intl.formatMessage({ id: 'tasks.noHistoric' })
            )}
          </div>
        </div>
      </div>
    </>
  );
}

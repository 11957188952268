export default [
  'tania@foodforjoe.es',
  'daniel.garrido@foodforjoe.es',
  'luis.felipe@foodforjoe.es',
  'albane@foodforjoe.es',
  'diego@foodforjoe.es',
  'josep@foodforjoe.es',
  'lucia.mercedes@foodforjoe.es',
  'carla@foodforjoe.es',
  'noelia@foodforjoe.es',
  'erika.lazaro@foodforjoe.es',
  'juan@foodforjoe.es',
  'erika@foodforjoe.es',
];

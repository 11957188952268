import { useDispatch, shallowEqual, useSelector } from 'react-redux';

import { MealPreferenceModel } from '../models/MealPreferenceModel';
import * as petRedux from '../redux/PetRedux';
import { RootState } from '../../../../setup';
import { useIntl } from 'react-intl';
import { FormField, GenericForm } from '../../../components/GenericForm';
import { useState, useEffect } from 'react';
import { PetModel } from '../models/PetModel';
import { toast } from 'react-toastify';

export default function MealPreferencesComponent() {
  const [editing, setEditing] = useState(false);
  const intl = useIntl();
  const dispatch = useDispatch();
  const mealPreferences: Array<MealPreferenceModel> = useSelector(
    (state: RootState) => state.pet.mealPreferences,
    shallowEqual
  );
  const selectedPet: PetModel | undefined = useSelector(
    petRedux.selectedPetSelector,
    shallowEqual
  );
  const status: string = useSelector(petRedux.petStatusSelector, shallowEqual);
  let formFields: Array<FormField<Array<MealPreferenceModel>>> = [];

  mealPreferences.map((mealPreference, i) => {
    formFields = formFields.concat([
      {
        id: mealPreference.id.toString() + 'name',
        inputType: 'text',
        readonly: true,
        label: intl.formatMessage({ id: 'petProfile.mealPreferences.name' }),
        disabled: true,
        getter: (state: MealPreferenceModel[]) => state[i].meal?.name,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        setter: (state: MealPreferenceModel[], _value: string) => state,
      },
      {
        id: mealPreference.id.toString() + 'preference',
        label: intl.formatMessage({
          id: 'petProfile.mealPreferences.preference',
        }),
        inputType: 'select',
        options: [
          {
            value: 'auto',
            label: intl.formatMessage({
              id: 'petProfile.mealPreferences.auto',
            }),
          },
          {
            value: 'add',
            label: intl.formatMessage({ id: 'petProfile.mealPreferences.add' }),
          },
          {
            value: 'discard',
            label: intl.formatMessage({
              id: 'petProfile.mealPreferences.discard',
            }),
          },
        ],
        getter: (state: MealPreferenceModel[]) => state[i]?.preference,
        //
        setter: (
          state: MealPreferenceModel[],
          value: string
        ): MealPreferenceModel[] => [
          ...state.slice(0, i),
          {
            ...state[i],
            preference: value,
          } as MealPreferenceModel,
          ...state.slice(i + 1),
        ],
      },
      {
        id: mealPreference.id.toString() + 'weight',
        inputType: 'number',
        label: intl.formatMessage({ id: 'petProfile.mealPreferences.weight' }),
        getter: (state: MealPreferenceModel[]) => state[i]?.weight,
        setter: (
          state: MealPreferenceModel[],
          value: string
        ): MealPreferenceModel[] => [
          ...state.slice(0, i),
          {
            ...state[i],
            weight: parseInt(value, 10),
          },
          ...state.slice(i + 1),
        ],
      },
    ]);
  });

  function onSubmit(formData: Array<MealPreferenceModel>) {
    const petId = selectedPet?.id;
    if (petId) {
      dispatch(petRedux.actions.updateMealPreferences(petId, formData));
    }
  }

  useEffect(() => {
    if (status === 'success_meal_preferences') {
      toast.success(
        intl.formatMessage({ id: 'petProfile.mealPreferences.successToast' })
      );
      setEditing(false);
    }
  }, [status]);

  return (
    <section className="card p-10">
      {status === 'loading_request_selected_pet' ? (
        <div className="spinner-border text-primary" role="status" />
      ) : (
        <GenericForm
          table={true}
          title={intl.formatMessage({ id: 'petProfile.mealPreferences.title' })}
          columns={3}
          fields={formFields}
          onSubmit={onSubmit}
          ctaLabel={intl.formatMessage({
            id: 'petProfile.mealPreferences.saveChanges',
          })}
          error={{}}
          toggleEdit={() => setEditing(!editing)}
          editing={editing}
          submitting={status === 'loading_meal_preferences'}
          submittingLabel={intl.formatMessage({
            id: 'petProfile.mealPreferences.loading',
          })}
          initialState={mealPreferences}
        />
      )}
    </section>
  );
}

import { useIntl } from 'react-intl';
import { OrderModel } from '../../b2b-orders';
import { Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { DeliveryNoteLineRequestModel } from '../models/DeliveryNoteLineRequestModel';
import clsx from 'clsx';

type PropTypes = {
  visible?: boolean;
  onClose?: () => void;
  onCreateDeliveryNote?: (
    date: string,
    deliveryNoteLines: Array<DeliveryNoteLineRequestModel>
  ) => void;
  order?: OrderModel;
  loading?: boolean;
};

export default function ModalDeliveryNoteForm({
  visible = false,
  onClose = () => {},
  order,
  loading = false,
  onCreateDeliveryNote = () => {},
}: PropTypes) {
  const intl = useIntl();
  const [date, setDate] = useState<string>(
    new Date().toISOString().split('T')[0]
  );
  const [deliveryNoteLines, setDeliveryNoteLines] = useState<
    Array<DeliveryNoteLineRequestModel>
  >([]);

  useEffect(() => {
    const newDeliveryNoteLines = order?.order_lines?.map(
      (orderLine) =>
        ({
          order_line: orderLine.id,
          served_units: orderLine.units - orderLine.total_served_units,
        }) as DeliveryNoteLineRequestModel
    );
    setDeliveryNoteLines(newDeliveryNoteLines || []);
  }, [order]);

  function isInvalidDeliveryNote() {
    return deliveryNoteLines.some((deliveryNoteLine) => {
      const orderLine = order?.order_lines?.find(
        (orderLine) => orderLine.id === deliveryNoteLine.order_line
      );
      return (
        orderLine &&
        deliveryNoteLine?.served_units >
          orderLine.units - orderLine.total_served_units
      );
    });
  }

  return (
    <Modal show={visible} onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {intl.formatMessage(
            { id: 'modalDeliveryNoteForm.title' },
            { code: order?.code }
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group mb-4 w-200px">
          <label htmlFor="date" className="mb-1 fw-bolder">
            {intl.formatMessage({ id: 'modalDeliveryNoteForm.date' })}
          </label>
          <input
            type="date"
            className="form-control"
            id="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>
        <table className="table table-hover table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
          <thead className="table-header">
            <tr className="fw-bolder">
              <th className="w-auto ps-4">
                {intl.formatMessage({ id: 'modalDeliveryNoteForm.product' })}
              </th>
              <th className="w-100px">
                {intl.formatMessage({ id: 'modalDeliveryNoteForm.units' })}
              </th>
              <th className="w-100px">
                {intl.formatMessage({
                  id: 'modalDeliveryNoteForm.alreadyServedUnits',
                })}
              </th>
              <th className="w-150px">
                {intl.formatMessage({
                  id: 'modalDeliveryNoteForm.servedUnits',
                })}
              </th>
              <th className="w-100px">
                {intl.formatMessage({
                  id: 'modalDeliveryNoteForm.remainingUnits',
                })}
              </th>
            </tr>
          </thead>
          <tbody>
            {order?.order_lines?.map((orderLine, index) => {
              const deliveryNoteLine = deliveryNoteLines.find(
                (deliveryNoteLine) =>
                  deliveryNoteLine.order_line === orderLine.id
              );
              const remainingUnits =
                orderLine.units -
                orderLine.total_served_units -
                (deliveryNoteLine?.served_units || 0);

              return (
                <tr key={index} className="align-middle">
                  <td className="ps-4">{orderLine.name}</td>
                  <td className="">{orderLine.units}</td>
                  <td className="">{orderLine.total_served_units}</td>
                  <td>
                    <input
                      type="number"
                      className={clsx(
                        'form-control',
                        remainingUnits < 0 && 'is-invalid'
                      )}
                      value={deliveryNoteLine?.served_units}
                      min={0}
                      max={orderLine.units - orderLine.total_served_units}
                      onChange={(e) => {
                        const newDeliveryNoteLines = [...deliveryNoteLines];
                        const newDeliveryNoteLine = newDeliveryNoteLines.find(
                          (deliveryNoteLine) =>
                            deliveryNoteLine.order_line === orderLine.id
                        );
                        if (newDeliveryNoteLine) {
                          newDeliveryNoteLine.served_units = parseInt(
                            e.target.value
                          );
                        } else {
                          newDeliveryNoteLines.push({
                            order_line: orderLine.id,
                            served_units: parseInt(e.target.value),
                          });
                        }
                        setDeliveryNoteLines(newDeliveryNoteLines);
                      }}
                    />
                  </td>
                  <td className="">
                    {remainingUnits > -1 ? (
                      remainingUnits
                    ) : (
                      <span className="text-danger fw-bolder">
                        {remainingUnits}
                      </span>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-tertiary" onClick={onClose}>
          {intl.formatMessage({ id: 'modalDeliveryNoteForm.cancel' })}
        </button>
        <button
          disabled={isInvalidDeliveryNote() || loading}
          className="btn btn-primary"
          onClick={() => onCreateDeliveryNote(date, deliveryNoteLines)}
        >
          {intl.formatMessage({ id: 'modalDeliveryNoteForm.generate' })}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

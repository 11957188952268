import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../_metronic/layout/core';
import {
  InvoiceList,
  InvoiceModel,
  InvoiceStateEnum,
  listInvoices,
  updateInvoice,
  updateInvoiceNumber,
  UpdateInvoiceNumberProps,
} from '../../modules/b2b-invoices';
import { toast } from 'react-toastify';
import { DistributorModel, listDistributors } from '../../modules/distributors';
import InvoiceNumberModal from '../../modules/b2b-invoices/components/InvoiceNumberModal';
import { authUserSelector } from '../../modules/auth';
import { useSelector } from 'react-redux';
import invoiceEditingUsers from '../../modules/tasks/constants/invoiceEditingUsers';
import CreditNoteModal from '../../modules/b2b-invoices/components/CreditNoteModal';
import { createCreditNote } from '../../modules/b2b-invoices/services/CreditNoteCRUD';
import Select from 'react-select';

const InvoicesPage: FC = () => {
  const intl = useIntl();
  const authUser = useSelector(authUserSelector);
  const [distributors, setDistributors] = useState<Array<DistributorModel>>([]);
  const [state, setState] = useState<InvoiceStateEnum | null>(null);
  const [isProforma, setIsProforma] = useState<boolean | null>(null);
  const [isCredit, setIsCredit] = useState<boolean | null>(null);
  const [invoices, setInvoices] = useState<Array<InvoiceModel>>([]);
  const [loading, setLoading] = useState(false);
  const [selectedDistributor, setSelectedDistributor] =
    useState<DistributorModel | null>(null);
  const [invoiceNumberModalVisible, setInvoiceNumberModalVisible] =
    useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [visibleCreditNoteModal, setVisibleCreditNoteModal] = useState(false);
  const [invoiceForCreditNoteModal, setInvoiceForCreditNoteModal] =
    useState<InvoiceModel | null>(null);
  const [isGeneratingCreditNote, setIsGeneratingCreditNote] = useState(false);
  const distributorOptions = [
    ...distributors.map((distributor) => ({
      value: distributor.id,
      label: distributor.name,
    })),
    {
      value: '',
      label: intl.formatMessage({ id: 'deliveryNotes.anyDistributor' }),
    },
  ];

  const fetchDistributors = async () => {
    try {
      const { data } = await listDistributors('active');
      setDistributors(data);
    } catch (error) {
      console.warn(error);
    }
  };

  const fetchInvoices = async (withLoader = true) => {
    try {
      if (withLoader) {
        setLoading(true);
      }
      const { data } = await listInvoices({
        distributorId: selectedDistributor?.id,
        page,
        state,
        isProforma,
        isCredit,
      });
      setInvoices(data.results);
      setCount(data.count);
    } catch (error) {
      toast.error(error.message);
    } finally {
      if (withLoader) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchDistributors();
  }, []);

  useEffect(() => {
    fetchInvoices();
  }, [selectedDistributor, page, isProforma, isCredit, state]);

  useEffect(() => {
    setPage(1);
    setCount(0);
  }, [selectedDistributor, isProforma, isCredit, state]);

  async function onUpdateState(order: InvoiceModel, state: InvoiceStateEnum) {
    try {
      await updateInvoice({
        ...order,
        state,
      });
      fetchInvoices(false);
      toast.success(intl.formatMessage({ id: 'invoices.updateStateSuccess' }));
    } catch (error) {
      toast.error(intl.formatMessage({ id: 'invoices.updateStateError' }));
      console.warn(error);
    }
  }

  async function onCreateCreditNote(invoice: InvoiceModel) {
    setInvoiceForCreditNoteModal(invoice);
    setVisibleCreditNoteModal(true);
  }

  async function createACreditNote(creditNoteRequest: any) {
    try {
      setIsGeneratingCreditNote(true);
      await createCreditNote(creditNoteRequest);
      toast.success(
        intl.formatMessage({ id: 'invoices.createCreditNoteSuccess' })
      );
      setVisibleCreditNoteModal(false);
    } catch (error) {
      toast.error(intl.formatMessage({ id: 'invoices.createCreditNoteError' }));
      console.warn(error);
    } finally {
      setIsGeneratingCreditNote(false);
    }
  }

  function changeInvoiceNumber() {
    setInvoiceNumberModalVisible(true);
  }

  async function onSaveInvoiceNumber({
    next_num,
    is_credit,
    is_proforma,
  }: UpdateInvoiceNumberProps) {
    try {
      await updateInvoiceNumber({ next_num, is_credit, is_proforma });
      setInvoiceNumberModalVisible(false);
      toast.success(intl.formatMessage({ id: 'invoices.updateNumberSuccess' }));
    } catch (error) {
      toast.error(
        `${intl.formatMessage({ id: 'invoices.updateNumberError' })} ${
          error?.response?.data?.next_num
            ? `: ${error?.response?.data?.next_num}`
            : ''
        }`
      );
      console.warn(error);
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'menu.invoices' })}
      </PageTitle>
      <div className="row">
        <div className="col-12">
          <InvoiceList
            page={page}
            count={count}
            onPageChange={setPage}
            loading={loading}
            invoices={invoices}
            onCreateCreditNote={onCreateCreditNote}
            onUpdateState={onUpdateState}
          >
            <div className="d-flex gap-4 align-items-end">
              <div className="form-group">
                <label className="fw-bolder mb-1">
                  {intl.formatMessage({
                    id: 'deliveryNotes.selectDistributor',
                  })}
                </label>
                <Select
                  className="react-select-container w-300px"
                  classNamePrefix="react-select"
                  options={distributorOptions}
                  value={distributorOptions.find(
                    (d) => d.value === selectedDistributor?.id
                  )}
                  onChange={(e) => {
                    const distributorId = e?.value;
                    const distributor = distributors.find(
                      (d) => d.id === distributorId
                    );
                    setSelectedDistributor(distributor);
                  }}
                  placeholder={intl.formatMessage({ id: 'planDetail.select' })}
                />
              </div>
              <div className="form-group d-inline-block">
                <label className="fw-bolder mb-1">
                  {intl.formatMessage({ id: 'invoices.selectState' })}
                </label>
                <select
                  className="form-control w-250px form-select"
                  onChange={(e) => {
                    const state = e.target.value;
                    setState(state === '' ? null : (state as InvoiceStateEnum));
                  }}
                >
                  <option value="">
                    {intl.formatMessage({ id: 'invoices.anyState' })}
                  </option>
                  {Object.values(InvoiceStateEnum).map((state) => (
                    <option key={state} value={state}>
                      {intl.formatMessage({
                        id: `invoices.${state.toLowerCase()}`,
                      })}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group d-inline-block">
                <label className="fw-bolder mb-1">
                  {intl.formatMessage({ id: 'invoices.selectType' })}
                </label>
                <select
                  className="form-control w-250px form-select"
                  onChange={(e) => {
                    const type = e.target.value;

                    switch (type) {
                      case 'proforma':
                        setIsCredit(false);
                        setIsProforma(true);
                        break;
                      case 'credit':
                        setIsProforma(false);
                        setIsCredit(true);
                        break;
                      case 'invoice':
                      default:
                        setIsProforma(false);
                        setIsCredit(false);
                        break;
                    }
                  }}
                >
                  <option value="">
                    {intl.formatMessage({ id: 'invoices.anyType' })}
                  </option>
                  <option value="proforma">
                    {intl.formatMessage({ id: 'invoices.proforma' })}
                  </option>
                  <option value="credit">
                    {intl.formatMessage({ id: 'invoices.credit' })}
                  </option>
                  <option value="invoice">
                    {intl.formatMessage({ id: 'invoices.invoice' })}
                  </option>
                </select>
              </div>
              {invoiceEditingUsers?.includes(authUser?.email) && (
                <div>
                  <button
                    className="btn btn-secondary text-dark ms-auto d-inline-block"
                    onClick={changeInvoiceNumber}
                  >
                    <span className="fa-icon fas fa-exclamation-triangle me-3" />
                    {intl.formatMessage({ id: 'invoices.changeNumber' })}
                  </button>
                </div>
              )}
            </div>
          </InvoiceList>
        </div>
      </div>
      <InvoiceNumberModal
        visible={invoiceNumberModalVisible}
        onClose={() => setInvoiceNumberModalVisible(false)}
        onSave={onSaveInvoiceNumber}
        currentNumber={invoices[0]?.number}
      />

      <CreditNoteModal
        invoice={invoiceForCreditNoteModal}
        visible={visibleCreditNoteModal}
        onClose={() => setVisibleCreditNoteModal(false)}
        onCreateCreditNote={createACreditNote}
        loading={isGeneratingCreditNote}
      />
    </>
  );
};

export { InvoicesPage };

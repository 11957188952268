import { useState } from 'react';
import { useIntl } from 'react-intl';

import { importProduct } from '../services/ShopifyOrderCRUD';
import { toast } from 'react-toastify';

export default function ShopifyImport() {
  const intl = useIntl();
  const [productId, setProductId] = useState('');
  const [loading, setLoading] = useState(false);

  async function onImportProduct() {
    try {
      setLoading(true);
      await importProduct(productId);

      toast.success(intl.formatMessage({ id: 'shopifyImport.importSuccess' }));
    } catch (error) {
      if (error?.response?.data?.length > 0) {
        toast.error(error?.response?.data[0]);
      } else {
        toast.error(intl.formatMessage({ id: 'shopifyImport.importError' }));
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <section className="card p-10">
      <h1 className="card-title mb-4">
        {intl.formatMessage({ id: 'shopifyImport.importProducts' })}
      </h1>
      <label className="form-label mb-2">
        {intl.formatMessage({ id: 'shopifyImport.productId' })}
      </label>
      <input
        type="text"
        placeholder={intl.formatMessage({
          id: 'shopifyImport.productIdPlaceholder',
        })}
        className="form-control mb-4"
        value={productId}
        onChange={(e) => setProductId(e.target.value)}
      />
      <button
        className="btn btn-primary ms-auto"
        disabled={!productId || loading}
        onClick={onImportProduct}
      >
        {loading ? (
          <span className="spinner-border spinner-border-sm me-2"></span>
        ) : (
          <span className="fas fa-download me-2"></span>
        )}
        {intl.formatMessage({ id: 'shopifyImport.import' })}
      </button>
    </section>
  );
}

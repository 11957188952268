import { Modal } from 'react-bootstrap';
import { DeliveryChangeReasonEnum } from '../../delivery-changes/models/DeliveryChangeReasonEnum';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { createDeliveryChange, reshipOrder } from '../services/OrderCRUD';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import * as OrderRedux from '../redux/OrderRedux';
import { ReshipReasonEnum } from '../../delivery-changes/models/ReshipReasonEnum';

export default function DeliveryChangeModal({
  order,
  visible,
  onClose,
  isReship = false,
}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState<
    DeliveryChangeReasonEnum | ReshipReasonEnum | null
  >(null);
  const [observations, setObservations] = useState('');
  const [isRecurrent, setIsRecurrent] = useState(false);

  async function onConfirm() {
    try {
      setLoading(true);
      if (isReship) {
        await reshipOrder({
          orderId: order.id,
          reason,
          observations,
        });
      } else {
        await createDeliveryChange({
          orderId: order.id,
          reason,
          observations,
          isRecurrent,
        });
      }
      toast.success(intl.formatMessage({ id: 'deliveryChangeModal.success' }));
      onClose();
    } catch (error) {
      console.warn(error);
      toast.error(intl.formatMessage({ id: 'deliveryChangeModal.error' }));
    } finally {
      dispatch(OrderRedux.actions.requestOrder(order.id));
      setLoading(false);
    }
  }

  useEffect(() => {
    if (visible) {
      setReason(null);
      setObservations('');
    }
  }, [visible]);

  return (
    <Modal
      show={visible}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {intl.formatMessage({ id: 'deliveryChangeModal.title' })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group mb-4">
          <label className="form-label">
            {intl.formatMessage({ id: 'deliveryChangeModal.reason' })}
          </label>
          <select
            className="form-control form-select"
            name="reason"
            required
            disabled={loading}
            onChange={(e) =>
              setReason(
                isReship
                  ? (e.target.value as ReshipReasonEnum)
                  : (e.target.value as DeliveryChangeReasonEnum)
              )
            }
            value={reason}
          >
            <option value="">
              {intl.formatMessage({ id: 'deliveryChangeModal.selectReason' })}
            </option>
            {Object.values(
              isReship ? ReshipReasonEnum : DeliveryChangeReasonEnum
            ).map((key) => (
              <option key={key} value={key}>
                {intl.formatMessage({ id: `deliveryChangeModal.${key}` })}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group mb-4">
          <label className="form-label">
            {intl.formatMessage({ id: 'deliveryChangeModal.observations' })}
          </label>
          <textarea
            maxLength={500}
            className="form-control"
            name="observations"
            rows={3}
            value={observations}
            onChange={(e) => setObservations(e.target.value)}
            disabled={loading}
          />
        </div>
        <div className="form-group">
          <label className="form-label">
            {intl.formatMessage({ id: 'deliveryChangeModal.isRecurrent' })}
          </label>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="flexSwitchCheckDefault"
              checked={isRecurrent}
              onChange={(e) => setIsRecurrent(e.target.checked)}
            />
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckDefault"
            >
              {isRecurrent
                ? intl.formatMessage({ id: 'deliveryChangeModal.yes' })
                : intl.formatMessage({ id: 'deliveryChangeModal.no' })}
            </label>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-text" onClick={onClose} disabled={loading}>
          {intl.formatMessage({ id: 'deliveryChangeModal.cancel' })}
        </button>
        <button
          className="btn btn-primary"
          onClick={onConfirm}
          disabled={loading || !reason}
        >
          {loading ? (
            <span className="spinner-border spinner-border-sm align-middle me-3"></span>
          ) : (
            intl.formatMessage({ id: 'deliveryChangeModal.confirm' })
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

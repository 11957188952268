import { actions } from '../../../src/app/modules/auth/redux/AuthRedux';

export default function setupAxios(axios: any, store: any) {
  axios.defaults.headers.Accept = 'application/json';
  axios.interceptors.request.use(
    (request: any) => {
      const {
        auth: { accessToken },
      } = store.getState();

      if (accessToken) {
        request.headers.Authorization = `Token ${accessToken}`;
      }

      return request;
    },
    (err: any) => Promise.reject(err)
  );
  axios.interceptors.response.use(
    function (response: any) {
      return response;
    },
    function (error: any) {
      if (
        403 === error?.response?.status &&
        error?.response?.data?.detail === 'No such token in DB'
      ) {
        store.dispatch(actions.logout());
      }
      throw error;
    }
  );
}

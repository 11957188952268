import { useIntl } from 'react-intl';
import { OrderModel, retrieveOrder } from '../../b2b-orders';
import { Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { CreditNoteResquestModel } from '../models/CreditNoteResquestModel';
import { InvoiceModel } from '../models/InvoiceModel';

interface PropTypes {
  visible?: boolean;
  onClose?: () => void;
  onCreateCreditNote?: (creditNoteRequest: CreditNoteResquestModel) => void;
  invoice?: InvoiceModel;
  loading?: boolean;
}

export default function CreditNoteModal({
  visible = false,
  onClose = () => {},
  invoice,
  loading = false,
  onCreateCreditNote,
}: PropTypes) {
  const intl = useIntl();
  const [order, setOrder] = useState<OrderModel | null>(null);
  const [creditNoteRequest, setCreditNoteRequest] =
    useState<CreditNoteResquestModel>({
      invoice: invoice?.id || 0,
      shipping_fee: 0,
      total_discounts: 0,
      order_lines: [],
    });

  async function fetchOrder(orderId: number) {
    try {
      const { data } = await retrieveOrder(orderId);
      setOrder(data);
    } catch (error) {
      console.warn(error);
    }
  }

  useEffect(() => {
    if (invoice?.order) {
      fetchOrder(invoice?.order);
    }
  }, [invoice]);

  useEffect(() => {
    const newCreditNoteRequest = {
      invoice: invoice?.id || 0,
      shipping_fee: order?.shipping_fee || 0,
      total_discounts: order?.total_discounts || 0,
      order_lines:
        order?.order_lines.map((orderLine) => ({
          order_line: orderLine.id,
          units: orderLine.units,
          is_free: orderLine.is_free,
        })) || [],
    };
    setCreditNoteRequest(newCreditNoteRequest);
  }, [order]);

  function isInvalid() {
    return false;
  }

  return (
    <Modal show={visible} onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {intl.formatMessage(
            { id: 'creditNoteModal.title' },
            { code: invoice?.code }
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-start gap-4">
          <div className="form-group mb-4 w-200px">
            <label htmlFor="date" className="mb-1 fw-bolder">
              {intl.formatMessage({ id: 'creditNoteModal.shippingFee' })}
            </label>
            <div className="input-group">
              <input
                type="number"
                min={0}
                max={order?.shipping_fee || 0}
                value={creditNoteRequest?.shipping_fee}
                className="form-control"
                placeholder={intl.formatMessage({
                  id: 'creditNoteModal.shippingFee',
                })}
                onChange={(e) => {
                  setCreditNoteRequest({
                    ...creditNoteRequest,
                    shipping_fee: parseFloat(e.target.value),
                  });
                }}
              />
              <span className="input-group-text">€</span>
            </div>
          </div>
          <div className="form-group mb-4 w-200px">
            <label htmlFor="date" className="mb-1 fw-bolder">
              {intl.formatMessage({ id: 'creditNoteModal.totalDiscounts' })}
            </label>

            <div className="input-group">
              <input
                type="number"
                step="0.01"
                value={creditNoteRequest?.total_discounts}
                className="form-control"
                placeholder={intl.formatMessage({
                  id: 'creditNoteModal.totalDiscounts',
                })}
                onChange={(e) => {
                  setCreditNoteRequest({
                    ...creditNoteRequest,
                    total_discounts: parseFloat(e.target.value),
                  });
                }}
              />
              <span className="input-group-text">€</span>
            </div>
          </div>
        </div>
        <table className="table table-hover table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
          <thead className="table-header">
            <tr className="fw-bolder">
              <th className="w-auto ps-4">
                {intl.formatMessage({ id: 'creditNoteModal.product' })}
              </th>
              <th className="w-100px">
                {intl.formatMessage({ id: 'creditNoteModal.isFree' })}
              </th>
              <th className="w-100px">
                {intl.formatMessage({ id: 'creditNoteModal.units' })}
              </th>
              <th className="w-100px">
                {intl.formatMessage({
                  id: 'creditNoteModal.unitsToBeCredited',
                })}
              </th>
              <th className="w-100px">
                {intl.formatMessage({ id: 'creditNoteModal.remainingUnits' })}
              </th>
            </tr>
          </thead>
          <tbody>
            {creditNoteRequest?.order_lines?.map((orderLine, index) => {
              const fullOrderLine = order?.order_lines.find(
                (fullOrderLine) => fullOrderLine.id === orderLine.order_line
              );
              const lineName = fullOrderLine?.name || '';
              const originalUnits = fullOrderLine?.units || 0;
              const remainingUnits = originalUnits - orderLine.units;

              return (
                <tr key={index} className="align-middle">
                  <td className="ps-4">{lineName}</td>
                  <td className="">
                    {orderLine.is_free
                      ? intl.formatMessage({ id: 'creditNoteModal.yes' })
                      : intl.formatMessage({ id: 'creditNoteModal.no' })}
                  </td>
                  <td className="">{originalUnits}</td>
                  <td>
                    <input
                      type="number"
                      className={clsx('form-control')}
                      value={creditNoteRequest.order_lines[index].units}
                      min={0}
                      max={originalUnits}
                      onChange={(e) => {
                        const newOrderLines = creditNoteRequest.order_lines;
                        newOrderLines[index].units = parseInt(
                          e.target.value,
                          10
                        );
                        setCreditNoteRequest({
                          ...creditNoteRequest,
                          order_lines: newOrderLines,
                        });
                      }}
                    />
                  </td>
                  <td className="">{remainingUnits}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-tertiary" onClick={onClose}>
          {intl.formatMessage({ id: 'creditNoteModal.cancel' })}
        </button>
        <button
          disabled={isInvalid() || loading}
          className="btn btn-primary"
          onClick={() => onCreateCreditNote(creditNoteRequest)}
        >
          {intl.formatMessage({ id: 'creditNoteModal.generate' })}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { currentUserSelector } from '../../user-profile';

export default function UserComments({ loading = false, comment = '' }) {
  const intl = useIntl();
  const currentUser = useSelector(currentUserSelector);

  return (
    <>
      <h1 className="mb-4">
        {intl.formatMessage(
          { id: 'comments.userComments' },
          {
            user: currentUser?.name || currentUser?.email || '-',
          }
        )}
      </h1>
      {loading && <div className="spinner-border text-primary" role="status" />}
      {!loading && comment.length === 0 && (
        <p className="text-muted mb-0">
          {intl.formatMessage({ id: 'comments.noUserComments' })}
        </p>
      )}
      {!loading && comment.length > 0 && (
        <div className="bg-light-primary rounded border-primary border border-dashed p-4">
          {comment}
        </div>
      )}
    </>
  );
}

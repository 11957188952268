import { useEffect, useState } from 'react';
import DateRangeSelector from '../../../components/DateRangeSelector';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { getLeadKpis, LeadKpis } from '../services/DashboardCRUD';
import { toast } from 'react-toastify';
import { staffUsersSelector } from '../../tasks';
import { useSelector } from 'react-redux';

enum State {
  IDLE,
  LOADING,
}

export default function LeadStats() {
  const intl = useIntl();
  const staffUsers = useSelector(staffUsersSelector);
  const initialSince = new Date(new Date().setDate(new Date().getDate() - 31));
  const initialUntil = new Date(new Date().setDate(new Date().getDate() - 1));
  const [since, setSince] = useState(initialSince);
  const [until, setUntil] = useState(initialUntil);
  const [agent, setAgent] = useState('');
  const [state, setState] = useState(State.IDLE);
  const [kpis, setKpis] = useState<LeadKpis>(null);

  useEffect(() => {
    fetchStats();
  }, [since, until, agent]);

  async function fetchStats() {
    try {
      setState(State.LOADING);
      const { data } = await getLeadKpis({
        start_date: moment(since).format('YYYY-MM-DD'),
        end_date: moment(until).format('YYYY-MM-DD'),
        agent: +agent || null,
      });
      setKpis(data);
    } catch (error) {
      console.warn(error);
      toast.error(intl.formatMessage({ id: 'atcDashboard.statsError' }));
    } finally {
      setState(State.IDLE);
    }
  }

  return (
    <>
      <h1 className="title mb-8">
        {intl.formatMessage({ id: 'atcDashboard.leadStats' })}
      </h1>
      <div className="d-flex gap-4 align-items-end">
        <DateRangeSelector
          initialSince={initialSince}
          initialUntil={initialUntil}
          onSelected={(since, until) => {
            setSince(since);
            setUntil(until);
          }}
          disableFutureDates={false}
        />
        <div>
          <label className="form-label">
            {intl.formatMessage({ id: 'atcDashboard.agent' })}
          </label>
          <select
            className="form-select"
            value={agent}
            onChange={(e) => setAgent(e.target.value)}
          >
            <option value="">
              {intl.formatMessage({ id: 'atcDashboard.allAgents' })}
            </option>
            {staffUsers.map((user) => (
              <option key={user.id} value={user.id}>
                {user.name}
              </option>
            ))}
          </select>
        </div>
        {state === State.LOADING && (
          <span className="spinner-border text-primary spinner-border-md mb-3 ms-4" />
        )}
      </div>
      {kpis && (
        <section className="mt-8 bg-light-primary px-10 py-5 rounded">
          <h2>{intl.formatMessage({ id: 'atcDashboard.attempts' })}</h2>
          <ul>
            {Object.entries(kpis?.task_lead_attempts || {}).length > 0 ? (
              Object.entries(kpis?.task_lead_attempts || {}).map(
                ([key, value]) => (
                  <li key={key}>
                    {key
                      .split('-')
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(' ')}
                    : <strong>{value}</strong>
                  </li>
                )
              )
            ) : (
              <li>{intl.formatMessage({ id: 'atcDashboard.noAttempts' })}</li>
            )}
          </ul>
          <h2>{intl.formatMessage({ id: 'atcDashboard.contactData' })}</h2>
          <ul>
            <li>
              {intl.formatMessage({ id: 'atcDashboard.leadsTouched' })}
              <strong> {kpis?.leads_touched_data?.leads_touched || 0}</strong>
            </li>
            <li>
              {intl.formatMessage({ id: 'atcDashboard.leadsTouchedPercent' })}
              <strong>
                {' '}
                {Math.round(
                  kpis?.leads_touched_data?.leads_touched_percentage
                ) || 0}
                %
              </strong>
            </li>
            <li>
              {intl.formatMessage({ id: 'atcDashboard.leadsNotTouched' })}
              <strong>
                {' '}
                {kpis?.leads_touched_data?.leads_not_touched || 0}
              </strong>
            </li>
            <li>
              {intl.formatMessage({
                id: 'atcDashboard.leadsNotTouchedPercent',
              })}
              <strong>
                {' '}
                {Math.round(
                  kpis?.leads_touched_data?.leads_not_touched_percentage
                ) || 0}
                %
              </strong>
            </li>
          </ul>
          <h2>{intl.formatMessage({ id: 'atcDashboard.conversionData' })}</h2>
          <ul>
            <li>
              {intl.formatMessage({ id: 'atcDashboard.convertedLeads' })}{' '}
              <strong>
                {' '}
                {kpis?.leads_converted_data?.leads_converted || 0}
              </strong>
            </li>
            <li>
              {intl.formatMessage({ id: 'atcDashboard.convertedLeadsPercent' })}{' '}
              <strong>
                {' '}
                {Math.round(
                  kpis?.leads_converted_data?.leads_converted_percentage
                ) || 0}
                %
              </strong>
            </li>
          </ul>
          <h2>{intl.formatMessage({ id: 'atcDashboard.koData' })}</h2>
          <ul>
            <li>
              {intl.formatMessage({ id: 'atcDashboard.totalKos' })}
              <strong> {kpis?.ko_total_data?.ko_total || 0}</strong>
            </li>
            <li>
              {intl.formatMessage({ id: 'atcDashboard.kosInCallRequests' })}
              <strong>
                {' '}
                {kpis?.ko_total_data['ko_total_call-requested-lead'] || 0}
              </strong>
            </li>
            <li>
              {intl.formatMessage({
                id: 'atcDashboard.kosInCallRequestsPercent',
              })}
              <strong>
                {' '}
                {Math.round(
                  kpis?.ko_total_data['ko_total_call-requested-lead_percentaje']
                ) || 0}
                %
              </strong>
            </li>
            <li>
              {intl.formatMessage({ id: 'atcDashboard.kosInNewLeads' })}
              <strong> {kpis?.ko_total_data['ko_total_new-lead'] || 0}</strong>
            </li>
            <li>
              {intl.formatMessage({ id: 'atcDashboard.kosInNewLeadsPercent' })}
              <strong>
                {' '}
                {Math.round(
                  kpis?.ko_total_data['ko_total_new-lead_percentage']
                ) || 0}
                %
              </strong>
            </li>
            <li>
              {intl.formatMessage({ id: 'atcDashboard.kosWithNoResults' })}
              <strong>
                {' '}
                {kpis?.ko_total_data[
                  'ko_total_quiz-completed-and-no-results'
                ] || 0}
              </strong>
            </li>
            <li>
              {intl.formatMessage({
                id: 'atcDashboard.kosWithNoResultsPercent',
              })}
              <strong>
                {' '}
                {Math.round(
                  kpis?.ko_total_data[
                    'ko_total_quiz-completed-and-no-results_percentage'
                  ]
                ) || 0}
                %
              </strong>
            </li>
          </ul>
        </section>
      )}
    </>
  );
}

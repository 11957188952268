import { useIntl } from 'react-intl';
import GoogleMap from 'google-maps-react-markers';

import { useEffect, useRef, useState } from 'react';
import { ShopModel } from '../models/ShopModel';
import { Modal } from 'react-bootstrap';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { searchPlaces } from '../../lead-distributors';

const DEFAULT_ZOOM = 18;

type Props = {
  shop?: ShopModel;
  visible: boolean;
  onSaveShop: (shop: ShopModel) => void;
  onCancel: () => void;
};

export default function ModalShopForm({
  shop,
  visible,
  onSaveShop,
  onCancel,
}: Props) {
  const intl = useIntl();
  const mapRef = useRef(null);
  const mapsRef = useRef(null);
  const [shopState, setShopState] = useState(shop);

  useEffect(() => {
    if (shop) {
      setShopState(shop);
    } else {
      setShopState({
        name: '',
        address: '',
        location: '',
        lat: 0,
        long: 0,
        id: undefined,
      });
    }
  }, [shop, visible]);

  function onGoogleApiLoaded({ map, maps }) {
    mapRef.current = map;
    mapsRef.current = maps;
  }

  useEffect(() => {
    if (
      shopState?.lat &&
      shopState?.long &&
      mapRef?.current?.setCenter &&
      mapRef?.current?.setZoom
    ) {
      mapRef.current.setCenter({
        lat: shopState?.lat,
        lng: shopState?.long,
      });
      mapRef.current.setZoom(DEFAULT_ZOOM);
    }
  }, [shopState?.lat, shopState?.long, mapRef?.current]);

  async function onGeolocatePlace() {
    if (mapsRef?.current) {
      const {
        data: { results: places },
      } = await searchPlaces(
        `${shopState?.name}, ${shopState?.address}, ${shopState?.location}`
      );

      if (places?.length > 0) {
        const selectedResult = places[0];
        const name = selectedResult.name;
        const lat = selectedResult.location.lat;
        const long = selectedResult.location.lng;

        // Ej:
        // España
        // "P.º de las Acacias, 22, Arganzuela, 28005 Madrid, Spain"
        // "Av. Diagonal, 310, L'Eixample, 08013 Barcelona, Spain"
        // Francia
        // "4 Rte des Fauvins, 05000 Gap, France"
        // "2 Rue de la République, 13001 Marseille, France"
        const address =
          selectedResult.formatted_address
            ?.split(',')
            ?.slice(0, -2)
            .join(',')
            .trim() || shopState?.address;
        const postcodeAndLocation = selectedResult.formatted_address
          ?.split(',')
          ?.slice(-2)
          .join(',')
          .trim();
        const location =
          postcodeAndLocation?.split(' ').slice(1).join(' ').trim() ||
          shopState?.location;

        setShopState({
          ...shopState,
          name,
          address,
          location,
          lat,
          long,
        });
      } else {
        toast.error(intl.formatMessage({ id: 'shops.geolocateError' }));
      }
    }
  }

  return (
    <Modal show={visible} onClose={onCancel}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">
            {shopState?.id
              ? intl.formatMessage({ id: 'shops.editShop' })
              : intl.formatMessage({ id: 'shops.addShop' })}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={onCancel}
          ></button>
        </div>
        <div className="modal-body">
          <form>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                {intl.formatMessage({ id: 'shops.name' })}
              </label>
              <input
                type="text"
                value={shopState?.name}
                onChange={(e) =>
                  setShopState({ ...shopState, name: e.target.value })
                }
                className="form-control"
                id="name"
                placeholder={intl.formatMessage({
                  id: 'shops.namePlaceholder',
                })}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="address" className="form-label">
                {intl.formatMessage({ id: 'shops.address' })}
              </label>
              <input
                type="text"
                value={shopState?.address}
                onChange={(e) =>
                  setShopState({ ...shopState, address: e.target.value })
                }
                className="form-control"
                id="address"
                placeholder={intl.formatMessage({
                  id: 'shops.addressPlaceholder',
                })}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="location" className="form-label">
                {intl.formatMessage({ id: 'shops.location' })}
              </label>
              <input
                type="text"
                value={shopState?.location}
                onChange={(e) =>
                  setShopState({ ...shopState, location: e.target.value })
                }
                className="form-control"
                id="location"
                placeholder={intl.formatMessage({
                  id: 'shops.locationPlaceholder',
                })}
              />
            </div>
            <button
              type="button"
              className="d-block mb-4 ms-auto btn btn-primary"
              disabled={!mapsRef?.current}
              onClick={onGeolocatePlace}
            >
              <span className="fa fa-map-marker me-3"></span>
              {intl.formatMessage({ id: 'shops.geocode' })}
            </button>
            <div className="mb-3">
              <label htmlFor="lat" className="form-label">
                {intl.formatMessage({ id: 'shops.latitude' })}
              </label>
              <input
                type="text"
                className="form-control"
                id="lat"
                value={shopState?.lat}
                onChange={(e) =>
                  setShopState({
                    ...shopState,
                    lat: parseFloat(e.target.value),
                  })
                }
              />
            </div>
            <div className="mb-3">
              <label htmlFor="long" className="form-label">
                {intl.formatMessage({ id: 'shops.longitude' })}
              </label>
              <input
                type="text"
                className="form-control"
                id="long"
                value={shopState?.long}
                onChange={(e) =>
                  setShopState({
                    ...shopState,
                    long: parseFloat(e.target.value),
                  })
                }
              />
            </div>
          </form>
          {
            <div
              className={clsx(
                'mt-4',
                shopState?.lat && shopState?.long ? '' : 'd-none'
              )}
            >
              <GoogleMap
                onGoogleApiLoaded={onGoogleApiLoaded}
                apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}
                defaultCenter={{ lat: shopState?.lat, lng: shopState?.long }}
                defaultZoom={DEFAULT_ZOOM}
                mapMinHeight="300px"
                options={{
                  clickableIcons: false,
                  mapTypeControl: false,
                  streetViewControl: false,
                }}
              >
                {shopState?.lat && shopState?.long && (
                  <div
                    className="rounded-circle bg-primary border border-2 border-white d-flex justify-content-center align-items-center"
                    id={`${shopState?.id}`}
                    {...{
                      lat: shopState?.lat,
                      lng: shopState?.long,
                    }}
                    style={{
                      width: 30,
                      height: 30,
                      cursor: 'pointer',
                      transform: `translate(${-15}px, ${-15}px)`,
                    }}
                  >
                    <div className="" role="button" tabIndex={0}></div>
                  </div>
                )}
              </GoogleMap>
            </div>
          }
          <div className="d-flex gap-4 pt-8 justify-content-end">
            <button
              type="button"
              className="btn btn-tertiary"
              data-bs-dismiss="modal"
              onClick={onCancel}
            >
              {intl.formatMessage({ id: 'common.close' })}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => onSaveShop(shopState)}
              disabled={!shopState?.lat || !shopState?.long}
            >
              <span className="fa fa-save me-3"></span>
              {intl.formatMessage({ id: 'common.save' })}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

import { TaskModel } from '../models/TaskModel';
import { TaskSetResolutionModel } from '../models/TaskSetResolutionModel';
import TaskInfo from './TaskInfo';
import TaskHistory from './TaskHistory';

type TaskProps = {
  task: TaskModel;
  tab: number;
  onAddComment: (TaskCommentModel) => void;
  onResolve: (
    taskSetResolution: TaskSetResolutionModel,
    replaceAgent: boolean,
    isAlarm: boolean
  ) => void;
  displayOwner?: boolean;
};

export const solvableStates = [
  'pending',
  'dispatched',
  'dispatched-alternatively',
  'sent',
  'cancelled',
];

const Task = ({
  task,
  tab,
  onAddComment,
  onResolve,
  displayOwner = false,
}: TaskProps) => {
  const resolutionCodes: Array<string> =
    task?.resolutions?.map((resolution) => resolution.code) || [];
  let incidenceResolutionCodes: any = [];
  resolutionCodes.forEach((code) => {
    if (
      code === 'technical-incidence' ||
      code === 'pet-incidence' ||
      code === 'payment-incidence'
    ) {
      incidenceResolutionCodes.push({
        value: code,
        label: code.split('-').join(' '),
      });
    }
  });

  if (task) {
    return (
      <>
        {tab === 0 && (
          <TaskInfo
            onAddComment={onAddComment}
            task={task}
            displayOwner={displayOwner}
            onResolve={onResolve}
          />
        )}
        {tab === 1 && <TaskHistory task={task} />}
      </>
    );
  }
  return <></>;
};

export default Task;

import axios from 'axios';
import { SaleableProductModel } from '../models/SaleableProductModel';

const API_URL = process.env.REACT_APP_API_URL;
const SALEABLE_PRODUCTS_URL = `${API_URL}/api/b2b/saleable-products/`;

export async function listSaleableProducts(
  distributorId
): Promise<{ data: Array<SaleableProductModel> }> {
  if (!distributorId) return await axios.get(SALEABLE_PRODUCTS_URL);

  return await axios.get(SALEABLE_PRODUCTS_URL, {
    params: {
      distributor: distributorId,
    },
  });
}

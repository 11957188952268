import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { checkIsActive, KTSVG } from '../../../helpers';
import { useLayout } from '../../core';

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
  children?: any;
};

const AsideMenuItem = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
}: Props) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);
  const { config } = useLayout();
  const { aside } = config;

  return (
    <div className="menu-item ">
      <Link
        className={clsx('menu-link without-sub py-5 mb-2', {
          active: isActive,
        })}
        to={to}
      >
        {hasBullet && (
          <span className="menu-bullet">
            <span className="bullet bullet-dot"></span>
          </span>
        )}
        {icon && aside.menuIcon === 'svg' && (
          <span className="menu-icon">
            <KTSVG path={icon} className="svg-icon-2" />
          </span>
        )}
        {fontIcon && aside.menuIcon === 'font' && (
          <i className={clsx('fas fs-3 w-20px me-7', fontIcon)}></i>
        )}
        <span className="menu-title fs-6">{title}</span>
      </Link>
      {children}
    </div>
  );
};

export { AsideMenuItem };

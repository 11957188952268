import { useIntl } from 'react-intl';
import MealPricingModel from '../models/MealPricingModel';
import { useEffect, useState } from 'react';
import {
  listMealPricing,
  updateMealPricing,
} from '../services/MealPricingCRUD';
import { toast } from 'react-toastify';

export default function MealPricing() {
  const intl = useIntl();
  const [mealPricing, setMealPricing] = useState<Array<MealPricingModel>>(null);
  const [dirtyMealPricing, setDirtyMealPricing] =
    useState<Array<MealPricingModel>>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [mealBeingUpdated, setMealBeingUpdated] =
    useState<MealPricingModel>(null);

  async function fetchMealPricing(withLoader = true) {
    try {
      if (withLoader) {
        setLoading(true);
      }

      let { data } = await listMealPricing();

      data = data.sort((a, b) => {
        if (a.store.name > b.store.name) {
          return 1;
        } else if (a.store.name < b.store.name) {
          return -1;
        } else {
          if (a.meal.name > b.meal.name) {
            return 1;
          } else if (a.meal.name < b.meal.name) {
            return -1;
          } else {
            if (a.size > b.size) {
              return 1;
            } else if (a.size < b.size) {
              return -1;
            } else {
              return 0;
            }
          }
        }
      });

      // Prevent crossed references to objects as
      // we will compare dirtyMealPricing with mealPricing later on
      setMealPricing([...data.map((meal) => ({ ...meal }))]);
      setDirtyMealPricing([...data.map((meal) => ({ ...meal }))]);
    } catch (error) {
      console.warn(error);
      toast.error(intl.formatMessage({ id: 'mealPricing.list.error' }));
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchMealPricing(true);
  }, []);

  async function onChangeCogs(
    meal: MealPricingModel,
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    event.preventDefault();
    const cogs = Number(event.target.value);
    const mealIndex = dirtyMealPricing.findIndex(
      (dirtyMeal) => dirtyMeal.id === meal.id
    );

    if (mealIndex > -1) {
      setDirtyMealPricing((prevState) => {
        const newState = [...prevState];
        newState[mealIndex].cogs_cost = cogs;
        return newState;
      });
    }
  }

  async function onChangePvp(
    meal: MealPricingModel,
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    event.preventDefault();
    const pvp = Number(event.target.value);
    const mealIndex = dirtyMealPricing.findIndex(
      (dirtyMeal) => dirtyMeal.id === meal.id
    );

    if (mealIndex > -1) {
      setDirtyMealPricing((prevState) => {
        const newState = [...prevState];
        newState[mealIndex].pvp = pvp;
        return newState;
      });
    }
  }

  async function onUpdateCogs(
    meal: MealPricingModel,
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    event.preventDefault();
    const mealIndex = dirtyMealPricing.findIndex(
      (dirtyMeal) => dirtyMeal.id === meal.id
    );

    if (
      mealPricing[mealIndex].cogs_cost !== dirtyMealPricing[mealIndex].cogs_cost
    ) {
      try {
        setMealBeingUpdated(meal);
        await updateMealPricing(dirtyMealPricing[mealIndex]);
        toast.success(intl.formatMessage({ id: 'mealPricing.cogsSuccess' }));
      } catch (error) {
        console.warn(error);
        toast.error(intl.formatMessage({ id: 'mealPricing.cogsError' }));
      } finally {
        setMealBeingUpdated(null);
        fetchMealPricing(false);
      }
    }
  }

  async function onUpdatePvp(
    meal: MealPricingModel,
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    event.preventDefault();
    const mealIndex = dirtyMealPricing.findIndex(
      (dirtyMeal) => dirtyMeal.id === meal.id
    );

    if (mealPricing[mealIndex].pvp !== dirtyMealPricing[mealIndex].pvp) {
      try {
        setMealBeingUpdated(meal);
        await updateMealPricing(dirtyMealPricing[mealIndex]);
        toast.success(intl.formatMessage({ id: 'mealPricing.pvpSuccess' }));
      } catch (error) {
        console.warn(error);
        toast.error(intl.formatMessage({ id: 'mealPricing.pvpError' }));
      } finally {
        setMealBeingUpdated(null);
        fetchMealPricing(false);
      }
    }
  }

  return (
    <div>
      <div className="">
        <h1 className="title mb-8">
          {intl.formatMessage({ id: 'mealPricing.title' })}
        </h1>
      </div>

      {loading && <div className="spinner-border text-primary" role="status" />}
      {!loading && !(dirtyMealPricing.length > 0) && (
        <p>{intl.formatMessage({ id: 'mealPricing.noPricing' })}</p>
      )}
      {!loading && dirtyMealPricing.length > 0 && (
        <table className="table table-striped table-hover align-middle">
          <thead className="table-header">
            <tr className="fw-bolder">
              <th className="">
                {intl.formatMessage({ id: 'mealPricing.store' })}
              </th>
              <th className="">
                {intl.formatMessage({ id: 'mealPricing.meal' })}
              </th>
              <th className="">
                {intl.formatMessage({ id: 'mealPricing.format' })}
              </th>
              <th className="">
                {intl.formatMessage({ id: 'mealPricing.cogs' })}
              </th>
              <th className="">
                {intl.formatMessage({ id: 'mealPricing.pvp' })}
              </th>
            </tr>
          </thead>
          <tbody>
            {dirtyMealPricing &&
              dirtyMealPricing.map((meal) => {
                return (
                  <tr key={meal.id} className="">
                    <td className="ps-4">{meal.store.country.name}</td>
                    <td>{meal.meal.name}</td>
                    <td>{meal.size} gr</td>
                    <td className="text-end">
                      {mealBeingUpdated && mealBeingUpdated.id === meal.id ? (
                        <div
                          className="spinner-border text-primary d-block w-20px h-20px mx-auto"
                          role="status"
                        />
                      ) : (
                        <div className="d-flex align-items-center gap-2">
                          <input
                            disabled={!!mealBeingUpdated}
                            onChange={(event) => onChangeCogs(meal, event)}
                            onBlur={(event) => onUpdateCogs(meal, event)}
                            type="text"
                            className="form-control w-100px"
                            value={meal.cogs_cost}
                          />{' '}
                          {meal.store.country.currency.symbol}
                        </div>
                      )}
                    </td>
                    <td className="text-end pe-4">
                      {mealBeingUpdated && mealBeingUpdated.id === meal.id ? (
                        <div
                          className="spinner-border text-primary d-block w-20px h-20px mx-auto"
                          role="status"
                        />
                      ) : (
                        <div className="d-flex align-items-center gap-2">
                          <input
                            disabled={!!mealBeingUpdated}
                            onBlur={(event) => onUpdatePvp(meal, event)}
                            onChange={(event) => onChangePvp(meal, event)}
                            type="text"
                            className="form-control w-100px"
                            value={meal.pvp}
                          />{' '}
                          {meal.store.country.currency.symbol}
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
    </div>
  );
}

import { all, delay, put, select, takeEvery } from 'redux-saga/effects';
import {
  listPets,
  getPetById,
  listMealPreferences,
  updatePet,
  uploadPetDocuments,
  updateMealPreference,
  deletePet,
  setAsPriorityPet,
  recalculatePlans,
  createNewPlan,
  removePetDocument,
} from '../services/PetCRUD';
import { PetModel } from '../models/PetModel';
import { MealPreferenceModel } from '../models/MealPreferenceModel';
import { actions as planActions } from '../../plans/redux/PlanRedux';
import { actions as orderActions } from '../../orders/redux/OrderRedux';
import { RootState, ActionWithPayload } from '../../../../setup';
import { currentUserSelector } from '../../user-profile';

export const actionTypes = {
  ListPets: 'PET_REDUX_LIST_PETS',
  SetPets: 'PET_REDUX_SET_PETS',
  ResetPets: 'PET_REDUX_RESET_PETS',
  SetStatus: 'PET_REDUX_SET_STATUS',
  SetError: 'PET_REDUX_SET_ERROR',
  PetRequested: 'PET_REDUX_REQUEST_PET',
  MealPreferencesRequested: 'PET_REDUX_REQUEST_MEAL_PREFERENCES',
  SetSelectedPet: 'PET_REDUX_SET_SELECTED_PET',
  UpdateSelectedPet: 'PET_REDUX_UPDATE_SELECTED_PET',
  UploadPetDocuments: 'PET_REDUX_UPDATE_SELECTED_PET_DOCUMENTS',
  RemovePetDocument: 'PET_REDUX_REMOVE_SELECTED_PET_DOCUMENT',
  UpdateMealPreferences: 'PET_REDUX_UPDATE_MEAL_PREFERENCES',
  SetMealPreferences: 'PET_REDUX_SET_MEAL_PREFERENCES',
  DeletePet: 'PET_REDUX_DELETE_PET',
  SetSelectedPetAsPriorityPet: 'PET_REDUX_SET_SELECTED_PET_AS_PRIORITY_PET',
  RecalculatePlans: 'PET_REDUX_RECALCULATE_PLANS',
  CreateNewPlan: 'PET_REDUX_CREATE_NEW_PLAN',
};

type Status =
  | 'idle'
  // request lifecycle
  | 'loading_request_selected_pet'
  | 'error_request_selected_pet'
  | 'success_request_selected_pet'
  // update lifecycle
  | 'loading_update_selected_pet'
  | 'error_update_selected_pet'
  | 'success_update_selected_pet'
  // delete lifecycle
  | 'loading_delete_selected_pet'
  | 'error_delete_selected_pet'
  | 'success_delete_selected_pet'
  // load meal preferences lifecycle
  | 'loading_meal_preferences'
  | 'error_meal_preferences'
  | 'success_meal_preferences'
  // update priority
  | 'loading_update_selected_pet_priority'
  | 'error_update_selected_pet_priority'
  | 'success_update_selected_pet_priority'
  // recalculate plans lifecycle
  | 'loading_recalculate_plans_selected_pet'
  | 'error_recalculate_plans_selected_pet'
  | 'success_recalculate_plans_selected_pet'
  // create new plan lifecycle
  | 'loading_create_new_plan_selected_pet'
  | 'error_create_new_plan_selected_pet'
  | 'success_create_new_plan_selected_pet'
  // list pets lifecycle
  | 'loading_list_pets'
  | 'error_list_pets'
  | 'success_list_pets'
  // upload documents lifecycle
  | 'loading_upload_documents'
  | 'error_upload_documents'
  | 'success_upload_documents'
  // remove document lifecycle
  | 'loading_remove_document'
  | 'error_remove_document'
  | 'success_remove_document';

export interface IPetState {
  pets: Array<PetModel>;
  selectedPet?: PetModel;
  mealPreferences: Array<MealPreferenceModel>;
  status: Status;
  error?: any;
}

const initialState: IPetState = {
  pets: [],
  selectedPet: undefined,
  mealPreferences: [],
  status: 'loading_list_pets',
  error: undefined,
};

export const petsSelector = (state: RootState) => state.pet.pets;
export const selectedPetSelector = (state: RootState) => state.pet.selectedPet;
export const mealPreferencesSelector = (state: RootState) =>
  state.pet.mealPreferences;
export const petStatusSelector = (state: RootState) => state.pet.status;
export const petErrorSelector = (state: RootState) => state.pet.error;

export const reducer = (
  state: IPetState = initialState,
  action: ActionWithPayload<IPetState>
): IPetState => {
  switch (action.type) {
    case actionTypes.SetPets: {
      const pets: PetModel[] = action.payload?.pets || [];
      return { ...state, pets };
    }

    case actionTypes.SetStatus: {
      const status = action.payload?.status || 'idle';
      return { ...state, status };
    }

    case actionTypes.SetMealPreferences: {
      const mealPreferences = action.payload?.mealPreferences || [];
      return { ...state, mealPreferences };
    }

    case actionTypes.SetError: {
      const error = action.payload?.error || undefined;
      return { ...state, error };
    }

    case actionTypes.ResetPets: {
      return initialState;
    }

    case actionTypes.SetSelectedPet: {
      const selectedPet = action.payload?.selectedPet || undefined;
      return {
        ...state,
        selectedPet,
        status: 'idle',
      };
    }

    default:
      return state;
  }
};

export const actions = {
  setPets: (pets: PetModel[]) => ({
    type: actionTypes.SetPets,
    payload: { pets },
  }),
  listPets: (userId: number) => ({
    type: actionTypes.ListPets,
    payload: { userId },
  }),
  resetPets: () => ({ type: actionTypes.ResetPets }),
  setStatus: (status: Status) => ({
    type: actionTypes.SetStatus,
    payload: { status },
  }),
  setError: (error: any) => ({
    type: actionTypes.SetError,
    payload: { error },
  }),
  setSelectedPet: (selectedPet: PetModel) => ({
    type: actionTypes.SetSelectedPet,
    payload: { selectedPet },
  }),
  setMealPreferences: (mealPreferences: MealPreferenceModel[]) => ({
    type: actionTypes.SetMealPreferences,
    payload: { mealPreferences },
  }),
  requestPet: (petId: number) => ({
    type: actionTypes.PetRequested,
    payload: { petId },
  }),
  requestMealPreferences: (petId: number) => ({
    type: actionTypes.MealPreferencesRequested,
    payload: { petId },
  }),
  updateMealPreferences: (
    petId: number,
    mealPreferences: MealPreferenceModel[]
  ) => ({
    type: actionTypes.UpdateMealPreferences,
    payload: { petId, mealPreferences },
  }),
  updateSelectedPet: (pet: Partial<PetModel>) => ({
    type: actionTypes.UpdateSelectedPet,
    payload: { pet },
  }),
  uploadPetDocuments: (petId: number, documents: Array<File>) => ({
    type: actionTypes.UploadPetDocuments,
    payload: { petId, documents },
  }),
  removePetDocument: (petId: number, documentId: string) => ({
    type: actionTypes.RemovePetDocument,
    payload: { petId, documentId },
  }),
  deleteSelectedPet: () => ({ type: actionTypes.DeletePet }),
  setSelectedPetAsPriorityPet: () => ({
    type: actionTypes.SetSelectedPetAsPriorityPet,
  }),
  recalculatePlans: () => ({ type: actionTypes.RecalculatePlans }),
  createNewPlan: () => ({ type: actionTypes.CreateNewPlan }),
  store: () => ({ type: 'def' }),
};

export function* saga() {
  yield takeEvery(
    actionTypes.SetSelectedPet,
    function* setSelectedPetEffect(
      action: ActionWithPayload<{ selectedPet: PetModel }>
    ) {
      const selectedPet = action?.payload?.selectedPet;

      if (selectedPet?.id) {
        yield put(planActions.listPetPlans(selectedPet?.id));
      }
    }
  );

  yield takeEvery(
    actionTypes.UpdateSelectedPet,
    function* UpdateSelectedPetEffect(
      action: ActionWithPayload<{ pet: Partial<PetModel> }>
    ) {
      const pet = action?.payload?.pet;
      const id = pet?.id;
      yield put(actions.setError(undefined));
      if (id) {
        yield put(actions.setStatus('loading_update_selected_pet'));
        try {
          const response: any = yield updatePet({
            id,
            props: {
              ...pet,
              activity_level: pet.activity_level?.id,
              allergies: pet.allergies?.map((allergy) => allergy.id),
              breed: pet.breed?.id,
              current_food_type: pet.current_food_type?.id,
              health_issues: pet.health_issues?.map(
                (healthIssue) => healthIssue.id
              ),
              pet_kind: pet.pet_kind?.id,
              body_condition: pet.body_condition?.id,
              has_treats: pet.has_treats ? true : false,
              is_neutered: pet.is_neutered ? true : false,
              is_priority_pet: pet.is_priority_pet ? true : false,
            },
          });
          if (response?.data) {
            const { data: updatedPet } = response;
            yield put(actions.setSelectedPet(updatedPet as PetModel));
            yield put(actions.recalculatePlans());
          }
          yield put(actions.setStatus('success_update_selected_pet'));
        } catch (error: any) {
          console.warn({ error });
          yield put(actions.setStatus('error_update_selected_pet'));
          if (error?.response?.data) {
            yield put(actions.setError(error?.response?.data));
          }
        } finally {
          yield delay(0);
          yield put(actions.setStatus('idle'));
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.UploadPetDocuments,
    function* UploadPetDocumentsEffect(
      action: ActionWithPayload<{ petId: number; documents: Array<File> }>
    ) {
      const petId = action?.payload?.petId;
      const documents = action?.payload?.documents;

      yield put(actions.setError(undefined));

      if (petId && documents) {
        yield put(actions.setStatus('loading_upload_documents'));
        try {
          const response: any = yield uploadPetDocuments({
            id: petId,
            documents,
          });
          if (response?.data) {
            const { data: updatedPet } = response;
            yield put(actions.setSelectedPet(updatedPet as PetModel));
          }
          yield put(actions.setStatus('success_upload_documents'));
        } catch (error: any) {
          console.warn({ error });
          yield put(actions.setStatus('error_upload_documents'));

          if (error?.response?.data) {
            yield put(actions.setError(error?.response?.data));
          }
        } finally {
          yield delay(0);
          yield put(actions.setStatus('idle'));
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.RemovePetDocument,
    function* RemovePetDocumentEffect(
      action: ActionWithPayload<{ petId: number; documentId: string }>
    ) {
      const petId = action?.payload?.petId;
      const documentId = action?.payload?.documentId;

      yield put(actions.setError(undefined));

      if (petId && documentId) {
        yield put(actions.setStatus('loading_remove_document'));
        try {
          const response: any = yield removePetDocument({ petId, documentId });
          if (response?.data) {
            const { data: updatedPet } = response;
            yield put(actions.setSelectedPet(updatedPet as PetModel));
          }
          yield put(actions.setStatus('success_remove_document'));
        } catch (error: any) {
          console.warn({ error });
          yield put(actions.setStatus('error_remove_document'));

          if (error?.response?.data) {
            yield put(actions.setError(error?.response?.data));
          }
        } finally {
          yield delay(0);
          yield put(actions.setStatus('idle'));
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.SetSelectedPetAsPriorityPet,
    function* SetSelectedPetAsPriorityPetEffect() {
      const selectedPet = yield select(selectedPetSelector);
      const currentUser = yield select(currentUserSelector);
      const id = selectedPet?.id;
      yield put(actions.setError(undefined));
      if (id) {
        yield put(actions.setStatus('loading_update_selected_pet_priority'));
        try {
          const { data: updatedPet } = yield setAsPriorityPet(id);
          yield put(actions.setSelectedPet(updatedPet as PetModel));
          yield put(actions.listPets(currentUser.id));
          yield put(actions.setStatus('success_update_selected_pet_priority'));
        } catch (error: any) {
          console.warn({ error });
          yield put(actions.setStatus('error_update_selected_pet_priority'));
          if (error?.response?.data) {
            yield put(actions.setError(error?.response?.data));
          }
        } finally {
          yield delay(0);
          yield put(actions.setStatus('idle'));
        }
      }
    }
  );

  yield takeEvery(actionTypes.DeletePet, function* DeletePetEffect() {
    const selectedPet = yield select(selectedPetSelector);
    const currentUser = yield select(currentUserSelector);
    const id = selectedPet?.id;
    yield put(actions.setError(undefined));
    if (id) {
      yield put(actions.setStatus('loading_delete_selected_pet'));
      try {
        yield deletePet(id);
        yield put(actions.listPets(currentUser.id));
        yield put(actions.setStatus('success_delete_selected_pet'));
      } catch (error: any) {
        console.warn({ error });
        yield put(actions.setStatus('error_delete_selected_pet'));
        if (error?.response?.data) {
          yield put(actions.setError(error?.response?.data));
        }
      } finally {
        yield delay(0);
        yield put(actions.setStatus('idle'));
      }
    }
  });

  yield takeEvery(
    actionTypes.RecalculatePlans,
    function* RecalculatePlansEffect() {
      const selectedPet = yield select(selectedPetSelector);
      const id = selectedPet?.id;
      yield put(actions.setError(undefined));
      if (id) {
        yield put(actions.setStatus('loading_recalculate_plans_selected_pet'));
        try {
          yield recalculatePlans(id);
          yield put(planActions.listPetPlans(selectedPet?.id));
          yield put(orderActions.listPetOrders(selectedPet?.id, 1));
          yield put(actions.requestPet(selectedPet?.id));
          yield put(
            actions.setStatus('success_recalculate_plans_selected_pet')
          );
        } catch (error: any) {
          console.warn({ error });
          yield put(actions.setStatus('error_recalculate_plans_selected_pet'));
          if (error?.response?.data) {
            yield put(actions.setError(error?.response?.data));
          }
        } finally {
          yield delay(0);
          yield put(actions.setStatus('idle'));
        }
      }
    }
  );

  yield takeEvery(actionTypes.CreateNewPlan, function* CreateNewPlanEffect() {
    const selectedPet = yield select(selectedPetSelector);
    const currentUser = yield select(currentUserSelector);
    const id = selectedPet?.id;
    yield put(actions.setError(undefined));
    if (id) {
      yield put(actions.setStatus('loading_create_new_plan_selected_pet'));
      try {
        yield createNewPlan(id);
        yield put(planActions.listPlans(currentUser.id));
        yield put(actions.requestPet(selectedPet?.id));
        yield put(actions.setStatus('success_create_new_plan_selected_pet'));
      } catch (error: any) {
        console.warn({ error });
        yield put(actions.setStatus('error_create_new_plan_selected_pet'));
        if (error?.response?.data) {
          yield put(actions.setError(error?.response?.data));
        }
      } finally {
        yield delay(0);
        yield put(actions.setStatus('idle'));
      }
    }
  });

  yield takeEvery(
    actionTypes.ListPets,
    function* ListPetsEffect(action: ActionWithPayload<{ userId: number }>) {
      const userId = action?.payload?.userId;
      yield put(actions.setStatus('loading_list_pets'));

      if (userId) {
        try {
          const { data } = yield listPets(userId);

          yield put(actions.setPets(data || []));
          yield put(actions.setStatus('success_list_pets'));
        } catch (e: any) {
          yield put(actions.setStatus('error_list_pets'));
          console.warn(e);
          if (e?.length > 0) {
            yield put(actions.setError(e[0].toString()));
          }
        }
      }
      yield delay(0);
      yield put(actions.setStatus('idle'));
    }
  );

  yield takeEvery(
    actionTypes.MealPreferencesRequested,
    function* mealPreferencesRequestedEffect(
      action: ActionWithPayload<{ petId: number }>
    ) {
      const petId = action?.payload?.petId;
      if (petId) {
        try {
          const { data: mealsPreferenes } = yield listMealPreferences(petId);
          yield put(actions.setMealPreferences(mealsPreferenes));
        } catch (e) {
          console.warn(e);
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.UpdateMealPreferences,
    function* updateMealPreferencesEffect(
      action: ActionWithPayload<{
        petId: number;
        mealPreferences: MealPreferenceModel[];
      }>
    ) {
      const petId = action?.payload?.petId;
      const mealPreferences = action?.payload?.mealPreferences || [];

      yield put(actions.setStatus('loading_meal_preferences'));
      if (petId) {
        try {
          yield all(
            mealPreferences.map((mealPreference) =>
              updateMealPreference({
                mealPreferenceId: mealPreference.id,
                preference: mealPreference.preference,
                weight: mealPreference.weight,
              })
            )
          );
          yield put(actions.requestMealPreferences(petId));
        } catch (error) {
          console.warn(error);
        } finally {
          yield put(actions.recalculatePlans());
        }
      }

      yield put(actions.setStatus('success_meal_preferences'));
      yield put(actions.setStatus('idle'));
    }
  );

  yield takeEvery(
    actionTypes.PetRequested,
    function* petRequestedEffect(action: ActionWithPayload<{ petId: number }>) {
      const petId = action?.payload?.petId;

      yield put(actions.setError(undefined));
      if (petId) {
        try {
          yield put(actions.setStatus('loading_request_selected_pet'));
          yield put(actions.requestMealPreferences(petId));
          yield put(orderActions.listPetOrders(petId, 1));
          const { data: pet } = yield getPetById(petId);
          yield put(
            actions.setSelectedPet({
              id: petId,
              ...pet,
            })
          );
          yield put(actions.setStatus('success_request_selected_pet'));
        } catch (e) {
          yield put(actions.setStatus('error_request_selected_pet'));
          console.warn(e);
        } finally {
          yield delay(0);
          yield put(actions.setStatus('idle'));
        }
      }
    }
  );
}

import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { currentUserSelector } from '../../user-profile';
import * as petRedux from '../redux/PetRedux';
import * as planRedux from '../../plans/redux/PlanRedux';

export default function PetActions() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const status = useSelector(petRedux.petStatusSelector);
  const error = useSelector(petRedux.petErrorSelector);
  const selectedPet = useSelector(petRedux.selectedPetSelector);
  const petPlans = useSelector(planRedux.petPlansSelector);
  const deleteLoading = status === 'loading_delete_selected_pet';
  const priorityLoading = status === 'loading_update_selected_pet_priority';
  const recalculateLoading =
    status === 'loading_recalculate_plans_selected_pet';
  const createLoading = status === 'loading_create_new_plan_selected_pet';
  const history = useHistory();
  const currentUser = useSelector(currentUserSelector);

  useEffect(() => {
    if (
      status.includes('success_') &&
      status.includes('selected_pet') &&
      status !== 'success_request_selected_pet' &&
      status !== 'success_update_selected_pet'
    ) {
      toast.success(intl.formatMessage({ id: `petProfile.${status}` }));
    }
    if (status.includes('error_') && status.includes('selected_pet')) {
      toast.error(error || intl.formatMessage({ id: `petProfile.${status}` }));
    }
    if (status === 'success_delete_selected_pet') {
      history.push(`/user/${currentUser?.id}/pets/`);
    }
  }, [status, error]);

  function onDelete() {
    const confirms = window.confirm(
      intl.formatMessage({ id: 'petProfile.deleteConfirmation' })
    );
    if (confirms) {
      dispatch(petRedux.actions.deleteSelectedPet());
    }
  }

  return (
    <>
      <section className="card p-10">
        <h1 className="mb-8">
          {intl.formatMessage({ id: 'petProfile.actionsTitle' })}
        </h1>
        <div className="d-flex flex-column gap-4">
          {selectedPet?.is_active && (
            <button
              disabled={status !== 'idle'}
              className="btn btn-danger"
              onClick={onDelete}
              data-kt-users-modal-action="submit"
              data-kt-indicator={deleteLoading ? 'on' : 'off'}
            >
              <span className="indicator-label">
                <span className="fas fa-times-circle me-3" />
              </span>
              <span className="indicator-progress">
                <span className="spinner-border spinner-border-sm align-middle me-3"></span>
              </span>
              {intl.formatMessage({ id: 'petProfile.deletePet' })}
            </button>
          )}
          {petPlans?.length > 0 ? (
            <button
              disabled={status !== 'idle'}
              className="btn btn-secondary"
              onClick={() => dispatch(petRedux.actions.recalculatePlans())}
              data-kt-users-modal-action="submit"
              data-kt-indicator={recalculateLoading ? 'on' : 'off'}
            >
              <span className="indicator-label">
                <span className="fas fa-sync-alt me-3" />
              </span>
              <span className="indicator-progress">
                <span className="spinner-border spinner-border-sm align-middle me-3"></span>
              </span>
              {intl.formatMessage({ id: 'petProfile.recalculatePlans' })}
            </button>
          ) : (
            <button
              disabled={status !== 'idle'}
              className="btn btn-secondary"
              onClick={() => dispatch(petRedux.actions.createNewPlan())}
              data-kt-users-modal-action="submit"
              data-kt-indicator={createLoading ? 'on' : 'off'}
            >
              <span className="indicator-label">
                <span className="fas fa-plus me-3" />
              </span>
              <span className="indicator-progress">
                <span className="spinner-border spinner-border-sm align-middle me-3"></span>
              </span>
              {intl.formatMessage({ id: 'petProfile.createPlan' })}
            </button>
          )}
          {!selectedPet?.is_priority_pet && (
            <button
              disabled={status !== 'idle'}
              className="btn btn-primary"
              onClick={() =>
                dispatch(petRedux.actions.setSelectedPetAsPriorityPet())
              }
              data-kt-users-modal-action="submit"
              data-kt-indicator={priorityLoading ? 'on' : 'off'}
            >
              <span className="indicator-label">
                <span className="fas fa-star me-3" />
              </span>
              <span className="indicator-progress">
                <span className="spinner-border spinner-border-sm align-middle me-3"></span>
              </span>
              {intl.formatMessage({ id: 'petProfile.setAsPriorityPet' })}
            </button>
          )}
          {currentUser?.id && selectedPet?.id && (
            <a
              href={`/user/${currentUser?.id}/pet/${selectedPet?.id}/logs`}
              className="btn btn-secondary"
            >
              <span className="fas fa-history me-3" />
              {intl.formatMessage({ id: 'logs.seeLogs' })}
            </a>
          )}
        </div>
      </section>
    </>
  );
}

import Pagination from '@mui/material/Pagination';
import { useIntl } from 'react-intl';
import { Incident, INCIDENT_LABELS } from '../models/TrackingHistory';
import { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import debounce from 'lodash.debounce';
import FileSaver from 'file-saver';

import DateRangeSelector from '../../../components/DateRangeSelector';
import {
  addCommentToTrackingHistory,
  downloadOrderTrackingExcel,
  listOrderTrackings,
  ListOrderTrackingsResponse,
  ORDER_TRACKINGS_PER_PAGE,
  updateIncident,
} from '../services/OrderCRUD';
import { OrderTracking } from '../models/OrderTracking';
import { OrderStateEnum } from '../models/OrderModel';
import OrderTrackingDetail from './OrderTrackingDetail';
import { useSelector } from 'react-redux';
import { authUserSelector } from '../../auth';
import OrderTrackingTableHeader from './OrderTrackingTableHeader';
import MrwStates from '../constants/MrwStates';

const MOCK_ORDER_TRACKINGS: Array<OrderTracking> = [
  {
    id: 1,
    code: 'SO-123456',
    state: 'processing' as OrderStateEnum,
    kind: 'trial',
    promise_date: '2021-09-02T19:14:56.461567+02:00',
    confirmed_at: '2021-09-02T19:14:56.461567+02:00',
    dispatched_at: '2021-09-03T19:14:56.461567+02:00',
    tracking_history: {
      id: 1,
      incident: null,
      tracking_history_stages: [
        {
          id: 1,
          state: 'Entregado a carrier',
          state_description: 'El paquete ha sido entregado a SEUR Frío',
          n_attemps: null,
          delivered_to: null,
          created_at: '2021-09-01T19:14:56.461567+02:00',
        },
        {
          id: 2,
          state: 'En tránsito',
          state_description: 'El paquete está en camino',
          n_attemps: null,
          delivered_to: null,
          created_at: '2021-09-02T19:14:56.461567+02:00',
        },
        {
          id: 3,
          state: 'Entregado',
          state_description: 'El paquete ha sido entregado al cliente',
          n_attemps: 1,
          delivered_to: 'cliente',
          created_at: '2021-09-03T19:14:56.461567+02:00',
        },
      ],
      tracking_history_comments: [
        {
          id: 1,
          comment: 'El cliente solicitó entrega por la tarde',
          author_agent: null,
          commented_at: '2021-09-02T19:14:56.461567+02:00',
        },
      ],
    },
  },
  {
    id: 2,
    code: 'SO-123456',
    state: 'processing' as OrderStateEnum,
    kind: 'trial',
    promise_date: '2021-09-02T19:14:56.461567+02:00',
    confirmed_at: '2021-09-02T19:14:56.461567+02:00',
    dispatched_at: '2021-09-03T19:14:56.461567+02:00',
    tracking_history: {
      id: 1,
      incident: 'retraso' as Incident,
      tracking_history_stages: [
        {
          id: 1,
          state: 'Entregado a carrier',
          state_description: 'El paquete ha sido entregado a SEUR Frío',
          n_attemps: null,
          delivered_to: null,
          created_at: '2021-09-01T19:14:56.461567+02:00',
        },
        {
          id: 2,
          state: 'En tránsito',
          state_description: 'El paquete está en camino',
          n_attemps: null,
          delivered_to: null,
          created_at: '2021-09-02T19:14:56.461567+02:00',
        },
        {
          id: 3,
          state: 'Entregado',
          state_description: 'El paquete ha sido entregado al cliente',
          n_attemps: 1,
          delivered_to: 'cliente',
          created_at: '2021-09-03T19:14:56.461567+02:00',
        },
      ],
      tracking_history_comments: [
        {
          id: 1,
          comment: 'La furgoneta ha derrapado en una rotonda',
          author_agent: null,
          commented_at: '2021-09-02T19:14:56.461567+02:00',
        },
        {
          id: 2,
          comment: 'El cliente solicitó entrega por la tarde',
          author_agent: null,
          commented_at: '2021-09-02T19:14:56.461567+02:00',
        },
      ],
    },
  },
  {
    id: 3,
    code: 'SO-123456',
    state: 'processing' as OrderStateEnum,
    kind: 'trial',
    promise_date: '2021-09-02T19:14:56.461567+02:00',
    confirmed_at: '2021-09-02T19:14:56.461567+02:00',
    dispatched_at: '2021-09-03T19:14:56.461567+02:00',
    tracking_history: {
      id: 1,
      incident: null,
      tracking_history_stages: [],
      tracking_history_comments: [],
    },
  },
  {
    id: 4,
    code: 'SO-123457',
    state: 'processing' as OrderStateEnum,
    kind: 'regular',
    promise_date: '2021-09-01T19:14:56.461567+02:00',
    confirmed_at: '2021-09-02T19:14:56.461567+02:00',
    dispatched_at: '2021-09-05T19:14:56.461567+02:00',
    tracking_history: {
      id: 1,
      incident: null,
      tracking_history_stages: [
        {
          id: 1,
          state: 'Entregado a carrier',
          state_description: 'El paquete ha sido entregado a SEUR Frío',
          n_attemps: null,
          delivered_to: null,
          created_at: '2021-09-01T19:14:56.461567+02:00',
        },
        {
          id: 2,
          state: 'En tránsito',
          state_description: 'El paquete está en camino',
          n_attemps: null,
          delivered_to: null,
          created_at: '2021-09-02T19:14:56.461567+02:00',
        },
        {
          id: 3,
          state: 'Devuelto al almacén',
          state_description: 'El cliente no estaba en casa',
          n_attemps: 1,
          delivered_to: null,
          created_at: '2021-09-03T19:14:56.461567+02:00',
        },
        {
          id: 4,
          state: 'En reparto',
          state_description: 'El paquete ha salido de los almacenes',
          n_attemps: null,
          delivered_to: null,
          created_at: '2021-09-04T19:14:56.461567+02:00',
        },
        {
          id: 5,
          state: 'En tránsito',
          state_description: 'El paquete está en camino',
          n_attemps: null,
          delivered_to: null,
          created_at: '2021-09-04T19:14:56.461567+02:00',
        },
        {
          id: 6,
          state: 'Entregado',
          state_description: 'El paquete ha sido entregado al cliente',
          n_attemps: 1,
          delivered_to: 'cliente',
          created_at: '2021-09-04T19:14:56.461567+02:00',
        },
      ],
      tracking_history_comments: [
        {
          id: 1,
          comment:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sollicitudin massa leo, vitae lobortis neque pellentesque nec. Etiam suscipit quam et ante dictum, ut auctor sapien bibendum. Phasellus et enim ac massa rutrum dignissim non vitae sem. Cras ut tellus ipsum. Nunc eu accumsan nunc. Cras eleifend ultricies feugiat. Vestibulum sollicitudin accumsan nibh, id fringilla libero blandit quis. Quisque blandit sodales dapibus. Proin imperdiet ante non efficitur lacinia. Morbi consequat, sapien ac vulputate facilisis, enim turpis vulputate ex, in tincidunt nisi dui ut urna. Aliquam quis tincidunt nisi. Suspendisse vel elit non felis consequat vehicula nec ac lacus. Phasellus varius magna quis blandit vehicula. Cras scelerisque varius lorem, molestie sodales magna dignissim et.',
          author_agent: null,
          commented_at: '2021-09-02T19:14:56.461567+02:00',
        },
        {
          id: 2,
          comment:
            'Vivamus dictum augue augue, nec lobortis dolor hendrerit vitae. Phasellus suscipit metus tristique sollicitudin mattis. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Morbi quis pretium leo, ut venenatis ligula. In hac habitasse platea dictumst. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nullam mattis rutrum sem, ac porttitor velit iaculis vel. Pellentesque nec aliquam felis, at placerat nisi.',
          author_agent: null,
          commented_at: '2021-09-02T19:14:56.461567+02:00',
        },
        {
          id: 3,
          comment:
            'Cras ut leo interdum, accumsan dui et, scelerisque libero. In imperdiet aliquam risus, eget sodales ante varius nec. Vestibulum sed ipsum placerat est dictum sodales vel quis dui. Integer arcu neque, tincidunt sit amet lobortis ut, blandit et eros. Phasellus enim massa, suscipit sagittis dui vitae, lacinia cursus lacus. Quisque at tempor nunc. Phasellus congue orci a mi ultrices, eget sollicitudin augue scelerisque. Nunc dignissim fermentum nibh quis posuere. Praesent egestas eros libero, in auctor quam feugiat at. Mauris dignissim, mi sed mattis mattis, turpis nisl commodo magna, sit amet aliquam mauris ante sit amet mi.',
          author_agent: null,
          commented_at: '2021-09-02T19:14:56.461567+02:00',
        },
      ],
    },
  },
];
const USE_MOCK = false;
const FETCH_DEBOUNCE_TIME = 700;

export default function OrderTrackingHistoryList() {
  const intl = useIntl();
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<ListOrderTrackingsResponse>({
    results: [],
    count: 0,
    next: null,
    previous: null,
  });
  const orderTrackings = USE_MOCK ? MOCK_ORDER_TRACKINGS : response.results;
  const [since, setSince] = useState<Date | null>(null);
  const [until, setUntil] = useState<Date | null>(null);
  const [code, setCode] = useState<string | null>(null);
  const [state, setState] = useState<OrderStateEnum | null>(null);
  const [agencyState, setAgencyState] = useState<string | null>(null);
  const [incident, setIncident] = useState<Incident | null>(null);
  const [displayIncidentsOnly, setDisplayIncidentsOnly] =
    useState<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);
  const authUser = useSelector(authUserSelector);

  async function executeFetchOrderTrackingHistory({
    page,
    since,
    until,
    code,
    state,
    agencyState,
    incident,
    displayIncidentsOnly,
  }) {
    try {
      setLoading(true);
      const response = await listOrderTrackings({
        page,
        since,
        until,
        code,
        state,
        agencyState,
        incident,
        displayIncidentsOnly,
      });
      setResponse(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const fetchOrderTrackingHistory = useCallback(
    debounce(executeFetchOrderTrackingHistory, FETCH_DEBOUNCE_TIME),
    []
  );

  async function onCommentAdded(
    comment: string,
    orderTracking: OrderTracking
  ): Promise<void> {
    if (!orderTracking?.tracking_history?.id) {
      toast.error(
        intl.formatMessage({
          id: 'orderTrackingHistory.errorNoTrackingHistory',
        })
      );
      return Promise.resolve();
    }
    try {
      const { data: commentAddedResponse } = await addCommentToTrackingHistory({
        trackingHistoryId: orderTracking?.tracking_history?.id,
        comment,
      });
      const updatedOrderTrackings = orderTrackings.map((someOrderTracking) => {
        if (someOrderTracking.id === orderTracking.id) {
          return {
            ...someOrderTracking,
            tracking_history: {
              ...someOrderTracking.tracking_history,
              tracking_history_comments: [
                ...someOrderTracking.tracking_history.tracking_history_comments,
                {
                  id: Math.floor(Math.random() * 1000000),
                  comment: commentAddedResponse.comment,
                  author_agent: authUser,
                  commented_at: new Date().toISOString(),
                },
              ],
            },
          };
        }
        return someOrderTracking;
      });
      setResponse({
        ...response,
        results: updatedOrderTrackings,
      });
    } catch (error) {
      console.error(error);
      toast.error(
        intl.formatMessage({ id: 'orderTrackingHistory.errorAddingComment' })
      );
    }
  }

  async function onChangeIncident(
    incident: Incident,
    orderTracking: OrderTracking
  ): Promise<void> {
    if (!orderTracking?.tracking_history?.id) {
      toast.error(
        intl.formatMessage({
          id: 'orderTrackingHistory.errorNoTrackingHistory',
        })
      );
      return Promise.resolve();
    }
    try {
      const { data: updateIncidentResponse } = await updateIncident({
        trackingHistoryId: orderTracking?.tracking_history?.id,
        incident,
      });

      const updatedOrderTrackings = orderTrackings.map((someOrderTracking) => {
        if (someOrderTracking.id === orderTracking.id) {
          return {
            ...someOrderTracking,
            tracking_history: {
              ...someOrderTracking.tracking_history,
              incident: updateIncidentResponse.incident as Incident,
            },
          };
        }
        return someOrderTracking;
      });

      setResponse({
        ...response,
        results: updatedOrderTrackings,
      });
    } catch (error) {
      console.error(error);
      toast.error(
        intl.formatMessage({ id: 'orderTrackingHistory.errorChangingIncident' })
      );
    }
  }

  async function downloadExcel() {
    try {
      setDownloading(true);
      const { data, headers } = await downloadOrderTrackingExcel({
        since,
        until,
        code,
        state,
        agencyState,
        incident,
        displayIncidentsOnly,
      });
      const blob = new Blob([data], {
        type: headers['content-type'],
      });
      FileSaver.saveAs(blob, 'control-entregabilidad.xlsx');
    } catch (error) {
      console.error(error);
      toast.error(
        intl.formatMessage({ id: 'orderTrackingHistory.errorDownloadingExcel' })
      );
    } finally {
      setDownloading(false);
    }
  }

  useEffect(() => {
    fetchOrderTrackingHistory({
      page,
      since,
      until,
      code,
      state,
      agencyState,
      incident,
      displayIncidentsOnly,
    });
  }, [
    page,
    since,
    until,
    code,
    state,
    agencyState,
    incident,
    displayIncidentsOnly,
  ]);

  useEffect(() => {
    setPage(1);
  }, [since, until, code, state, agencyState, incident, displayIncidentsOnly]);

  return (
    <>
      <main className="card">
        <section className="card-body">
          <section className="mb-8 d-flex gap-4 align-items-start flex-wrap">
            <DateRangeSelector
              label={intl.formatMessage({
                id: 'orderTrackingHistory.selectDateRange',
              })}
              initialSince={since}
              initialUntil={until}
              onSelected={(since, until) => {
                setSince(since);
                setUntil(until);
              }}
            />
            <div className="input-group w-300px">
              <label className="form-label">
                {intl.formatMessage({
                  id: 'orderTrackingHistory.filterByOrderCode',
                })}
              </label>
              <div className="input-group">
                <label className="input-group-text">
                  <span className="fas fa-search" />
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={intl.formatMessage({
                    id: 'orderTrackingHistory.orderCode',
                  })}
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
              </div>
            </div>
            <div className="w-250px">
              <label className="form-label">
                {intl.formatMessage({
                  id: 'orderTrackingHistory.filterByState',
                })}
              </label>
              <select
                className="form-select"
                value={state || ''}
                onChange={(e) => setState(e.target.value as OrderStateEnum)}
              >
                <option value="">
                  {intl.formatMessage({ id: 'orderTrackingHistory.allStates' })}
                </option>
                {Object.values(OrderStateEnum).map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-250px">
              <label className="form-label">
                {intl.formatMessage({
                  id: 'orderTrackingHistory.filterByAgencyState',
                })}
              </label>
              <select
                className="form-select"
                value={agencyState || ''}
                onChange={(e) => setAgencyState(e.target.value)}
              >
                <option value="">
                  {intl.formatMessage({ id: 'orderTrackingHistory.allStates' })}
                </option>
                {MrwStates.map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-300px">
              <label className="form-label">
                {intl.formatMessage({
                  id: 'orderTrackingHistory.filterByIncident',
                })}
              </label>
              <select
                className="form-select"
                value={incident || ''}
                onChange={(e) => setIncident(e.target.value as Incident)}
              >
                <option value="">
                  {intl.formatMessage({
                    id: 'orderTrackingHistory.allIncidents',
                  })}
                </option>
                {Object.values(Incident)
                  ?.filter((incident) => incident !== Incident.NO_INCIDENT)
                  ?.map((incident) => (
                    <option key={incident} value={incident}>
                      {INCIDENT_LABELS[incident]}
                    </option>
                  ))}
              </select>
            </div>
            <div className="w-250px">
              <label
                className="form-label text-nowrap"
                htmlFor="hasDeliveryProblem"
              >
                {intl.formatMessage({
                  id: 'orderTrackingHistory.filterByDeliveryProblem',
                })}
              </label>
              <div className="form-check form-switch mt-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="hasDeliveryProblem"
                  checked={displayIncidentsOnly}
                  onChange={(e) => setDisplayIncidentsOnly(e.target.checked)}
                />
              </div>
            </div>
            <button
              disabled={downloading}
              className="btn btn-primary mt-8"
              onClick={downloadExcel}
            >
              {downloading ? (
                <>
                  <span className="spinner-border spinner-border-sm me-2" />
                  {intl.formatMessage({
                    id: 'orderTrackingHistory.generatingExcel',
                  })}
                </>
              ) : (
                <>
                  <span className="fas fa-download me-2" />
                  {intl.formatMessage({
                    id: 'orderTrackingHistory.downloadExcel',
                  })}
                </>
              )}
            </button>
          </section>
          {loading && (
            <div className="d-flex justify-content-center py-10">
              <div className="spinner-border text-primary" role="status" />
            </div>
          )}
          {!loading && response?.count === 0 && (
            <section>
              <p className="text-dark">
                {intl.formatMessage({ id: 'orderTrackingHistory.noResults' })}
              </p>
            </section>
          )}
          {!loading && response?.count > 0 && (
            <section>
              <p className="text-muted">
                {intl.formatMessage(
                  { id: 'orderTrackingHistory.results' },
                  {
                    visibleCount: orderTrackings?.length || 0,
                    totalCount: response?.count || 0,
                  }
                )}
              </p>
              <div className="table table-hover table-row-dashed table-row-gray-200 gs-0 gy-4">
                <OrderTrackingTableHeader />
                <main className="table-body">
                  {orderTrackings.map((orderTracking) => (
                    <OrderTrackingDetail
                      orderTracking={orderTracking}
                      key={orderTracking.id}
                      onCommentAdded={async (comment) =>
                        onCommentAdded(comment, orderTracking)
                      }
                      onChangeIncident={async (incident) =>
                        onChangeIncident(incident, orderTracking)
                      }
                    />
                  ))}
                </main>
              </div>
            </section>
          )}
          {response.count > 0 && (
            <Pagination
              className="d-flex justify-content-center"
              count={Math.ceil(response.count / ORDER_TRACKINGS_PER_PAGE)}
              page={page}
              onChange={(_e, page) => {
                setPage(page);
              }}
            />
          )}
        </section>
      </main>
    </>
  );
}

import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import moment from 'moment';

import { getBusinessDashboard } from '../services/DashboardCRUD';
import DateRangeSelector from '../../../components/DateRangeSelector';
import LineChart from './LineChart';
import MultipleOptionSelector from '../../../components/MultipleOptionSelector';

export default function BusinessDashboard() {
  const intl = useIntl();
  const initialSince = moment().subtract(31, 'days').toDate();
  const initialUntil = moment().subtract(1, 'days').toDate();
  const [activePlansData, setActivePlansData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedStores, setSelectedStores] = useState(['ES', 'FR']);

  function onAcceptDateRange(aSince, anUntil) {
    fetchData(aSince, anUntil);
  }

  async function fetchData(since, until) {
    let stringSince = `${since?.getFullYear()}-${(since?.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${since?.getDate().toString().padStart(2, '0')}`;
    let stringUntil = `${until?.getFullYear()}-${(until?.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${until?.getDate().toString().padStart(2, '0')}`;

    setLoading(true);
    try {
      const { data } = await getBusinessDashboard({
        since: stringSince,
        until: stringUntil,
      });
      setActivePlansData(data['active-plans']);
    } catch (error) {
      console.warn(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData(initialSince, initialUntil);
  }, []);

  function getStores() {
    // TODO: extract from API
    return ['ES', 'FR'];
  }

  return (
    <div className="card">
      <h1 className="mb-8">
        {intl.formatMessage({ id: 'businessDashboard.title' })}
      </h1>
      <div className="mb-10 d-flex align-items-start gap-8">
        <DateRangeSelector
          initialSince={initialSince}
          initialUntil={initialUntil}
          onSelected={onAcceptDateRange}
          disableFutureDates={true}
        />
        <MultipleOptionSelector
          options={getStores()}
          selectedOptions={selectedStores}
          onChange={setSelectedStores}
          label={intl.formatMessage({ id: 'businessDashboard.selectStores' })}
        />
      </div>
      <LineChart
        title={intl.formatMessage({ id: 'businessDashboard.dailyActivePlans' })}
        data={activePlansData}
        loading={loading}
        serieField="store_country"
        visibleSeries={selectedStores}
      />
    </div>
  );
}

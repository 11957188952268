import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import DateRangeSelector from '../../../components/DateRangeSelector';
import { toast } from 'react-toastify';
import moment from 'moment';
import { getGiftPrevision, GiftPrevision } from '../services/LoyaltyCRUD';

export default function GiftPrevisionReport() {
  const intl = useIntl();
  const initialSince = new Date(
    new Date().setDate(new Date().getDate() - new Date().getDay() + 1)
  );
  const initialUntil = new Date(
    new Date().setDate(new Date().getDate() - new Date().getDay() + 7)
  );
  const [since, setSince] = useState(initialSince);
  const [until, setUntil] = useState(initialUntil);
  const [generatingGiftPrevision, setGeneratingGiftPrevision] = useState(false);
  const [giftPrevision, setGiftPrevision] = useState<GiftPrevision>(null);

  async function generateGiftPrevision() {
    try {
      setGeneratingGiftPrevision(true);
      const { data } = await getGiftPrevision({
        promiseDateFrom: moment(since).format('YYYY-MM-DD'),
        promiseDateTo: moment(until).format('YYYY-MM-DD'),
      });
      setGiftPrevision(data);
    } catch (error) {
      toast.error(
        intl.formatMessage({ id: 'businessDashboard.giftPrevisionError' })
      );
      console.warn(error);
    } finally {
      setGeneratingGiftPrevision(false);
    }
  }

  useEffect(() => {
    if (since && until) {
      generateGiftPrevision();
    }
  }, [since, until]);

  return (
    <>
      <h1 className="title mb-8">
        {intl.formatMessage({ id: 'businessDashboard.generateGiftPrevision' })}
      </h1>
      <div className="d-flex gap-4 align-items-END">
        <DateRangeSelector
          initialSince={initialSince}
          initialUntil={initialUntil}
          onSelected={(since, until) => {
            setSince(since);
            setUntil(until);
          }}
          disableFutureDates={false}
        />
        {generatingGiftPrevision && (
          <span className="spinner-border spinner-border-sm mb-5 text-primary ms-2" />
        )}
      </div>
      {giftPrevision && (
        <section className="card bg-light-primary mt-8 w-300px">
          <div className="card-body">
            <h2 className="card-title mb-8">
              {intl.formatMessage({ id: 'businessDashboard.giftPrevision' })}
            </h2>
            <p>
              <strong>
                {intl.formatMessage({ id: 'businessDashboard.dogGifts' })}
              </strong>{' '}
              <span className="text-primary">{giftPrevision?.dog}</span>
            </p>
            <p>
              <strong>
                {intl.formatMessage({ id: 'businessDashboard.catGifts' })}
              </strong>{' '}
              <span className="text-primary">{giftPrevision?.cat}</span>
            </p>
          </div>
        </section>
      )}
    </>
  );
}

import { FC } from 'react';
import { useIntl } from 'react-intl';

import { usePathProps } from '../../modules/user-profile/hooks/usePathProps';
import { PageTitle } from '../../../_metronic/layout/core';
import { NewLeadTable } from '../../modules/tasks';

const NewLeadComponent: FC = () => {
  return (
    <>
      <NewLeadTable />
    </>
  );
};

const NewLeadsPage: FC = () => {
  const intl = useIntl();
  usePathProps();

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'newLeads.title' })}
      </PageTitle>
      <NewLeadComponent />
    </>
  );
};

export { NewLeadsPage };

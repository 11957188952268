import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { KTSVG } from '../../../../_metronic/helpers';

import { TaskModel } from '../models/TaskModel';
import {
  actions,
  pendingTaskErrorSelector,
  pendingTaskSelector,
  pendingTaskStatusSelector,
} from '../redux/TaskRedux';
import * as authRedux from '../../auth/redux/AuthRedux';
import TaskManager from './TaskManager';
import { useEffect } from 'react';

function TaskDrawer() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const task = useSelector(pendingTaskSelector) as TaskModel;
  const authUser = useSelector(authRedux.authUserSelector);
  const onAddComment = (comment) =>
    dispatch(actions.commentPendingTask(comment));
  const onResolve = (taskSetResolution, replaceAgent, isAlarm) =>
    dispatch(
      actions.resolvePendingTask(taskSetResolution, replaceAgent, isAlarm)
    );
  const status = useSelector(pendingTaskStatusSelector);
  const error = useSelector(pendingTaskErrorSelector);

  useEffect(() => {
    dispatch(actions.dispatchTask(null, null));
  }, [dispatch, authUser?.id]);

  return (
    <div
      id="kt_activities"
      className="bg-white w-100"
      data-kt-drawer="true"
      data-kt-drawer-name="activities"
      data-kt-drawer-activate="true"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'522px', 'lg': '522px'}"
      data-kt-drawer-direction="end"
      data-kt-drawer-toggle="#kt_activities_toggle"
      data-kt-drawer-close="#kt_activities_close"
    >
      <div
        className="card shadow-none rounded-0 w-100"
        style={{ backgroundColor: '#F8F8F8' }}
      >
        <div
          className="card-header d-flex align-items-center"
          id="kt_activities_header"
        >
          <h2 className="mt-3 d-flex align-items-center">
            {intl.formatMessage({ id: 'app.myNextTask' })}
            {task?.total_tasks > 0 && (
              <span className="badge badge-primary ms-4">
                {intl.formatMessage(
                  { id: 'tasks.totalTasks' },
                  { count: task?.total_tasks }
                )}
              </span>
            )}
          </h2>
          <div className="card-toolbar">
            <button
              type="button"
              className="btn btn-sm btn-icon btn-active-light-primary me-n5"
              id="kt_activities_close"
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon-1"
              />
            </button>
          </div>
        </div>
        {
          <div
            className="card-body position-relative pt-5"
            id="kt_activities_body"
          >
            <div
              id="kt_activities_scroll"
              className="position-relative scroll-y me-n5 pe-5"
              data-kt-scroll="true"
              data-kt-scroll-height="auto"
              data-kt-scroll-wrappers="#kt_activities_body"
              data-kt-scroll-dependencies="#kt_activities_header, #kt_activities_footer"
              data-kt-scroll-offset="5px"
            >
              {task?.id > -1 && (
                <TaskManager
                  task={task}
                  onAddComment={onAddComment}
                  onResolve={onResolve}
                  status={status}
                  error={error}
                />
              )}
              {!task?.id && (
                <section className="d-block h-100 w-400px mx-auto fs-4 text-center ">
                  <div className="fs-4x">🙌</div>
                  {intl.formatMessage({ id: 'tasks.noPendingTasks' })}
                </section>
              )}
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export default TaskDrawer;

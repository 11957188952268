import { useEffect } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { DrawerComponent } from '../../assets/ts/components';

import { useLayout } from '../core';
import { actions } from '../../../app/modules/tasks/redux/TaskRedux';

const Content = ({ children }) => {
  const { classes } = useLayout();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.listGroups());
    dispatch(actions.retrieveStaffUsers());
  }, []);

  useEffect(() => {
    DrawerComponent.hideAll();
  }, [location]);

  return (
    <div
      id="kt_content_container"
      className={clsx(classes.contentContainer.join(' '))}
    >
      {children}
    </div>
  );
};

export { Content };

import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import {
  OrderTable,
  orderStatusSelector,
  planOrdersSelector,
} from '../../orders';
import { selectedPlanSelector } from '..';
import * as orderRedux from '../../orders/redux/OrderRedux';

export default function PlanOrders() {
  const intl = useIntl();
  const status: string = useSelector(orderStatusSelector, shallowEqual);
  const orders = useSelector(planOrdersSelector, shallowEqual);
  const loading = status === 'loading_list_orders';
  const dispatch = useDispatch();
  const selectedPlan = useSelector(selectedPlanSelector, shallowEqual);

  return (
    <section className="card p-10">
      <h1 className="mb-8">
        {intl.formatMessage({ id: 'planDetail.planOrders' })}
      </h1>
      {[
        'loading_selected_plan',
        'loading_recalculate_plan',
        'loading_plan_orders',
      ].includes(status) ? (
        <div className="spinner-border text-primary" role="status" />
      ) : (
        <OrderTable
          orders={orders?.results}
          page={orders?.page}
          count={orders?.count}
          loading={loading}
          displayPetName={false}
          onRefresh={() =>
            dispatch(
              orderRedux.actions.listPlanOrders(selectedPlan?.id, orders.page)
            )
          }
          onPageChange={(page) =>
            dispatch(orderRedux.actions.listPlanOrders(selectedPlan?.id, page))
          }
          emptyMessage={intl.formatMessage({
            id: 'orders.noOrdersForSelectedPlan',
          })}
        />
      )}
    </section>
  );
}

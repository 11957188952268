import { FC, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { Link, useHistory } from 'react-router-dom';
import { ClientCard } from './ClientCard';
import { useSearchBarState } from '../hooks/useSearchBarState';
import { TaskCard } from './TaskCard';

const SearchBar: FC = () => {
  const intl = useIntl();
  const {
    dropdownVisible,
    setDropdownVisible,
    clients,
    tasks,
    loading,
    onChange,
  } = useSearchBarState();
  const inputRef = useRef<any>(null);
  const [focusedResult, setFocusedResult] = useState(-1);
  const history = useHistory();

  function onFocus() {
    setDropdownVisible(true);
  }

  function clearInput() {
    inputRef.current.value = '';
    onChange({ e: { target: { value: inputRef.current.value } } });
  }

  function onWindowClick(event: any) {
    if (event?.target?.name !== 'clientSearchInput') {
      setDropdownVisible(false);
    }
  }

  function getClientLink(client) {
    return `/user/${client?.id}/profile`;
  }

  function getTaskLink(task) {
    return task?.user?.id
      ? `/user/${task.user.id}/task/${task.id}`
      : `/task/${task.id}`;
  }

  function onKeyDown(event: any) {
    if (!dropdownVisible) {
      return;
    }
    // Arrow up
    if (event.keyCode === 38) {
      event?.preventDefault();
      setFocusedResult((result) => Math.max(-1, result - 1));
    }
    // Arrow down (same as tab)
    if (event.keyCode === 40 || event.keyCode === 9) {
      event?.preventDefault();
      setFocusedResult((result) =>
        Math.min(tasks.length + clients.length - 1, result + 1)
      );
    }
    if (event.key === 'Enter' && dropdownVisible && focusedResult > -1) {
      event?.preventDefault();
      if (focusedResult < tasks.length) {
        history.push(getTaskLink(tasks[focusedResult]));
      } else {
        history.push(getClientLink(clients[focusedResult]));
      }
      setFocusedResult(-1);
      inputRef?.current?.blur();
      clearInput();
      setDropdownVisible(false);
    }
  }

  useEffect(() => {
    window.addEventListener('click', onWindowClick);
    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('click', onWindowClick);
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [clients, tasks, focusedResult, dropdownVisible]);

  useEffect(
    function resetFocusedResult() {
      if (clients) {
        setFocusedResult(-1);
      }
    },
    [clients]
  );

  useEffect(
    function resetFocusedResult() {
      if (tasks) {
        setFocusedResult(-1);
      }
    },
    [tasks]
  );

  useEffect(() => {
    const resultElement = document.querySelector(`#result-${focusedResult}`);
    resultElement?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  }, [focusedResult]);

  return (
    <>
      <div className="card-title">
        <div className="d-flex align-items-center position-relative">
          <span className="svg-icon svg-icon-1 position-absolute ms-4 mt-2">
            {loading ? (
              <div className="spinner-border spinner-border-sm align-middle ms-2" />
            ) : (
              <img src="/media/icons/duotune/general/gen021.svg" alt="" />
            )}
          </span>
          <input
            ref={inputRef}
            name="clientSearchInput"
            type="text"
            autoComplete="off"
            data-kt-user-table-filter="search"
            className="form-control form-control-lg form-control-solid border-primary border-2  w-400px mt-3 ps-14 pe-14"
            onFocus={onFocus}
            autoFocus
            onChange={onChange}
            placeholder={intl.formatMessage({
              id: 'clientSearch.searchClients',
            })}
          />
          {inputRef.current?.value?.length > 0 && (
            <span
              onClick={clearInput}
              className="opacity-50 cursor-pointer svg-icon svg-icon-1 position-absolute top-0 end-0 mt-6 me-3"
            >
              <img src="/media/icons/duotune/general/gen040.svg" alt="" />
            </span>
          )}
        </div>
      </div>
      <div
        className={clsx(
          'ds-dropdown-menu position-absolute px-0 py-1 top-100 scroll-y mt bg-light-dark',
          clients?.length === 0 ? 'w-400px' : 'w-800px',
          (loading ||
            !dropdownVisible ||
            inputRef?.current?.value?.length === 0) &&
            'collapse'
        )}
        style={{ maxHeight: 'calc(100vh - 70px)' }}
      >
        {clients?.length === 0 && inputRef?.current?.value.length > 0 && (
          <div className="dropdown-item px-2">
            <div className="card px-5 py-4 fs-5">
              <p className="mb-0 text-wrap">
                {intl.formatMessage(
                  {
                    id: 'clientSearch.noClientsFound',
                  },
                  {
                    query: (
                      <span className="fw-bolder me-2 d-inline">
                        &quot;{inputRef?.current?.value}&quot;
                      </span>
                    ),
                  }
                )}
                <span className="fs-2">😢</span>
              </p>
            </div>
          </div>
        )}
        {tasks?.length > 0 &&
          tasks.map((task, i) => (
            <Link
              id={`result-${i}`}
              key={task.id}
              className="dropdown-item px-2"
              to={getTaskLink(task)}
              onClick={clearInput}
            >
              <TaskCard task={task} focused={focusedResult === i} />
            </Link>
          ))}
        {clients?.length > 0 &&
          clients.map((client, i) => (
            <Link
              id={`result-${(tasks?.length || 0) + i}`}
              key={client.id}
              className="dropdown-item px-2"
              to={getClientLink(client)}
              onClick={clearInput}
            >
              <ClientCard
                client={client}
                focused={focusedResult === (tasks?.length || 0) + i}
              />
            </Link>
          ))}
      </div>
    </>
  );
};

export { SearchBar };

import { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  registerWhatsappTemplate,
  RegisterWhatsappTemplateRequest,
} from '../services/WhatsappTemplatesCRUD';
import { toast } from 'react-toastify';

enum State {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
}
const DEFAULT_REQUEST: RegisterWhatsappTemplateRequest = {
  externalTemplateId: undefined,
  botId: undefined,
  name: '',
  minHoursToResend: 0,
  archiveChat: true,
};

export default function TemplateRegistrationForm() {
  const intl = useIntl();
  const [state, setState] = useState<State>(State.IDLE);
  const [request, setRequest] =
    useState<RegisterWhatsappTemplateRequest>(DEFAULT_REQUEST);

  async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setState(State.LOADING);
    try {
      await registerWhatsappTemplate(request);
      toast.success(
        intl.formatMessage({ id: 'whatsappTemplates.templateRegistered' })
      );
      setRequest(DEFAULT_REQUEST);
      setTimeout(() => window.location.reload(), 1000);
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    } finally {
      setState(State.IDLE);
    }
  }

  return (
    <div className="card p-10">
      <h1 className="mb-8">
        {intl.formatMessage({ id: 'whatsappTemplates.registerTemplate' })}
      </h1>
      <form className="d-flex flex-column gap-4" onSubmit={onSubmit}>
        <div className="form-group">
          <label className="form-label" htmlFor="name">
            {intl.formatMessage({ id: 'whatsappTemplates.name' })}
          </label>
          <input
            required
            className="form-control"
            id="name"
            type="text"
            placeholder={intl.formatMessage({ id: 'whatsappTemplates.name' })}
            value={request.name}
            onChange={(e) => setRequest({ ...request, name: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="externalTemplateId">
            {intl.formatMessage({ id: 'whatsappTemplates.externalTemplateId' })}
          </label>
          <input
            required
            className="form-control"
            id="externalTemplateId"
            type="number"
            placeholder={intl.formatMessage({
              id: 'whatsappTemplates.externalTemplateId',
            })}
            value={request.externalTemplateId}
            onChange={(e) =>
              setRequest({ ...request, externalTemplateId: +e.target.value })
            }
          />
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="botId">
            {intl.formatMessage({ id: 'whatsappTemplates.botId' })}
          </label>
          <input
            required
            className="form-control"
            id="botId"
            type="number"
            placeholder={intl.formatMessage({ id: 'whatsappTemplates.botId' })}
            value={request.botId}
            onChange={(e) => setRequest({ ...request, botId: +e.target.value })}
          />
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="minHoursToResend">
            {intl.formatMessage({ id: 'whatsappTemplates.minHoursToResend' })}
          </label>
          <input
            required
            className="form-control"
            id="minHoursToResend"
            type="number"
            placeholder={intl.formatMessage({
              id: 'whatsappTemplates.minHoursToResend',
            })}
            value={request.minHoursToResend}
            onChange={(e) =>
              setRequest({ ...request, minHoursToResend: +e.target.value })
            }
          />
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="archiveChat">
            {intl.formatMessage({ id: 'whatsappTemplates.archiveChat' })}
          </label>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              id="archiveChat"
              type="checkbox"
              checked={request.archiveChat}
              onChange={(e) =>
                setRequest({ ...request, archiveChat: e.target.checked })
              }
            />
          </div>
        </div>
        <button
          className="btn btn-primary mt-4 ms-auto"
          type="submit"
          disabled={state === State.LOADING}
        >
          {state === State.LOADING && (
            <span className="spinner-border spinner-border-sm me-1"></span>
          )}
          {intl.formatMessage({ id: 'whatsappTemplates.register' })}
        </button>
      </form>
    </div>
  );
}

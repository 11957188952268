import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as pet from '../redux/PetRedux';
import { PetModel } from '../models/PetModel';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { GenericForm, FormField } from '../../../components/GenericForm';
import {
  listBreeds,
  listBodyConditions,
  listActivityLevels,
  listCurrentFoodTypes,
  listAllergies,
  listHealthIssues,
} from '../services/PetCRUD';
import { BreedModel } from '../models/BreedModel';
import { BodyConditionModel } from '../models/BodyConditionModel';
import { ActivityLevelModel } from '../models/ActivityLevelModel';
import { CurrentFoodTypeModel } from '../models/CurrentFoodTypeModel';
import { AllergyModel } from '../models/AllergyModel';
import { HealthIssueModel } from '../models/HealthIssueModel';
import { toast } from 'react-toastify';

export default function PetDetailComponent() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [editing, setEditing] = useState(false);
  const selectedPet: PetModel | undefined = useSelector(
    pet.selectedPetSelector
  );
  const status: string = useSelector(pet.petStatusSelector);
  const error: any = useSelector(pet.petErrorSelector);
  const [breeds, setBreeds] = useState<Array<BreedModel>>([]);
  const [bodyConditions, setBodyConditions] = useState<
    Array<BodyConditionModel>
  >([]);
  const [activityLevels, setActivityLevels] = useState<
    Array<ActivityLevelModel>
  >([]);
  const [currentFoodTypes, setCurrentFoodTypes] = useState<
    Array<CurrentFoodTypeModel>
  >([]);
  const [allergies, setAllergies] = useState<Array<AllergyModel>>([]);
  const [healthIssues, setHealthIssues] = useState<Array<HealthIssueModel>>([]);

  async function fetchBreeds(pet_kind: number) {
    setBreeds((await listBreeds({ pet_kind })).data);
  }

  async function fetchBodyConditions(pet_kind: number) {
    setBodyConditions((await listBodyConditions({ pet_kind })).data);
  }

  async function fetchActivityLevels(pet_kind: number) {
    setActivityLevels((await listActivityLevels({ pet_kind })).data);
  }

  async function fetchCurrentFoodTypes(pet_kind: number) {
    setCurrentFoodTypes((await listCurrentFoodTypes({ pet_kind })).data);
  }

  async function fetchAllergies(pet_kind: number) {
    setAllergies((await listAllergies({ pet_kind })).data);
  }

  async function fetchHealthIssues(pet_kind: number) {
    setHealthIssues((await listHealthIssues({ pet_kind })).data);
  }

  function fetchFormOptions(pet_kind: number) {
    fetchBreeds(pet_kind);
    fetchBodyConditions(pet_kind);
    fetchActivityLevels(pet_kind);
    fetchCurrentFoodTypes(pet_kind);
    fetchAllergies(pet_kind);
    fetchHealthIssues(pet_kind);
  }

  useEffect(() => {
    if (selectedPet?.pet_kind.id) {
      fetchFormOptions(selectedPet?.pet_kind.id);
    }
  }, [selectedPet?.pet_kind.id]);

  useEffect(() => {
    if (status === 'success_update_selected_pet') {
      toast.success(intl.formatMessage({ id: 'petProfile.successToast' }), {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setEditing(false);
    }
  }, [status]);

  function onSubmit(formState: PetModel): void {
    const {
      id,
      name,
      pet_kind,
      breed,
      body_condition,
      gender,
      age_years,
      age_months,
      weight_in_kg,
      is_neutered,
      allergies,
      health_issues,
      activity_level,
      current_food_type,
      has_treats,
      is_priority_pet,
      customer_multiplier,
      notes,
    } = formState as PetModel;
    dispatch(
      pet.actions.updateSelectedPet({
        id,
        name,
        pet_kind,
        breed,
        body_condition,
        gender,
        age_years,
        age_months,
        weight_in_kg,
        is_neutered,
        allergies,
        health_issues,
        activity_level,
        current_food_type,
        has_treats,
        is_priority_pet,
        customer_multiplier,
        notes,
      })
    );
  }

  const formFields: Array<FormField<PetModel>> = [
    {
      id: 'pet_kind',
      label: intl.formatMessage({ id: 'petProfile.petKindLabel' }),
      inputType: 'text',
      readonly: true,
      getter: (pet: PetModel) => pet.pet_kind?.name,
    },
    {
      id: 'name',
      label: intl.formatMessage({ id: 'petProfile.nameLabel' }),
      inputType: 'text',
    },
    {
      id: 'gender',
      label: intl.formatMessage({ id: 'petProfile.genderLabel' }),
      inputType: 'select',
      options: [
        {
          value: 'male',
          label: intl.formatMessage({ id: 'petProfile.male' }),
        },
        {
          value: 'female',
          label: intl.formatMessage({ id: 'petProfile.female' }),
        },
      ],
    },
    {
      id: 'breed',
      label: intl.formatMessage({ id: 'petProfile.breedLabel' }),
      inputType: 'select',
      options: breeds.map((breed) => ({
        value: breed.id.toString(),
        label: breed.name,
      })),
      getter: (pet: PetModel) => pet.breed?.id.toString(),
      setter: (pet: PetModel, value: string): PetModel => {
        return {
          ...pet,
          breed: breeds.find(
            (breed) => breed.id === parseInt(value)
          ) as BreedModel,
        };
      },
    },
    {
      id: 'body_condition',
      label: intl.formatMessage({ id: 'petProfile.bodyConditionLabel' }),
      inputType: 'select',
      options: bodyConditions.map((bodyCondition) => ({
        value: bodyCondition.id.toString(),
        label: bodyCondition.name,
      })),
      getter: (pet: PetModel) => pet.body_condition?.id.toString(),
      setter: (pet: PetModel, value: string): PetModel => {
        return {
          ...pet,
          body_condition: bodyConditions.find(
            (bodyCondition) => bodyCondition.id === parseInt(value)
          ) as BodyConditionModel,
        };
      },
    },
    {
      id: 'activity_level',
      label: intl.formatMessage({ id: 'petProfile.activityLevelLabel' }),
      inputType: 'select',
      options: activityLevels.map((activityLevel) => ({
        value: activityLevel.id.toString(),
        label: activityLevel.name,
      })),
      getter: (pet: PetModel) => pet.activity_level?.id.toString(),
      setter: (pet: PetModel, value: string): PetModel => {
        return {
          ...pet,
          activity_level: activityLevels.find(
            (activityLevel) => activityLevel.id === parseInt(value)
          ) as ActivityLevelModel,
        };
      },
    },
    {
      id: 'current_food_type',
      label: intl.formatMessage({ id: 'petProfile.currentFoodTypeLabel' }),
      inputType: 'select',
      options: currentFoodTypes.map((currentFoodType) => ({
        value: currentFoodType.id.toString(),
        label: currentFoodType.name,
      })),
      getter: (pet: PetModel) => pet.current_food_type?.id.toString(),
      setter: (pet: PetModel, value: string): PetModel => {
        return {
          ...pet,
          current_food_type: currentFoodTypes.find(
            (currentFoodType) => currentFoodType.id === parseInt(value)
          ) as CurrentFoodTypeModel,
        };
      },
    },
    {
      id: 'weight_in_kg',
      label: intl.formatMessage({ id: 'petProfile.weightLabel' }),
      inputType: 'number',
    },
    {
      id: 'age_years',
      label: intl.formatMessage({ id: 'petProfile.ageYearsLabel' }),
      inputType: 'number',
    },
    {
      id: 'age_months',
      label: intl.formatMessage({ id: 'petProfile.ageMonthsLabel' }),
      inputType: 'number',
    },
    {
      id: 'allergies',
      label: intl.formatMessage({ id: 'petProfile.allergiesLabel' }),
      inputType: 'multiple',
      options: allergies.map((allergy) => ({
        value: allergy.id.toString(),
        label: allergy.name,
      })),
      getter: (pet: PetModel) =>
        pet.allergies?.map((allergy) => allergy.id.toString()).join(','),
      setter: (pet: PetModel, value: string): PetModel => {
        return {
          ...pet,
          allergies: allergies.filter((allergy) =>
            value.split(',').includes(allergy.id.toString())
          ) as AllergyModel[],
        };
      },
    },
    {
      id: 'health_issues',
      label: intl.formatMessage({ id: 'petProfile.healthIssuesLabel' }),
      inputType: 'multiple',
      options: healthIssues.map((healthIssue) => ({
        value: healthIssue.id.toString(),
        label: healthIssue.name,
      })),
      getter: (pet: PetModel) =>
        pet.health_issues
          ?.map((healthIssue) => healthIssue.id.toString())
          .join(','),
      setter: (pet: PetModel, value: string): PetModel => {
        return {
          ...pet,
          health_issues: healthIssues.filter((healthIssue) =>
            value.split(',').includes(healthIssue.id.toString())
          ) as HealthIssueModel[],
        };
      },
    },
    {
      id: 'is_neutered',
      label: intl.formatMessage({ id: 'petProfile.isNeuteredLabel' }),
      inputType: 'checkbox',
    },
    {
      id: 'has_treats',
      label: intl.formatMessage({ id: 'petProfile.hasTreatsLabel' }),
      inputType: 'checkbox',
    },
    {
      id: 'daily_calorie_intake',
      label: intl.formatMessage({ id: 'petProfile.dailyCalorieIntakeLabel' }),
      inputType: 'number',
      readonly: true,
      units: 'kcal',
    },
    {
      id: 'daily_protein_intake',
      label: intl.formatMessage({ id: 'petProfile.dailyProteinIntakeLabel' }),
      inputType: 'number',
      readonly: true,
      units: 'gr',
    },
    {
      id: 'is_priority_pet',
      label: intl.formatMessage({ id: 'petProfile.hasPriorityLabel' }),
      inputType: 'checkbox',
    },
    {
      id: 'customer_multiplier',
      label: intl.formatMessage({ id: 'petProfile.customerMultiplierLabel' }),
      inputType: 'number',
      units: '%',
    },
    {
      id: 'notes',
      label: intl.formatMessage({ id: 'petProfile.notesLabel' }),
      inputType: 'textarea',
    },
    {
      id: 'from_ftk',
      label: intl.formatMessage({ id: 'petProfile.fromFrankie' }),
      inputType: 'checkbox',
      readonly: true,
    },
  ];

  return (
    <main className="card p-10">
      {status === 'loading_request_selected_pet' ? (
        <div className="spinner-border text-primary" role="status" />
      ) : (
        <>
          {selectedPet && (
            <>
              <GenericForm
                title={intl.formatMessage({ id: 'petProfile.petData' })}
                columns={2}
                toggleEdit={() => setEditing(!editing)}
                ctaLabel={intl.formatMessage({ id: 'petProfile.saveChanges' })}
                editing={editing}
                initialState={selectedPet}
                fields={formFields}
                onSubmit={onSubmit}
                error={error}
                submitting={status === 'loading_update_selected_pet'}
                submittingLabel={intl.formatMessage({
                  id: 'petProfile.pleaseWait',
                })}
              />
            </>
          )}
        </>
      )}
    </main>
  );
}

import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { GenericForm, FormField } from '../../../components/GenericForm';
import { toast } from 'react-toastify';
import { LeadDistributorModel } from '../models/LeadDistributorModel';
import {
  retrieveLeadDistributor,
  updateLeadDistributor,
} from '../services/LeadDistributorCRUD';

const LOADING_STATUS = 'LOADING_STATUS';
const IDLE_STATUS = 'IDLE_STATUS';

export default function LeadDistributorDetail({ id }) {
  const intl = useIntl();
  const [status, setStatus] = useState(IDLE_STATUS);
  const [error, setError]: any | undefined = useState({});
  const [editing, setEditing] = useState(false);
  const [distributor, setDistributor] = useState<LeadDistributorModel | null>(
    null
  );

  const fetchDistributor = async (id) => {
    try {
      const { data } = await retrieveLeadDistributor(id);
      setDistributor(data);
    } catch (error) {
      console.warn(error);
    }
  };

  useEffect(() => {
    fetchDistributor(id);
  }, [id]);

  async function onSubmit(formState: LeadDistributorModel) {
    try {
      setStatus(LOADING_STATUS);
      const { data } = await updateLeadDistributor(formState);
      setDistributor(data);
      setEditing(false);
      toast.success(intl.formatMessage({ id: 'distributorDetail.saved' }));
    } catch (error) {
      console.warn(error);
      if (error.response?.data) {
        setError(error.response?.data);
      }
      toast.error(intl.formatMessage({ id: 'distributorDetail.errorSaving' }));
    } finally {
      setStatus(IDLE_STATUS);
    }
  }

  const formFields: Array<FormField<LeadDistributorModel>> = [
    {
      id: 'name',
      label: intl.formatMessage({ id: 'distributors.name' }),
      inputType: 'text',
    },
    {
      id: 'email',
      label: intl.formatMessage({ id: 'distributors.email' }),
      inputType: 'email',
    },
    {
      id: 'phone',
      label: intl.formatMessage({ id: 'distributors.phone' }),
      inputType: 'text',
      maxLength: 14,
    },
    {
      id: 'pending_review',
      label: intl.formatMessage({ id: 'distributors.pendingReview' }),
      inputType: 'checkbox',
    },
  ];

  return (
    <div className="card p-10">
      {status === 'loading_request_user' ? (
        <div className="spinner-border text-primary" role="status" />
      ) : (
        <>
          {distributor ? (
            <>
              <GenericForm
                columns={2}
                error={error}
                editing={editing}
                toggleEdit={() => setEditing(!editing)}
                title={intl.formatMessage({
                  id: 'leadDistributorDetail.infoTitle',
                })}
                initialState={distributor}
                fields={formFields}
                ctaLabel={intl.formatMessage({
                  id: 'distributorDetail.saveChanges',
                })}
                onSubmit={onSubmit}
                submitting={status === LOADING_STATUS}
                submittingLabel={intl.formatMessage({
                  id: 'distributorDetail.pleaseWait',
                })}
              />
            </>
          ) : (
            <p>
              {intl.formatMessage({ id: 'distributorDetail.noDistributor' })}
            </p>
          )}
        </>
      )}
    </div>
  );
}

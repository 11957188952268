import { TrackingHistoryComment } from './TrackingHistoryComment';
import { TrackingHistoryStage } from './TrackingHistoryStage';

export enum Incident {
  NO_INCIDENT = '',
  RETRASO = 'retraso',
  ON_TIME = 'on-time',
  DETERIORADO = 'deteriorado',
  RETRASO_MAS_24H = 'retraso+24h',
  PERDIDO = 'perdido',
  RETRASO_CLIENTE = 'retraso-cliente',
  RETRASO_CLIENTE_MAS_24H = 'retraso-cliente+24h',
  CLIENTE_NO_ACEPTA_NO_RECOGE = 'cliente-no-acepta-no-recoge',
  NO_ENVIADO_NO_RECIBIDO_POR_ERROR_FFJ = 'no-enviado-no-recibido-por-error-ffj',
  FESTIVO = 'festivo',
  MALA_PRAXIS = 'mala-praxis',
}

export const INCIDENT_LABELS = {
  [Incident.NO_INCIDENT]: 'Sin incidente',
  [Incident.RETRASO]: 'Retraso',
  [Incident.ON_TIME]: 'A tiempo',
  [Incident.DETERIORADO]: 'Deteriorado',
  [Incident.RETRASO_MAS_24H]: 'Retraso +24h',
  [Incident.PERDIDO]: 'Perdido',
  [Incident.RETRASO_CLIENTE]: 'Retraso cliente',
  [Incident.RETRASO_CLIENTE_MAS_24H]: 'Retraso cliente +24h',
  [Incident.CLIENTE_NO_ACEPTA_NO_RECOGE]: 'Cliente no acepta / no recoge',
  [Incident.NO_ENVIADO_NO_RECIBIDO_POR_ERROR_FFJ]:
    'No enviado / No recibido por error FFJ',
  [Incident.FESTIVO]: 'Festivo',
  [Incident.MALA_PRAXIS]: 'Mala praxis',
};

export interface TrackingHistory {
  id: number;
  incident?: Incident;
  tracking_history_comments: Array<TrackingHistoryComment>;
  tracking_history_stages: Array<TrackingHistoryStage>;
  shipping_tracking?: string;
  tracking_link?: string;
  tracking_number?: string;
}

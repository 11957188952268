import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import * as user from '../redux/UserRedux';
import { CouponModel } from '../models/CouponModel';
import { RootState } from '../../../../setup';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

export default function Coupons() {
  const intl = useIntl();
  const [code, setCode] = useState('');
  const dispatch = useDispatch();
  const coupons: Array<CouponModel> = useSelector(
    (state: RootState) => state.user.coupons,
    shallowEqual
  );
  const status = useSelector(user.userStatusSelector, shallowEqual);
  const [removingCoupon, setRemovingCoupon] = useState<CouponModel>();

  function onRemove(coupon: CouponModel) {
    const confirms = window.confirm(
      intl.formatMessage({ id: 'userProfile.removeCouponConfirmation' })
    );
    if (confirms) {
      setRemovingCoupon(coupon);
      dispatch(user.actions.removeCoupon(coupon));
    }
  }

  function onAdd(event: any) {
    event?.preventDefault();
    dispatch(user.actions.addCoupon(code));
  }

  useEffect(() => {
    switch (status) {
      case 'success_add_coupon': {
        toast.success(
          intl.formatMessage({ id: 'userProfile.addCouponSuccessful' })
        );
        setCode('');
        break;
      }
      case 'error_add_coupon': {
        toast.error(intl.formatMessage({ id: 'userProfile.addCouponError' }));
        break;
      }
      case 'success_remove_coupon': {
        toast.success(
          intl.formatMessage({ id: 'userProfile.removeCouponSuccessful' })
        );
        setCode('');
        setRemovingCoupon(undefined);
        break;
      }
      case 'error_remove_coupon': {
        toast.error(
          intl.formatMessage({ id: 'userProfile.removeCouponError' })
        );
        setRemovingCoupon(undefined);
        break;
      }
    }
  }, [status]);

  return (
    <>
      <div className="card p-10">
        <div className="d-flex align-items-center justify-content-between">
          <h1 className="d-inline-block mb-10">
            {intl.formatMessage({ id: 'userProfile.coupons' })}
          </h1>
        </div>
        {status === 'loading_request_user' ? (
          <div className="spinner-border text-primary" role="status" />
        ) : (
          <>
            <form className="d-flex col-12 flex-row align-items-end gap-4 mb-10">
              <div className="form-group">
                <label className="fw-bold form-label">
                  {intl.formatMessage({ id: 'userProfile.addCoupon' })}
                </label>
                <input
                  type="text"
                  placeholder={intl.formatMessage({
                    id: 'userProfile.codePlaceholder',
                  })}
                  className="form-control"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary"
                onClick={onAdd}
                disabled={!code?.length || status === 'loading_add_coupon'}
                data-kt-users-modal-action="submit"
                data-kt-indicator={
                  status === 'loading_add_coupon' ? 'on' : 'off'
                }
              >
                {intl.formatMessage({ id: 'userProfile.add' })}
                <span className="indicator-progress">
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </button>
            </form>
            {coupons?.length > 0 ? (
              <div className="table-responsive">
                <table className="table table-hover table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
                  <thead className="table-header">
                    <tr className="border-0 fw-bold ">
                      <th className="p-0">
                        {intl.formatMessage({ id: 'userProfile.couponName' })}
                      </th>
                      <th className="py-0 ps-2">
                        {intl.formatMessage({ id: 'userProfile.couponCode' })}
                      </th>
                      <th className="py-0 ps-2">
                        {intl.formatMessage({
                          id: 'userProfile.couponDiscount',
                        })}
                      </th>
                      <th className="py-0 ps-2">
                        {intl.formatMessage({ id: 'userProfile.appliesTo' })}
                      </th>
                      <th className="py-0 ps-2">
                        {intl.formatMessage({ id: 'userProfile.expiresAt' })}
                      </th>
                      <th className="py-0 ps-2">
                        {intl.formatMessage({
                          id: 'userProfile.lockingExpiresAt',
                        })}
                      </th>
                      <th className="py-0 ps-2">
                        {intl.formatMessage({
                          id: 'userProfile.onlyForInactive',
                        })}
                      </th>
                      <th className="py-0 ps-2">
                        {intl.formatMessage({ id: 'userProfile.onlyForNew' })}
                      </th>
                      <th className="py-0 ps-2">
                        {intl.formatMessage({
                          id: 'userProfile.onlyForOnePlan',
                        })}
                      </th>
                      <th className="py-0 ps-2">
                        {intl.formatMessage({
                          id: 'userProfile.prioritizeFirstPet',
                        })}
                      </th>
                      <th className="py-0 ps-2">
                        {intl.formatMessage({ id: 'userProfile.multipleUses' })}
                      </th>
                      <th className="py-0 ps-2">
                        {intl.formatMessage({
                          id: 'userProfile.maxOrdersPerPet',
                        })}
                      </th>
                      <th className="p-0 text-center">
                        {intl.formatMessage({ id: 'userProfile.couponState' })}
                      </th>
                      <th className="p-0 w-10px text-center"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {coupons.map((coupon: CouponModel) => (
                      <tr key={coupon.code} className="">
                        <td>{coupon.discount.name}</td>
                        <td>{coupon.code}</td>
                        <td className="text-center">
                          {coupon.discount.mode === 'absolute'
                            ? `${coupon.discount.discount}€`
                            : `${Math.floor(coupon.discount.discount * 100)}%`}
                        </td>
                        <td className="text-center">{coupon.applies_to}</td>
                        <td className="">
                          {coupon?.expires_at
                            ? new Date(coupon?.expires_at).toLocaleDateString(
                                'es-ES'
                              )
                            : '∞'}
                        </td>
                        <td className="">
                          {coupon?.locking_expires_at
                            ? new Date(
                                coupon?.locking_expires_at
                              ).toLocaleDateString('es-ES')
                            : '∞'}
                        </td>
                        <td className="text-center">
                          {coupon.only_for_inactive_users ? (
                            <span className="badge badge-light-success rounded-pill p-0 w-25px h-25px align-items-center d-flex justify-content-center m-auto text-center rounded-circle">
                              <span className="fa fa-check"></span>
                            </span>
                          ) : (
                            <span className="badge badge-light-danger rounded-pill p-0 w-25px h-25px align-items-center d-flex justify-content-center m-auto text-centerrounded-circle">
                              <span className="fa fa-times"></span>
                            </span>
                          )}
                        </td>
                        <td className="text-center">
                          {coupon.only_for_new_users ? (
                            <span className="badge badge-light-success rounded-pill p-0 w-25px h-25px align-items-center d-flex justify-content-center m-auto text-center rounded-circle">
                              <span className="fa fa-check"></span>
                            </span>
                          ) : (
                            <span className="badge badge-light-danger rounded-pill p-0 w-25px h-25px align-items-center d-flex justify-content-center m-auto text-centerrounded-circle">
                              <span className="fa fa-times"></span>
                            </span>
                          )}
                        </td>
                        <td className="text-center">
                          {coupon.only_for_one_plan ? (
                            <span className="badge badge-light-success rounded-pill p-0 w-25px h-25px align-items-center d-flex justify-content-center m-auto text-center rounded-circle">
                              <span className="fa fa-check"></span>
                            </span>
                          ) : (
                            <span className="badge badge-light-danger rounded-pill p-0 w-25px h-25px align-items-center d-flex justify-content-center m-auto text-centerrounded-circle">
                              <span className="fa fa-times"></span>
                            </span>
                          )}
                        </td>
                        <td className="text-center">
                          {coupon.prioritize_first_pet ? (
                            <span className="badge badge-light-success rounded-pill p-0 w-25px h-25px align-items-center d-flex justify-content-center m-auto text-center rounded-circle">
                              <span className="fa fa-check"></span>
                            </span>
                          ) : (
                            <span className="badge badge-light-danger rounded-pill p-0 w-25px h-25px align-items-center d-flex justify-content-center m-auto text-centerrounded-circle">
                              <span className="fa fa-times"></span>
                            </span>
                          )}
                        </td>
                        <td className="text-center">
                          {coupon.has_multiple_uses ? (
                            <span className="badge badge-light-success rounded-pill p-0 w-25px h-25px align-items-center d-flex justify-content-center m-auto text-center rounded-circle">
                              <span className="fa fa-check"></span>
                            </span>
                          ) : (
                            <span className="badge badge-light-danger rounded-pill p-0 w-25px h-25px align-items-center d-flex justify-content-center m-auto text-centerrounded-circle">
                              <span className="fa fa-times"></span>
                            </span>
                          )}
                        </td>
                        <td className="text-center">
                          {coupon?.max_orders_per_pet || '∞'}
                        </td>
                        <td className="">
                          <span className="badge ms-2 badge-success d-inline-block badge-pill bg-success">
                            {coupon.discount.state}
                          </span>
                        </td>
                        <td className="">
                          <button
                            type="button"
                            onClick={() => onRemove(coupon)}
                            className="btn btn-tertiary text-muted btn-sm"
                            disabled={!!removingCoupon}
                            data-kt-users-modal-action="submit"
                            data-kt-indicator={
                              removingCoupon?.discount.code ===
                              coupon.discount.code
                                ? 'on'
                                : 'off'
                            }
                          >
                            <span className="indicator-label">
                              <span className="icon fa fa-trash-alt fs-4"></span>
                            </span>
                            <span className="indicator-progress">
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <>
                <p>{intl.formatMessage({ id: 'userProfile.noCoupons' })}</p>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

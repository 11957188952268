import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectedPlanSelector } from '../redux/PlanRedux';
import { listTaxes } from '../services/PlanCRUD';
import { addExtraBillableItem } from '../services/PlanCRUD';
import { toast } from 'react-toastify';
import { actions } from '../redux/PlanRedux';
import { currentUserSelector } from '../../user-profile';

export default function AddCustomConceptModal({ visible, onClose }) {
  const [concept, setConcept] = useState(null);
  const [price, setPrice] = useState(null);
  const [selectedTax, setSelectedTax] = useState(null);
  const intl = useIntl();
  const [taxes, setTaxes] = useState([]);
  const [loading, setLoading] = useState(false);
  const selectedPlan = useSelector(selectedPlanSelector);
  const currentUser = useSelector(currentUserSelector);
  const dispatch = useDispatch();

  async function onConfirm() {
    try {
      setLoading(true);
      const { data: updatedPlan } = await addExtraBillableItem({
        planId: selectedPlan.id,
        productText: concept,
        fixedPrice: price,
        fiscalPosition: selectedTax,
      });
      onClose();
      dispatch(actions.setSelectedPlan(updatedPlan));
      toast.success(intl.formatMessage({ id: 'planDetail.conceptAdded' }));
    } catch (error) {
      console.warn(error);
      toast.error(intl.formatMessage({ id: 'planDetail.errorAddingConcept' }));
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setConcept(null);
    setPrice(null);
    setSelectedTax(taxes[0]?.id);
  }, [visible]);

  const fetchTaxes = async () => {
    try {
      const { data: taxesData } = await listTaxes({
        country: currentUser?.store.country.code,
      });
      setTaxes(taxesData);
    } catch (error) {
      console.warn(error);
    }
  };

  useEffect(() => {
    if (currentUser?.store.country.code) {
      fetchTaxes();
    }
  }, [currentUser?.store.country.code]);

  useEffect(() => {
    if (!selectedTax) {
      setSelectedTax(taxes[0]?.id);
    }
  }, [taxes]);

  return (
    <Modal show={visible} onClose={onClose}>
      <Modal.Header>
        <h4>
          <FormattedMessage id="planDetail.addConceptToPlan" />
        </h4>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group mb-6 w-100">
          <label className="fw-bolder mb-2">
            <FormattedMessage id="planDetail.concept" />
          </label>
          <input
            placeholder={intl.formatMessage({
              id: 'planDetail.conceptPlaceholder',
            })}
            type="text"
            className="form-control form-control-solid"
            value={concept}
            onChange={(e) => setConcept(e.target.value)}
          />
        </div>
        <div className="d-flex gap-4">
          <div className="form-group">
            <label className="fw-bolder mb-2">
              <FormattedMessage id="planDetail.price" />
            </label>
            <div className="input-group w-100">
              <input
                type="number"
                className="form-control form-control-solid"
                value={price}
                placeholder={intl.formatMessage({
                  id: 'planDetail.pricePlaceholder',
                })}
                onChange={(e) => setPrice(e.target.value)}
              />
              <span className="input-group-text bg-light border-0 fs-4">€</span>
            </div>
          </div>
          <div className="form-group w-100">
            <label className="fw-bolder mb-2">
              <FormattedMessage id="planDetail.taxType" />
            </label>
            <select
              className="form-select form-select-solid"
              value={selectedTax}
              onChange={(e) => setSelectedTax(e.target.value)}
            >
              {taxes.map((tax) => (
                <option key={tax.id} value={tax.id}>
                  {tax.name} {tax.tax_class.name} (
                  {Math.floor(tax.vat * 10000) / 100}%)
                </option>
              ))}
            </select>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-between w-100">
          <Button variant="tertiary" onClick={onClose}>
            <FormattedMessage id="planDetail.close" />
          </Button>
          <Button
            variant="primary"
            onClick={onConfirm}
            disabled={!(concept && price > -1 && selectedTax) || loading}
          >
            <FormattedMessage id="planDetail.addProduct" />
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

import { FC } from 'react';
import { useIntl } from 'react-intl';

import { PageTitle } from '../../../_metronic/layout/core';
import { BusinessDashboard } from '../../modules/dashboard';
import SalesReport from '../../modules/dashboard/components/SalesReport';
import OrdersReport from '../../modules/dashboard/components/OrdersReport';
import UserKpiReport from '../../modules/dashboard/components/UserKpiReport';
import { GiftPrevision } from '../../modules/loyalty';
import RecipeSalesReport from '../../modules/dashboard/components/RecipeSalesReport';

const BusinessDashboardPage: FC = () => {
  const intl = useIntl();

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'businessDashboard.title' })}
      </PageTitle>
      <div className="row gy-4">
        <div className="card p-10">
          <SalesReport />
        </div>
        <div className="card p-10">
          <RecipeSalesReport />
        </div>
        <div className="card p-10">
          <GiftPrevision />
        </div>
        <div className="card p-10">
          <UserKpiReport />
        </div>
        <div className="card p-10">
          <OrdersReport />
        </div>
        <div className="card p-10">
          <BusinessDashboard />
        </div>
      </div>
    </>
  );
};

export { BusinessDashboardPage };

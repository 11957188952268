import { authUserSelector } from '../../auth';
import { updateUser } from '../../user-profile';
import { delay, put, select, takeEvery } from 'redux-saga/effects';
import { ActionWithPayload } from '../../../../setup';
import { actionTypes, actions } from './TaskActions';
import {
  addComment,
  dispatchTask,
  listAlarmTasks,
  listTasks,
  listGroups,
  retrieveStaffUsers,
  retrieveTask,
  solveTask,
  updateTask,
  listPendings,
  listPendingAgentTasks,
  listBagTasks,
  listClientTasks,
} from '../services/TaskCRUD';
import {
  pendingTaskFiltersSelector,
  pendingTaskSelector,
  selectedTaskSelector,
} from './TaskSelectors';
import { TaskCommentModel } from '../models/TaskCommentModel';
import { TaskSetResolutionModel } from '../models/TaskSetResolutionModel';
import * as userRedux from '../../user-profile/redux/UserRedux';

export const TASKS_PER_PAGE = 20;
/*
TODO: extend endpoint to get groups by code and not by id
*/
export const ACQUISITION_GROUP_ID = 15;
export const SUBSCRIPTION_GROUP_ID = 16;
export const PET_CARE_GROUP_ID = 17;
export const GROUPS_PER_EVENT_PROFILE = {
  ['event-complaint']: SUBSCRIPTION_GROUP_ID,
  ['event-customer-cancellation-request']: SUBSCRIPTION_GROUP_ID,
  ['event-pause-plan-request']: SUBSCRIPTION_GROUP_ID,
  ['event-change-promise-date']: SUBSCRIPTION_GROUP_ID,
  ['event-invoice']: SUBSCRIPTION_GROUP_ID,
  ['event-customer-inquiries']: SUBSCRIPTION_GROUP_ID,
  ['event-lead-inquiries']: ACQUISITION_GROUP_ID,
  ['event-changes-on-plan']: SUBSCRIPTION_GROUP_ID,
  ['event-change-cancel-locked-order']: ACQUISITION_GROUP_ID,
  ['event-logistics-problem']: SUBSCRIPTION_GROUP_ID,
  ['event-meal-reaction']: PET_CARE_GROUP_ID,
  ['event-missed-call']: ACQUISITION_GROUP_ID,
  ['new-lead-manual']: ACQUISITION_GROUP_ID,
};
export const POSSIBLE_TASK_RESOLUTION = 19;
export const PENDING_CUSTOMER_TASK_RESOLUTION = 22;
export const NOT_REACHABLE_TASK_RESOLUTION = 5;
export const UNKNOWN_TASK_PROFILE = 22;
export const RECOVERY_TASK_PROFILE = 25;
export const NEW_LEAD_TASK_PROFILE = 1;
export const NO_RECIPES_TASK_PROFILE = 6;
export const LEAD_CALL_REQUEST_TASK_PROFILE = 7;
export const NO_ANSWER_TASK_RESOLUTION = 26;

export function* saga() {
  yield takeEvery(
    actionTypes.DispatchTask,
    function* DispatchTaskEffect(
      action: ActionWithPayload<{ taskResolution; taskProfile }>
    ) {
      try {
        yield put(
          actions.setPendingTaskFilters({
            taskResolution: action.payload.taskResolution || null,
            taskProfile: action.payload.taskProfile || null,
          })
        );
        const { data } = yield dispatchTask({
          taskResolution: action.payload?.taskResolution || null,
          taskProfile: action.payload?.taskProfile || null,
        });
        yield put(actions.setPendingTask(data));
      } catch (exception: any) {
        console.warn(exception);
      }
    }
  );

  yield takeEvery(
    actionTypes.RequestTask,
    function* RequestTaskEffect(action: ActionWithPayload<{ taskId: number }>) {
      try {
        const { data } = yield retrieveTask(action?.payload?.taskId);
        yield put(actions.setSelectedTask(data));
      } catch (exception: any) {
        console.warn(exception);
      }
    }
  );

  yield takeEvery(actionTypes.listGroups, function* listGroupsEffect() {
    try {
      const { data } = yield listGroups();
      yield put(actions.setGroups(data));
    } catch (exception: any) {
      console.warn(exception);
    }
  });

  yield takeEvery(
    actionTypes.RetrievePossibleTasks,
    function* RetrievePossibleTasksEffect(
      action: ActionWithPayload<{ page?: number }>
    ) {
      const page = action?.payload?.page || 1;

      try {
        yield put(actions.setPossibleTasksStatus('loading'));
        const { data } = yield listBagTasks({
          offset: (page - 1) * TASKS_PER_PAGE,
          limit: TASKS_PER_PAGE,
          task_resolution: POSSIBLE_TASK_RESOLUTION,
        });
        yield put(
          actions.setPossibleTasks({
            ...data,
            page,
          })
        );
      } catch (exception: any) {
        console.warn(exception);
        yield put(actions.setPossibleTasksStatus('failed'));
      } finally {
        yield delay(0);
        yield put(actions.setPossibleTasksStatus('idle'));
      }
    }
  );

  yield takeEvery(
    actionTypes.RetrievePendingCustomerTasks,
    function* RetrievePendingCustomerTasksEffect(
      action: ActionWithPayload<{ page?: number }>
    ) {
      const page = action?.payload?.page || 1;

      try {
        yield put(actions.setPendingCustomerTasksStatus('loading'));
        const { data } = yield listBagTasks({
          offset: (page - 1) * TASKS_PER_PAGE,
          limit: TASKS_PER_PAGE,
          task_resolution: PENDING_CUSTOMER_TASK_RESOLUTION,
        });
        yield put(actions.setPendingCustomerTasks({ ...data, page }));
      } catch (exception: any) {
        console.warn(exception);
        yield put(actions.setPendingCustomerTasksStatus('failed'));
      } finally {
        yield delay(0);
        yield put(actions.setPendingCustomerTasksStatus('idle'));
      }
    }
  );

  yield takeEvery(
    actionTypes.RetrieveNotReachableExpiredTasks,
    function* RetrieveNotReachableExpiredTasksEffect(
      action: ActionWithPayload<{ page?: number }>
    ) {
      const page = action?.payload?.page || 1;

      try {
        yield put(actions.setNotReachableExpiredTasksStatus('loading'));
        const { data } = yield listBagTasks({
          offset: (page - 1) * TASKS_PER_PAGE,
          limit: TASKS_PER_PAGE,
          task_resolution: NOT_REACHABLE_TASK_RESOLUTION,
        });
        yield put(actions.setNotReachableExpiredTasks({ ...data, page }));
      } catch (exception: any) {
        console.warn(exception);
        yield put(actions.setNotReachableExpiredTasksStatus('failed'));
      } finally {
        yield put(actions.setNotReachableExpiredTasksStatus('idle'));
      }
    }
  );

  yield takeEvery(
    actionTypes.RetrieveAlarmTasks,
    function* RetrieveAlarmTasksEffect(
      action: ActionWithPayload<{ page?: number }>
    ) {
      const page = action?.payload?.page || 1;

      try {
        yield put(actions.setAlarmTasksStatus('loading'));
        const { data } = yield listAlarmTasks({
          offset: (page - 1) * TASKS_PER_PAGE,
          limit: TASKS_PER_PAGE,
        });
        yield put(actions.setAlarmTasks({ ...data, page }));
      } catch (exception: any) {
        console.warn(exception);
        yield put(actions.setAlarmTasksStatus('failed'));
      } finally {
        yield put(actions.setAlarmTasksStatus('idle'));
      }
    }
  );

  yield takeEvery(
    actionTypes.RetrievePendingTasks,
    function* RetrievePendingTasksEffect(
      action: ActionWithPayload<{ since?: string; until?: string }>
    ) {
      try {
        yield put(actions.setPendingTasksStatus('loading'));
        yield put(actions.setPendingTasks([]));
        const { until, since } = action.payload;
        const authUser = yield select(authUserSelector);
        const { data } = yield listTasks({
          ownerId: authUser.id,
          since,
          until,
        });
        let tasks = data.results;
        let nextTaskUrl = data.next;
        while (nextTaskUrl) {
          const { data: nextData } = yield listTasks({
            next: nextTaskUrl,
          });
          tasks = tasks.concat(nextData.results);
          nextTaskUrl = nextData.next;
        }
        yield put(actions.setPendingTasks(tasks || []));
      } catch (exception: any) {
        console.warn(exception);
        yield put(actions.setPendingTasksStatus('failed'));
      } finally {
        yield delay(0);
        yield put(actions.setPendingTasksStatus('idle'));
      }
    }
  );

  yield takeEvery(
    actionTypes.RetrieveRecoveryTasks,
    function* RetrieveRecoveryTasksEffect(
      action: ActionWithPayload<{ page?: number }>
    ) {
      const page = action?.payload?.page || 1;

      try {
        yield put(actions.setRecoveryTasksStatus('loading'));
        const { data } = yield listBagTasks({
          offset: (page - 1) * TASKS_PER_PAGE,
          limit: TASKS_PER_PAGE,
          task_profile: RECOVERY_TASK_PROFILE,
        });
        yield put(actions.setRecoveryTasks({ ...data, page }));
      } catch (exception: any) {
        console.warn(exception);
        yield put(actions.setRecoveryTasksStatus('failed'));
      } finally {
        yield put(actions.setRecoveryTasksStatus('idle'));
      }
    }
  );

  yield takeEvery(
    actionTypes.RetrieveUnknownTasks,
    function* RetrieveUnknownTasksEffect(
      action: ActionWithPayload<{ page?: number }>
    ) {
      const page = action?.payload?.page || 1;

      try {
        yield put(actions.setUnknownTasksStatus('loading'));
        const { data } = yield listBagTasks({
          offset: (page - 1) * TASKS_PER_PAGE,
          limit: TASKS_PER_PAGE,
          task_profile: UNKNOWN_TASK_PROFILE,
        });
        yield put(actions.setUnknownTasks({ ...data, page }));
      } catch (exception: any) {
        console.warn(exception);
        yield put(actions.setUnknownTasksStatus('failed'));
      } finally {
        yield put(actions.setUnknownTasksStatus('idle'));
      }
    }
  );

  yield takeEvery(
    actionTypes.RetrieveNewLeadTasks,
    function* RetrieveNewLeadTasksEffect(
      action: ActionWithPayload<{ page?: number }>
    ) {
      const page = action?.payload?.page || 1;

      try {
        yield put(actions.setNewLeadTasksStatus('loading'));
        const { data } = yield listBagTasks({
          offset: (page - 1) * TASKS_PER_PAGE,
          limit: TASKS_PER_PAGE,
          task_profile: NEW_LEAD_TASK_PROFILE,
        });
        yield put(actions.setNewLeadTasks({ ...data, page }));
      } catch (exception: any) {
        console.warn(exception);
        yield put(actions.setNewLeadTasksStatus('failed'));
      } finally {
        yield put(actions.setNewLeadTasksStatus('idle'));
      }
    }
  );

  yield takeEvery(
    actionTypes.RetrieveNoRecipesTasks,
    function* RetrieveNoRecipesTasksEffect(
      action: ActionWithPayload<{ page?: number }>
    ) {
      const page = action?.payload?.page || 1;

      try {
        yield put(actions.setNoRecipesTasksStatus('loading'));
        const { data } = yield listBagTasks({
          offset: (page - 1) * TASKS_PER_PAGE,
          limit: TASKS_PER_PAGE,
          task_profile: NO_RECIPES_TASK_PROFILE,
        });
        yield put(actions.setNoRecipesTasks({ ...data, page }));
      } catch (exception: any) {
        console.warn(exception);
        yield put(actions.setNoRecipesTasksStatus('failed'));
      } finally {
        yield put(actions.setNoRecipesTasksStatus('idle'));
      }
    }
  );

  yield takeEvery(
    actionTypes.RetrieveLeadCallRequestTasks,
    function* RetrieveLeadCallRequestTasksEffect(
      action: ActionWithPayload<{ page?: number }>
    ) {
      const page = action?.payload?.page || 1;

      try {
        yield put(actions.setLeadCallRequestTasksStatus('loading'));
        const { data } = yield listBagTasks({
          offset: (page - 1) * TASKS_PER_PAGE,
          limit: TASKS_PER_PAGE,
          task_profile: LEAD_CALL_REQUEST_TASK_PROFILE,
        });
        yield put(actions.setLeadCallRequestTasks({ ...data, page }));
      } catch (exception: any) {
        console.warn(exception);
        yield put(actions.setLeadCallRequestTasksStatus('failed'));
      } finally {
        yield put(actions.setLeadCallRequestTasksStatus('idle'));
      }
    }
  );

  yield takeEvery(
    actionTypes.RetrieveNoAnswerTasks,
    function* RetrieveNoAnswerTasksEffect(
      action: ActionWithPayload<{ page?: number }>
    ) {
      const page = action?.payload?.page || 1;

      try {
        yield put(actions.setNoAnswerTasksStatus('loading'));
        const { data } = yield listBagTasks({
          offset: (page - 1) * TASKS_PER_PAGE,
          limit: TASKS_PER_PAGE,
          task_resolution: NO_ANSWER_TASK_RESOLUTION,
        });
        yield put(actions.setNoAnswerTasks({ ...data, page }));
      } catch (exception: any) {
        console.warn(exception);
        yield put(actions.setNoAnswerTasksStatus('failed'));
      } finally {
        yield put(actions.setNoAnswerTasksStatus('idle'));
      }
    }
  );

  yield takeEvery(
    actionTypes.RetrieveClientTasks,
    function* RetrieveClientTasksEffect(
      action: ActionWithPayload<{ id: number; page?: number }>
    ) {
      const page = action?.payload?.page || 1;
      const id = action?.payload?.id;

      try {
        yield put(actions.setClientTasksStatus('loading'));
        const { data } = yield listClientTasks({
          id,
          offset: (page - 1) * TASKS_PER_PAGE,
          limit: TASKS_PER_PAGE,
        });
        yield put(actions.setClientTasks({ ...data, page }));
      } catch (exception: any) {
        console.warn(exception);
        yield put(actions.setClientTasksStatus('failed'));
      } finally {
        yield put(actions.setClientTasksStatus('idle'));
      }
    }
  );

  yield takeEvery(
    actionTypes.RetrievePendingAgentTasks,
    function* RetrievePendingAgentTasksEffect(
      action: ActionWithPayload<{
        id?: number;
        page?: number;
        taskProfile?: number;
        taskResolution?: number;
        since?: string;
        until?: string;
        current_attempt_number: number;
        order: 'created_at' | '-created_at';
      }>
    ) {
      try {
        yield put(actions.setPendingAgentTasksStatus('loading'));
        const {
          id,
          page,
          taskProfile,
          taskResolution,
          since,
          until,
          current_attempt_number,
          order,
        } = action.payload;
        const { data } = yield listPendingAgentTasks({
          offset: (page - 1) * TASKS_PER_PAGE,
          limit: TASKS_PER_PAGE,
          agentId: id,
          taskProfile,
          taskResolution,
          since,
          until,
          current_attempt_number,
          order,
        });
        yield put(
          actions.setPendingAgentTasks({
            ...data,
            page,
            taskProfile,
            taskResolution,
            since,
            until,
            currentAttemptNumber: current_attempt_number,
            order,
          })
        );
      } catch (exception: any) {
        console.warn(exception);
        yield put(actions.setPendingAgentTasksStatus('failed'));
      } finally {
        yield delay(0);
        yield put(actions.setPendingAgentTasksStatus('idle'));
      }
    }
  );

  yield takeEvery(
    actionTypes.RetrieveStaffUsers,
    function* RetrieveStaffUsersEffect() {
      try {
        const { data } = yield retrieveStaffUsers();
        yield put(actions.setStaffUsers(data));
      } catch (exception: any) {
        console.warn(exception);
      }
    }
  );

  yield takeEvery(
    actionTypes.CommentPendingTask,
    function* CommentPendingTaskEffect(
      action: ActionWithPayload<{ taskComment: TaskCommentModel }>
    ) {
      try {
        const pendingTask = yield select(pendingTaskSelector);
        yield addComment(pendingTask.id, action.payload.taskComment);
      } catch (exception: any) {
        console.warn(exception);
      }
    }
  );

  yield takeEvery(
    actionTypes.CommentSelectedTask,
    function* CommentSelectedTaskEffect(
      action: ActionWithPayload<{ taskComment: TaskCommentModel }>
    ) {
      try {
        const selectedTask = yield select(selectedTaskSelector);
        yield addComment(selectedTask.id, action.payload.taskComment);
      } catch (exception: any) {
        console.warn(exception);
      }
    }
  );

  yield takeEvery(
    actionTypes.ResolvePendingTask,
    function* ResolvePendingTaskEffect(
      action: ActionWithPayload<{
        taskSetResolution: TaskSetResolutionModel;
        replaceAgent: boolean;
        isAlarm: boolean;
      }>
    ) {
      try {
        const pendingTask = yield select(pendingTaskSelector);
        yield solveTask(pendingTask.id, action?.payload?.taskSetResolution);
        if (action?.payload?.taskSetResolution.dispatch_after) {
          yield put(actions.setPendingTaskStatus('postponed'));
        } else {
          yield put(actions.setPendingTaskStatus('solved'));
        }
        if (typeof action?.payload?.isAlarm !== 'undefined') {
          yield updateTask(pendingTask.id, {
            is_alarm: action?.payload?.isAlarm,
          });
        }
        if (
          action?.payload?.replaceAgent &&
          action?.payload?.taskSetResolution.owner
        ) {
          const csGroup = pendingTask.group.id;
          let updatedAgent = {};
          switch (csGroup) {
            case ACQUISITION_GROUP_ID:
              updatedAgent = {
                cs_acquisition_agent: action?.payload?.taskSetResolution.owner,
              };
              break;
            case SUBSCRIPTION_GROUP_ID:
              updatedAgent = {
                cs_subscription_agent: action?.payload?.taskSetResolution.owner,
              };
              break;
            case PET_CARE_GROUP_ID:
              updatedAgent = {
                cs_pet_care_agent: action?.payload?.taskSetResolution.owner,
              };
              break;
          }
          yield updateUser({
            id: pendingTask.user.id,
            ...updatedAgent,
          });
        }
      } catch (exception: any) {
        yield put(
          actions.setPendingTaskError(exception?.response?.data?.error)
        );
        yield put(actions.retrievePendingTasks());
        yield put(actions.retrieveAlarmTasks());
      } finally {
        const { taskProfile, taskResolution } = yield select(
          pendingTaskFiltersSelector
        );
        yield put(actions.dispatchTask(taskResolution, taskProfile));
        yield put(actions.retrieveAlarmTasks());
        yield put(actions.setPendingTaskStatus('idle'));
      }
    }
  );

  yield takeEvery(
    actionTypes.ResolveSelectedTask,
    function* ResolveSelectedTaskEffect(
      action: ActionWithPayload<{
        taskSetResolution: TaskSetResolutionModel;
        replaceAgent: boolean;
        isAlarm: boolean;
      }>
    ) {
      try {
        const selectedTask = yield select(selectedTaskSelector);
        yield solveTask(selectedTask.id, action.payload.taskSetResolution);
        if (action.payload.taskSetResolution.dispatch_after) {
          yield put(actions.setSelectedTaskStatus('postponed'));
        } else {
          yield put(actions.setSelectedTaskStatus('solved'));
        }
        if (typeof action?.payload?.isAlarm !== 'undefined') {
          yield updateTask(selectedTask.id, {
            is_alarm: action.payload.isAlarm,
          });
        }
        if (
          action.payload.replaceAgent &&
          action.payload.taskSetResolution.owner
        ) {
          const csGroup = selectedTask.group.id;
          let updatedAgent = {};
          switch (csGroup) {
            case ACQUISITION_GROUP_ID:
              updatedAgent = {
                cs_acquisition_agent: action.payload.taskSetResolution.owner,
              };
              break;
            case SUBSCRIPTION_GROUP_ID:
              updatedAgent = {
                cs_subscription_agent: action.payload.taskSetResolution.owner,
              };
              break;
            case PET_CARE_GROUP_ID:
              updatedAgent = {
                cs_pet_care_agent: action.payload.taskSetResolution.owner,
              };
              break;
          }
          yield updateUser({
            id: selectedTask.user.id,
            ...updatedAgent,
          });
          yield put(userRedux.actions.requestUser(selectedTask.user.id));
        }
        yield put(actions.requestTask(selectedTask.id));
      } catch (exception: any) {
        yield put(
          actions.setSelectedTaskError(exception?.response?.data?.error)
        );
      } finally {
        const { taskProfile, taskResolution } = yield select(
          pendingTaskFiltersSelector
        );
        yield put(actions.dispatchTask(taskResolution, taskProfile));
        yield put(actions.retrieveAlarmTasks());
        yield put(actions.setSelectedTaskStatus('idle'));
      }
    }
  );

  yield takeEvery(
    actionTypes.RetrievePendingsPerAgent,
    function* RetrievePendingsPerAgentEffect(
      action: ActionWithPayload<{ ownerId: number }>
    ) {
      const ownerId = action.payload.ownerId;

      try {
        const { data } = yield listPendings(ownerId);
        yield put(actions.setPendingsPerAgent(data));
      } catch (exception: any) {
        console.warn(exception);
        yield put(actions.setPendingsPerAgentStatus('failed'));
      } finally {
        yield put(actions.setPendingsPerAgentStatus('idle'));
      }
    }
  );
}

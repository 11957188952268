import { useIntl } from 'react-intl';

export default function OrderTrackingTableHeader({
  singleOrderDisplay = false,
}) {
  const intl = useIntl();

  return (
    <header className="table-header sticky-top bg-white border-bottom-dashed border-gray-200">
      <div className="fw-bolder d-flex justify-content-between gap-2">
        <div className="text-start w-200px">
          {intl.formatMessage({ id: 'orderTrackingHistory.order' })}
        </div>
        <div className="text-start w-200px">
          {intl.formatMessage({ id: 'orderTrackingHistory.lastEvent' })}
        </div>
        <div className="text-start w-200px">
          {intl.formatMessage({ id: 'orderTrackingHistory.tracking' })}
        </div>
        <div className="text-start w-200px">
          {intl.formatMessage({ id: 'orderTrackingHistory.dates' })}
        </div>
        <div className="text-start w-250px">
          {intl.formatMessage({ id: 'orderTrackingHistory.trackingNumber' })}
        </div>
        {!singleOrderDisplay && (
          <div className="w-100px">
            {
              // Empty for toggle column
            }
          </div>
        )}
      </div>
    </header>
  );
}

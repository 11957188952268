import { put, takeEvery, delay, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  listPlans,
  listPetPlans,
  getPlanById,
  updatePlan,
  pausePlan,
  resumePlan,
  cancelPlan,
  confirmPlan,
  detainPlan,
  reproposePlan,
  refreshPromiseDate,
  discardPlan,
  recalculatePlan,
  refreshPrices,
  createCustomMeal as createCustomMealService,
  updateCustomMeal as updateCustomMealService,
  deleteCustomMeal as deleteCustomMealService,
  reactivatePlan,
  addDiscount,
  removeDiscount,
  generateOrders,
  editExtraProducts,
  removeProductFromPlan,
} from '../services/PlanCRUD';
import { PlanModel } from '../models/PlanModel';
import { PlanListingModel } from '../models/PlanListingModel';
import { RootState, ActionWithPayload } from '../../../../setup';
import * as OrderRedux from '../../orders/redux/OrderRedux';
import { currentUserSelector } from '../../user-profile';
import { actions as orderActions } from '../../orders/redux/OrderRedux';

export const actionTypes = {
  ListPlans: 'PLAN_REDUX_LIST_PLANS',
  ListPetPlans: 'PLAN_REDUX_LIST_PET_PLANS',
  SetPlans: 'PLAN_REDUX_SET_PLANS',
  ResetPlans: 'PLAN_REDUX_RESET_PLANS',
  SetStatus: 'PLAN_REDUX_SET_STATUS',
  SetError: 'PLAN_REDUX_SET_ERROR',
  PlanRequested: 'PLAN_REDUX_REQUEST_PLAN',
  SetSelectedPlan: 'PLAN_REDUX_SET_SELECTED_PLAN',
  UpdateSelectedPlan: 'PLAN_REDUX_UPDATE_SELECTED_PLAN',
  SetPetPlans: 'PLAN_REDUX_SET_PET_PLANS',
  DiscardPlan: 'PLAN_REDUX_DISCARD_PLAN',
  PausePlan: 'PLAN_REDUX_PAUSE_PLAN',
  ResumePlan: 'PLAN_REDUX_RESUME_PLAN',
  CancelPlan: 'PLAN_REDUX_CANCEL_PLAN',
  RetryPayment: 'PLAN_REDUX_RETRY_PAYMENT',
  ConfirmPlan: 'PLAN_REDUX_CONFIRM_PLAN',
  RecalculatePlan: 'PLAN_REDUX_RECALCULATE_PLAN',
  ReactivatePlan: 'PLAN_REDUX_REACTIVATE_PLAN',
  RefreshPrices: 'PLAN_REDUX_refresh_plan',
  GenerateOrders: 'PLAN_REDUX_GENERATE_ORDERS',
  DetainPlan: 'PLAN_REDUX_DETAIN_PLAN',
  ReproposePlan: 'PLAN_REDUX_REPROPOSE_PLAN',
  AddCustomMeal: 'PLAN_REDUX_ADD_CUSTOM_MEAL',
  UpdateCustomMeal: 'PLAN_REDUX_UPDATE_CUSTOM_MEAL',
  DeleteCustomMeal: 'PLAN_REDUX_DELETE_CUSTOM_MEAL',
  AddDiscount: 'PLAN_REDUX_ADD_DISCOUNT',
  RemoveDiscount: 'PLAN_REDUX_REMOVE_DISCOUNT',
  SetExtraProducts: 'PLAN_REDUX_SET_PRODUCT_UNITS_IN_SELECTED_PLAN',
  RemoveProductFromSelectedPlan: 'PLAN_REDUX_REMOVE_PRODUCT_FROM_SELECTED_PLAN',
};

type Status =
  | 'idle'
  // fetch lifecycle
  | 'loading_selected_plan'
  | 'error_selected_plan'
  | 'success_selected_plan'
  // update lifecycle
  | 'loading_update_selected_plan'
  | 'success_update_selected_plan'
  | 'error_update_selected_plan'
  // discard lifecycle
  | 'loading_discard_plan'
  | 'error_discard_plan'
  | 'success_discard_plan'
  // pause lifecycle
  | 'loading_pause_plan'
  | 'error_pause_plan'
  | 'success_pause_plan'
  // resume lifecycle
  | 'loading_resume_plan'
  | 'error_resume_plan'
  | 'success_resume_plan'
  // recalculate lifecycle
  | 'loading_recalculate_plan'
  | 'error_recalculate_plan'
  | 'success_recalculate_plan'
  // reactivate lifecycle
  | 'loading_reactivate_plan'
  | 'error_reactivate_plan'
  | 'success_reactivate_plan'
  // refresh_plan lifecycle
  | 'loading_refresh_plan'
  | 'error_refresh_plan'
  | 'success_refresh_plan'
  // generate_orders lifecycle
  | 'loading_generate_orders'
  | 'error_generate_orders'
  | 'success_generate_orders'
  // retry_payment lifecycle
  | 'loading_retry_payment_plan'
  | 'error_retry_payment_plan'
  | 'success_retry_payment_plan'
  // cancel lifecycle
  | 'loading_cancel_plan'
  | 'error_cancel_plan'
  | 'success_cancel_plan'
  // confirm lifecycle
  | 'loading_confirm_plan'
  | 'error_confirm_plan'
  | 'success_confirm_plan'
  // detain lifecycle
  | 'loading_detain_plan'
  | 'error_detain_plan'
  | 'success_detain_plan'
  // repropose lifecycle
  | 'loading_repropose_plan'
  | 'error_repropose_plan'
  | 'success_repropose_plan'
  // create custom meal lifecycle
  | 'loading_create_custom_meal'
  | 'error_create_custom_meal'
  | 'success_create_custom_meal'
  // update custom meal lifecycle
  | 'loading_update_custom_meal'
  | 'error_update_custom_meal'
  | 'success_update_custom_meal'
  // delete custom meal lifecycle
  | 'loading_delete_custom_meal'
  | 'error_delete_custom_meal'
  | 'success_delete_custom_meal'
  // add discount lifecycle
  | 'loading_add_discount'
  | 'error_add_discount'
  | 'success_add_discount'
  // remove discount lifecycle
  | 'loading_remove_discount'
  | 'error_remove_discount'
  | 'success_remove_discount'
  // list plans lifecycle
  | 'loading_list_plans'
  | 'error_list_plans'
  | 'success_list_plans'
  // list pet plans lifecycle
  | 'loading_list_pet_plans'
  | 'error_list_pet_plans'
  | 'success_list_pet_plans'
  // set product units in selected plan lifecycle
  | 'loading_set_product_units_in_selected_plan'
  | 'error_set_product_units_in_selected_plan'
  | 'success_set_product_units_in_selected_plan'
  // remove product from selected plan lifecycle
  | 'loading_remove_product_from_selected_plan'
  | 'error_remove_product_from_selected_plan'
  | 'success_remove_product_from_selected_plan';

export interface IPlanState {
  plans: Array<PlanListingModel>;
  petPlans: Array<PlanListingModel>;
  selectedPlan?: PlanModel;
  status: Status;
  error?: any;
}

const initialState: IPlanState = {
  plans: [],
  petPlans: [],
  selectedPlan: undefined,
  status: 'loading_list_plans',
  error: undefined,
};

export const planStatusSelector = (state: RootState) => state.plan.status;
export const selectedPlanSelector = (state: RootState) =>
  state.plan.selectedPlan;
export const petPlansSelector = (state: RootState) => state.plan.petPlans;
export const plansSelector = (state: RootState) => state.plan.plans;
export const planErrorSelector = (state: RootState) => state.plan.error;

export const reducer = (
  state: IPlanState = initialState,
  action: ActionWithPayload<IPlanState>
): IPlanState => {
  switch (action.type) {
    case actionTypes.SetPlans: {
      const plans: PlanListingModel[] = action.payload?.plans || [];
      return { ...state, plans };
    }

    case actionTypes.SetPetPlans: {
      const petPlans: PlanListingModel[] = action.payload?.petPlans || [];
      return { ...state, petPlans };
    }

    case actionTypes.SetStatus: {
      const status = action.payload?.status || 'idle';
      return { ...state, status };
    }

    case actionTypes.SetError: {
      const error = action.payload?.error || undefined;
      return { ...state, error };
    }

    case actionTypes.ResetPlans: {
      return initialState;
    }

    case actionTypes.SetSelectedPlan: {
      const selectedPlan = action.payload?.selectedPlan || undefined;
      return {
        ...state,
        selectedPlan,
        status: 'idle',
      };
    }

    default:
      return state;
  }
};

export const actions = {
  store: () => ({ type: 'def' }),
  setPlans: (plans: PlanListingModel[]) => ({
    type: actionTypes.SetPlans,
    payload: { plans },
  }),
  setPetPlans: (petPlans: PlanListingModel[]) => ({
    type: actionTypes.SetPetPlans,
    payload: { petPlans },
  }),
  listPlans: (userId: number) => ({
    type: actionTypes.ListPlans,
    payload: { userId },
  }),
  listPetPlans: (petId: number) => ({
    type: actionTypes.ListPetPlans,
    payload: { petId },
  }),
  resetPlans: () => ({ type: actionTypes.ResetPlans }),
  setStatus: (status: Status) => ({
    type: actionTypes.SetStatus,
    payload: { status },
  }),
  setError: (error: any) => ({
    type: actionTypes.SetError,
    payload: { error },
  }),
  setSelectedPlan: (selectedPlan: PlanModel) => ({
    type: actionTypes.SetSelectedPlan,
    payload: { selectedPlan },
  }),
  requestPlan: (planId: number) => ({
    type: actionTypes.PlanRequested,
    payload: { planId },
  }),
  updateSelectedPlan: (plan: Partial<PlanModel>) => ({
    type: actionTypes.UpdateSelectedPlan,
    payload: { plan },
  }),
  cancelPlan: (planId: number, koReason: string) => ({
    type: actionTypes.CancelPlan,
    payload: { planId, koReason },
  }),
  discardPlan: (planId: number, koReason: string) => ({
    type: actionTypes.DiscardPlan,
    payload: { planId, koReason },
  }),
  pausePlan: (planId: number, koReason: string) => ({
    type: actionTypes.PausePlan,
    payload: { planId, koReason },
  }),
  resumePlan: (planId: number) => ({
    type: actionTypes.ResumePlan,
    payload: { planId },
  }),
  confirmPlan: (planId: number) => ({
    type: actionTypes.ConfirmPlan,
    payload: { planId },
  }),
  reactivatePlan: (planId: number) => ({
    type: actionTypes.ReactivatePlan,
    payload: { planId },
  }),
  retryPayment: (planId: number) => ({
    type: actionTypes.RetryPayment,
    payload: { planId },
  }),
  recalculatePlan: (planId: number) => ({
    type: actionTypes.RecalculatePlan,
    payload: { planId },
  }),
  detainPlan: (planId: number, koReason: string) => ({
    type: actionTypes.DetainPlan,
    payload: { planId, koReason },
  }),
  reproposePlan: (planId: number) => ({
    type: actionTypes.ReproposePlan,
    payload: { planId },
  }),
  refreshPrices: (planId: number) => ({
    type: actionTypes.RefreshPrices,
    payload: { planId },
  }),
  generateOrders: (planId: number) => ({
    type: actionTypes.GenerateOrders,
    payload: { planId },
  }),
  addCustomMeal: (
    planId: number,
    mealId: number,
    sizeId: number,
    units: number
  ) => ({
    type: actionTypes.AddCustomMeal,
    payload: { planId, mealId, sizeId, units },
  }),
  setExtraProductsInSelectedPlan: (
    extraProducts: Array<{
      product_variant: number;
      units: number;
    }>
  ) => ({
    type: actionTypes.SetExtraProducts,
    payload: { extraProducts },
  }),
  removeProductFromSelectedPlan: (productVariantId: number) => ({
    type: actionTypes.RemoveProductFromSelectedPlan,
    payload: { productVariantId },
  }),
  updateCustomMeal: (
    planId: number,
    customMealId: number,
    mealId: number,
    sizeId: number,
    units: number
  ) => ({
    type: actionTypes.UpdateCustomMeal,
    payload: { planId, customMealId, mealId, sizeId, units },
  }),
  deleteCustomMeal: (planId: number, customMealId: number) => ({
    type: actionTypes.DeleteCustomMeal,
    payload: { planId, customMealId },
  }),
  addDiscount: (discountId: number, expiresAt?: string) => ({
    type: actionTypes.AddDiscount,
    payload: { discountId, expiresAt },
  }),
  removeDiscount: (discountId: number) => ({
    type: actionTypes.RemoveDiscount,
    payload: { discountId },
  }),
};

export function* saga() {
  yield takeEvery(
    actionTypes.SetSelectedPlan,
    function* (action: ActionWithPayload<{ selectedPlan: PlanModel }>) {
      const currentUser = yield select(currentUserSelector);
      const selectedPlan = action.payload.selectedPlan;
      if (currentUser?.id) {
        yield put(OrderRedux.actions.listOrders(currentUser.id, 1));
      }
      yield put(OrderRedux.actions.listPlanOrders(selectedPlan.id, 1));
    }
  );

  yield takeEvery(
    actionTypes.UpdateSelectedPlan,
    function* UpdateSelectedPlanEffect(
      action: ActionWithPayload<{ plan: Partial<PlanModel> }>
    ) {
      const plan = action?.payload?.plan;
      const id = plan?.id;

      yield put(actions.setError(undefined));
      if (id) {
        yield put(actions.setStatus('loading_update_selected_plan'));
        try {
          const response: any = yield updatePlan(id, plan);

          if (response?.data) {
            const { data: updatedPlan } = response;
            yield put(actions.setSelectedPlan(updatedPlan as PlanModel));
          }
          yield put(actions.recalculatePlan(id));
          yield put(actions.setStatus('success_update_selected_plan'));
          yield put(actions.setStatus('idle'));
        } catch (error: any) {
          yield put(actions.setStatus('error_update_selected_plan'));
          if (error?.response?.data) {
            yield put(actions.setError(error?.response?.data));
          }
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.ListPlans,
    function* ListPlansEffect(action: ActionWithPayload<{ userId: number }>) {
      const userId = action?.payload?.userId;
      yield put(actions.setError(undefined));
      yield put(actions.setStatus('loading_list_plans'));
      yield put(actions.setPlans([]));

      if (userId) {
        try {
          const { data } = yield listPlans(userId);

          yield put(actions.setPlans(data));
          yield put(actions.setStatus('success_list_plans'));
        } catch (e: any) {
          console.warn(e);
          yield put(actions.setStatus('error_list_plans'));

          if (e?.length > 0) {
            yield put(actions.setError(e[0].toString()));
          }
        }
      }
      yield delay(0);
      yield put(actions.setStatus('idle'));
    }
  );

  yield takeEvery(
    actionTypes.ListPetPlans,
    function* ListPetPlansEffect(action: ActionWithPayload<{ petId: number }>) {
      const petId = action?.payload?.petId;
      yield put(actions.setError(undefined));
      yield put(actions.setStatus('loading_list_pet_plans'));

      if (petId) {
        try {
          const { data } = yield listPetPlans(petId);

          yield put(actions.setPetPlans(data || []));
          yield put(actions.setStatus('success_list_pet_plans'));
        } catch (e: any) {
          console.warn(e);
          yield put(actions.setStatus('error_list_pet_plans'));
          if (e?.length > 0) {
            yield put(actions.setError(e[0].toString()));
          }
        }
      }
      yield delay(0);
      yield put(actions.setStatus('idle'));
    }
  );

  yield takeEvery(
    actionTypes.PlanRequested,
    function* PlanRequestedEffect(
      action: ActionWithPayload<{ planId: number }>
    ) {
      const planId = action?.payload?.planId;
      yield put(actions.setError(undefined));
      if (planId) {
        yield put(actions.setStatus('loading_selected_plan'));
        try {
          const response: any = yield getPlanById(planId);
          if (response?.data) {
            const { data: selectedPlan } = response;
            yield put(actions.setSelectedPlan(selectedPlan as PlanModel));
          }
          yield put(actions.setStatus('success_selected_plan'));
        } catch (error: any) {
          yield put(actions.setStatus('error_selected_plan'));
          if (error?.response?.data) {
            yield put(actions.setError(error?.response?.data));
          }
        } finally {
          yield delay(0);
          yield put(actions.setStatus('idle'));
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.CancelPlan,
    function* CancelPlanEffect(
      action: ActionWithPayload<{ planId: number; koReason: string }>
    ) {
      const { planId, koReason } = action.payload;

      if (planId) {
        yield put(actions.setStatus('loading_cancel_plan'));
        try {
          const { data: updatedPlan } = yield updatePlan(planId, {
            ko_reason: koReason,
          });
          yield put(actions.setSelectedPlan(updatedPlan as PlanModel));

          yield cancelPlan(planId);
          yield put(
            actions.setPlans(
              yield select((state: RootState) =>
                state.plan.plans.filter(
                  (plan: PlanListingModel) => plan.id !== planId
                )
              )
            )
          );
          yield put(
            actions.setPetPlans(
              yield select((state: RootState) =>
                state.plan.petPlans.filter(
                  (plan: PlanListingModel) => plan.id !== planId
                )
              )
            )
          );
          yield put(actions.setStatus('success_cancel_plan'));
        } catch (e: any) {
          console.warn(e);
          if (e?.length > 0) {
            yield put(actions.setError(e[0].toString()));
          }
          yield put(actions.setStatus('error_cancel_plan'));
        } finally {
          yield delay(0);
          yield put(actions.setStatus('idle'));
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.PausePlan,
    function* PausePlanEffect(
      action: ActionWithPayload<{ planId: number; koReason: string }>
    ) {
      const { planId, koReason } = action.payload;

      if (planId) {
        yield put(actions.setStatus('loading_pause_plan'));
        try {
          const { data: updatedPlan } = yield updatePlan(planId, {
            ko_reason: koReason,
          });
          yield put(actions.setSelectedPlan(updatedPlan as PlanModel));

          const { data: pausedPlan } = yield pausePlan(planId);
          yield put(actions.setSelectedPlan(pausedPlan as PlanModel));
          yield put(actions.setStatus('success_pause_plan'));
        } catch (e: any) {
          console.warn(e);
          if (e?.length > 0) {
            yield put(actions.setError(e[0].toString()));
          }
          console.warn(e);
          const errorData = e?.response?.data;

          if (Object.keys(errorData).length > 0) {
            toast.error(errorData[Object.keys(errorData)[0]]);
          }
          yield put(actions.setStatus('error_pause_plan'));
        } finally {
          yield delay(0);
          yield put(actions.setStatus('idle'));
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.ResumePlan,
    function* ResumePlanEffect(action: ActionWithPayload<{ planId: number }>) {
      const planId = action.payload?.planId;

      if (planId) {
        yield put(actions.setStatus('loading_resume_plan'));
        try {
          const { data: resumedPlan } = yield resumePlan(planId);
          yield put(actions.setSelectedPlan(resumedPlan as PlanModel));
          yield put(actions.setStatus('success_resume_plan'));
        } catch (e: any) {
          console.warn(e);
          if (e?.length > 0) {
            yield put(actions.setError(e[0].toString()));
          }
          yield put(actions.setStatus('error_resume_plan'));
        } finally {
          yield delay(0);
          yield put(actions.setStatus('idle'));
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.ConfirmPlan,
    function* ConfirmPlanEffect(action: ActionWithPayload<{ planId: number }>) {
      const planId = action.payload?.planId;

      if (planId) {
        yield put(actions.setStatus('loading_confirm_plan'));
        try {
          yield refreshPromiseDate(planId);
          const { data: confirmedPlan } = yield confirmPlan(planId);
          yield put(actions.setSelectedPlan(confirmedPlan as PlanModel));
          yield put(actions.setStatus('success_confirm_plan'));
          yield put(actions.requestPlan(confirmedPlan.id));
        } catch (e: any) {
          console.warn(e);
          const errorData = e?.response?.data;

          if (Object.keys(errorData).length > 0) {
            toast.error(errorData[Object.keys(errorData)[0]]);
          }
          yield put(actions.setStatus('error_confirm_plan'));
        } finally {
          yield delay(0);
          yield put(actions.setStatus('idle'));
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.DiscardPlan,
    function* DiscardPlanEffect(
      action: ActionWithPayload<{ planId: number; koReason: string }>
    ) {
      const { planId, koReason } = action.payload;

      if (planId) {
        yield put(actions.setStatus('loading_discard_plan'));
        try {
          const { data: updatedPlan } = yield updatePlan(planId, {
            ko_reason: koReason,
          });
          yield put(actions.setSelectedPlan(updatedPlan as PlanModel));

          const { data: discardedPlan } = yield discardPlan(planId);
          yield put(actions.setSelectedPlan(discardedPlan as PlanModel));
          yield put(actions.setStatus('success_discard_plan'));
        } catch (e: any) {
          console.warn(e);
          const errorData = e?.response?.data;

          if (Object.keys(errorData).length > 0) {
            toast.error(errorData[Object.keys(errorData)[0]]);
          }
          yield put(actions.setStatus('error_discard_plan'));
        } finally {
          yield delay(0);
          yield put(actions.setStatus('idle'));
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.DetainPlan,
    function* DetainPlanEffect(
      action: ActionWithPayload<{ planId: number; koReason: string }>
    ) {
      const { planId, koReason } = action.payload;

      if (planId) {
        yield put(actions.setStatus('loading_detain_plan'));
        try {
          const { data: updatedPlan } = yield updatePlan(planId, {
            ko_reason: koReason,
          });
          yield put(actions.setSelectedPlan(updatedPlan as PlanModel));

          const { data: detaindPlan } = yield detainPlan(planId);
          yield put(actions.setSelectedPlan(detaindPlan as PlanModel));
          yield put(actions.setStatus('success_detain_plan'));
          yield put(actions.requestPlan(detaindPlan.id));
        } catch (e: any) {
          console.warn(e);
          const errorData = e?.response?.data;

          if (Object.keys(errorData).length > 0) {
            toast.error(errorData[Object.keys(errorData)[0]]);
          }
          yield put(actions.setStatus('error_detain_plan'));
        } finally {
          yield delay(0);
          yield put(actions.setStatus('idle'));
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.ReproposePlan,
    function* ReproposePlanEffect(
      action: ActionWithPayload<{ planId: number }>
    ) {
      const planId = action.payload?.planId;

      if (planId) {
        yield put(actions.setStatus('loading_repropose_plan'));
        try {
          const { data: reproposedPlan } = yield reproposePlan(planId);
          yield put(actions.setSelectedPlan(reproposedPlan as PlanModel));
          yield put(actions.setStatus('success_repropose_plan'));
          yield put(actions.requestPlan(reproposedPlan.id));
        } catch (e: any) {
          console.warn(e);
          const errorData = e?.response?.data;

          if (Object.keys(errorData).length > 0) {
            toast.error(errorData[Object.keys(errorData)[0]]);
          }
          yield put(actions.setStatus('error_repropose_plan'));
        } finally {
          yield delay(0);
          yield put(actions.setStatus('idle'));
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.RecalculatePlan,
    function* RecalculatePlanEffect(
      action: ActionWithPayload<{ planId: number }>
    ) {
      const planId = action.payload?.planId;

      if (planId) {
        yield put(actions.setStatus('loading_recalculate_plan'));
        try {
          const { data: recalculatedPlan } = yield recalculatePlan(planId);
          yield put(actions.setSelectedPlan(recalculatedPlan as PlanModel));
          yield put(actions.setStatus('success_recalculate_plan'));
          yield put(actions.requestPlan(recalculatedPlan.id));
          yield put(orderActions.listPlanOrders(recalculatedPlan.id, 1));
        } catch (e: any) {
          console.warn(e);
          const errorData = e?.response?.data;

          if (Object.keys(errorData).length > 0) {
            toast.error(errorData[Object.keys(errorData)[0]]);
          }
          yield put(actions.setStatus('error_recalculate_plan'));
        } finally {
          yield delay(0);
          yield put(actions.setStatus('idle'));
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.GenerateOrders,
    function* GenerateOrdersEffect(
      action: ActionWithPayload<{ planId: number }>
    ) {
      const planId = action.payload?.planId;

      if (planId) {
        yield put(actions.setStatus('loading_generate_orders'));
        try {
          const { data: refreshedPlan } = yield generateOrders(planId);
          yield put(actions.setSelectedPlan(refreshedPlan as PlanModel));
          yield put(actions.setStatus('success_generate_orders'));
          yield put(actions.requestPlan(refreshedPlan.id));
        } catch (e: any) {
          console.warn(e);
          const errorData = e?.response?.data;

          if (Object.keys(errorData).length > 0) {
            toast.error(errorData[Object.keys(errorData)[0]]);
          }
          yield put(actions.setStatus('error_generate_orders'));
        } finally {
          yield delay(0);
          yield put(actions.setStatus('idle'));
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.RefreshPrices,
    function* RefreshPricesEffect(
      action: ActionWithPayload<{ planId: number }>
    ) {
      const planId = action.payload?.planId;

      if (planId) {
        yield put(actions.setStatus('loading_refresh_plan'));
        try {
          const { data: refreshedPlan } = yield refreshPrices(planId);
          yield put(actions.setSelectedPlan(refreshedPlan as PlanModel));
          yield put(actions.setStatus('success_refresh_plan'));
          yield put(actions.requestPlan(refreshedPlan.id));
        } catch (e: any) {
          console.warn(e);
          const errorData = e?.response?.data;

          if (Object.keys(errorData).length > 0) {
            toast.error(errorData[Object.keys(errorData)[0]]);
          }
          yield put(actions.setStatus('error_refresh_plan'));
        } finally {
          yield delay(0);
          yield put(actions.setStatus('idle'));
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.ReactivatePlan,
    function* ReactivatePlanEffect(
      action: ActionWithPayload<{ planId: number }>
    ) {
      const planId = action.payload?.planId;

      if (planId) {
        yield put(actions.setStatus('loading_reactivate_plan'));
        try {
          const { data: reactivatingPlan } = yield reactivatePlan(planId);
          yield put(actions.setSelectedPlan(reactivatingPlan as PlanModel));
          yield put(actions.setStatus('success_reactivate_plan'));
          yield put(actions.requestPlan(reactivatingPlan.id));
        } catch (e: any) {
          console.warn(e);
          const errorData = e?.response?.data;

          if (Object.keys(errorData).length > 0) {
            toast.error(errorData[Object.keys(errorData)[0]]);
          }
          yield put(actions.setStatus('error_reactivate_plan'));
        } finally {
          yield delay(0);
          yield put(actions.setStatus('idle'));
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.AddCustomMeal,
    function* AddCustomMealEffect(
      action: ActionWithPayload<{
        planId: number;
        mealId: number;
        sizeId: number;
        units: number;
      }>
    ) {
      const { planId, mealId, sizeId, units } = action.payload;

      if (planId) {
        yield put(actions.setStatus('loading_create_custom_meal'));
        try {
          const { data: updatedPlan } = yield createCustomMealService(
            planId,
            mealId,
            sizeId,
            units
          );
          yield put(actions.setSelectedPlan(updatedPlan as PlanModel));
          yield put(actions.setStatus('success_create_custom_meal'));
          yield put(actions.requestPlan(updatedPlan.id));
        } catch (e: any) {
          console.warn(e);
          yield put(actions.setStatus('error_create_custom_meal'));
        } finally {
          yield delay(0);
          yield put(actions.setStatus('idle'));
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.UpdateCustomMeal,
    function* UpdateCustomMealEffect(
      action: ActionWithPayload<{
        planId: number;
        customMealId: number;
        mealId: number;
        sizeId: number;
        units: number;
      }>
    ) {
      const { planId, customMealId, mealId, sizeId, units } = action.payload;

      if (planId) {
        yield put(actions.setStatus('loading_update_custom_meal'));
        try {
          const { data: updatedPlan } = yield updateCustomMealService(
            planId,
            mealId,
            customMealId,
            sizeId,
            units
          );
          yield put(actions.setSelectedPlan(updatedPlan as PlanModel));
          yield put(actions.setStatus('success_update_custom_meal'));
          yield put(actions.requestPlan(updatedPlan.id));
        } catch (e: any) {
          console.warn(e);
          yield put(actions.setStatus('error_update_custom_meal'));
        } finally {
          yield delay(0);
          yield put(actions.setStatus('idle'));
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.DeleteCustomMeal,
    function* DeleteCustomMealEffect(
      action: ActionWithPayload<{ planId: number; customMealId: number }>
    ) {
      const { planId, customMealId } = action.payload;

      if (planId) {
        yield put(actions.setStatus('loading_delete_custom_meal'));
        try {
          const { data: updatedPlan } = yield deleteCustomMealService(
            planId,
            customMealId
          );
          yield put(actions.setSelectedPlan(updatedPlan as PlanModel));
          yield put(actions.setStatus('success_delete_custom_meal'));
          yield put(actions.requestPlan(updatedPlan.id));
        } catch (e: any) {
          const errorData = e?.response?.data;

          if (Object.keys(errorData).length > 0) {
            if (typeof errorData === 'string') {
              toast.error(errorData.split('.')[0]);
            } else if (errorData[Object.keys(errorData)[0]]?.length > 0) {
              toast.error(errorData[Object.keys(errorData)[0]][0]);
            } else {
              toast.error(errorData[Object.keys(errorData)[0]]);
            }
          }
          yield put(actions.setStatus('error_delete_custom_meal'));
        } finally {
          yield delay(0);
          yield put(actions.setStatus('idle'));
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.AddDiscount,
    function* AddDiscountEffect(
      action: ActionWithPayload<{ discountId: number; expiresAt?: string }>
    ) {
      const plan = yield select(selectedPlanSelector);
      const planId = plan?.id;
      const discountId = action.payload?.discountId;
      const expiresAt = action.payload?.expiresAt;

      yield put(actions.setError(undefined));
      if (planId) {
        yield put(actions.setStatus('loading_add_discount'));
        try {
          const response: any = yield addDiscount(
            planId,
            discountId,
            expiresAt
          );
          if (response?.data) {
            const { data: updatedPlan } = response;
            yield put(actions.setSelectedPlan(updatedPlan as PlanModel));
            yield put(actions.requestPlan(updatedPlan.id));
          }
          yield put(actions.setStatus('success_add_discount'));
          yield put(actions.setStatus('idle'));
        } catch (error: any) {
          yield put(actions.setStatus('error_add_discount'));
          if (error?.response?.data) {
            yield put(actions.setError(error?.response?.data));
          }
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.RemoveDiscount,
    function* RemoveDiscountEffect(
      action: ActionWithPayload<{ discountId: number }>
    ) {
      const plan = yield select(selectedPlanSelector);
      const planId = plan?.id;
      const discountId = action.payload?.discountId;

      yield put(actions.setError(undefined));
      if (planId) {
        yield put(actions.setStatus('loading_remove_discount'));
        try {
          const response: any = yield removeDiscount(planId, discountId);
          if (response?.data) {
            const { data: updatedPlan } = response;
            yield put(actions.setSelectedPlan(updatedPlan as PlanModel));
            yield put(actions.requestPlan(updatedPlan.id));
          }
          yield put(actions.setStatus('success_remove_discount'));
          yield put(actions.setStatus('idle'));
        } catch (error: any) {
          yield put(actions.setStatus('error_remove_discount'));
          if (error?.response?.data) {
            yield put(actions.setError(error?.response?.data));
          }
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.SetExtraProducts,
    function* SetExtraProductsEffect(
      action: ActionWithPayload<{
        planId: number;
        extraProducts: Array<{ product_variant: number; units: number }>;
      }>
    ) {
      const plan = yield select(selectedPlanSelector);
      const planId = plan?.id;
      const extraProducts = action.payload?.extraProducts;

      yield put(actions.setError(undefined));
      if (planId) {
        yield put(
          actions.setStatus('loading_set_product_units_in_selected_plan')
        );
        try {
          const response: any = yield editExtraProducts({
            planId,
            extraProducts,
          });

          if (response?.data) {
            const { data: updatedPlan } = response;
            yield put(actions.setSelectedPlan(updatedPlan as PlanModel));
            yield put(actions.requestPlan(updatedPlan.id));
          }
          yield put(
            actions.setStatus('success_set_product_units_in_selected_plan')
          );
          yield put(actions.setStatus('idle'));
        } catch (error: any) {
          yield put(
            actions.setStatus('error_set_product_units_in_selected_plan')
          );
          if (error?.response?.data) {
            yield put(actions.setError(error?.response?.data));
          }
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.RemoveProductFromSelectedPlan,
    function* RemoveProductFromSelectedPlanEffect(
      action: ActionWithPayload<{ productVariantId: number }>
    ) {
      const plan = yield select(selectedPlanSelector);
      const planId = plan?.id;
      const productVariantId = action.payload?.productVariantId;

      yield put(actions.setError(undefined));
      if (planId) {
        yield put(
          actions.setStatus('loading_remove_product_from_selected_plan')
        );
        try {
          const response: any = yield removeProductFromPlan({
            planId,
            productVariantId,
          });

          if (response?.data) {
            const { data: updatedPlan } = response;
            yield put(actions.setSelectedPlan(updatedPlan as PlanModel));
            yield put(actions.requestPlan(updatedPlan.id));
          }
          yield put(
            actions.setStatus('success_remove_product_from_selected_plan')
          );
          yield put(actions.setStatus('idle'));
        } catch (error: any) {
          yield put(
            actions.setStatus('error_remove_product_from_selected_plan')
          );
          if (error?.response?.data) {
            yield put(actions.setError(error?.response?.data));
          }
        }
      }
    }
  );
}

import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { staffUsersSelector } from '../../tasks';

export default function DeliveryChanges({
  loading = false,
  deliveryChanges = [],
}) {
  const intl = useIntl();
  const staffUsers = useSelector(staffUsersSelector);

  return (
    <>
      <h1 className="mb-4">
        {intl.formatMessage({ id: 'comments.deliveryChanges' })}
      </h1>
      {loading && <div className="spinner-border text-primary" role="status" />}
      {!loading && deliveryChanges.length === 0 && (
        <p className="text-muted mb-0">
          {intl.formatMessage({ id: 'comments.noDeliveryChanges' })}
        </p>
      )}
      {!loading && deliveryChanges.length > 0 && (
        <section className="timeline">
          {deliveryChanges?.map((deliveryChange) => {
            return (
              <div className="timeline-item" key={deliveryChange.id}>
                <div className="timeline-line w-40px"></div>
                <div className="timeline-icon symbol symbol-circle symbol-40px me-4">
                  <div className="symbol-label bg-light">
                    <span className="svg-icon svg-icon-2 svg-icon-gray-500">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.3"
                          d="M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </span>
                  </div>
                </div>
                <div className="timeline-content mb-1 mt-n1">
                  <div className="pe-3 mt-2 mb-2 fw-bolder text-primary">
                    {
                      staffUsers?.find(
                        (user) => user.id === deliveryChange.agent
                      )?.name
                    }{' '}
                    ·{' '}
                    {new Date(deliveryChange.created_at).toLocaleString(
                      'es-ES',
                      {
                        day: '2-digit',
                        month: '2-digit',
                        year: '2-digit',
                      }
                    )}
                  </div>
                  <div className="fw-bold mb-2 align-items-center d-flex">
                    {deliveryChange.orders
                      ?.map((order) => order.code)
                      .join(', ')}
                    <span className="badge badge-primary ms-2">
                      {deliveryChange.reason}
                    </span>
                  </div>
                  {deliveryChange.observations ? (
                    <div className="bg-light-primary rounded border-primary border border-dashed p-4 mb-2">
                      {deliveryChange.observations}
                    </div>
                  ) : (
                    <div className="rounded border border-dashed p-4 mb-2 border-muted text-muted">
                      {intl.formatMessage({ id: 'comments.noObservations' })}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </section>
      )}
    </>
  );
}

export default function getMultiSelectStrings(intl) {
  return {
    allItemsAreSelected: intl.formatMessage({ id: 'tasks.allOptionsSelected' }),
    clearSearch: intl.formatMessage({ id: 'tasks.clearSearch' }),
    clearSelected: intl.formatMessage({ id: 'tasks.clearSelected' }),
    noOptions: intl.formatMessage({ id: 'tasks.noOptions' }),
    search: intl.formatMessage({ id: 'tasks.search' }),
    selectAll: intl.formatMessage({ id: 'tasks.selectAll' }),
    selectAllFiltered: intl.formatMessage({ id: 'tasks.selectAllFiltered' }),
    selectSomeItems: intl.formatMessage({ id: 'tasks.selectSomeItems' }),
    create: intl.formatMessage({ id: 'tasks.create' }),
  };
}

import axios from 'axios';
import { DistributorModel } from '../models/DistributorModel';

const API_URL = process.env.REACT_APP_API_URL;
const DISTRIBUTORS_URL = `${API_URL}/api/b2b/distributor/`;

export function listDistributors(
  state
): Promise<{ data: Array<DistributorModel> }> {
  return axios.get(`${DISTRIBUTORS_URL}?state=${state}`);
}

export function retrieveDistributor(
  id: number
): Promise<{ data: DistributorModel }> {
  return axios.get(`${DISTRIBUTORS_URL}${id}/`);
}

export function createDistributor(
  distributor: DistributorModel
): Promise<{ data: DistributorModel }> {
  return axios.post(DISTRIBUTORS_URL, distributor);
}

export function updateDistributor(
  distributor: DistributorModel
): Promise<{ data: DistributorModel }> {
  let distributorPayload = { ...distributor };

  Object.keys(distributorPayload).forEach(
    (key) => distributorPayload[key] === null && delete distributorPayload[key]
  );
  return axios.patch(
    `${DISTRIBUTORS_URL}${distributor.id}/`,
    distributorPayload
  );
}

interface AddFileParams {
  distributorId: number;
  file: File;
}

interface AddFileResponse {
  url: string;
  name: string;
}
export function addFile({
  distributorId,
  file,
}: AddFileParams): Promise<{ data: AddFileResponse }> {
  const formData = new FormData();
  formData.append('file', file);

  return axios.post(`${DISTRIBUTORS_URL}${distributorId}/add-file/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function getAllDistributorFiles() {
  const endpoint = `${DISTRIBUTORS_URL}get-all-distrubutors-files/`;
  return axios.get(endpoint);
}

import { CreditNoteResquestModel } from '../models/CreditNoteResquestModel';
import axios from 'axios';
import { CreditNoteModel } from '../models/CreditNoteModel';

const API_URL = process.env.REACT_APP_API_URL;
const CREDIT_NOTES_URL = `${API_URL}/api/b2b/invoices/credit-note/`;
export const CREDIT_NOTES_PER_PAGE = 20;

export function createCreditNote(
  creditNoteResquestModel: CreditNoteResquestModel
): Promise<{ data: CreditNoteModel }> {
  return axios.post(CREDIT_NOTES_URL, {
    ...creditNoteResquestModel,
  });
}

import { ShippingAddressModel } from '../../user-profile/models/ShippingAddressModel';
import { ShopifyOrderLine } from './ShopifyOrderLine';

export enum ShopifyOrderStateEnum {
  FAILED = 'failed',
  LOCKED = 'locked',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
}

export interface ShopifyOrder {
  id?: number;
  reference?: string;
  internal_reference?: string;
  email?: string;
  created_at?: string;
  updated_at?: string;
  promise_date?: string;
  fulfillment_status?: string;
  shipping_address?: ShippingAddressModel;
  state: ShopifyOrderStateEnum;
  lines: ShopifyOrderLine[];
  tracking_link?: string;
}

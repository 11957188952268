import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  sendMassiveWhatsapp,
  listTemplates,
  SendMassiveWhatsappRequest,
} from '../services/WhatsappTemplatesCRUD';
import { toast } from 'react-toastify';

enum State {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
}
const DEFAULT_REQUEST: SendMassiveWhatsappRequest = {
  file: null,
  code: '',
};

export default function MassiveWhatsappsForm() {
  const intl = useIntl();
  const [state, setState] = useState<State>(State.IDLE);
  const [request, setRequest] =
    useState<SendMassiveWhatsappRequest>(DEFAULT_REQUEST);
  const [templates, setTemplates] = useState([]);

  async function fetchTemplates() {
    try {
      const { data: templates } = await listTemplates();
      setTemplates(templates);
    } catch {
      toast.error(
        intl.formatMessage({ id: 'whatsappTemplates.errorFetchingTemplates' })
      );
    }
  }

  useEffect(() => {
    fetchTemplates();
  }, []);

  async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setState(State.LOADING);
    try {
      await sendMassiveWhatsapp(request);
      setRequest(DEFAULT_REQUEST);
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    } finally {
      setState(State.IDLE);
    }
  }

  useEffect(() => {
    if (templates.length > 0 && !request.code) {
      setRequest({ ...request, code: templates[0].code });
    }
  }, [templates]);

  return (
    <div className="card p-10">
      <h1 className="mb-8">
        {intl.formatMessage({ id: 'whatsappTemplates.submitMassiveWhatsapps' })}
      </h1>
      <form className="d-flex flex-column gap-4" onSubmit={onSubmit}>
        <div className="form-group">
          <label className="form-label" htmlFor="code">
            {intl.formatMessage({ id: 'whatsappTemplates.code' })}
          </label>
          <select
            className="form-select"
            id="code"
            required
            onChange={(e) => setRequest({ ...request, code: e.target.value })}
            value={request.code}
          >
            {templates?.map(({ code, name }) => (
              <option key={code} value={code}>
                {name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="file">
            {intl.formatMessage({ id: 'whatsappTemplates.file' })}
          </label>
          <input
            required
            className="form-control"
            id="file"
            type="file"
            placeholder={intl.formatMessage({ id: 'whatsappTemplates.file' })}
            onChange={(e) =>
              setRequest({ ...request, file: e.target.files[0] as File })
            }
          />
        </div>
        <button
          className="btn btn-primary mt-4 ms-auto"
          type="submit"
          disabled={state === State.LOADING || !request.file || !request.code}
        >
          {state === State.LOADING && (
            <span className="spinner-border spinner-border-sm me-1"></span>
          )}
          {intl.formatMessage({ id: 'whatsappTemplates.submitMassive' })}
        </button>
      </form>
    </div>
  );
}

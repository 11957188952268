import moment from 'moment';
import axios from 'axios';

import { EventModel } from '../models/EventModel';
import { EventProfileModel } from '../models/EventProfileModel';
import { MediumModel } from '../models/MediumModel';
import { TaskCommentModel } from '../models/TaskCommentModel';
import { TaskModel } from '../models/TaskModel';
import { TaskGroupModel } from '../models/TaskGroupModel';
import { TaskListModel } from '../models/TaskListModel';
import { TaskSetResolutionModel } from '../models/TaskSetResolutionModel';
import { TaskUserModel } from '../models/TaskUserModel';
import { PendingsModel } from '../models/PendingsModel';
import { TaskProfileModel } from '../models/TaskProfileModel';
import { TaskResolutionModel } from '../models/TaskResolutionModel';

const API_URL = process.env.REACT_APP_API_URL;
export const TASKS_URL = `${API_URL}/api/tasks/`;

export function listGroups(): Promise<{ data: Array<TaskGroupModel> }> {
  const GROUPS_URL = `${API_URL}/api/users/groups/`;

  return axios({
    method: 'get',
    url: GROUPS_URL,
  });
}

export function retrieveStaffUsers(
  groupId?: number
): Promise<{ data: Array<TaskUserModel> }> {
  const STAFF_USERS_URL = `${API_URL}/api/users/staff-users/`;
  let params = {};

  if (groupId !== undefined) {
    params = {
      group_id: groupId,
    };
  }

  return axios({
    method: 'get',
    url: STAFF_USERS_URL,
    params,
  });
}

export function listPendings(ownerId): Promise<{ data: Array<PendingsModel> }> {
  if (ownerId) {
    return axios.get(`${TASKS_URL}workload/`, {
      params: {
        owner_id: ownerId,
      },
    });
  }
  return axios.get(`${TASKS_URL}workload/`);
}

export function listTaskCounts(): Promise<{ data: Array<PendingsModel> }> {
  return axios.get(`${TASKS_URL}pendings-agent/`);
}

export function listClientTasks({
  id,
  limit = 20,
  offset = 0,
}): Promise<{ data: TaskListModel }> {
  return axios.get(`${TASKS_URL}`, {
    params: {
      user_id: id,
      limit,
      offset,
    },
  });
}

type PendingAgentListProps = {
  agentId?: number;
  limit?: number;
  offset?: number;
  state?: string;
  taskProfile?: number;
  taskResolution?: number;
  until?: string;
  since?: string;
  current_attempt_number?: number;
  order?: '-created_at' | 'created_at';
};

export function listPendingAgentTasks({
  agentId,
  limit = 20,
  offset = 0,
  state = 'pending,dispatched,sent,dispatched-alternatively',
  taskProfile = null,
  taskResolution = null,
  since = null,
  until = null,
  current_attempt_number = null,
  order,
}: PendingAgentListProps): Promise<{ data: TaskListModel }> {
  return axios.get(`${TASKS_URL}`, {
    params: {
      limit,
      offset,
      owner_id: agentId ? agentId : null,
      state,
      task_profile: taskProfile,
      task_resolution: taskResolution,
      since_data: since,
      until_data: until,
      current_attempt_number,
      order,
    },
  });
}

type DownloadPendingAgentTaskReportProps = {
  agentId?: number;
  state?: string;
  taskProfile?: number;
  taskResolution?: number;
  until?: string;
  since?: string;
  current_attempt_number?: number;
  order?: '-created_at' | 'created_at';
};
export function downloadPendingAgentTaskReport({
  state = 'pending,dispatched,sent,dispatched-alternatively',
  taskProfile = null,
  taskResolution = null,
  since = null,
  until = null,
  current_attempt_number = null,
  order,
  agentId,
}: DownloadPendingAgentTaskReportProps): Promise<{ data: any }> {
  return axios.get(`${TASKS_URL}download-report/`, {
    params: {
      state,
      task_profile: taskProfile,
      task_resolution: taskResolution,
      since_data: since,
      until_data: until,
      current_attempt_number,
      order,
      owner_id: agentId,
    },
  });
}

type BagPaginatedListProps = {
  limit?: number;
  offset?: number;
  task_profile?: number;
  task_resolution?: number;
};

export function listBagTasks({
  limit = 20,
  offset = 0,
  task_profile = null,
  task_resolution = null,
}: BagPaginatedListProps): Promise<{ data: TaskListModel }> {
  return axios.get(`${TASKS_URL}bag/`, {
    params: {
      limit,
      offset,
      task_profile,
      task_resolution,
    },
  });
}

export function listAlarmTasks({
  limit = 20,
  offset = 0,
}): Promise<{ data: TaskListModel }> {
  return axios.get(`${TASKS_URL}alarms/`, {
    params: {
      limit,
      offset,
    },
  });
}

type ListTasksProps = {
  ownerId?: number;
  taskProfile?: number;
  taskResolution?: number;
  next?: string;
  limit?: number;
  until?: string;
  since?: string;
};

export function listTasks({
  ownerId,
  taskProfile = null,
  taskResolution = null,
  next,
  limit = 50,
  until = moment().add(6, 'days').format('YYYY-MM-DD'),
  since = moment().subtract(1, 'days').format('YYYY-MM-DD'),
}: ListTasksProps): Promise<{ data: TaskListModel }> {
  return axios.get(`${next || TASKS_URL}`, {
    params: next
      ? {}
      : {
          owner_id: ownerId,
          task_profile: taskProfile,
          task_resolution: taskResolution,
          limit,
          until_data: until,
          since_data: since,
        },
  });
}

export function retrieveTask(taskId: number): Promise<{ data: TaskModel }> {
  return axios.get(`${TASKS_URL}${taskId}/`);
}

export function retrieveMediums(): Promise<{ data: Array<MediumModel> }> {
  return axios({
    method: 'get',
    url: `${TASKS_URL}mediums/`,
  });
}

export function retrieveEventProfiles(): Promise<{
  data: Array<EventProfileModel>;
}> {
  return axios({
    method: 'get',
    url: `${TASKS_URL}event-profiles/`,
  });
}

export function listTaskProfiles(): Promise<{ data: Array<TaskProfileModel> }> {
  return axios({
    method: 'get',
    url: `${TASKS_URL}profiles/`,
  });
}

export function listTaskResolutions(): Promise<{
  data: Array<TaskResolutionModel>;
}> {
  return axios({
    method: 'get',
    url: `${TASKS_URL}resolutions/`,
  });
}

type DispatchTaskProps = {
  taskResolution?: number;
  taskProfile?: number;
};

export function dispatchTask({
  taskResolution = null,
  taskProfile = null,
}: DispatchTaskProps): Promise<{ data: TaskModel }> {
  return axios.patch(`${TASKS_URL}dispatch-task/`, {
    task_resolution: taskResolution,
    task_profile: taskProfile,
  });
}

export function solveTask(
  taskId: number,
  params: TaskSetResolutionModel
): Promise<{ data: any }> {
  return axios.patch(`${TASKS_URL}${taskId}/solve/`, params);
}

export function bulkSolveTasks(
  tasks: Array<number>,
  task_resolution: number,
  owner?: number,
  replaceAgent?: boolean
): Promise<{ data: any }> {
  return axios.patch(`${TASKS_URL}bulk-solve/`, {
    tasks,
    task_resolution,
    owner,
    replace_default_agent: replaceAgent,
  });
}

export function addComment(
  taskId: number,
  taskComment: TaskCommentModel
): Promise<{ data: any }> {
  return axios.post(`${TASKS_URL}${taskId}/comment/`, {
    comment: taskComment.comment,
  });
}

export function bulkAddComment(
  tasks: Array<number>,
  comment: string
): Promise<{ data: any }> {
  return axios.post(`${TASKS_URL}bulk-comment/`, { tasks, comment });
}

export function addEvent(event: EventModel): Promise<{ data: any }> {
  const params = {
    event_id: event.eventProfileId,
    reporter_user_id: event.reporterUserId,
    user_id: event.userId,
    medium_id: event.mediumId,
    pets: event.petIds,
    plans: event.planIds,
    sale_orders: event.saleOrderIds,
    dispatch_after: event?.dispatchAfter,
    owner_id: event?.ownerId,
    is_alarm: event?.isAlarm,
    replace_default_agent: event?.replaceDefaultAgent,
    phone: event?.phone,
    email: event?.email,
    additional_info: event?.additionalInfo || '',
  };

  if (event?.resolutionId) {
    params['resolution_id'] = event.resolutionId;
  }

  return axios.post(`${TASKS_URL}event/`, params);
}

export function launchAction(
  actionId: number,
  taskId: number,
  medium: string
): Promise<{ data: any }> {
  return axios.post(`${TASKS_URL}${actionId}/launch-action/`, {
    task_id: taskId,
    medium: medium,
  });
}

export function sendEmail(
  taskId: number,
  subject: string,
  message: string
): Promise<{ data: any }> {
  return axios.post(`${TASKS_URL}${taskId}/send-email/`, {
    message: message,
    subject: subject,
  });
}

export function updateTask(
  taskId: number,
  props: Partial<TaskModel>
): Promise<{ data: any }> {
  return axios.patch(`${TASKS_URL}${taskId}/`, props);
}

export function listWorkLogs(agentId: number, endDatetime: string) {
  return axios.get(`${TASKS_URL}worklog/`, {
    params: {
      agent_id: agentId,
      end_datetime: endDatetime,
    },
  });
}

export function listResolutions() {
  //return axios.get(`${TASKS_URL}resolutions/`)
  return axios({
    method: 'get',
    url: `${TASKS_URL}resolutions/`,
  });
}

import { FC } from 'react';
import { useIntl } from 'react-intl';

import { usePathProps } from '../../modules/user-profile/hooks/usePathProps';
import { PageTitle } from '../../../_metronic/layout/core';
import { PendingCustomerTable } from '../../modules/tasks';

const PendingCustomerComponent: FC = () => {
  return (
    <>
      <PendingCustomerTable />
    </>
  );
};

const PendingCustomerPage: FC = () => {
  const intl = useIntl();
  usePathProps();

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'pendingCustomer.title' })}
      </PageTitle>
      <PendingCustomerComponent />
    </>
  );
};

export { PendingCustomerPage };

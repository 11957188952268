import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { useTransition, animated, config } from '@react-spring/web';

import {
  groupsSelector,
  staffUsersSelector,
  TaskStatus,
} from '../redux/TaskRedux';
import Task, { solvableStates } from './Task';
import { TaskResolutionModal } from './TaskResolutionModal';
import { TaskModel } from '..';
import { TaskSetResolutionModel } from '../models/TaskSetResolutionModel';
import { toast } from 'react-toastify';
import { authUserSelector } from '../../auth';
import superUsers from '../constants/superUsers';

const SpecialDiv = animated.div as any;

type TaskManagerProps = {
  task: TaskModel;
  status: TaskStatus;
  error?: string;
  onAddComment: (TaskCommentModel) => void;
  onResolve: (
    TaskSetResolution: TaskSetResolutionModel,
    replaceAgent: boolean,
    isAlarm: boolean
  ) => void;
  disableTransition?: boolean;
  displayOwner?: boolean;
};

const TaskManager: FC<TaskManagerProps> = ({
  task,
  status = 'loading',
  error = undefined,
  onAddComment,
  onResolve,
  disableTransition = false,
  displayOwner = false,
}) => {
  const intl = useIntl();
  const [tab, setTab] = useState<number>(0);
  const [resolutionCode, setResolutionCode] = useState<string | null>(null);
  const staffUsers = useSelector(staffUsersSelector);
  const expertStaffUsers = useSelector(staffUsersSelector).filter((staffUser) =>
    staffUser?.cs_groups.find((aGroup) => aGroup.id === task?.group?.id)
  );
  const groups = useSelector(groupsSelector);
  const authUser = useSelector(authUserSelector);
  const resolutionCodes: Array<string> =
    task?.resolutions?.map((resolution) => resolution.code) || [];
  const transitions = useTransition(task, {
    key: task?.id,
    initial: { opacity: 1, transform: 'translateX(0)' },
    from: disableTransition
      ? { opacity: 1, transform: 'translateX(0)' }
      : { opacity: 0, transform: 'translateX(-100%)' },
    enter: { opacity: 1, transform: 'translateX(0)' },
    leave: disableTransition
      ? { opacity: 1, transform: 'translateX(0)' }
      : { opacity: 0, transform: 'translateX(100%)' },
    delay: disableTransition ? 0 : 1000,
    config: {
      ...config.default,
      mass: 0.1,
      tension: 120,
    },
  });

  useEffect(() => {
    if (task?.id) {
      setTab(0);
    }
  }, [task?.id]);

  useEffect(() => {
    switch (status) {
      case 'postponed': {
        setResolutionCode(null);
        toast.success(intl.formatMessage({ id: 'tasks.taskPostponed' }));
        break;
      }
      case 'solved': {
        setResolutionCode(null);
        toast.success(intl.formatMessage({ id: 'tasks.taskSolved' }));
        break;
      }
      case 'failed': {
        if (error) {
          toast.error(error);
        } else {
          toast.error(intl.formatMessage({ id: 'tasks.errorResolvingTask' }));
        }
        break;
      }
    }
  }, [status, error]);

  return (
    <>
      {solvableStates.includes(task?.state) &&
        (task?.owner?.id === authUser?.id ||
          superUsers.includes(authUser?.email) ||
          !!authUser.cs_groups.find(
            (aGroup) => aGroup.name === 'ATC - Subscription'
          )) && (
          <div className="card ps-4 pe-4 overflow-hidden mb-4">
            <div className="d-flex justify-content-center gap-5 align-items-center pt-5 pb-5">
              {resolutionCodes.indexOf('reassign-owner') > -1 && (
                <button
                  title={intl.formatMessage({ id: 'tasks.reassignOwner' })}
                  className="btn btn-sm ps-4 d-inline-flex justify-content-start align-items-center"
                  style={{ background: 'rgba(181, 214, 231, 0.26)' }}
                  onClick={() => setResolutionCode('reassign-owner')}
                >
                  <img
                    className="ps-0 pe-0 ms-0 w-35px h-35px"
                    alt="Reassign Owner"
                    src="/media/icons/tasks/reassign-owner.svg"
                  />
                  <span className="sr-only text-start w-80 text-primary">
                    {intl.formatMessage({ id: 'tasks.reassignOwner' })}
                  </span>
                </button>
              )}
              {resolutionCodes.indexOf('reassign-group') > -1 && (
                <button
                  title={intl.formatMessage({ id: 'tasks.reassignGroup' })}
                  className="btn btn-sm ps-4 d-inline-flex justify-content-start align-items-center"
                  style={{ background: 'rgba(181, 214, 231, 0.26)' }}
                  onClick={() => setResolutionCode('reassign-group')}
                >
                  <img
                    className="ps-0 pe-0 ms-0 w-35px h-35px"
                    alt="Reassign Group"
                    src="/media/icons/tasks/reassign-group.svg"
                  />
                  <span className="sr-only text-start w-80 text-primary">
                    {intl.formatMessage({ id: 'tasks.reassignGroup' })}
                  </span>
                </button>
              )}
              {resolutionCodes.indexOf('reassign-owner-and-group') > -1 && (
                <button
                  title={intl.formatMessage({
                    id: 'tasks.reassignOwnerAndGroup',
                  })}
                  className="btn btn-sm ps-4 d-inline-flex justify-content-start align-items-center"
                  style={{ background: 'rgba(181, 214, 231, 0.26)' }}
                  onClick={() => setResolutionCode('reassign-owner-and-group')}
                >
                  <img
                    className="ps-0 pe-0 ms-0 w-35px h-35px"
                    alt="Reassign Group"
                    src="/media/icons/tasks/reassign-owner-and-group.svg"
                  />
                  <span className="sr-only text-start w-80 text-primary">
                    {intl.formatMessage({ id: 'tasks.reassignOwnerAndGroup' })}
                  </span>
                </button>
              )}
              {resolutionCodes.indexOf('postpone') > -1 && (
                <button
                  title={intl.formatMessage({ id: 'tasks.postpone' })}
                  className="btn btn-sm ps-4 d-inline-flex justify-content-start align-items-center"
                  style={{ background: 'rgba(181, 214, 231, 0.26)' }}
                  onClick={() => setResolutionCode('postpone')}
                >
                  <img
                    className="ps-0 pe-0 ms-0 me-0 w-35px h-35px"
                    alt="Postpone"
                    src="/media/icons/tasks/postpone.svg"
                  />
                  <span className="sr-only text-start w-80 text-primary">
                    {intl.formatMessage({ id: 'tasks.postpone' })}
                  </span>
                </button>
              )}
            </div>
          </div>
        )}
      <div className="">
        <div className="timeline-item position-relative overflow-x-hidden">
          <div className="timeline-line w-40px"></div>
          <div className="timeline-content mt-n1 ">
            <div
              className="card p-4 mb-4 menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold align-items-stretch justify-content-center"
              id="#kt_header_menu"
              data-kt-menu="true"
            >
              <div
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-start"
                className={clsx('menu-item menu-lg-down-accordion me-lg-1', {
                  'hover showing': tab === 0,
                })}
                onClick={() => setTab(0)}
              >
                <span className="menu-link py-3">
                  <span className="menu-title">
                    {intl.formatMessage({ id: 'tasks.information' })}
                  </span>
                </span>
              </div>
              <div
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-start"
                className={clsx('menu-item menu-lg-down-accordion me-lg-1', {
                  'hover showing': tab === 1,
                })}
                onClick={() => setTab(1)}
              >
                <span className="menu-link py-3">
                  <span className="menu-title">
                    {intl.formatMessage({ id: 'tasks.historic' })}
                  </span>
                </span>
              </div>
            </div>
            {transitions(
              (styles, renderingTask) =>
                renderingTask && (
                  <SpecialDiv
                    style={{ ...styles, position: 'absolute', width: '100%' }}
                  >
                    <Task
                      key={renderingTask?.id}
                      task={renderingTask}
                      tab={tab}
                      onAddComment={(comment) => onAddComment(comment)}
                      onResolve={onResolve}
                      displayOwner={displayOwner}
                    />
                  </SpecialDiv>
                )
            )}
          </div>
        </div>
      </div>
      <TaskResolutionModal
        onResolve={onResolve}
        onHide={() => setResolutionCode(null)}
        staffUsers={staffUsers}
        expertStaffUsers={expertStaffUsers}
        groups={groups}
        resolutions={task?.resolutions}
        resolutionCode={resolutionCode}
        show={!!resolutionCode}
      />
    </>
  );
};

export default TaskManager;

import { FC } from 'react';

import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from '../../../setup';
import { PageTitle } from '../../../_metronic/layout/core';
import {
  PlanDetail,
  PlanActions,
  PlanModel,
  MealPlanList,
  PlanOrders,
  MealSuggestionList,
  CustomMealList,
  PlanDiscounts,
} from '../../modules/plans';
import { usePathProps } from '../../modules/user-profile/hooks/usePathProps';
import ExtraProducts from '../../modules/plans/components/ExtraProducts';

const PlanDetailComponent: FC = () => {
  return (
    <>
      <div className="row">
        <div className="col-md-8">
          <div className="mb-4">
            <PlanDetail />
          </div>
          <div className="mb-4">
            <CustomMealList />
          </div>
          <div className="mb-4">
            <ExtraProducts />
          </div>
          <div className="mb-4">
            <MealPlanList />
          </div>
          <div className="mb-4">
            <PlanOrders />
          </div>
          <div className="mb-4">
            <MealSuggestionList />
          </div>
          <div className="mb-4">
            <PlanDiscounts />
          </div>
        </div>
        <div className="col-md-4">
          <div className="position-fixed m-0 p-0">
            <PlanActions />
          </div>
        </div>
      </div>
    </>
  );
};

const PlanDetailPage: FC = () => {
  const intl = useIntl();
  usePathProps();
  const selectedPlan: PlanModel | undefined = useSelector(
    (state: RootState) => state.plan.selectedPlan
  );

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage(
          { id: 'planDetail.title' },
          { code: selectedPlan?.code }
        )}
      </PageTitle>
      <PlanDetailComponent />
    </>
  );
};

export { PlanDetailPage };

import { useEffect, useRef, useState } from 'react';

import WaveSurfer from 'wavesurfer.js';

const formWaveSurferOptions = (ref) => ({
  container: ref,
  waveColor: '#009ef7',
  progressColor: '#009ef7',
  cursorColor: '#009ef7',
  barWidth: 4,
  barRadius: 4,
  responsive: true,
  height: 50,
  normalize: true,
  partialRender: true,
});

export default function Waveform({ url }) {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [playing, setPlay] = useState(false);
  const [volume, setVolume] = useState(1);

  useEffect(() => {
    try {
      setPlay(false);

      const options = formWaveSurferOptions(waveformRef.current);
      wavesurfer.current = WaveSurfer.create(options);
      wavesurfer.current.load(url);

      wavesurfer.current.on('ready', function () {
        if (wavesurfer.current) {
          wavesurfer.current.setVolume(volume);
          setVolume(volume);
        }
      });

      wavesurfer.current.on('finish', function () {
        setPlay(false);
      });
    } catch (error) {
      console.warn(error);
    }
    return () => wavesurfer.current.destroy();
  }, [url]);

  const handlePlayPause = () => {
    setPlay(!playing);
    wavesurfer.current.playPause();
  };

  return (
    <div className="d-flex gap-8 w-100 align-items-center">
      <div className="w-100 py-4 bg-light-primary rounded cursor-pointer">
        <div id="waveform" ref={waveformRef} />
      </div>
      <button
        onClick={handlePlayPause}
        className="btn btn-primary"
        style={{
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          lineHeight: '50px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {playing ? (
          <i className="fa fa-pause"></i>
        ) : (
          <i className="fa fa-play"></i>
        )}
      </button>
    </div>
  );
}

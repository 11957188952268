import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../../setup';

import { PlanModel } from '../models/PlanModel';
import { planStatusSelector } from '../redux/PlanRedux';

export default function MealSuggestionList() {
  const intl = useIntl();
  const selectedPlan: PlanModel | undefined = useSelector(
    (state: RootState) => state.plan.selectedPlan,
    shallowEqual
  );
  const status: string = useSelector(planStatusSelector, shallowEqual);
  const isLoading = status === 'loading_selected_plan';

  function getMeal(suggestion) {
    return selectedPlan?.eligible_meals.find(
      (eligibleMeal) => eligibleMeal.id === suggestion.meal_id
    );
  }

  return (
    <section className="card p-10">
      <h1 className="mb-8">
        {intl.formatMessage({ id: 'planDetail.mealSuggestions' })}
      </h1>
      {isLoading && (
        <div>
          <div className="spinner-border text-primary" role="status" />
        </div>
      )}
      {!isLoading && !selectedPlan?.suggestions.length && (
        <p>{intl.formatMessage({ id: 'planDetail.noMealSuggestions' })}</p>
      )}
      {!isLoading && selectedPlan?.suggestions.length > 0 && (
        <div className="overflow-scroll">
          <table className="table table-hover table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
            <thead className="table-header">
              <tr className="border-0 fw-bold">
                <th className="ps-4 w-100">
                  {intl.formatMessage({ id: 'planDetail.meal' })}
                </th>
                <th className="text-center">
                  {intl.formatMessage({ id: 'planDetail.action' })}
                </th>
              </tr>
            </thead>
            <tbody>
              {selectedPlan?.suggestions.map((aSuggestion) => (
                <tr
                  key={aSuggestion.meal_id}
                  style={{
                    background:
                      aSuggestion.action === 'use'
                        ? 'rgb(80 205 137 / 5%)'
                        : 'transparent',
                  }}
                >
                  <td className="ps-4">
                    {getMeal(aSuggestion)?.name || (
                      <span className="fw-bold">
                        {intl.formatMessage({
                          id: 'planDetail.nonEligibleMeal',
                        })}
                      </span>
                    )}
                  </td>
                  <td className="text-center">
                    {aSuggestion.action === 'use' ? (
                      <span className="fas fa-check me-2 w-20 text-success" />
                    ) : (
                      <span className="fas fa-times me-2 w-20 text-danger" />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </section>
  );
}

import { FC } from 'react';
import { PageTitle } from '../../../_metronic/layout/core';
import { useIntl } from 'react-intl';

import { InfluencerStats } from '../../modules/influencers';

export const InfluencerStatsPage: FC = () => {
  const intl = useIntl();

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'app.influencerStats' })}
      </PageTitle>
      <div className="row gy-5 g-xl-8">
        <div className="card p-10">
          <InfluencerStats />
        </div>
      </div>
    </>
  );
};

export default [
  'Destinatario ausente o cerrado',
  'Destinatario aplaza la entrega',
  'Pendiente de recoger en franquicia destino',
  'Cliente ordena destruir el envío',
  'Devuelto al remitente',
  'Envío recogido en origen',
  'Emisor aplaza la recogida',
  'No acepta el reembolso',
  'Emisor anula la recogida',
  'Pendiente de recibir en destino',
  'Dirección entrega incorrecta',
  'Pendiente de recibir datos de entrega',
  'No acepta el envío',
  'Emisor aplaza la entrega',
  'Envío en reparto',
  'Entrega aplazada pendiente de cobro o cita previa',
  'Entrega parcial',
  'Festivo en localidad de destino',
  'Pendiente de recoger en MRW Point',
  'Pendiente de recoger en Franquicia destino',
  'Extraviado en destino',
  'Concertada próxima entrega',
  'Recibido en Franquicia de destino',
  'Dirección entrega incompleta',
  'Envío rechazado por embalaje dañado',
  'En tránsito',
  'Pendiente de recoger',
  'Retenido en Franquicia por orden del cliente',
  'Entregado',
  'Destinatario desconocido',
];

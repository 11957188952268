import { FC } from 'react';

import { useIntl } from 'react-intl';
import { PageTitle } from '../../../_metronic/layout/core';
import TasksPerAgent from '../../modules/tasks/components/TasksPerAgent';
import { usePathProps } from '../../modules/user-profile/hooks/usePathProps';

const TasksPerAgentPageComponent: FC = () => {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <TasksPerAgent />
        </div>
      </div>
    </>
  );
};

const TasksPerAgentPage: FC = () => {
  const intl = useIntl();
  usePathProps();

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'app.tasksPerAgent' })}
      </PageTitle>
      <TasksPerAgentPageComponent />
    </>
  );
};

export { TasksPerAgentPage };

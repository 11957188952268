import { Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { PlanModel } from '../models/PlanModel';
import { selectedPlanSelector } from '../redux/PlanRedux';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

export default function CustomMealModal({
  visible = false,
  loading = false,
  onClose,
  onSubmitCustomMeal,
  initialCustomMeal,
}) {
  const intl = useIntl();
  const selectedPlan: PlanModel | undefined = useSelector(selectedPlanSelector);
  const [meal, setMeal] = useState(0);
  const [size, setSize] = useState(0);
  const [units, setUnits] = useState(1);
  const [id, setId] = useState(null);

  const cta = initialCustomMeal?.id
    ? intl.formatMessage({ id: `planDetail.editCustomMeal` })
    : intl.formatMessage({ id: `planDetail.addCustomMeal` });

  function getSizes(mealId) {
    const sizes = selectedPlan?.eligible_meals
      ?.find((aMeal) => aMeal.id === mealId)
      ?.sizes?.map((aSize) => ({
        label: `${aSize.weight_in_gr} gr`,
        value: aSize.id,
      }));
    return sizes;
  }

  useEffect(() => {
    const sizes = getSizes(meal);
    if (!initialCustomMeal?.size && sizes?.length > 0) {
      setSize(sizes[0].value);
    }
  }, [meal, initialCustomMeal]);

  useEffect(() => {
    const newMeal =
      initialCustomMeal?.meal || selectedPlan?.eligible_meals[0]?.id;
    const newSize = initialCustomMeal?.size || getSizes(newMeal)?.[0].value;
    const newUnits = initialCustomMeal?.units || 1;
    const newId = initialCustomMeal?.id || null;

    setMeal(newMeal);
    setSize(newSize);
    setUnits(newUnits);
    setId(newId);
  }, [
    initialCustomMeal?.meal,
    initialCustomMeal?.size,
    initialCustomMeal?.units,
    initialCustomMeal?.id,
    selectedPlan?.eligible_meals[0]?.id,
  ]);

  return (
    <Modal show={visible} centered onHide={onClose}>
      <Modal.Header>
        <Modal.Title>
          {initialCustomMeal?.id
            ? intl.formatMessage({ id: 'planDetail.editExistingCustomMeal' })
            : intl.formatMessage({ id: `planDetail.addRecipeToCustomPlan` })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          className="form"
          onSubmit={(event) => onSubmitCustomMeal(event, id, meal, size, units)}
        >
          <div className="form-group mb-4">
            <label className="form-label">
              {intl.formatMessage({ id: `planDetail.meal` })}
            </label>
            <select
              className="form-control form-control-solid form-select"
              value={meal}
              onChange={(event) => setMeal(parseInt(event.target.value, 10))}
              required
            >
              {selectedPlan?.eligible_meals.map((meal) => (
                <option key={meal.id} value={meal.id}>
                  {meal.name}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group mb-4">
            <label className="form-label">
              {intl.formatMessage({ id: `planDetail.mealSize` })}
            </label>
            <select
              required
              className="form-control form-control-solid form-select"
              value={size}
              onChange={(event) => setSize(parseInt(event.target.value, 10))}
            >
              {getSizes(meal)?.map((aSize) => (
                <option key={aSize.value} value={aSize.value}>
                  {aSize.label}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group mb-4">
            <label className="form-label">
              {intl.formatMessage({ id: `planDetail.units` })}
            </label>
            <input
              required
              className="form-control form-control-solid "
              type="number"
              value={units}
              onChange={(event) => setUnits(parseInt(event.target.value))}
              min="1"
            />
          </div>
          <button
            type="submit"
            disabled={loading}
            className="ms-auto px-10 btn btn-primary d-block"
            data-kt-users-modal-action="submit"
            data-kt-indicator={loading ? 'on' : 'off'}
          >
            <span className="indicator-label">{cta}</span>
            <span className="indicator-progress">
              <span className="spinner-border spinner-border-sm align-middle me-4"></span>
              &nbsp;{cta}
            </span>
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
}

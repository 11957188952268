import { FC } from 'react';

import { useIntl } from 'react-intl';
import { PageTitle } from '../../../_metronic/layout/core';
import { OrderList } from '../../modules/orders';
import { usePathProps } from '../../modules/user-profile/hooks/usePathProps';

const OrdersComponent: FC = () => {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <OrderList />
        </div>
      </div>
    </>
  );
};

const OrdersPage: FC = () => {
  const intl = useIntl();
  usePathProps();

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'menu.orders' })}
      </PageTitle>
      <OrdersComponent />
    </>
  );
};

export { OrdersPage };

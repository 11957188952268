import { FC } from 'react';
import { PageTitle } from '../../../_metronic/layout/core';
import { usePathProps } from '../../modules/user-profile/hooks/usePathProps';
import { useIntl } from 'react-intl';

import AgentDashboard from '../../modules/dashboard/components/AgentDashboard';

const HomePage: FC = () => {
  const intl = useIntl();
  usePathProps();

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'menu.agentMetrics' })}
      </PageTitle>
      <div className="row gy-5 g-xl-8">
        <div className="card p-10 mw-1000px">
          <AgentDashboard />
        </div>
      </div>
    </>
  );
};

export { HomePage };

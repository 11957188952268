import { FC } from 'react';

import { useIntl } from 'react-intl';
import { PageTitle } from '../../../_metronic/layout/core';
import {
  PetDetail,
  MealPreferences,
  PetPlans,
  PetOrders,
  PetDocuments,
} from '../../modules/pets';
import PetActions from '../../modules/pets/components/PetActions';
import { usePathProps } from '../../modules/user-profile/hooks/usePathProps';

const PetDetailComponent: FC = () => {
  return (
    <>
      <div className="row mb-6">
        <div className="col-lg-7 col-md-8">
          <div className="mb-4">
            <PetDetail />
          </div>
          <div className="mb-4">
            <PetDocuments />
          </div>
          <div className="mb-4">
            <MealPreferences />
          </div>
          <div className=" mb-4">
            <PetPlans />
          </div>
          <div className="mb-4">
            <PetOrders />
          </div>
        </div>
        <div className="col-lg-4">
          <div className="position-fixed">
            <PetActions />
          </div>
        </div>
      </div>
    </>
  );
};

const PetDetailPage: FC = () => {
  const intl = useIntl();
  usePathProps();

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'menu.petDetail' })}
      </PageTitle>
      <PetDetailComponent />
    </>
  );
};

export { PetDetailPage };

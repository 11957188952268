import { put, takeEvery, delay, select } from 'redux-saga/effects';
import {
  listUserOrders,
  listPetOrders,
  listPlanOrders,
  getOrderById,
  updateOrder,
  cancelOrder,
  processOrder,
  lockOrder,
  completeOrder,
  refreshPrices,
  setProduct,
  undeliverOrder,
  reshipOrder,
  markAsReadyToShipOrder,
  changeShippingAddress,
  markOrderAsWithIncidence,
  lockOrderPendingPayment,
  forceLockOrder,
} from '../services/OrderCRUD';
import { OrderModel } from '../models/OrderModel';
import { RootState, ActionWithPayload } from '../../../../setup';
import { OrderListModel } from '../models/OrderListModel';

export const actionTypes = {
  ListOrders: 'ORDER_REDUX_LIST_ORDERS',
  ListPetOrders: 'ORDER_REDUX_LIST_PET_ORDERS',
  ListPlanOrders: 'ORDER_REDUX_LIST_PLAN_ORDERS',
  SetOrders: 'ORDER_REDUX_SET_ORDERS',
  ResetOrders: 'ORDER_REDUX_RESET_ORDERS',
  SetStatus: 'ORDER_REDUX_SET_STATUS',
  SetError: 'ORDER_REDUX_SET_ERROR',
  OrderRequested: 'ORDER_REDUX_REQUEST_ORDER',
  SetSelectedOrder: 'ORDER_REDUX_SET_SELECTED_ORDER',
  UpdateSelectedOrder: 'ORDER_REDUX_UPDATE_SELECTED_ORDER',
  UpdateShippingAddress: 'ORDER_REDUX_UPDATE_SHIPPING_ADDRESS',
  UpdateShippingService: 'ORDER_REDUX_UPDATE_SHIPPING_SERVICE',
  SetPetOrders: 'ORDER_REDUX_SET_PET_ORDERS',
  SetPlanOrders: 'ORDER_REDUX_SET_PLAN_ORDERS',
  CancelSelectedOrder: 'ORDER_REDUX_CANCEL_SELECTED_ORDER',
  LockSelectedOrder: 'ORDER_REDUX_LOCK_SELECTED_ORDER',
  ForceLockSelectedOrder: 'ORDER_REDUX_FORCE_LOCK_SELECTED_ORDER',
  LockPendingPaymentSelectedOrder:
    'ORDER_REDUX_LOCK_PENDING_PAYMENT_SELECTED_ORDER',
  CompleteSelectedOrder: 'ORDER_REDUX_COMPLETE_SELECTED_ORDER',
  ProcessSelectedOrder: 'ORDER_REDUX_PROCESS_SELECTED_ORDER',
  UndeliverSelectedOrder: 'ORDER_REDUX_UNDELIVER_SELECTED_ORDER',
  ReshipSelectedOrder: 'ORDER_REDUX_RESHIP_SELECTED_ORDER',
  MarkAsReadyToShipSelectedOrder:
    'ORDER_REDUX_MARK_AS_READY_TO_SHIP_SELECTED_ORDER',
  RefreshSelectedOrderPrices: 'ORDER_REDUX_REFRESH_SELECTED_ORDER_PRICES',
  SetProductUnitsInSelectedOrder:
    'ORDER_REDUX_SET_PRODUCT_UNITS_IN_SELECTED_ORDER',
  RemoveProductFromSelectedOrder:
    'ORDER_REDUX_REMOVE_PRODUCT_FROM_SELECTED_ORDER',
  MarkSelectedOrderAsWithIncidence:
    'ORDER_REDUX_MARK_SELECTED_ORDER_AS_WITH_INCIDENCE',
};

export const ORDERS_PER_PAGE = 20;

type Status =
  | 'idle'
  // loading lifecycle
  | 'loading_selected_order'
  | 'error_selected_order'
  | 'success_selected_order'
  // remove lifecycle
  | 'loading_cancel_selected_order'
  | 'error_cancel_selected_order'
  | 'success_cancel_selected_order'
  // update lifecycle
  | 'loading_update_selected_order'
  | 'error_update_selected_order'
  | 'success_update_selected_order'
  // update shipping address lifecycle
  | 'loading_update_shipping_address_selected_order'
  | 'error_update_shipping_address_selected_order'
  | 'success_update_shipping_address_selected_order'
  // update shipping service lifecycle
  | 'loading_update_shipping_service_selected_order'
  | 'error_update_shipping_service_selected_order'
  | 'success_update_shipping_service_selected_order'
  // lock lifecycle
  | 'loading_lock_selected_order'
  | 'error_lock_selected_order'
  | 'success_lock_selected_order'
  // lock pending payment lifecycle
  | 'loading_lock_pending_payment_selected_order'
  | 'error_lock_pending_payment_selected_order'
  | 'success_lock_pending_payment_selected_order'
  // complete lifecycle
  | 'loading_complete_selected_order'
  | 'error_complete_selected_order'
  | 'success_complete_selected_order'
  // process lifecycle
  | 'loading_process_selected_order'
  | 'error_process_selected_order'
  | 'success_process_selected_order'
  // undeliver lifecycle
  | 'loading_undeliver_selected_order'
  | 'error_undeliver_selected_order'
  | 'success_undeliver_selected_order'
  // reship lifecycle
  | 'loading_reship_selected_order'
  | 'error_reship_selected_order'
  | 'success_reship_selected_order'
  // mark as ready-to-ship lifecycle
  | 'loading_mark_as_ready_to_ship_selected_order'
  | 'error_mark_as_ready_to_ship_selected_order'
  | 'success_mark_as_ready_to_ship_selected_order'
  // refresh-prices lifecycle
  | 'loading_refresh_prices_selected_order'
  | 'error_refresh_prices_selected_order'
  | 'success_refresh_prices_selected_order'
  // list lifecycle
  | 'loading_list_orders'
  | 'error_list_orders'
  | 'success_list_orders'
  // add-product lifecycle
  | 'loading_add_product_to_selected_order'
  | 'error_add_product_to_selected_order'
  | 'success_add_product_to_selected_order'
  // remove-product lifecycle
  | 'loading_remove_product_from_selected_order'
  | 'error_remove_product_from_selected_order'
  | 'success_remove_product_from_selected_order'
  // mark as with incidence lifecycle
  | 'loading_mark_as_with_incidence_selected_order'
  | 'error_mark_as_with_incidence_selected_order'
  | 'success_mark_as_with_incidence_selected_order'
  // force lock lifecycle
  | 'loading_force_lock_selected_order'
  | 'error_force_lock_selected_order'
  | 'success_force_lock_selected_order';

export interface IOrderState {
  orders: OrderListModel;
  petOrders: OrderListModel;
  planOrders: OrderListModel;
  selectedOrder?: OrderModel;
  status: Status;
  error?: any;
}

const initialState: IOrderState = {
  orders: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  petOrders: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  planOrders: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  selectedOrder: undefined,
  status: 'loading_list_orders',
  error: undefined,
};

export const selectedOrderSelector = (state: RootState) =>
  state.order.selectedOrder;
export const orderStatusSelector = (state: RootState) => state.order.status;
export const orderErrorSelector = (state: RootState) => state.order.error;
export const ordersSelector = (state: RootState) => state.order.orders;
export const planOrdersSelector = (state: RootState) => state.order.planOrders;
export const petOrdersSelector = (state: RootState) => state.order.petOrders;

export const reducer = (
  state: IOrderState = initialState,
  action: ActionWithPayload<IOrderState>
): IOrderState => {
  switch (action.type) {
    case actionTypes.SetOrders: {
      const orders: OrderListModel = action.payload?.orders;
      return { ...state, orders };
    }

    case actionTypes.SetPetOrders: {
      const petOrders: OrderListModel = action.payload?.petOrders;
      return { ...state, petOrders };
    }

    case actionTypes.SetPlanOrders: {
      const planOrders: OrderListModel = action.payload?.planOrders;
      return { ...state, planOrders };
    }

    case actionTypes.SetStatus: {
      const status = action.payload?.status || 'idle';
      return { ...state, status };
    }

    case actionTypes.SetError: {
      const error = action.payload?.error || undefined;
      return { ...state, error };
    }

    case actionTypes.ResetOrders: {
      return initialState;
    }

    case actionTypes.SetSelectedOrder: {
      const selectedOrder = action.payload?.selectedOrder || undefined;
      return {
        ...state,
        selectedOrder,
        status: 'idle',
      };
    }

    default:
      return state;
  }
};

export const actions = {
  setOrders: (orders: OrderListModel) => ({
    type: actionTypes.SetOrders,
    payload: { orders },
  }),
  setPetOrders: (petOrders: OrderListModel) => ({
    type: actionTypes.SetPetOrders,
    payload: { petOrders },
  }),
  setPlanOrders: (planOrders: OrderListModel) => ({
    type: actionTypes.SetPlanOrders,
    payload: { planOrders },
  }),
  listOrders: (userId: number, page: number = 1) => ({
    type: actionTypes.ListOrders,
    payload: { userId, page },
  }),
  listPetOrders: (petId: number, page: number = 1) => ({
    type: actionTypes.ListPetOrders,
    payload: { petId, page },
  }),
  listPlanOrders: (planId: number, page: number = 1) => ({
    type: actionTypes.ListPlanOrders,
    payload: { planId, page },
  }),
  resetOrders: () => ({ type: actionTypes.ResetOrders }),
  setStatus: (status: Status) => ({
    type: actionTypes.SetStatus,
    payload: { status },
  }),
  setError: (error: any) => ({
    type: actionTypes.SetError,
    payload: { error },
  }),
  setSelectedOrder: (selectedOrder: OrderModel) => ({
    type: actionTypes.SetSelectedOrder,
    payload: { selectedOrder },
  }),
  requestOrder: (orderId: number) => ({
    type: actionTypes.OrderRequested,
    payload: { orderId },
  }),
  updateSelectedOrder: (order: Partial<OrderModel>) => ({
    type: actionTypes.UpdateSelectedOrder,
    payload: { order },
  }),
  updateShippingAddress: (order: Partial<OrderModel>) => ({
    type: actionTypes.UpdateShippingAddress,
    payload: { order },
  }),
  updateShippingService: (order: Partial<OrderModel>) => ({
    type: actionTypes.UpdateShippingService,
    payload: { order },
  }),
  cancelSelectedOrder: () => ({ type: actionTypes.CancelSelectedOrder }),
  lockSelectedOrder: () => ({ type: actionTypes.LockSelectedOrder }),
  forceLockSelectedOrder: () => ({ type: actionTypes.ForceLockSelectedOrder }),
  lockPendingPaymentSelectedOrder: () => ({
    type: actionTypes.LockPendingPaymentSelectedOrder,
  }),
  completeSelectedOrder: () => ({ type: actionTypes.CompleteSelectedOrder }),
  processSelectedOrder: () => ({ type: actionTypes.ProcessSelectedOrder }),
  undeliverSelectedOrder: () => ({ type: actionTypes.UndeliverSelectedOrder }),
  reshipSelectedOrder: () => ({ type: actionTypes.ReshipSelectedOrder }),
  markSelectedOrderAsReadyToShip: () => ({
    type: actionTypes.MarkAsReadyToShipSelectedOrder,
  }),
  refreshSelectedOrderPrices: () => ({
    type: actionTypes.RefreshSelectedOrderPrices,
  }),
  setProductUnitsInSelectedOrder: ({
    productId,
    units,
    isFree,
  }: {
    productId: number;
    units: number;
    isFree?: boolean;
  }) => ({
    type: actionTypes.SetProductUnitsInSelectedOrder,
    payload: { productId, units, isFree },
  }),
  removeProductFromSelectedOrder: (productId: number) => ({
    type: actionTypes.RemoveProductFromSelectedOrder,
    payload: { productId },
  }),
  markSelectedOrderAsWithIncidence: () => ({
    type: actionTypes.MarkSelectedOrderAsWithIncidence,
  }),
  store: () => ({ type: 'def' }),
};

export function* saga() {
  yield takeEvery(
    actionTypes.ListOrders,
    function* ListOrdersEffect(
      action: ActionWithPayload<{ userId: number; page: number }>
    ) {
      const userId = action?.payload?.userId;
      const page = action?.payload?.page || 1;

      yield put(actions.setError(undefined));

      yield put(actions.setStatus('loading_list_orders'));
      if (userId) {
        try {
          const { data } = yield listUserOrders(userId, page);

          yield put(
            actions.setOrders({
              ...data,
              page,
            })
          );
          yield put(actions.setStatus('success_list_orders'));
        } catch (e: any) {
          console.warn(e);
          yield put(actions.setStatus('error_list_orders'));
          if (e?.length > 0) {
            yield put(actions.setError(e[0].toString()));
          }
        }
      }
      yield delay(0);
      yield put(actions.setStatus('idle'));
    }
  );

  yield takeEvery(
    actionTypes.ListPetOrders,
    function* ListPetOrdersEffect(
      action: ActionWithPayload<{ petId: number; page: number }>
    ) {
      const petId = action?.payload?.petId;
      const page = action?.payload?.page || 1;

      yield put(actions.setError(undefined));

      if (petId) {
        try {
          yield put(actions.setStatus('loading_list_orders'));

          const { data } = yield listPetOrders(petId, page);

          yield put(
            actions.setPetOrders({
              ...data,
              page,
            })
          );
        } catch (e: any) {
          console.warn(e);
          if (e?.length > 0) {
            yield put(actions.setError(e[0].toString()));
          }
        } finally {
          yield delay(0);
          yield put(actions.setStatus('idle'));
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.ListPlanOrders,
    function* ListPlanOrdersEffect(
      action: ActionWithPayload<{ planId: number; page: number }>
    ) {
      const planId = action?.payload?.planId;
      const page = action?.payload?.page || 1;

      yield put(actions.setError(undefined));

      if (planId) {
        try {
          yield put(actions.setStatus('loading_list_orders'));

          const { data } = yield listPlanOrders(planId, page);

          yield put(
            actions.setPlanOrders({
              ...data,
              page,
            })
          );
        } catch (e: any) {
          console.warn(e);
          if (e?.length > 0) {
            yield put(actions.setError(e[0].toString()));
          }
        } finally {
          yield delay(0);
          yield put(actions.setStatus('idle'));
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.OrderRequested,
    function* OrderRequestedEffect(
      action: ActionWithPayload<{ orderId: number }>
    ) {
      const orderId = action?.payload?.orderId;
      yield put(actions.setError(undefined));
      if (orderId) {
        yield put(actions.setStatus('loading_selected_order'));
        try {
          const response: any = yield getOrderById(orderId);
          if (response?.data) {
            const { data: selectedOrder } = response;

            yield put(actions.setSelectedOrder(selectedOrder as OrderModel));
          }
          yield put(actions.setStatus('success_selected_order'));
        } catch (error: any) {
          yield put(actions.setStatus('error_selected_order'));
          if (error?.response?.data) {
            yield put(actions.setError(error?.response?.data));
          }
        } finally {
          yield delay(0);
          yield put(actions.setStatus('idle'));
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.SetProductUnitsInSelectedOrder,
    function* SetProductUnitsInSelectedOrderEffect(
      action: ActionWithPayload<{
        productId: number;
        units: number;
        isFree?: boolean;
      }>
    ) {
      const productId = action?.payload?.productId;
      const units = action?.payload?.units;
      const isFree = action?.payload?.isFree;
      const selectedOrder = yield select(selectedOrderSelector);

      yield put(actions.setError(undefined));
      if (productId) {
        yield put(actions.setStatus('loading_add_product_to_selected_order'));
        try {
          const response: any = yield setProduct(
            selectedOrder.id,
            productId,
            units,
            isFree
          );
          if (response?.data) {
            const { data: selectedOrder } = response;

            yield put(actions.setSelectedOrder(selectedOrder as OrderModel));
          }
          yield put(actions.setStatus('success_add_product_to_selected_order'));
        } catch (error: any) {
          yield put(actions.setStatus('error_add_product_to_selected_order'));
          if (error?.response?.data) {
            yield put(actions.setError(error?.response?.data));
          }
        } finally {
          yield delay(0);
          yield put(actions.setStatus('idle'));
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.RemoveProductFromSelectedOrder,
    function* RemoveProductFromSelectedOrderEffect(
      action: ActionWithPayload<{ productId: number }>
    ) {
      const productId = action?.payload?.productId;
      const selectedOrder = yield select(selectedOrderSelector);

      yield put(actions.setError(undefined));
      if (productId) {
        yield put(
          actions.setStatus('loading_remove_product_from_selected_order')
        );
        try {
          const response: any = yield setProduct(
            selectedOrder.id,
            productId,
            0
          );
          if (response?.data) {
            const { data: selectedOrder } = response;

            yield put(actions.setSelectedOrder(selectedOrder as OrderModel));
          }
          yield put(
            actions.setStatus('success_remove_product_from_selected_order')
          );
        } catch (error: any) {
          yield put(
            actions.setStatus('error_remove_product_from_selected_order')
          );
          if (error?.response?.data) {
            yield put(actions.setError(error?.response?.data));
          }
        } finally {
          yield delay(0);
          yield put(actions.setStatus('idle'));
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.UpdateSelectedOrder,
    function* UpdateSelectedOrderEffect(
      action: ActionWithPayload<{ order: OrderModel }>
    ) {
      const order = action?.payload?.order;
      yield put(actions.setError(undefined));
      if (order.id && order.promise_date) {
        yield put(actions.setStatus('loading_update_selected_order'));
        try {
          const response: any = yield updateOrder(order.id, order);
          if (response?.data) {
            const { data: selectedOrder } = response;

            yield put(actions.setSelectedOrder(selectedOrder as OrderModel));
          }
          yield put(actions.setStatus('success_update_selected_order'));
        } catch (error: any) {
          yield put(actions.setStatus('error_update_selected_order'));
          if (error?.response?.data) {
            if (error?.response?.data?.promise_date) {
              yield put(actions.setError(error?.response?.data.promise_date));
            } else {
              yield put(actions.setError(error?.response?.data));
            }
          }
        } finally {
          yield delay(0);
          yield put(actions.setStatus('idle'));
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.UpdateShippingAddress,
    function* UpdateShippingAddressEffect(
      action: ActionWithPayload<{ order: OrderModel }>
    ) {
      const order = action?.payload?.order;
      yield put(actions.setError(undefined));
      if (order.id && order.shipping_address.id) {
        yield put(
          actions.setStatus('loading_update_shipping_address_selected_order')
        );
        try {
          const response: any = yield changeShippingAddress(
            order.id,
            order.shipping_address.id
          );

          if (response?.data) {
            const { data: selectedOrder } = response;

            yield put(actions.setSelectedOrder(selectedOrder as OrderModel));
          }
          yield put(
            actions.setStatus('success_update_shipping_address_selected_order')
          );
        } catch (error: any) {
          yield put(
            actions.setStatus('error_update_shipping_address_selected_order')
          );
          if (error?.response?.data) {
            if (error?.response?.data?.promise_date) {
              yield put(actions.setError(error?.response?.data.promise_date));
            } else {
              yield put(actions.setError(error?.response?.data));
            }
          }
        } finally {
          yield delay(0);
          yield put(actions.setStatus('idle'));
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.UpdateShippingService,
    function* UpdateShippingServiceEffect(
      action: ActionWithPayload<{ order: OrderModel }>
    ) {
      const order = action?.payload?.order;
      yield put(actions.setError(undefined));
      if (order.id && order.shipping_service) {
        yield put(
          actions.setStatus('loading_update_shipping_service_selected_order')
        );
        try {
          const response: any = yield updateOrder(order.id, {
            shipping_service: order.shipping_service,
          });

          if (response?.data) {
            const { data: selectedOrder } = response;

            yield put(actions.setSelectedOrder(selectedOrder as OrderModel));
          }
          yield put(
            actions.setStatus('success_update_shipping_service_selected_order')
          );
        } catch (error: any) {
          yield put(
            actions.setStatus('error_update_shipping_service_selected_order')
          );
          if (error?.response?.data) {
            if (error?.response?.data?.promise_date) {
              yield put(actions.setError(error?.response?.data.promise_date));
            } else {
              yield put(actions.setError(error?.response?.data));
            }
          }
        } finally {
          yield delay(0);
          yield put(actions.setStatus('idle'));
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.CancelSelectedOrder,
    function* CancelSelectedOrderEffect() {
      const selectedOrder = yield select(selectedOrderSelector);
      const orderId = selectedOrder?.id;

      if (orderId) {
        yield put(actions.setStatus('loading_cancel_selected_order'));
        try {
          const updatedOrder = yield cancelOrder(orderId);
          yield put(actions.setSelectedOrder(updatedOrder as OrderModel));
          yield put(actions.setStatus('success_cancel_selected_order'));
        } catch (e: any) {
          console.warn(e);
          if (e?.length > 0) {
            yield put(actions.setError(e[0].toString()));
          }
          yield put(actions.setStatus('error_cancel_selected_order'));
        } finally {
          yield delay(0);
          yield put(actions.setStatus('idle'));
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.RefreshSelectedOrderPrices,
    function* RefreshSelectedOrderPricesEffect() {
      const selectedOrder = yield select(selectedOrderSelector);
      const orderId = selectedOrder?.id;

      if (orderId) {
        yield put(actions.setStatus('loading_refresh_prices_selected_order'));
        try {
          const updatedOrder = yield refreshPrices(orderId);
          yield put(actions.setSelectedOrder(updatedOrder as OrderModel));
          yield put(actions.setStatus('success_refresh_prices_selected_order'));
        } catch (e: any) {
          console.warn(e);
          if (e?.length > 0) {
            yield put(actions.setError(e[0].toString()));
          }
          yield put(actions.setStatus('error_refresh_prices_selected_order'));
        } finally {
          yield delay(0);
          yield put(actions.setStatus('idle'));
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.LockSelectedOrder,
    function* LockSelectedOrderEffect() {
      const selectedOrder = yield select(selectedOrderSelector);
      const orderId = selectedOrder?.id;

      if (orderId) {
        yield put(actions.setStatus('loading_lock_selected_order'));
        try {
          const updatedOrder = yield lockOrder(orderId);
          yield put(actions.setSelectedOrder(updatedOrder as OrderModel));
          yield put(actions.setStatus('success_lock_selected_order'));
        } catch (e: any) {
          console.warn(e);
          if (e?.length > 0) {
            yield put(actions.setError(e[0].toString()));
          }
          yield put(actions.setStatus('error_lock_selected_order'));
        } finally {
          yield delay(0);
          yield put(actions.setStatus('idle'));
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.ForceLockSelectedOrder,
    function* ForceLockSelectedOrderEffect() {
      const selectedOrder = yield select(selectedOrderSelector);
      const orderId = selectedOrder?.id;

      if (orderId) {
        yield put(actions.setStatus('loading_force_lock_selected_order'));
        try {
          const updatedOrder = yield forceLockOrder(orderId);
          yield put(actions.setSelectedOrder(updatedOrder as OrderModel));
          yield put(actions.setStatus('success_force_lock_selected_order'));
        } catch (e: any) {
          console.warn(e);
          if (e?.length > 0) {
            yield put(actions.setError(e[0].toString()));
          }
          yield put(actions.setStatus('error_force_lock_selected_order'));
        } finally {
          yield delay(0);
          yield put(actions.setStatus('idle'));
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.LockPendingPaymentSelectedOrder,
    function* LockPendingPaymentSelectedOrderEffect() {
      const selectedOrder = yield select(selectedOrderSelector);
      const orderId = selectedOrder?.id;

      if (orderId) {
        yield put(
          actions.setStatus('loading_lock_pending_payment_selected_order')
        );
        try {
          const updatedOrder = yield lockOrderPendingPayment(orderId);
          yield put(actions.setSelectedOrder(updatedOrder as OrderModel));
          yield put(
            actions.setStatus('success_lock_pending_payment_selected_order')
          );
        } catch (e: any) {
          console.warn(e);
          if (e?.length > 0) {
            yield put(actions.setError(e[0].toString()));
          }
          yield put(
            actions.setStatus('error_lock_pending_payment_selected_order')
          );
        } finally {
          yield delay(0);
          yield put(actions.setStatus('idle'));
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.CompleteSelectedOrder,
    function* CompleteSelectedOrderEffect() {
      const selectedOrder = yield select(selectedOrderSelector);
      const orderId = selectedOrder?.id;

      if (orderId) {
        yield put(actions.setStatus('loading_complete_selected_order'));
        try {
          const updatedOrder = yield completeOrder(orderId);
          yield put(actions.setSelectedOrder(updatedOrder as OrderModel));
          yield put(actions.setStatus('success_complete_selected_order'));
        } catch (e: any) {
          console.warn(e);
          if (e?.length > 0) {
            yield put(actions.setError(e[0].toString()));
          }
          yield put(actions.setStatus('error_complete_selected_order'));
        } finally {
          yield delay(0);
          yield put(actions.setStatus('idle'));
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.ProcessSelectedOrder,
    function* ProcessSelectedOrderEffect() {
      const selectedOrder = yield select(selectedOrderSelector);
      const orderId = selectedOrder?.id;

      if (orderId) {
        yield put(actions.setStatus('loading_process_selected_order'));
        try {
          const updatedOrder = yield processOrder(orderId);
          yield put(actions.setSelectedOrder(updatedOrder as OrderModel));
          yield put(actions.setStatus('success_process_selected_order'));
        } catch (e: any) {
          console.warn(e);
          if (e?.length > 0) {
            yield put(actions.setError(e[0].toString()));
          }
          yield put(actions.setStatus('error_process_selected_order'));
        } finally {
          yield delay(0);
          yield put(actions.setStatus('idle'));
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.UndeliverSelectedOrder,
    function* UndeliverSelectedOrderEffect() {
      const selectedOrder = yield select(selectedOrderSelector);
      const orderId = selectedOrder?.id;

      if (orderId) {
        yield put(actions.setStatus('loading_undeliver_selected_order'));
        try {
          const updatedOrder = yield undeliverOrder(orderId);
          yield put(actions.setSelectedOrder(updatedOrder as OrderModel));
          yield put(actions.setStatus('success_undeliver_selected_order'));
        } catch (e: any) {
          console.warn(e);
          if (e?.length > 0) {
            yield put(actions.setError(e[0].toString()));
          }
          yield put(actions.setStatus('error_undeliver_selected_order'));
        } finally {
          yield delay(0);
          yield put(actions.setStatus('idle'));
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.ReshipSelectedOrder,
    function* ReshipSelectedOrderEffect() {
      const selectedOrder = yield select(selectedOrderSelector);
      const orderId = selectedOrder?.id;

      if (orderId) {
        yield put(actions.setStatus('loading_reship_selected_order'));
        try {
          const updatedOrder = yield reshipOrder(orderId);
          yield put(actions.setSelectedOrder(updatedOrder as OrderModel));
          yield put(actions.requestOrder(updatedOrder?.id));
          yield put(actions.setStatus('success_reship_selected_order'));
        } catch (e: any) {
          console.warn(e);
          if (e?.length > 0) {
            yield put(actions.setError(e[0].toString()));
          }
          yield put(actions.setStatus('error_reship_selected_order'));
        } finally {
          yield delay(0);
          yield put(actions.setStatus('idle'));
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.MarkSelectedOrderAsWithIncidence,
    function* MarkSelectedOrderAsWithIncidenceEffect() {
      const selectedOrder = yield select(selectedOrderSelector);
      const orderId = selectedOrder?.id;

      if (orderId) {
        yield put(
          actions.setStatus('loading_mark_as_with_incidence_selected_order')
        );
        try {
          const updatedOrder = yield markOrderAsWithIncidence(orderId);
          yield put(actions.setSelectedOrder(updatedOrder as OrderModel));
          yield put(
            actions.setStatus('success_mark_as_with_incidence_selected_order')
          );
        } catch (e: any) {
          console.warn(e);
          if (e?.length > 0) {
            yield put(actions.setError(e[0].toString()));
          }
          yield put(
            actions.setStatus('error_mark_as_with_incidence_selected_order')
          );
        } finally {
          yield delay(0);
          yield put(actions.setStatus('idle'));
        }
      }
    }
  );

  yield takeEvery(
    actionTypes.MarkAsReadyToShipSelectedOrder,
    function* MarkAsReadyToShipSelectedOrderEffect() {
      const selectedOrder = yield select(selectedOrderSelector);
      const orderId = selectedOrder?.id;

      if (orderId) {
        yield put(
          actions.setStatus('loading_mark_as_ready_to_ship_selected_order')
        );
        try {
          const updatedOrder = yield markAsReadyToShipOrder(orderId);
          yield put(actions.setSelectedOrder(updatedOrder as OrderModel));
          yield put(
            actions.setStatus('success_mark_as_ready_to_ship_selected_order')
          );
        } catch (e: any) {
          console.warn(e);
          if (e?.length > 0) {
            yield put(actions.setError(e[0].toString()));
          }
          yield put(
            actions.setStatus('error_mark_as_ready_to_ship_selected_order')
          );
        } finally {
          yield delay(0);
          yield put(actions.setStatus('idle'));
        }
      }
    }
  );
}

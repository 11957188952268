import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { authUserSelector } from '../../auth';
import { UserModel } from '../../auth/models/UserModel';
import { TaskCommentModel } from '../models/TaskCommentModel';
import { TaskUserModel } from '../models/TaskUserModel';

export default function TaskComments({ task, onAddComment }) {
  const intl = useIntl();
  const [comment, setComment] = useState<string>('');
  const user: UserModel = useSelector(
    authUserSelector,
    shallowEqual
  ) as UserModel;
  const taskUser: TaskUserModel = {
    id: user.id,
    email: user.email,
    name:
      user.last_name === null
        ? `${user.name}`
        : `${user.name} ${user.last_name}`,
    standard_phone: undefined,
    preferred_language: user.preferred_language,
  };

  useEffect(() => {
    if (task?.id) {
      setComment('');
    }
  }, [task?.id]);

  const handleAddComment = (e: any) => {
    e.preventDefault();
    if (comment?.trim() !== '') {
      const taskComment: TaskCommentModel = {
        user: taskUser,
        created_at: new Date().toISOString(),
        comment: comment.trim() as string,
      };
      try {
        onAddComment(taskComment);
      } catch (error) {
        toast.error(error);
      } finally {
        setComment('');
      }
    } else {
      toast.error(intl.formatMessage({ id: 'tasks.fieldCommentIsRequired' }));
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-body pt-5 pb-5">
          {!!task?.user_notes && (
            <>
              <div>
                <p className="fw-bold mb-0">
                  {intl.formatMessage({ id: 'tasks.userComments' })}
                </p>
                <p>{task.user_notes}</p>
              </div>
              <hr className="border-p" />
            </>
          )}
          {!!task?.pet_notes && (
            <>
              <div>
                <p className="fw-bold mb-0">
                  {intl.formatMessage({ id: 'tasks.petComments' })}
                </p>
                <p>{task.pet_notes}</p>
              </div>
              <hr className="border-p" />
            </>
          )}
          {task.comments?.length > 0 &&
            task.comments.map((comment, i) => (
              <div key={i}>
                <p className="fw-bold mb-0">{comment?.user?.name}</p>
                <span style={{ color: '#B4A99C' }}>
                  {comment.created_at &&
                    new Intl.DateTimeFormat('es-ES', {
                      dateStyle: 'short',
                      timeStyle: 'medium',
                    }).format(new Date(Date.parse(comment.created_at)))}
                </span>
                <p>{comment.comment}</p>
                {i !== task?.comments.length - 1 && <hr className="border-p" />}
              </div>
            ))}
          {task.comments?.length === 0 &&
            !task?.pet_notes &&
            !task?.user_notes && (
              <p className="mb-5">
                {intl.formatMessage({ id: 'tasks.noComments' })}
              </p>
            )}
          <form onSubmit={handleAddComment}>
            <textarea
              required
              id="task-comment-input"
              className="form-control mt-5"
              cols={100}
              maxLength={320}
              onChange={(e) => setComment(e.target.value)}
              value={comment}
              placeholder={intl.formatMessage({ id: 'tasks.writeAComment' })}
              rows={1}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleAddComment(e);
                }
              }}
            />
            <button
              type="submit"
              className={'btn mt-4 w-100 btn-light-primary'}
            >
              <span className="fa fa-paper-plane me-2" />
              {intl.formatMessage({ id: 'tasks.addComment' })}
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

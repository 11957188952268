/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import { PrivateRoutes } from './PrivateRoutes';
import { Logout, AuthPage, authUserSelector } from '../modules/auth';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import { MasterInit } from '../../_metronic/layout/MasterInit';

const Routes: FC = () => {
  const authUser = useSelector(authUserSelector, shallowEqual);

  return (
    <>
      <Switch>
        {authUser ? (
          <MasterLayout>
            <PrivateRoutes />
          </MasterLayout>
        ) : (
          <>
            <Route>
              <AuthPage />
            </Route>
            <Redirect to="/auth/login" />
          </>
        )}
        <Route path="/logout" component={Logout} />
        <Route path="/error" component={ErrorsPage} />
      </Switch>
      <MasterInit />
    </>
  );
};

export { Routes };

import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import * as taskRedux from '../redux/TaskRedux';
import { AddTaskModal } from './AddTaskModal';
import { default as TaskTable } from './TaskTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  clientTasksCountSelector,
  clientTasksPageSelector,
  clientTasksSelector,
  clientTasksStatusSelector,
} from '../redux/TaskSelectors';
import { Pagination } from '@mui/material';

interface RouteParams {
  id: string;
}

export default function TaskList() {
  const intl = useIntl();
  const { id: idParam } = useParams<RouteParams>();
  const id = parseInt(idParam, 10);
  const [showModal, setShowModal] = useState<string | null>(null);
  const dispatch = useDispatch();
  const tasks = useSelector(clientTasksSelector);
  const page = useSelector(clientTasksPageSelector);
  const status = useSelector(clientTasksStatusSelector);
  const count = useSelector(clientTasksCountSelector);

  function onPageChange(_event, page) {
    dispatch(taskRedux.actions.retrieveClientTasks(id, page));
  }

  useEffect(() => {
    if (id > -1) {
      dispatch(taskRedux.actions.retrieveClientTasks(id));
    }
  }, [id]);

  function onModalHide() {
    dispatch(taskRedux.actions.retrieveClientTasks(id));
    setShowModal(null);
  }

  return (
    <>
      <AddTaskModal
        onHide={onModalHide}
        setShowModal={setShowModal}
        show={showModal === 'add-task'}
      />
      <main className="card p-10">
        <div className="d-flex justify-content-between mb-4 align-items-center">
          <h1 className="text-2xl font-bold">
            {intl.formatMessage({ id: 'tasks.userTasks' })}
          </h1>
        </div>
        <TaskTable
          tasks={tasks}
          displayClient={false}
          onRefresh={() => dispatch(taskRedux.actions.retrieveClientTasks(id))}
          loading={status === 'loading'}
        >
          <button
            className="btn btn-primary"
            onClick={() => setShowModal('add-task')}
          >
            <span className="fas fa-plus me-4"></span>
            {intl.formatMessage({ id: 'tasks.addTask' })}
          </button>
        </TaskTable>
        {count > 0 && (
          <Pagination
            className="mx-auto mt-4 d-flex justify-content-center"
            count={Math.ceil(count / taskRedux.TASKS_PER_PAGE)}
            page={page}
            onChange={onPageChange}
          />
        )}
      </main>
    </>
  );
}

import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { LeadDistributorModel } from '../models/LeadDistributorModel';
import { Modal } from 'react-bootstrap';

type Props = {
  distributor?: LeadDistributorModel;
  visible: boolean;
  onSaveDistributor: (distributor: LeadDistributorModel) => void;
  onCancel: () => void;
};

export default function ModalDistributorForm({
  distributor,
  visible,
  onSaveDistributor,
  onCancel,
}: Props) {
  const intl = useIntl();
  const [distributorState, setDistributorState] = useState(distributor);

  useEffect(() => {
    if (distributor) {
      setDistributorState(distributor);
    } else {
      setDistributorState({
        id: 0,
        name: '',
        email: '',
        phone: '',
      });
    }
  }, [distributor, visible]);

  return (
    <Modal show={visible} onClose={onCancel} size="sm">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">
            {distributorState?.id
              ? intl.formatMessage({ id: 'leadDistributors.editDistributor' })
              : intl.formatMessage({ id: 'leadDistributors.addDistributor' })}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={onCancel}
          ></button>
        </div>
        <div className="modal-body">
          <form>
            <div className="row mb-3">
              <div className="col-12">
                <label htmlFor="name" className="form-label">
                  {intl.formatMessage({ id: 'leadDistributors.name' })}*
                </label>
                <input
                  type="text"
                  value={distributorState?.name}
                  onChange={(e) =>
                    setDistributorState({
                      ...distributorState,
                      name: e.target.value,
                    })
                  }
                  className="form-control"
                  id="name"
                  placeholder={intl.formatMessage({
                    id: 'leadDistributors.namePlaceholder',
                  })}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-12">
                <label htmlFor="email" className="form-label">
                  {intl.formatMessage({ id: 'leadDistributors.email' })}
                </label>
                <input
                  type="email"
                  required
                  value={distributorState?.email}
                  onChange={(e) =>
                    setDistributorState({
                      ...distributorState,
                      email: e.target.value,
                    })
                  }
                  className="form-control"
                  id="email"
                  placeholder={intl.formatMessage({
                    id: 'leadDistributors.emailPlaceholder',
                  })}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-12">
                <label htmlFor="phone" className="form-label">
                  {intl.formatMessage({ id: 'leadDistributors.phone' })}
                </label>
                <input
                  type="text"
                  value={distributorState?.phone}
                  onChange={(e) =>
                    setDistributorState({
                      ...distributorState,
                      phone: e.target.value,
                    })
                  }
                  className="form-control"
                  id="phone"
                  maxLength={14}
                  placeholder={intl.formatMessage({
                    id: 'leadDistributors.phonePlaceholder',
                  })}
                />
              </div>
            </div>
          </form>
          <div className="d-flex gap-4 pt-8 justify-content-end">
            <button
              type="button"
              className="btn btn-tertiary"
              data-bs-dismiss="modal"
              onClick={onCancel}
            >
              {intl.formatMessage({ id: 'common.close' })}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => onSaveDistributor(distributorState)}
              disabled={!distributorState?.name}
            >
              <span className="fa fa-save me-3"></span>
              {intl.formatMessage({ id: 'common.save' })}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

import { useEffect, useState } from 'react';
import AircallPhone from 'aircall-everywhere';
import Draggable from 'react-draggable';
import clsx from 'clsx';

type PropsPhoneModal = {
  onHide: () => void;
  phoneNumber?: string;
  show: boolean;
};

export const PhoneModal: React.FC<PropsPhoneModal> = ({
  onHide,
  phoneNumber = '',
  show,
}) => {
  const [instance, setInstance] = useState<any>();

  useEffect(() => {
    const newInstance = new AircallPhone({
      domToLoadPhone: '#aircall-container',
      onLogin: () => {
        newInstance.send(
          'dial_number',
          { phone_number: phoneNumber },
          () => {}
        );
      },
      onLogout: () => {},
    });
    setInstance(newInstance);
  }, [setInstance, phoneNumber]);

  useEffect(() => {
    if (instance && phoneNumber) {
      instance?.send('dial_number', { phone_number: phoneNumber }, () => {});
    }
  }, [phoneNumber, instance]);

  return (
    <Draggable handle=".handle" defaultPosition={{ x: 50, y: 50 }}>
      <div
        style={{
          cursor: 'grab',
          position: 'fixed',
          zIndex: 99999,
          maxHeight: show ? 'none' : '0px',
          overflow: 'hidden',
        }}
        className={clsx('handle card', show && 'shadow')}
      >
        <button
          title="Minimizar"
          className="btn btn-sm btn-outline border-primary fs-9 w-35px px-2 text-center ms-auto mx-6 my-5"
          onClick={onHide}
        >
          <i className="fa fa-window-minimize m-0 p-0" aria-hidden="true"></i>
        </button>
        <div className="container pb-14 pt-0 px-18">
          <div className="row text-center">
            <div id="aircall-container"></div>
          </div>
        </div>
      </div>
    </Draggable>
  );
};

import { FC } from 'react';
import { PageTitle } from '../../../_metronic/layout/core';
import { usePathProps } from '../../modules/user-profile/hooks/usePathProps';
import { useIntl } from 'react-intl';
import WhatsappLogs from '../../modules/whatsapp-logs/components/WhatsappLogs';

const WhatsappLogsPage: FC = () => {
  usePathProps();
  const intl = useIntl();

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'menu.whatsappLogs' })}
      </PageTitle>
      <div className="row">
        <div className="col-6">
          <WhatsappLogs />
        </div>
      </div>
    </>
  );
};

export { WhatsappLogsPage };

import { useEffect, useState } from 'react';
import OrderTrackingDetail from './OrderTrackingDetail';
import {
  addCommentToTrackingHistory,
  listOrderTrackings,
  selectedOrderSelector,
  updateIncident,
} from '..';
import { useSelector } from 'react-redux';
import OrderTrackingTableHeader from './OrderTrackingTableHeader';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { Incident } from '../models/TrackingHistory';
import { authUserSelector } from '../../auth';

export default function SingleOrderTracking() {
  const intl = useIntl();
  const selectedOrder = useSelector(selectedOrderSelector);
  const authUser = useSelector(authUserSelector);
  const [orderTracking, setOrderTracking] = useState(null);
  const [loading, setLoading] = useState(true);

  async function fetchOrderTracking() {
    try {
      setLoading(true);
      const { data } = await listOrderTrackings({
        page: 1,
        since: new Date('2000-01-01'),
        until: new Date('3000-12-31'),
        code: selectedOrder?.code,
      });
      if (data.results.length > 0) {
        setOrderTracking(data.results[0]);
      } else {
        setOrderTracking(null);
      }
    } catch (error) {
      console.error(error);
      toast.error(
        intl.formatMessage({ id: 'orderTrackingHistory.errorFetchingTracking' })
      );
      setOrderTracking(null);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (selectedOrder?.code) {
      fetchOrderTracking();
    }
  }, [selectedOrder?.code]);

  async function onCommentAdded(comment: string): Promise<void> {
    if (!orderTracking?.tracking_history?.id) {
      toast.error(
        intl.formatMessage({
          id: 'orderTrackingHistory.errorNoTrackingHistory',
        })
      );
      return Promise.resolve();
    }
    try {
      const { data: commentAddedResponse } = await addCommentToTrackingHistory({
        trackingHistoryId: orderTracking?.tracking_history?.id,
        comment,
      });
      const updatedOrderTracking = {
        ...orderTracking,
        tracking_history: {
          ...orderTracking.tracking_history,
          tracking_history_comments: [
            ...orderTracking.tracking_history.tracking_history_comments,
            {
              id: Math.floor(Math.random() * 1000000),
              comment: commentAddedResponse.comment,
              author_agent: authUser,
              commented_at: new Date().toISOString(),
            },
          ],
        },
      };
      setOrderTracking(updatedOrderTracking);
    } catch (error) {
      console.error(error);
      toast.error(
        intl.formatMessage({ id: 'orderTrackingHistory.errorAddingComment' })
      );
    }
  }

  async function onChangeIncident(incident: Incident): Promise<void> {
    if (!orderTracking?.tracking_history?.id) {
      toast.error(
        intl.formatMessage({
          id: 'orderTrackingHistory.errorNoTrackingHistory',
        })
      );
      return Promise.resolve();
    }
    try {
      const { data: updateIncidentResponse } = await updateIncident({
        trackingHistoryId: orderTracking?.tracking_history?.id,
        incident,
      });

      const updatedOrderTracking = {
        ...orderTracking,
        tracking_history: {
          ...orderTracking.tracking_history,
          incident: updateIncidentResponse.incident as Incident,
        },
      };
      setOrderTracking(updatedOrderTracking);
    } catch (error) {
      console.error(error);
      toast.error(
        intl.formatMessage({ id: 'orderTrackingHistory.errorChangingIncident' })
      );
    }
  }

  return (
    <div className="card p-10">
      <h2 className="mb-8">
        {intl.formatMessage({ id: 'orderDetail.trackingHistory' })}
      </h2>
      {loading && (
        <span
          className="spinner-border text-primary"
          role="status"
          aria-hidden="true"
        />
      )}
      {!loading && orderTracking && (
        <>
          <OrderTrackingTableHeader singleOrderDisplay />
          <OrderTrackingDetail
            singleOrderDisplay
            orderTracking={orderTracking}
            onChangeIncident={onChangeIncident}
            onCommentAdded={onCommentAdded}
          />
        </>
      )}
      {!loading && !orderTracking && (
        <p>{intl.formatMessage({ id: 'orderDetail.noTrackingInfo' })}</p>
      )}
    </div>
  );
}

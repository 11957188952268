import axios from 'axios';

import { UserModel } from '../models/UserModel';

const API_URL = process.env.REACT_APP_API_URL;

export const LOGIN_URL = `${API_URL}/api/users/admin-login/`;
export const GET_TOKEN_URL = `${API_URL}/api/api-sessions/`;
export const ME_URL = `${API_URL}/api/users/me/`;

export function getToken(): Promise<{ data: { token: string } }> {
  return axios.post(GET_TOKEN_URL);
}

export function login(
  email: string,
  password: string
): Promise<{ data: UserModel }> {
  return axios.post(LOGIN_URL, {
    email,
    password,
  });
}

export function getMe(): Promise<{ data: UserModel }> {
  return axios.get(ME_URL);
}

import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import { TaskModel } from '../models/TaskModel';
import SPECIAL_RESOLUTION_CODES from '../constants/specialResolutionCodes';
import Select from 'react-select';

type PropsModal = {
  visible: boolean;
  tasks: Array<TaskModel>;
  onHide: () => void;
  onResolve: (taskResolutionId: number) => void;
};

export const BulkResolutionModal: React.FC<PropsModal> = ({
  tasks = [],
  visible = false,
  onHide,
  onResolve,
}) => {
  const intl = useIntl();
  const [selectedResolution, setSelectedResolution] = useState<number>(-1);
  const [selectedChildResolution, setSelectedChildResolution] =
    useState<number>(-1);
  const [selectedSubChildResolution, setSelectedSubChildResolution] =
    useState<number>(-1);

  useEffect(() => {
    setSelectedResolution(-1);
    setSelectedChildResolution(-1);
    setSelectedSubChildResolution(-1);
  }, [visible]);

  useEffect(() => {
    setSelectedChildResolution(-1);
  }, [selectedResolution]);

  useEffect(() => {
    setSelectedSubChildResolution(-1);
  }, [selectedChildResolution]);

  function getParentResolutions() {
    const commonResolutions = [];
    tasks.forEach((task) => {
      task.resolutions.forEach((resolution) => {
        if (
          tasks.every((task) =>
            task.resolutions.some((res) => res.id === resolution.id)
          ) &&
          !SPECIAL_RESOLUTION_CODES.includes(resolution.code) &&
          !resolution?.parent &&
          !commonResolutions.find(
            (commonResolution) => commonResolution.id === resolution.id
          )
        ) {
          commonResolutions.push(resolution);
        }
      });
    });
    return (
      commonResolutions?.map((resolution) => ({
        value: `${resolution.id}`,
        label: resolution.name,
      })) || []
    );
  }

  function getChildResolutions() {
    const commonResolutions = [];
    tasks.forEach((task) => {
      task.resolutions.forEach((resolution) => {
        if (
          tasks.every((task) =>
            task.resolutions.some((res) => res.id === resolution.id)
          ) &&
          !SPECIAL_RESOLUTION_CODES.includes(resolution.code) &&
          resolution?.parent === selectedResolution &&
          !commonResolutions.find(
            (commonResolution) => commonResolution.code === resolution.code
          )
        ) {
          commonResolutions.push(resolution);
        }
      });
    });
    return (
      commonResolutions?.map((resolution) => ({
        value: `${resolution.id}`,
        label: resolution.name,
      })) || []
    );
  }

  function getSubChildResolutions() {
    const commonResolutions = [];
    tasks.forEach((task) => {
      task.resolutions.forEach((resolution) => {
        if (
          tasks.every((task) =>
            task.resolutions.some((res) => res.id === resolution.id)
          ) &&
          !SPECIAL_RESOLUTION_CODES.includes(resolution.code) &&
          resolution?.parent === selectedChildResolution &&
          !commonResolutions.find(
            (commonResolution) => commonResolution.code === resolution.code
          )
        ) {
          commonResolutions.push(resolution);
        }
      });
    });
    return (
      commonResolutions?.map((resolution) => ({
        value: `${resolution.id}`,
        label: resolution.name,
      })) || []
    );
  }

  function onFormSubmit(event) {
    event.preventDefault();
    onHide();
    if (selectedChildResolution > -1) {
      onResolve(selectedChildResolution);
    } else {
      onResolve(selectedResolution);
    }
  }

  return (
    <Modal
      show={visible}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <form onSubmit={onFormSubmit}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {intl.formatMessage({ id: 'tasks.resolveInBulk' })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              id="task_resolution"
              placeholder={intl.formatMessage({ id: 'tasks.selectResolution' })}
              onChange={(e: any) =>
                setSelectedResolution(parseInt(e.value, 10))
              }
              options={getParentResolutions()}
            />
            {getChildResolutions().length > 0 && (
              <>
                <label
                  htmlFor="task_child_resolution"
                  className="fw-bolder mt-4 mb-1"
                >
                  {intl.formatMessage({ id: 'tasks.selectChildResolution' })}
                </label>
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  id="task_child_resolution"
                  placeholder={intl.formatMessage({
                    id: 'tasks.selectResolution',
                  })}
                  onChange={(e: any) =>
                    setSelectedChildResolution(parseInt(e.value, 10))
                  }
                  options={getChildResolutions()}
                />
              </>
            )}
            {getSubChildResolutions().length > 0 && (
              <>
                <label
                  htmlFor="task_sub_child_resolution"
                  className="fw-bolder mt-4 mb-1"
                >
                  {intl.formatMessage({ id: 'tasks.selectSubChildResolution' })}
                </label>
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  id="task_sub_child_resolution"
                  placeholder={intl.formatMessage({
                    id: 'tasks.selectResolution',
                  })}
                  onChange={(e: any) =>
                    setSelectedSubChildResolution(parseInt(e.value, 10))
                  }
                  options={getSubChildResolutions()}
                />
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="tertiary" onClick={onHide}>
            {intl.formatMessage({ id: 'tasks.cancel' })}
          </Button>
          <Button
            disabled={
              selectedResolution === -1 ||
              (getChildResolutions().length > 0 &&
                selectedChildResolution === -1) ||
              (getSubChildResolutions().length > 0 &&
                selectedSubChildResolution === -1)
            }
            variant="primary"
            className="ms-10"
            type="submit"
          >
            {intl.formatMessage(
              { id: 'tasks.resolve' },
              { count: tasks?.length }
            )}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as orderRedux from '../redux/OrderRedux';
import { currentUserSelector } from '../../user-profile';
import DeliveryChangeModal from './DeliveryChangeModal';
import MergeOrdersModal from './MergeOrdersModal';
import { authUserSelector } from '../../auth';
import { generateInvoice, OrderStateEnum, STATES_WITH_INVOICE } from '..';
import superUsers from '../../tasks/constants/superUsers';

export default function OrderActions() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [deliveryChangeModalVisible, setDeliveryChangeModalVisible] =
    useState(false);
  const [mergeOrdersModalVisible, setMergeOrdersModalVisible] = useState(false);
  const [isReship, setIsReship] = useState(false);
  const [loadingOrderInvoice, setLoadingOrderInvoice] = useState(null);
  const status = useSelector(orderRedux.orderStatusSelector);
  const error = useSelector(orderRedux.orderErrorSelector);
  const orders = useSelector(orderRedux.ordersSelector);
  const selectedOrder = useSelector(orderRedux.selectedOrderSelector);
  const cancelLoading = status === 'loading_cancel_selected_order';
  const refreshPricesLoading =
    status === 'loading_refresh_prices_selected_order';
  const lockLoading = status === 'loading_lock_selected_order';
  const lockPendingPaymentLoading =
    status === 'loading_lock_pending_payment_selected_order';
  const processLoading = status === 'loading_process_selected_order';
  const undeliverLoading = status === 'loading_undeliver_selected_order';
  const completeLoading = status === 'loading_complete_selected_order';
  const reshipLoading = status === 'loading_reship_selected_order';
  const markAsWithIncidenceLoading =
    status === 'loading_mark_as_with_incidence_selected_order';
  const forceLockLoading = status === 'loading_force_lock_selected_order';
  const currentUser = useSelector(currentUserSelector);
  const authUser = useSelector(authUserSelector);

  useEffect(() => {
    if (
      status.includes('success_') &&
      status.includes('selected_order') &&
      !status.includes('update') &&
      status !== 'success_selected_order'
    ) {
      toast.success(intl.formatMessage({ id: `orderDetail.${status}` }));
    }
    if (status.includes('error_') && status.includes('selected_order')) {
      toast.error(error || intl.formatMessage({ id: `orderDetail.${status}` }));
    }
  }, [status, error]);

  async function onGenerateInvoice(order) {
    try {
      setLoadingOrderInvoice(order.id);

      if (order?.invoice_url?.length > 0) {
        window.open(order.invoice_url, '_blank');
        toast.success(
          intl.formatMessage({ id: 'operationOrders.invoiceGenerated' })
        );
        return;
      }

      const { data } = await generateInvoice(order.id);

      if (data?.pdf_file_url) {
        window.open(data.pdf_file_url, '_blank');
        toast.success(
          intl.formatMessage({ id: 'operationOrders.invoiceGenerated' })
        );
      } else {
        throw new Error('No PDF file URL returned');
      }
    } catch (error) {
      console.warn({ error });
      toast.error(intl.formatMessage({ id: 'operationOrders.invoiceError' }));
    } finally {
      setLoadingOrderInvoice(null);
    }
  }

  return (
    <>
      <section className="card p-10">
        <h1 className="mb-8">
          {intl.formatMessage({ id: 'orderDetail.actionsTitle' })}
        </h1>
        <div className="d-flex flex-column gap-4">
          {selectedOrder?.state !== 'cancelled' &&
            selectedOrder?.state !== 'completed' && (
              <button
                disabled={status !== 'idle'}
                className="btn btn-danger"
                onClick={() =>
                  dispatch(orderRedux.actions.cancelSelectedOrder())
                }
                data-kt-users-modal-action="submit"
                data-kt-indicator={cancelLoading ? 'on' : 'off'}
              >
                <span className="indicator-label">
                  <span className="fas fa-times-circle me-3" />
                </span>
                <span className="indicator-progress">
                  <span className="spinner-border spinner-border-sm align-middle me-3"></span>
                </span>
                {intl.formatMessage({ id: 'orderDetail.cancelOrder' })}
              </button>
            )}
          {selectedOrder?.state === 'processing' && (
            <button
              disabled={status !== 'idle'}
              className="btn btn-warning text-dark"
              onClick={() =>
                dispatch(orderRedux.actions.undeliverSelectedOrder())
              }
              data-kt-indicator={undeliverLoading ? 'on' : 'off'}
            >
              <span className="indicator-label">
                <span className="fas fa-user-slash me-3" />
              </span>
              <span className="indicator-progress">
                <span className="spinner-border spinner-border-sm align-middle me-3"></span>
              </span>
              {intl.formatMessage({ id: 'orderDetail.undeliverOrder' })}
            </button>
          )}
          {['processing', 'completed'].includes(selectedOrder?.state) && (
            <a
              aria-disabled={status !== 'idle'}
              className="btn btn-warning text-dark"
              onClick={() =>
                dispatch(orderRedux.actions.markSelectedOrderAsWithIncidence())
              }
              data-kt-indicator={markAsWithIncidenceLoading ? 'on' : 'off'}
            >
              <span className="fas fa-exclamation-circle me-3 " />
              {intl.formatMessage({ id: 'orderDetail.markAsWithIncidence' })}
            </a>
          )}
          {superUsers.includes(authUser?.email) &&
            ['ready-to-ship', 'processing', 'picking'].includes(
              selectedOrder?.state
            ) && (
              <button
                disabled={status !== 'idle'}
                className="btn btn-warning text-dark"
                onClick={() =>
                  dispatch(orderRedux.actions.forceLockSelectedOrder())
                }
                data-kt-indicator={forceLockLoading ? 'on' : 'off'}
              >
                <span className="indicator-label">
                  <span className="fas fa-lock me-3" />
                </span>
                <span className="indicator-progress">
                  <span className="spinner-border spinner-border-sm align-middle me-3"></span>
                </span>
                {intl.formatMessage({ id: 'orderDetail.forceLock' })}
              </button>
            )}
          {['confirmed', 'locked'].includes(selectedOrder?.state) && (
            <button
              disabled={status !== 'idle'}
              className="btn btn-warning text-dark"
              onClick={() => {
                setIsReship(false);
                setDeliveryChangeModalVisible(true);
              }}
            >
              <span className="indicator-label">
                <span className="fa fa-edit me-3" />
              </span>
              {intl.formatMessage({ id: 'orderDetail.registerDeliveryChange' })}
            </button>
          )}
          {['processing', 'completed'].includes(selectedOrder?.state) && (
            <button
              disabled={status !== 'idle'}
              className="btn btn-secondary text-dark"
              onClick={() => {
                setIsReship(true);
                setDeliveryChangeModalVisible(true);
              }}
              data-kt-indicator={reshipLoading ? 'on' : 'off'}
            >
              <span className="indicator-label">
                <span className="fas fa-redo me-3" />
              </span>
              <span className="indicator-progress">
                <span className="spinner-border spinner-border-sm align-middle me-3"></span>
              </span>
              {intl.formatMessage({ id: 'orderDetail.reshipOrder' })}
            </button>
          )}
          {selectedOrder?.is_modifyable && (
            <button
              disabled={status !== 'idle'}
              className="btn btn-secondary"
              onClick={() =>
                dispatch(orderRedux.actions.refreshSelectedOrderPrices())
              }
              data-kt-users-modal-action="submit"
              data-kt-indicator={refreshPricesLoading ? 'on' : 'off'}
            >
              <span className="indicator-label">
                <span className="fas fa-money-bill-wave me-3" />
              </span>
              <span className="indicator-progress">
                <span className="spinner-border spinner-border-sm align-middle me-3"></span>
              </span>
              {intl.formatMessage({ id: 'orderDetail.refreshPrices' })}
            </button>
          )}
          {selectedOrder?.state === 'confirmed' && (
            <button
              disabled={status !== 'idle'}
              className="btn btn-primary"
              onClick={() => dispatch(orderRedux.actions.lockSelectedOrder())}
              data-kt-indicator={lockLoading ? 'on' : 'off'}
            >
              <span className="indicator-label">
                <span className="fas fa-lock me-3" />
              </span>
              <span className="indicator-progress">
                <span className="spinner-border spinner-border-sm align-middle me-3"></span>
              </span>
              {intl.formatMessage({ id: 'orderDetail.lockOrder' })}
            </button>
          )}
          {['proposal', 'payment-hold', 'confirmed'].includes(
            selectedOrder?.state
          ) &&
            superUsers.includes(authUser?.email) && (
              <button
                disabled={status !== 'idle'}
                className="btn btn-warning text-dark"
                onClick={() =>
                  dispatch(orderRedux.actions.lockPendingPaymentSelectedOrder())
                }
                data-kt-indicator={lockPendingPaymentLoading ? 'on' : 'off'}
              >
                <span className="indicator-label">
                  <span className="fa fa-lock me-3" />
                </span>
                <span className="indicator-progress">
                  <span className="spinner-border spinner-border-sm align-middle me-3"></span>
                </span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({
                      id: 'orderDetail.lockPendingPaymentOrder',
                    }),
                  }}
                />
              </button>
            )}

          {['locked', 'ready-to-ship'].includes(selectedOrder?.state) && (
            <button
              disabled={status !== 'idle'}
              className="btn btn-primary"
              onClick={() =>
                dispatch(orderRedux.actions.processSelectedOrder())
              }
              data-kt-indicator={processLoading ? 'on' : 'off'}
            >
              <span className="indicator-label">
                <span className="fas fa-check-circle me-3" />
              </span>
              <span className="indicator-progress">
                <span className="spinner-border spinner-border-sm align-middle me-3"></span>
              </span>
              {intl.formatMessage({ id: 'orderDetail.processOrder' })}
            </button>
          )}
          {selectedOrder?.state === 'locked' && (
            <button
              disabled={status !== 'idle'}
              className="btn btn-primary"
              onClick={() =>
                dispatch(orderRedux.actions.markSelectedOrderAsReadyToShip())
              }
            >
              <span className="indicator-label">
                <span className="fas fa-truck me-3" />
              </span>
              {intl.formatMessage({ id: 'orderDetail.markAsReadyToShip' })}
            </button>
          )}
          {selectedOrder?.state === 'locked' && (
            <button
              disabled={status !== 'idle'}
              className="btn btn-primary"
              onClick={() => setMergeOrdersModalVisible(true)}
            >
              <span className="indicator-label">
                <span className="fas fa-compress-alt me-3" />
              </span>
              {intl.formatMessage({ id: 'orderDetail.mergeOrders' })}
            </button>
          )}
          {currentUser?.id && selectedOrder?.id && (
            <a
              href={`/user/${currentUser?.id}/order/${selectedOrder?.id}/logs`}
              className="btn btn-secondary"
            >
              <span className="fas fa-history me-3" />
              {intl.formatMessage({ id: 'logs.seeLogs' })}
            </a>
          )}
          {selectedOrder?.state === 'processing' && (
            <button
              disabled={status !== 'idle'}
              className="btn btn-primary"
              onClick={() =>
                dispatch(orderRedux.actions.completeSelectedOrder())
              }
              data-kt-indicator={completeLoading ? 'on' : 'off'}
            >
              <span className="indicator-label">
                <span className="fas fa-check-circle me-3" />
              </span>
              <span className="indicator-progress">
                <span className="spinner-border spinner-border-sm align-middle me-3"></span>
              </span>
              {intl.formatMessage({ id: 'orderDetail.completeOrder' })}
            </button>
          )}
          {STATES_WITH_INVOICE.includes(
            selectedOrder?.state as OrderStateEnum
          ) && (
            <button
              disabled={status !== 'idle'}
              className="btn btn-primary"
              onClick={() => onGenerateInvoice(selectedOrder)}
              data-kt-indicator={loadingOrderInvoice ? 'on' : 'off'}
            >
              <span className="indicator-label">
                <span className="fas fa-file-alt me-3" />
              </span>
              <span className="indicator-progress">
                <span className="spinner-border spinner-border-sm align-middle me-3"></span>
              </span>
              {intl.formatMessage({ id: 'orderDetail.generateInvoice' })}
            </button>
          )}
        </div>
      </section>
      <DeliveryChangeModal
        isReship={isReship}
        order={selectedOrder}
        visible={deliveryChangeModalVisible}
        onClose={() => setDeliveryChangeModalVisible(false)}
      />
      <MergeOrdersModal
        order={selectedOrder}
        orders={orders?.results?.filter(
          (order) => order.id !== selectedOrder?.id
        )}
        visible={mergeOrdersModalVisible}
        onClose={() => setMergeOrdersModalVisible(false)}
      />
    </>
  );
}

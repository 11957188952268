import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RootState } from '../../../../setup';

import { PlanModel } from '../models/PlanModel';
import * as planRedux from '../redux/PlanRedux';
import Select from 'react-select';

export default function PlanDiscounts() {
  const intl = useIntl();
  const selectedPlan: PlanModel | undefined = useSelector(
    (state: RootState) => state.plan.selectedPlan,
    shallowEqual
  );
  const status: string = useSelector(
    planRedux.planStatusSelector,
    shallowEqual
  );
  const isAddingDiscount = status === 'loading_add_discount';
  const isLoading = status === 'loading_selected_plan' || isAddingDiscount;
  const discounts = selectedPlan?.available_discounts;
  const coupons = selectedPlan?.current_coupons;
  const [selectedDiscount, setSelectedDiscount] = useState<{
    value: number;
    label: string;
  }>(undefined);
  const [expirationDate, setExpirationDate] = useState<string>(undefined);
  const [removingDiscount, setRemovingDiscount] = useState<number>(undefined);
  const dispatch = useDispatch();

  function addDiscount() {
    dispatch(
      planRedux.actions.addDiscount(selectedDiscount.value, expirationDate)
    );
  }

  function removeDiscount(discountId) {
    setRemovingDiscount(discountId);
    dispatch(planRedux.actions.removeDiscount(discountId));
  }

  useEffect(() => {
    switch (status) {
      case 'error_remove_discount': {
        setRemovingDiscount(undefined);
        break;
      }
      case 'success_remove_discount': {
        setRemovingDiscount(undefined);
        toast.success(
          intl.formatMessage({ id: 'planDetail.removeDiscountSuccessful' })
        );
        break;
      }
      case 'success_add_discount': {
        setSelectedDiscount(undefined);
        setExpirationDate(undefined);
        toast.success(
          intl.formatMessage({ id: 'planDetail.addDiscountSuccessful' })
        );
        break;
      }
      case 'error_add_discount': {
        setSelectedDiscount(undefined);
        setExpirationDate(undefined);
        break;
      }
    }
  }, [status]);

  function getDiscounts() {
    return (
      discounts
        .map((discount) => ({
          value: discount.id,
          label: `${discount.name} (${
            discount.mode === 'absolute'
              ? `${discount.discount}€`
              : `${Math.floor(discount.discount * 100)}%`
          })`,
        }))
        ?.sort((a, b) => a.label.localeCompare(b.label)) || []
    );
  }

  return (
    <section className="card p-10">
      <h1 className="mb-8">
        {intl.formatMessage({ id: 'planDetail.planDiscounts' })}
      </h1>
      {isLoading && (
        <div>
          <div className="spinner-border text-primary" role="status" />
        </div>
      )}
      {!isLoading && !coupons?.length && (
        <p>{intl.formatMessage({ id: 'planDetail.noDiscounts' })}</p>
      )}
      {!isLoading && coupons?.length > 0 && (
        <table className="table table-hover table-row-dashed table-row-gray-200 align-middle gs-0 gy-4 mb-10">
          <thead className="table-header">
            <tr>
              <th className="ps-4">
                {intl.formatMessage({ id: 'planDetail.discountName' })}
              </th>
              <th>{intl.formatMessage({ id: 'planDetail.discountValue' })}</th>
              <th>{intl.formatMessage({ id: 'planDetail.discountStatus' })}</th>
              <th>{intl.formatMessage({ id: 'planDetail.expirationDate' })}</th>
              <th>{intl.formatMessage({ id: 'planDetail.remove' })}</th>
            </tr>
          </thead>
          <tbody>
            {coupons?.map((coupon) => (
              <tr key={coupon.id}>
                <td className="ps-4">{coupon.discount.name}</td>
                <td>
                  {coupon.discount.mode === 'absolute'
                    ? `${coupon.discount.discount}€`
                    : `${Math.floor(coupon.discount.discount * 100)}%`}
                </td>
                <td>{coupon.discount.state}</td>
                <td>{coupon?.expires_at || '-'}</td>
                <td>
                  <button
                    style={{ pointerEvents: 'all' }}
                    className={clsx('btn btn-sm')}
                    onClick={() => removeDiscount(coupon.id)}
                    disabled={!!removingDiscount}
                  >
                    {removingDiscount === coupon.id ? (
                      <span className="spinner spinner-border spinner-border-sm" />
                    ) : (
                      <i className="fas fa-trash" />
                    )}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {discounts?.length > 0 && (
        <div>
          <div className="form-group d-flex gap-4 mb-4 ">
            <div className="flex-grow-1">
              <label className="form-label  w-200px flex-shrink-1">
                {intl.formatMessage({ id: 'planDetail.addNewDiscount' })}
              </label>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                options={getDiscounts()}
                value={selectedDiscount}
                onChange={setSelectedDiscount}
                placeholder={intl.formatMessage({ id: 'planDetail.select' })}
              />
            </div>
            <div className="w-200px">
              <label className="form-label ">
                {intl.formatMessage({ id: 'planDetail.expirationDate' })}
              </label>
              <input
                type="date"
                className="form-control pt-3 pb-2"
                onChange={(e) => setExpirationDate(e.target.value)}
                value={expirationDate}
              />
            </div>
          </div>
          <button
            className="btn btn-primary"
            onClick={addDiscount}
            data-kt-indicator={isAddingDiscount ? 'on' : 'off'}
            data-kt-users-modal-action="submit"
            disabled={isAddingDiscount || !selectedDiscount}
          >
            <span className="indicator-label">
              <span className="fas fa-plus me-4" />
              {intl.formatMessage({ id: 'planDetail.addDiscount' })}
            </span>
            <span className="indicator-progress">
              <span className="spinner spinner-border spinner-border-sm me-4" />
              {intl.formatMessage({ id: 'planDetail.addingDiscount' })}
            </span>
          </button>
        </div>
      )}
    </section>
  );
}

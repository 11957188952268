import { FC } from 'react';
import { useIntl } from 'react-intl';

import { PageTitle } from '../../../_metronic/layout/core';
import MealPricing from '../../modules/meal-pricing/components/MealPricing';

const MealPricingPage: FC = () => {
  const intl = useIntl();

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'mealPricing.title' })}
      </PageTitle>
      <div className="row gy-4 mw-700px">
        <div className="card p-10">
          <MealPricing />
        </div>
      </div>
    </>
  );
};

export { MealPricingPage };

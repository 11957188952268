import { shallowEqual, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { PetModel } from '../models/PetModel';
import { PlanTable } from '../../plans';
import { selectedPetSelector } from '../redux/PetRedux';
import { useEffect, useState } from 'react';
import * as planRedux from '../../plans/redux/PlanRedux';

export default function PetPlans() {
  const intl = useIntl();
  const selectedPet: PetModel | undefined = useSelector(
    selectedPetSelector,
    shallowEqual
  );
  const planStatus: string = useSelector(
    planRedux.planStatusSelector,
    shallowEqual
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (planStatus === 'loading_list_pet_plans') {
      setLoading(true);
    }
    if (
      planStatus === 'success_list_pet_plans' ||
      planStatus === 'error_list_pet_plans'
    ) {
      setLoading(false);
    }
  }, [planStatus]);

  return (
    <section className="card p-10">
      <h1 className="mb-8">
        {intl.formatMessage(
          { id: 'pets.petPlans' },
          { petName: selectedPet?.name || '-' }
        )}
      </h1>
      {loading ? (
        <div className="spinner-border text-primary" role="status" />
      ) : (
        <PlanTable selectedPetOnly={true} />
      )}
    </section>
  );
}
